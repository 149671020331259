import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import {
  TalentManagementColKey,
  TalentManagementTab,
  type TalentManagementSortColKey,
} from '../types'

interface PageSorting {
  order: 'ASC' | 'DESC'
  by: TalentManagementSortColKey
}

interface PageConfig {
  pageSize: number
  pageNumber: number
}

interface TabPagination {
  pageSize: number
  pageNumber: number
}
interface TabSorting<SortKey extends string> {
  order: 'ASC' | 'DESC'
  by: SortKey
}

interface TabState<SortKey extends string> {
  pagination: TabPagination
  sorting: TabSorting<SortKey>
}

interface TabsState {
  [TalentManagementTab.Active]: TabState<TalentManagementSortColKey>
  [TalentManagementTab.Archive]: TabState<TalentManagementSortColKey>
}

interface PageState {
  activeTab: TalentManagementTab
  tabs: TabsState
  pageConfig: PageConfig
  sorting: PageSorting
}

const sortingInitialState: PageSorting = {
  order: 'ASC',
  by: TalentManagementColKey.FullName,
}

const pageConfigInitialState: PageConfig = {
  pageSize: 50,
  pageNumber: 1,
}
const eventsTabInitialState: TabState<TalentManagementSortColKey> = {
  pagination: pageConfigInitialState,
  sorting: sortingInitialState,
}
const tabsInitialState: TabsState = {
  [TalentManagementTab.Active]: eventsTabInitialState,
  [TalentManagementTab.Archive]: eventsTabInitialState,
}
const initialState: PageState = {
  activeTab: TalentManagementTab.Active,
  tabs: tabsInitialState,
  pageConfig: pageConfigInitialState,
  sorting: sortingInitialState,
}
function updateTabState<
  T extends TalentManagementTab,
  K extends keyof TabsState[T],
>(state: Draft<PageState>, tab: T, key: K, config: TabsState[T][K]) {
  state.tabs[tab][key] = config
}

export const talentManagementSlice = createSlice({
  name: 'talentManagement',
  initialState,
  reducers: {
    setPageConfig: (state, action: PayloadAction<PageState['pageConfig']>) => {
      state.pageConfig = action.payload
    },
    setActiveTab: (state, action: PayloadAction<PageState['activeTab']>) => {
      state.activeTab = action.payload
    },
    setSorting: (state, action: PayloadAction<Partial<PageSorting>>) => {
      state.sorting = {
        ...sortingInitialState,
        ...action.payload,
      }
    },
  },
  selectors: {
    root: state => state,
    activeTab: state => state.activeTab,
    archiveTab: state => state.tabs[TalentManagementTab.Archive],
  },
})

export const {
  actions: talentManagementActions,
  selectors: talentManagementSelectors,
} = talentManagementSlice
