import { ReleaseCalendarEventColKey } from '../../types'
import { type ReleaseCalendarEventCol } from './types'

export const columns: ReleaseCalendarEventCol[] = [
  {
    key: ReleaseCalendarEventColKey.Title,
    sortKey: ReleaseCalendarEventColKey.Title,
    title: 'Event Title',
  },
  {
    key: ReleaseCalendarEventColKey.StartDate,
    sortKey: ReleaseCalendarEventColKey.StartDate,
    title: 'Start Date',
  },
  {
    key: ReleaseCalendarEventColKey.EndDate,
    sortKey: ReleaseCalendarEventColKey.EndDate,
    title: 'End Date',
  },

  { key: ReleaseCalendarEventColKey.EditEntry, width: 48 },
]
