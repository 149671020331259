import CryptoJS from 'crypto-js'
import { ChangeEvent, FormEvent, useState } from 'react'
import { BaseModal, Button, ValidationIndicator } from 'components'
import { useNavigate } from 'react-router-dom'
import { Input } from '../ui'
import { updateUserPassword } from '../../utils/amplify/auth-amplify'
import { set } from 'lodash'

export interface EditPasswordModalProps {
  isOpen: boolean
  handleClose: () => void
  title: string
  description: string
  onSubmit: () => void
  cancelTitle?: string
  saveTitle?: string
  maxWidth?: string
  handleSave?: () => void
  modalZIndex?: number
}

const validatePassword = (password: string) => ({
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasNumber: /\d/.test(password),
  hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  hasMinLength: password?.length >= 8,
})
// TBD : discuss and fix this complete flow.
export const EditPasswordModal = ({
  isOpen,
  handleClose,
  title,
  description,
  onSubmit,
  cancelTitle = 'Cancel',
  saveTitle = 'Save',
  maxWidth,
  modalZIndex,
}: EditPasswordModalProps) => {
  const navigate = useNavigate()
  const [validation, setValidation] = useState(validatePassword(''))
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [isDifferentPasswords, setIsDifferentPasswords] = useState(false)
  const [isOldPasswordWrong, setIsOldPasswordWrong] = useState(false)
  const [isNewPasswordWrong, setIsNewPasswordWrong] = useState(false)
  // TBD: password not handled by the API this should be client side only.
  //  CryptoJS.AES.decrypt(
  //   storedPassword,
  //   localStorage.getItem('access_token'),
  // ).toString(CryptoJS.enc.Utf8)

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target
    if (key === 'oldPassword') {
      setOldPassword(value)
      setIsOldPasswordWrong(value.trim().length === 0)
    } else if (key === 'newPassword') {
      setNewPassword(value)
      setIsNewPasswordWrong(value.trim().length === 0)
      setValidation(prevState => ({
        ...prevState,
        ...validatePassword(value),
      }))
    } else {
      setConfirmedPassword(value)
    }
  }
  const isValidationPassed = (
    validation: ReturnType<typeof validatePassword>,
  ) => {
    return Object.values(validation).every(value => value)
  }
  const validateForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsDifferentPasswords(confirmedPassword !== newPassword)
    setIsOldPasswordWrong(oldPassword.trim().length === 0)
    setIsNewPasswordWrong(newPassword.trim().length === 0)

    const isDifferentPasswords = confirmedPassword !== newPassword
    if (isValidationPassed(validation) && !isDifferentPasswords) {
      await updateUserPassword({ oldPassword, newPassword })
      handleClose()
    }
  }
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      titleClass='pb-2'
      maxWidth={maxWidth}
      modalZIndex={modalZIndex}
    >
      <div className={'mb-5'}>{description}</div>
      <form onSubmit={e => validateForm(e)}>
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='password'
          label='Your old password'
          labelClassName='text-sm font-semibold text-primary-black'
          isError={isOldPasswordWrong}
          error={'Current password can not be empty'}
          onChange={e => handleChange(e, 'oldPassword')}
        />
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='password'
          label='Your new password'
          labelClassName='text-sm font-semibold text-primary-black'
          placeholder={'Minimum 8 characters'}
          isError={isNewPasswordWrong}
          error={'Password is required'}
          onChange={e => handleChange(e, 'newPassword')}
        />
        <div className='pb-4'>
          <ValidationIndicator
            isValid={validation.hasLowercase}
            message='One lowercase character'
          />
          <ValidationIndicator
            isValid={validation.hasUppercase}
            message='One uppercase character'
          />
          <ValidationIndicator
            isValid={validation.hasNumber}
            message='One number'
          />
          <ValidationIndicator
            isValid={validation.hasSpecialChar}
            message='One special character'
          />
          <ValidationIndicator
            isValid={validation.hasMinLength}
            message='8 characters minimum'
          />
        </div>
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='password'
          label='Confirm new password'
          labelClassName='text-sm font-semibold text-primary-black'
          onChange={e => handleChange(e, 'confirmedPassword')}
          isError={isDifferentPasswords}
          error={isDifferentPasswords ? 'Passwords do not match' : ''}
        />
        <div className='flex items-center justify-end'>
          <div className='flex justify-end gap-2'>
            <Button
              kind='text'
              size='medium'
              onClick={handleClose}
              className='py-2.5'
              type={'button'}
            >
              <span className='px-1 text-sm font-semibold'>{cancelTitle}</span>
            </Button>

            <Button
              kind='filled'
              size='medium'
              className='bg-primary-red py-2.5 hover:bg-red-9 active:bg-primary-red/50'
              type='submit'
            >
              <span className='px-2 text-sm font-semibold'>{saveTitle}</span>
            </Button>
          </div>
        </div>
      </form>
    </BaseModal>
  )
}
