import { Button, ChevronLeft } from 'components'
import { Colors } from 'constnants'
import { useNavigate } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

interface TalentUpdateToolbarProps {
  title: string
  isActive: boolean
  onStatusChange: () => void
}

export const TalentUpdateToolbar = ({
  title,
  isActive = true,
  onStatusChange,
}: TalentUpdateToolbarProps) => {
  const navigate = useNavigate()
  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <div className='flex items-center gap-1'>
        <ChevronLeft
          fill={Colors.BASE_ICON}
          className='cursor-pointer'
          width={16}
          height={16}
          onClick={() => navigate(-1)}
        />
        <h1 className={'mr-12 text-2xl font-semibold text-primary-black'}>
          {title}
        </h1>
      </div>

      <div className='flex items-center gap-4'>
        <Button
          kind='text'
          size='medium'
          onClick={onStatusChange}
          className={twMerge(
            'py-2.5 text-white',
            isActive ? 'bg-red-10' : 'bg-primary-grey',
          )}
          type='button'
        >
          <span className='px-1 text-sm font-semibold'>
            {isActive ? 'Archive Talent' : 'Activate Talent'}
          </span>
        </Button>
      </div>
    </div>
  )
}
