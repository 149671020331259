import { OptionType } from 'types'
import { Gender, Race } from '../types'
import { mapValuesToOptions } from 'utils'
import { genderLabelByType, raceLabelByType } from './talentLabels'

export const genderOptions: OptionType<Gender>[] = mapValuesToOptions(
  Gender,
  genderLabelByType,
)
export const raceOptions: OptionType<Race>[] = mapValuesToOptions(
  Race,
  raceLabelByType,
)
