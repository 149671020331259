import React from 'react'
import { twMerge } from 'tailwind-merge'

interface PageLoaderProps {
  hasOpaquePrimaryBg?: boolean
}
const PageLoader = ({ hasOpaquePrimaryBg = false }: PageLoaderProps) => {
  return (
    <div
      className={twMerge(
        'bg-opacity fixed inset-0 z-[52] flex items-center justify-center bg-black/40',
        hasOpaquePrimaryBg && 'bg-primary-blue',
      )}
    >
      <div
        className='h-16 w-16 animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-blue-500'
        style={{
          borderWidth: '3px', // Custom border width
          borderColor: '#f3f3f3', // Default color for other sides
          borderTopColor: 'var(--Icon-Colored-icon-brand, #FF4449)', // Custom border top color
        }}
      ></div>
    </div>
  )
}

export default PageLoader
