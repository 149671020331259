import { Key, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import {
  LayoutContentWrapper,
  Subheader,
  Movie,
  Tabs,
  Tab,
  MyRoleList,
  EditRoleModalContent,
  ProjectDetailsEmptyContent,
  MyProjectLoglineList,
  AddOrEditLoglineModalContent,
  MyProjectAssignedUserList,
  AssignUsersToProject,
  AddRoleModalContent,
} from 'components'
import { useActions, useTypedSelector } from 'hooks'
import {
  columnsMyProjectAssignedUsers,
  expectedMpaaRating,
  newProjectRoleOptions,
  primaryGenre,
  releasePatternMenu,
  secondaryGenre,
} from 'constnants'
import {
  useGetProjectCompanyByIdQuery,
  useGetProjectRolesQuery,
} from 'store/api'
import { Logline, ProjectStatus, RoleProject } from 'types'
import { useAuthContext } from '../../context'

export const ProjectDetailPage = () => {
  const userAuthContext = useAuthContext()
  const companyId =
    userAuthContext?.auth?.userAttributes?.['custom:company_id'] || ''
  const [initialSelectTab, setInitialSelectTab] = useState<Key | null>('roles')
  const [isOpenAddRoles, setIsOpenAddRoles] = useState<boolean>(false)
  const [isOpenAddLogline, setIsOpenAddLogline] = useState<boolean>(false)
  const [isOpenAddAssignedUser, setIsOpenAddAssignedUser] =
    useState<boolean>(false)
  const [selectedLogline, setSelectedLogline] = useState<Logline | null>(null)
  const { assignedUsers } = useTypedSelector(state => state.myProjectReducer)
  const { id } = useParams()
  const { data, isLoading } = useGetProjectCompanyByIdQuery(
    {
      id: companyId ?? '',
    },
    { skip: !id || !companyId },
  )

  const projectCompanyData =
    data &&
    data.find(project => {
      return Number(project?.company_project_id) === Number(id)
    })
  const { data: projectRoles } = useGetProjectRolesQuery(
    {
      projectId: Number(id),
    },
    { skip: !id },
  )

  const genresPreparing = (primary: string, secondary: string[]) => {
    const primaryOptions = primaryGenre.find(({ id }) => id === primary)
    const secondaryOptions = secondaryGenre.filter(genreItem => {
      return secondary?.some(secondaryId => secondaryId === genreItem.id)
    })

    return {
      primaryGenre: primaryOptions?.id,
      secondaryGenre: secondaryOptions.map(opt => opt.id),
    }
  }

  const preparedRoles: RoleProject[] =
    projectRoles?.map(roleData => {
      const roleType = newProjectRoleOptions.find(
        roleValue => Number(roleValue.id) === Number(roleData.role.type),
      ) ?? { id: '', label: '', value: '' }

      const attachedTalent = roleData.talents[0]
        ? {
            id: roleData.talents[0].talentId || '',
            value: roleData.talents[0].talentId || '',
            label: roleData.talents[0].talentName || '',
          }
        : { id: '', value: '', label: '' }

      return {
        roleName: roleData.role.name,
        roleType: {
          id: roleType.id,
          value: roleType.value,
          label: roleType.label,
        },
        description: roleData.role.description,
        attachedTalent: attachedTalent,
        roleId: roleData.role.id,
        talents: roleData.talents.map(talent => ({
          talentId: talent.talentId,
          talentName: talent.talentName,
          profileImage: talent.profileImage,
        })),
      }
    }) ?? []

  const ratingPreparing = (expectedRating: string) => {
    const rating = expectedMpaaRating.find(
      rating => rating.id === expectedRating,
    )

    return {
      id: rating?.id ?? '1',
      value: rating?.name ?? 'PG-13',
      label: rating?.name ?? 'PG-13',
    }
  }

  const patternPreparing = (pattern: string) => {
    const currentPattern = releasePatternMenu.find(
      option => option.id === pattern,
    )

    return currentPattern?.label
  }

  const prepareProjectData = useMemo(() => {
    return {
      projectName: projectCompanyData?.name ?? '',
      projectId: Number(projectCompanyData?.company_project_id) ?? 0,
      descriptions:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. ',
      assignees: [
        { name: 'Daniel H', id: '1' },
        { name: 'Paul Feig', id: '2' },
        { name: 'Owen Wilson', id: '3' },
      ],
      projectStatus:
        projectCompanyData?.phase?.name ?? ProjectStatus.IN_DEVELOPMENT,
      genres: {
        primaryGenre:
          genresPreparing(
            projectCompanyData?.additional_details?.primaryGenre ?? '',
            projectCompanyData?.additional_details?.secondaryGenre ?? [],
          ).primaryGenre ?? '',
        secondaryGenre: genresPreparing(
          projectCompanyData?.additional_details?.primaryGenre ?? '',
          projectCompanyData?.additional_details?.secondaryGenre ?? [],
        ).secondaryGenre,
      },
      rating: ratingPreparing(
        projectCompanyData?.additional_details?.expectedRating || '',
      ),
      runTime: {
        hours: projectCompanyData?.additional_details?.runTime?.hours ?? '',
        minutes: projectCompanyData?.additional_details?.runTime?.minutes ?? '',
      },
      releaseDate: projectCompanyData?.additional_details?.releaseDate ?? '',
      budget: {
        id: projectCompanyData?.budgetId.id ?? 1,
        name: projectCompanyData?.budgetId.name ?? '',
      },
      releasePattern:
        patternPreparing(
          projectCompanyData?.additional_details.releasePattern ?? '',
        ) ?? '',
      director: projectCompanyData?.additional_details.expectedDirector ?? '',
      writer: projectCompanyData?.additional_details.expectedWriter ?? '',
      cast: ['Owen Wilson', 'Rupert Friend', 'Bill Hader'],
    }
  }, [projectCompanyData])

  const handleOpenEditLogline = (logline: Logline) => {
    setSelectedLogline(logline)
  }

  const handleSelectTab = (tab: Key | null) => {
    setInitialSelectTab(tab)
  }

  const preparedLoglines: Logline[] = Object.entries(
    projectCompanyData?.log_lines || {},
  )
    .map(([key, logline]) => ({
      id: logline.id,
      name: logline.name,
      lastEdited: logline.lastEdited,
      myLogline: logline.myLogline,
      isActive: logline.isActive,
    }))
    .filter(logline => logline.myLogline?.trim())

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-grey-4 overflow-y-auto'
      wrapperChildrenClassName='flex flex-col'
    >
      <Subheader containerClassName='bg-grey-3 h-[41px]' />
      <Movie project={prepareProjectData} isLoading={isLoading} />

      <Tabs
        initialSelectedTab={initialSelectTab}
        handleSelectTab={handleSelectTab}
        tabHeaderClasses='w-2/4 bg-primary-white'
        wrapperClasses='w-full bg-primary-white grow'
      >
        <Tab
          key='roles'
          label='Roles'
          headerClasses='text-sm font-medium normal-case bg-primary-white hover:bg-trasnparent px-10'
          activeTabHeaderClasses='border-b-2 border-b-primary-blue z-10'
          wrapperClasses='grow border-t-grey-5 -mt-[2px] border-t-2'
        >
          {!preparedRoles.length ? (
            <ProjectDetailsEmptyContent
              onOpen={() => setIsOpenAddRoles(true)}
              nameButton='New Role'
              title='You don’t have any Roles for this project yet'
              descriptions='Use your Projects roles in audience surveys, and power
              up your Talent pod by creating custom lists for them.'
            />
          ) : (
            <MyRoleList
              roles={preparedRoles}
              onOpen={() => setIsOpenAddRoles(true)}
              projectId={Number(id)}
            />
          )}
          <AddRoleModalContent
            isOpen={isOpenAddRoles}
            onClose={() => setIsOpenAddRoles(false)}
            projectId={Number(id)}
          />
        </Tab>
        <Tab
          key='loglines'
          label='Loglines'
          headerClasses='text-sm font-medium normal-case bg-primary-white hover:bg-trasnparent px-10'
          activeTabHeaderClasses='border-b-2 border-b-primary-blue z-10'
          wrapperClasses='grow border-t-grey-5 -mt-[2px] border-t-2'
        >
          {!preparedLoglines.length ? (
            <ProjectDetailsEmptyContent
              onOpen={() => setIsOpenAddLogline(true)}
              nameButton='New Logline'
              title='You don’t have any logline for this project yet'
              descriptions='Use your Projects loglines in audience surveys, and create multiple versions to see which perform better with viewers.'
            />
          ) : (
            <MyProjectLoglineList
              loglines={preparedLoglines}
              onOpen={() => {
                setIsOpenAddLogline(true)
              }}
              handleOpenEditLogline={handleOpenEditLogline}
              selectedLogline={selectedLogline}
              preparedLoglines={preparedLoglines}
              projectName={projectCompanyData?.name}
              projectId={Number(id)}
            />
          )}
          <AddOrEditLoglineModalContent
            isOpen={isOpenAddLogline}
            onClose={() => {
              setIsOpenAddLogline(false)
            }}
            preparedLoglines={preparedLoglines}
            projectId={Number(id)}
            selectedLogline={selectedLogline}
            projectName={projectCompanyData?.name}
          />
        </Tab>
        <Tab
          key='users'
          label='Users'
          headerClasses='text-sm font-medium normal-case bg-primary-white hover:bg-trasnparent px-10'
          activeTabHeaderClasses='border-b-2 border-b-primary-blue z-10'
          wrapperClasses='grow border-t-grey-5 -mt-[2px] border-t-2'
        >
          {!assignedUsers.length ? (
            <ProjectDetailsEmptyContent
              onOpen={() => setIsOpenAddAssignedUser(true)}
              nameButton='New User'
              title='You don’t have any user for this project yet'
              descriptions='Use your Projects user in audience surveys, and create multiple versions to see which perform better with viewers.'
            />
          ) : (
            <MyProjectAssignedUserList
              columns={columnsMyProjectAssignedUsers}
              data={assignedUsers}
              onOpen={() => {
                setIsOpenAddAssignedUser(true)
              }}
            />
          )}
          <AssignUsersToProject
            isOpen={isOpenAddAssignedUser}
            onClose={() => setIsOpenAddAssignedUser(false)}
          />
        </Tab>
      </Tabs>
    </LayoutContentWrapper>
  )
}
