import { BaseModal } from 'components'

import { ToggleHookState, ToggleHookStateControls } from 'hooks'
import { useCallback } from 'react'
import { useUpdateTVManagementMutation } from 'store/api/mock-api'
import { TVManagementData } from '../../types'
import {
  TVManagementForm,
  type TVManagementFormData,
} from '../TVManagementForm'

interface TVManagementEditDialogProps {
  dialogState: ToggleHookState<TVManagementData>
  dialogStateControls: ToggleHookStateControls<TVManagementData>
}

export const TVManagementEditDialog = ({
  dialogState,
  dialogStateControls,
}: TVManagementEditDialogProps) => {
  const [updateEvent] = useUpdateTVManagementMutation()

  const { id: eventId } = dialogState.data ?? {}

  const handleSubmit = useCallback(
    async (updates: TVManagementFormData) => {
      if (!eventId) {
        return
      }
      try {
        await updateEvent({ eventId, updates })
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [eventId, dialogStateControls, updateEvent],
  )

  return (
    <>
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'Edit Company Details'}
        maxWidth='450px'
      >
        <TVManagementForm
          submitButtonLabel='Save'
          defaultValues={dialogState.data}
          onSubmit={handleSubmit}
          onClose={dialogStateControls.deactivate}
        />
      </BaseModal>
    </>
  )
}
