import { useNavigate } from 'react-router-dom'
import { MgmtLayout } from 'modules/management/shared'
import { TalentCreationToolbar, TalentManagementForm } from '../../components'
import { useAddTalentManagementMutation } from 'store/api/mock-api'
import { useCallback } from 'react'
import { TalentManagementFormData } from '../../components/TalentManagementForm/TalentManagementFormSchema'
import { toInches } from 'utils'

export const CreateTalentPage = () => {
  const navigate = useNavigate()
  const closeTalentCreation = () => navigate('/talentManagement')

  const [createTalent] = useAddTalentManagementMutation()

  const handleSubmit = useCallback(
    async (data: TalentManagementFormData) => {
      try {
        const talentData = {
          ...data,
          height: toInches(data.height.feet, data.height.inches),
        }

        await createTalent(talentData)
      } finally {
        closeTalentCreation()
      }
    },
    [createTalent, closeTalentCreation],
  )

  return (
    <MgmtLayout>
      <div className='flex h-full flex-col'>
        <TalentCreationToolbar onClose={closeTalentCreation} />
        <div className='flex justify-center align-middle'>
          <TalentManagementForm
            submitButtonLabel='Add Talent'
            onClose={closeTalentCreation}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </MgmtLayout>
  )
}
