import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
} from 'react-hook-form'
import { OptionType } from 'types'
import { Input, Select, Textarea } from 'components'
import { MgmtEntryPageForm } from 'modules/management/shared'

import {
  talentManagementFormResolver,
  TalentManagementFormData,
} from './TalentManagementFormSchema'
import { genderOptions, raceOptions } from '../../config'
import { HeightInput } from './HeightInput'
import { TalentManagementDateInput } from '../TalentManagementDateInput'
import { formatDate } from 'utils'

interface TalentManagementFormProps {
  submitButtonLabel: string
  cancelButtonLabel?: string
  defaultValues?: TalentManagementFormData
  onSubmit: (data: TalentManagementFormData) => void
  onClose: () => void
  onStatusChange: () => void
  isActive: boolean
}

export const TalentManagementForm = ({
  submitButtonLabel,
  cancelButtonLabel,
  defaultValues,
  onSubmit,
  onClose,
  onStatusChange,
  isActive,
}: TalentManagementFormProps) => {
  const form = useForm<TalentManagementFormData>({
    resolver: talentManagementFormResolver,
    defaultValues,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form

  const renderControlledInput = (
    name: keyof TalentManagementFormData,
    label: string,
    isLabelOptional?: boolean,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? undefined}
          inputClassName='h-8 px-3'
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          isLabelOptional={isLabelOptional}
          error={errors[name]?.message}
          isError={!!errors[name]}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )

  const renderControlledTextArea = (
    name: keyof TalentManagementFormData,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Textarea
          {...field}
          value={field.value ?? undefined}
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          error={errors[name]?.message}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )
  const renderControlledHeightInput = (
    name: keyof TalentManagementFormData,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <HeightInput
          {...field}
          value={field.value ?? undefined}
          onChange={e => field.onChange(e)}
          label={label}
          error={errors[name]?.message}
          isError={!!errors[name]}
        />
      )}
    />
  )

  const renderControlledSelect = (
    name: keyof TalentManagementFormData,
    label: string,
    options: OptionType[],
    onChange: (
      filed: ControllerRenderProps<TalentManagementFormData, typeof name>,
      option: OptionType,
    ) => void = (field, option) => field.onChange(option.value),
    isDisabled = false,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          value={field.value ?? undefined}
          size='small'
          alignItems='start'
          errorClass='pt-1.5 text-xs'
          labelClass='pb-1 text-sm font-semibold text-primary-black'
          buttonClass='h-8 items-center whitespace-nowrap'
          hasRadioButton={false}
          label={label}
          isError={!!errors[name]}
          error={errors[name]?.message}
          options={options}
          disabled={isDisabled}
          handleClick={option => onChange(field, option)}
        />
      )}
    />
  )
  const formatEventDate = (date: Date) => formatDate(date, 'MM/DD/YYYY')

  const renderControlledDateInput = (
    name: keyof TalentManagementFormData,
    label: string,
    disabled = false,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <TalentManagementDateInput
            {...field}
            label={label}
            placeholder='mm/dd/yyyy'
            formatDate={formatEventDate}
            error={errors[name]?.message}
            isError={!!errors[name]}
            disabled={disabled}
          />
        )
      }}
    />
  )

  const isLabelOptional = true

  return (
    <FormProvider {...form}>
      <MgmtEntryPageForm
        submitButtonLabel={submitButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
        onStatusChange={onStatusChange}
        isActive={isActive}
        footerClassName='justify-between'
      >
        <div>
          <div className='mb-6 font-bold text-grey-7'>PERSONAL DETAILS</div>
          {renderControlledInput('fullName', 'Full Name')}
          {renderControlledInput('imdbId', 'IMDB ID')}
          {renderControlledDateInput('dob', 'Date of birth')}
          {renderControlledSelect('race', 'Race', raceOptions)}
          {renderControlledSelect('gender', 'Gender', genderOptions)}
          {renderControlledTextArea('bio', 'Bio')}
          {renderControlledHeightInput('height', 'Height')}
        </div>
        <div>
          <hr className='my-8' />

          <div className='mb-6 font-bold text-grey-7'>SIMILAR TALENT</div>
          {renderControlledInput(
            'similarTalent1',
            'Similar Talent 1 (Full Name)',
          )}
          {renderControlledInput(
            'similarTalent2',
            'Similar Talent 2 (Full Name)',
          )}
          {renderControlledInput(
            'similarTalent3',
            'Similar Talent 3 (Full Name)',
          )}
          {renderControlledInput(
            'similarTalent4',
            'Similar Talent 4 (Full Name)',
          )}
        </div>
        <div>
          <hr className='my-8' />
          <div className='mb-6'>
            <div className='font-bold text-grey-7'>SOCIAL</div>
            <div className='text-grey-7'>
              The social data you provide below is strictly for reference, and
              not a source of data for the system.
            </div>
          </div>

          {renderControlledInput(
            'wikipedia',
            'Wikipedia Art. Title',
            isLabelOptional,
          )}
          {renderControlledInput(
            'instagramHandle',
            'Instagram Handle',
            isLabelOptional,
          )}
          {renderControlledInput(
            'instagramUrl',
            'Instagram URL',
            isLabelOptional,
          )}
          {renderControlledInput('xHandle', 'X Handle', isLabelOptional)}
          {renderControlledInput('xUrl', 'X URL', isLabelOptional)}
          {renderControlledInput('hashtags', 'Hashtag', isLabelOptional)}
        </div>
      </MgmtEntryPageForm>
    </FormProvider>
  )
}
