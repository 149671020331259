import {
  AudienceDemoGroup,
  AudienceGeneralDemo,
  OptionFilterDef,
  OptionGroupFilterDef,
  OptionType,
  AudienceDemo,
  AudienceRaceDemoBlack,
  AudienceRaceDemoWhite,
  AudienceRaceDemoHispLat,
  AudienceIdentityDemo,
} from '../types'

export const generalOptions: OptionType<AudienceGeneralDemo>[] = [
  { id: '0', label: 'All', value: AudienceGeneralDemo.ALL },
  { id: '1', label: 'Men', value: AudienceGeneralDemo.MEN },
  { id: '2', label: 'Women', value: AudienceGeneralDemo.WOMEN },
  { id: '3', label: '<35', value: AudienceGeneralDemo.BELOW_35 },
  { id: '4', label: '35+', value: AudienceGeneralDemo.ABOVE_35 },
  {
    id: '5',
    label: 'Men <35',
    value: AudienceGeneralDemo.MEN_BELOW_35,
  },
  {
    id: '6',
    label: 'Men 35+',
    value: AudienceGeneralDemo.MEN_ABOVE_35,
  },
  {
    id: '7',
    label: 'Women <35',
    value: AudienceGeneralDemo.WOMEN_BELOW_35,
  },
  {
    id: '8',
    label: 'Women 35+',
    value: AudienceGeneralDemo.WOMEN_ABOVE_35,
  },
]

// Export options for each group using the base options
export const blackOptions: OptionType<AudienceRaceDemoBlack>[] = [
  { id: '0', label: 'All', value: AudienceRaceDemoBlack.ALL },
  { id: '1', label: 'Men', value: AudienceRaceDemoBlack.MEN },
  { id: '2', label: 'Women', value: AudienceRaceDemoBlack.WOMEN },
  { id: '3', label: '<35', value: AudienceRaceDemoBlack.BELOW_35 },
  { id: '4', label: '35+', value: AudienceRaceDemoBlack.ABOVE_35 },
]

export const hispanicLatinoOptions: OptionType<AudienceRaceDemoHispLat>[] = [
  { id: '0', label: 'All', value: AudienceRaceDemoHispLat.ALL },
  { id: '1', label: 'Men', value: AudienceRaceDemoHispLat.MEN },
  { id: '2', label: 'Women', value: AudienceRaceDemoHispLat.WOMEN },
  { id: '3', label: '<35', value: AudienceRaceDemoHispLat.BELOW_35 },
  { id: '4', label: '35+', value: AudienceRaceDemoHispLat.ABOVE_35 },
]

export const whiteOptions: OptionType<AudienceRaceDemoWhite>[] = [
  { id: '0', label: 'All', value: AudienceRaceDemoWhite.ALL },
  { id: '1', label: 'Men', value: AudienceRaceDemoWhite.MEN },
  { id: '2', label: 'Women', value: AudienceRaceDemoWhite.WOMEN },
  { id: '3', label: '<35', value: AudienceRaceDemoWhite.BELOW_35 },
  { id: '4', label: '35+', value: AudienceRaceDemoWhite.ABOVE_35 },
]

export const lgbtqiOptions: OptionType<AudienceIdentityDemo>[] = [
  { id: '1', label: 'All', value: AudienceIdentityDemo.ALL },
]

export const dataAudience: OptionFilterDef<AudienceDemo, AudienceDemoGroup>[] =
  [
    { id: AudienceDemoGroup.GENERAL, name: 'General', options: generalOptions },
    { id: AudienceDemoGroup.BLACK, name: 'Black', options: blackOptions },
    {
      id: AudienceDemoGroup.HISPANIC_LATINO,
      name: 'Hispanic/Latino',
      options: hispanicLatinoOptions,
    },
    { id: AudienceDemoGroup.WHITE, name: 'White', options: whiteOptions },
    { id: AudienceDemoGroup.LGBTQI, name: 'LGBTQI', options: lgbtqiOptions },
  ]

export const audienceFilterDef: OptionGroupFilterDef<
  AudienceDemo,
  AudienceDemoGroup
> = {
  id: 'audience',
  name: 'Demos',
  options: dataAudience,
}

export const visibleDemosFilterDef: OptionGroupFilterDef<
  AudienceDemo,
  AudienceDemoGroup
> = {
  id: 'visibleDemos',
  name: 'Visible Demos',
  options: [
    {
      id: AudienceDemoGroup.GENERAL,
      name: 'General',
      options: generalOptions.filter(
        it => it.value !== AudienceGeneralDemo.ALL,
      ),
    },
    ...dataAudience.filter(it => it.id !== AudienceDemoGroup.GENERAL),
  ],
}
