import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react'
import { AuthUser } from 'aws-amplify/auth'
import { useDispatch } from 'react-redux'

export type AuthStateType = {
  isSignIn?: boolean
  isSignOut?: boolean
  isLoading?: boolean
  user?: AuthUser | null
  userAttributes?: any
  isInvitesChecked?: boolean
  isNewAlerts?: boolean
}

export type AuthContextType = {
  auth: AuthStateType
  updateAuth: (state: AuthStateType) => void
}

const initialAuthState: AuthStateType = {
  isSignIn: false,
  isSignOut: false,
  isInvitesChecked: false,
  isNewAlerts: false,
  isLoading: true,
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const useAuthContext = () => {
  const authContext = useContext(AuthContext)

  if (!authContext) {
    throw new Error('authContext has to be used within <AuthContext.Provider>')
  }

  return authContext
}

type Props = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState<AuthStateType>({ ...initialAuthState })
  const dispatch = useDispatch()

  useEffect(() => {
    if (auth && !auth.isSignIn && auth.isSignOut && !auth.isLoading) {
      // dispatch(soicalApi.util.resetApiState());
    }
  }, [auth])

  const updateAuth = useCallback(
    (state: AuthStateType) => setAuth(prev => ({ ...prev, ...state })),
    [],
  )

  return (
    <AuthContext.Provider value={{ auth, updateAuth }}>
      {children}
    </AuthContext.Provider>
  )
}
