import { BaseModal } from 'components'
import {
  ReleaseCalendarRevenueForm,
  type ReleaseCalendarRevenueFormData,
} from '../ReleaseCalendarRevenueForm'
import { ToggleHookState, ToggleHookStateControls } from 'hooks'
import { useCallback } from 'react'
import { useReleaseCalendarControllerPutRevenueMutation } from 'store/api'
import { ReleaseCalendarRevenue } from '../../types'

interface PastRevenueEditDialogProps {
  dialogState: ToggleHookState<ReleaseCalendarRevenue>
  dialogStateControls: ToggleHookStateControls<ReleaseCalendarRevenue>
}

export const PastRevenueEditDialog = ({
  dialogState,
  dialogStateControls,
}: PastRevenueEditDialogProps) => {
  const [updateEntry] = useReleaseCalendarControllerPutRevenueMutation()

  const { id: revenueId } = dialogState.data ?? {}

  const handleSubmit = useCallback(
    async (data: ReleaseCalendarRevenueFormData) => {
      if (!revenueId) {
        return
      }
      try {
        await updateEntry({
          revenueId,
          updateRevenueDto: data,
        })
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [revenueId, updateEntry, dialogStateControls],
  )

  return (
    <>
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'Edit Revenue Entry Details'}
        maxWidth='450px'
      >
        <ReleaseCalendarRevenueForm
          submitButtonLabel='Save'
          defaultValues={dialogState.data}
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
