import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { ToastContainer } from 'react-toastify'
import reportWebVitals from './reportWebVitals'

import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import { configureAmplify } from 'amplify-configure'
console.log('amplifyConfig', process.env)
configureAmplify()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer
        position='bottom-center'
        hideProgressBar
        pauseOnFocusLoss
        draggable
        closeButton={false}
        autoClose={3000}
      />
    </BrowserRouter>
  </Provider>,
)

reportWebVitals()
