import { AdminMgmtUserResolvedStatus } from '../types'

export const adminMgmtUserStatusTypeLabelMapping: Record<
  AdminMgmtUserResolvedStatus,
  string
> = {
  active: 'Active',
  paused: 'Paused',
  pending: 'Pending',
  online: 'Online',
}
