import { BaseModal } from 'components'
import {
  ReleaseCalendarEventForm,
  type ReleaseCalendarEventFormData,
} from '../ReleaseCalendarEventForm'
import { ToggleHookStateControls, useToggle } from 'hooks'
import { ReactNode, useCallback } from 'react'
import { useReleaseCalendarControllerCreateEventMutation } from 'store/api'

interface HolidayEventAddDialogProps {
  renderTrigger: (dialogControls: ToggleHookStateControls) => ReactNode
}

export const HolidayEventAddDialog = ({
  renderTrigger,
}: HolidayEventAddDialogProps) => {
  const [dialogState, dialogStateControls] = useToggle()
  const [createEvent] = useReleaseCalendarControllerCreateEventMutation()

  const handleSubmit = useCallback(
    async ({ title, startDate, endDate }: ReleaseCalendarEventFormData) => {
      try {
        await createEvent({
          eventType: 'holidaySchool',
          createEventDto: { title, startDate, endDate },
        })
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [createEvent, dialogStateControls],
  )

  return (
    <>
      {renderTrigger(dialogStateControls)}
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'New Holiday Event'}
        maxWidth='420px'
      >
        <ReleaseCalendarEventForm
          submitButtonLabel='Add Event'
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
