import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { commonToastStyles, prepareUrlParams } from 'utils'
import {
  CompFinderFilmLookup,
  CompFinderRightSidebar,
  DeleteModal,
  FilterSearchButton,
  LayoutContentWrapper,
  ProjectsListSearch,
} from 'components'
import { CompFinderHeader } from 'components/CompFinderHeader'
import { SquarePlus, Trash } from '../../components/ui/icons'
import { Colors, CompFinderColumns } from '../../constnants'
import { useClickOutsideComponent, useProjectRightSidebar } from '../../hooks'
import {
  CompFinderGetData,
  moviesFiltersType,
  movieType,
  OptionType,
  ProjectNav,
} from '../../types'
import { CompFinderTable } from '../../components'
import { twMerge } from 'tailwind-merge'
import { NewSearchCompFinderModal } from '../../components/CompFinderSearchModal'
import {
  useGetCompFinderDataQuery,
  useAddFilmToProjectMutation,
  useDeleteFilmFromProjectMutation,
  useSaveSearchPresetMutation,
  useLazyGetAutoGeneratedCompListQuery,
} from '../../store/api'

const initialActiveFilters: moviesFiltersType = {
  distributor: ['all'],
  releaseMonth: ['all'],
  releaseYears: ['all'],
  hybrid: ['no'],
  openingWeekendTheaters: [500, 5000],
  boxOffice: [0, 400],
  totalGross: [0, 990],
  budget: ['all'],
  rating: ['all'],
  psrr: ['all'],
  franchise: ['all'],
  universe: ['all'],
  primaryGenre: ['all'],
  secondaryGenre: ['all'],
  groups: ['all'],
  onlyPlatformMovies: true,
}

const initialActiveFiltersUpdated = { ...initialActiveFilters }

type sortingBy =
  | 'release_date_id'
  | 'film_name'
  | 'rating'
  | 'ow_theatres'
  | 'open_weekend'
  | 'total_gross'
  | 'budget'
  | 'film_group_key'
  | 'abbreviation'
  | 'no'
  | ''

export const CompFinderPage = () => {
  const updateUpdatedFilters = (forceUpdateFilters = false) => {
    const queryParameters = window.location.search
    // Prepare initial filters from query parameters
    if (queryParameters) {
      const queryParamsObject = Object.fromEntries(
        new URLSearchParams(queryParameters).entries(),
      )
      const allowedParams = Object.keys(initialActiveFilters)
      Object.entries(queryParamsObject)
        .filter(([key]) => allowedParams.includes(key))
        .forEach(([key, value]) => {
          if (key === 'openCallout') return
          if (key === 'onlyPlatformMovies') {
            initialActiveFiltersUpdated[key] = value === 'true'
          } else if (key === 'releaseYears') {
            const values = value.split(',').map(v => v)
            initialActiveFiltersUpdated[key] = values.map(v =>
              Number(v) === 0 ? '' : v,
            )
          } else if (
            key === 'distributor' ||
            key === 'releaseMonth' ||
            key === 'releaseYears'
          ) {
            initialActiveFiltersUpdated[key] = value.split(',')
          } else if (value.includes(',')) {
            const values = value
              .split(',')
              .map(v => (isNaN(Number(v)) ? v : Number(v)))
            initialActiveFiltersUpdated[key] = values.map(v => v)
          } else {
            initialActiveFiltersUpdated[key] = [value]
          }
        })

      if (forceUpdateFilters) {
        handleSetFilters(initialActiveFiltersUpdated)
        setActiveFilters(initialActiveFiltersUpdated)
        setSavedFilters(initialActiveFiltersUpdated)
      }
    }
  }
  updateUpdatedFilters()
  const resetFilters = () => {
    handleSetFilters(initialActiveFilters)
    setActiveFilters(initialActiveFilters)
  }
  const searchToModify = localStorage.getItem('dataToUpdateSearchList') || ''

  const navigate = useNavigate()
  const location = useLocation()
  const { isOpenSidebar, handleOpenRightSidebar, handleCloseRightSidebar } =
    useProjectRightSidebar({ isOpened: false })
  const [activeTabHeader, setActiveTabHeader] = useState<string>('compFinder')
  const [projectsNav, setProjectsNav] = useState<ProjectNav[]>([])
  const tabsOptions = ['Group', 'Genre']
  const [activeTableTab, setActiveTableTab] = useState<string>(tabsOptions[0])
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const {
    ref: refMovie,
    isComponentVisible: showSearchMovie,
    setIsComponentVisible: setShowSearchMovie,
  } = useClickOutsideComponent(false)
  const [activeFilters, setActiveFilters] = useState<moviesFiltersType>(
    initialActiveFiltersUpdated,
  )
  const [updatedFilters, setUpdatedFilters] = useState<moviesFiltersType>(
    initialActiveFiltersUpdated,
  )
  const [savedFilters, setSavedFilters] = useState<moviesFiltersType>(
    initialActiveFiltersUpdated,
  )

  const handleSetFilters = (filters: moviesFiltersType) => {
    setUpdatedFilters(filters)
    setCurrentPage(1)
  }

  const resetSavedSearch = () => {
    setActiveFilters({
      ...savedFilters,
    })
  }
  const [isSavedFilterChanged, setIsSavedFilterChanged] = useState(false)
  const [filtersOpened, setFiltersOpened] = useState<boolean>(false)
  const [sortingBY, setSortingBY] = useState<sortingBy>('release_date_id')
  const [sortingOrder, setSortingOrder] = useState<string>('DESC')
  const [searchFilmId, setSearchFilmId] = useState<number>()

  const [totalFilmsCount, setTotalFilmsCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [filteredFilms, setFilteredFilms] = useState<movieType[]>([])
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [openWarningPopup, setOpenWarningPopup] = useState(false)
  const [projectJustAdded, setProjectJustAdded] = useState(false)
  const [showCallout, setShowCallout] = useState(false)
  const [searchModalOpenend, setSearchModalOpened] = useState<boolean>(false)
  const [addMovieToProject] = useAddFilmToProjectMutation()
  const [deleteMovieFromProject] = useDeleteFilmFromProjectMutation()
  const [saveSearchPreset] = useSaveSearchPresetMutation()
  const [getAutoGeneratedCompList] = useLazyGetAutoGeneratedCompListQuery()

  function getFilmsQuery(): CompFinderGetData {
    if (searchFilmId) {
      return {
        filmId: searchFilmId,
        ...(activeTabHeader !== 'compFinder' && {
          projectId: Number(activeTabHeader),
        }),
      }
    }

    const filmsQuery: CompFinderGetData = {
      pageSize: rowsPerPage,
      pageNumber: currentPage,
      sortBy: sortingBY,
      sortOrder: sortingOrder,
      hybrid: updatedFilters.hybrid[0] === 'yes',
      showPlatformMovies: updatedFilters.onlyPlatformMovies,
    }

    addFilterIfApplicable(
      filmsQuery,
      'dist',
      updatedFilters.distributor,
      'all',
      mapToNumberArray,
    )
    addFilterIfApplicable(
      filmsQuery,
      'releaseMonth',
      updatedFilters.releaseMonth,
      'all',
      mapToNumberArray,
    )
    addFilterIfApplicable(
      filmsQuery,
      'releaseYear',
      updatedFilters.releaseYears,
      'all',
      mapToNumberArray,
    )
    addRangeFilter(
      filmsQuery,
      'owTheaters',
      updatedFilters.openingWeekendTheaters,
      500,
      5000,
    )
    addRangeFilter(filmsQuery, 'boxOffice', updatedFilters.boxOffice, 0, 400)
    addRangeFilter(filmsQuery, 'totalGross', updatedFilters.totalGross, 0, 990)
    addFilterIfApplicable(
      filmsQuery,
      'budgetFilter',
      updatedFilters.budget,
      'all',
    )
    addFilterIfApplicable(
      filmsQuery,
      'ratingsFilter',
      updatedFilters.rating,
      'all',
    )
    addFilterIfApplicable(
      filmsQuery,
      'primaryGenre',
      updatedFilters.primaryGenre,
      'all',
    )
    addFilterIfApplicable(
      filmsQuery,
      'secondaryGenre',
      updatedFilters.secondaryGenre,
      'all',
    )
    addFilterIfApplicable(
      filmsQuery,
      'filmGroups',
      updatedFilters.groups,
      'all',
    )

    if (activeTabHeader !== 'compFinder') {
      filmsQuery.projectId = Number(activeTabHeader)
    }

    return filmsQuery
  }

  function addFilterIfApplicable(
    queryObj: any,
    key: string,
    filterValue: any,
    defaultValue: any,
    mapper?: (v: any) => any,
  ) {
    if (filterValue[0] !== defaultValue) {
      queryObj[key] = mapper ? mapper(filterValue) : filterValue
    }
  }

  function addRangeFilter(
    queryObj: any,
    key: string,
    filterValue: number[],
    min: number,
    max: number,
  ) {
    if (filterValue[0] !== min || filterValue[1] !== max) {
      queryObj[key] = [filterValue[0], filterValue[1]]
    }
  }

  function mapToNumberArray(arr: any[]): number[] {
    return arr.map(v => (v === '' ? 0 : Number(v)))
  }

  const {
    data: filmsListResponse,
    currentData,
    isLoading,
    refetch: refetchCompFinderData,
  } = useGetCompFinderDataQuery(getFilmsQuery())

  const openRecentUpdatedProject = ({ id, label }: OptionType<number>) => {
    if (projectsNav.some(item => item.id === id)) {
      setActiveTabHeader(id)
    } else {
      setProjectJustAdded(true)
      setProjectsNav([...projectsNav, { name: label, id }])
    }
  }
  const handleAddToProject = (
    project: OptionType<number>,
    filmIds: number[],
  ) => {
    setIsComponentVisible(false)
    addMovieToProject({ filmIds: filmIds, projectId: project.id }).then(() => {
      setSelectedRows([])
      setSelectAllChecked(false)
      toast.success(
        <div className={'flex items-center gap-2 whitespace-nowrap'}>
          <p>
            Comp(s) added to <b>{project.label}</b> project
          </p>
          <p
            className={'cursor-pointer border-b border-b-red-9/20 text-red-9'}
            onClick={() => openRecentUpdatedProject(project)}
          >
            Open List
          </p>
        </div>,
        {
          ...commonToastStyles,
        },
      )
    })
  }

  useEffect(() => {
    if (projectJustAdded) {
      setActiveTabHeader(projectsNav[projectsNav.length - 1]?.id)
      setProjectJustAdded(false)
    }
  }, [projectsNav])

  useEffect(() => {
    if (
      filmsListResponse &&
      'data' in filmsListResponse &&
      filmsListResponse.data.length === 0 &&
      !localStorage.getItem('hideCallout') &&
      activeTabHeader !== 'compFinder'
    ) {
      setShowCallout(true)
      generateDefaultMovieList()
    }
    setSelectedRows([])
    setSelectAllChecked(false)
  }, [activeTabHeader, filmsListResponse])

  useEffect(() => {
    if (!filmsListResponse) {
      return
    }

    if ('data' in filmsListResponse) {
      setTotalFilmsCount(filmsListResponse.totalCount)
      setFilteredFilms(filmsListResponse.data)
    } else {
      setTotalFilmsCount(1)
      setFilteredFilms(filmsListResponse)
    }
  }, [filmsListResponse, activeFilters, currentPage, rowsPerPage])

  const handleCheckboxChange = (row: number) => {
    const newSelected = selectedRows.includes(row)
      ? selectedRows.filter(selectedRow => selectedRow !== row)
      : [...selectedRows, row]
    setSelectedRows(newSelected)
    setSelectAllChecked(newSelected.length === filteredFilms.length)
  }

  const handleAddMovie = (filmId: number, projectId: string) => {
    addMovieToProject({ filmIds: [filmId], projectId: projectId }).then(() => {
      setShowSearchMovie(false)
      const tabTitle = projectsNav.find(
        project => project.id === projectId,
      )?.name
      toast.success(
        <p>
          Comp(s) added to <b>{tabTitle}</b> project
        </p>,
        {
          ...commonToastStyles,
        },
      )
    })
  }

  const handleConfirmDelete = () => {
    deleteMovieFromProject({
      filmIds: { filmIds: selectedRows },
      projectId: activeTabHeader,
    }).then(() => {
      setSelectedRows([])
      setSelectAllChecked(false)
      setOpenWarningPopup(false)
      toast.success(<p>Comp removed from list</p>, {
        icon: <Trash fill={Colors.ICON_NEGATIVE_RED} width={18} height={18} />,
        ...commonToastStyles,
      })
    })
  }

  const generateDefaultMovieList = async () => {
    await getAutoGeneratedCompList(activeTabHeader)
    refetchCompFinderData()
  }

  useEffect(() => {
    // Update URL with active filters
    const searchParams = prepareUrlParams(updatedFilters)
    if (!_.isEqual(updatedFilters, initialActiveFilters)) {
      navigate(`${searchParams}`)
    } else if (window.location.search) {
      // Reset URL if all filters are cleared
      navigate(`${searchParams}`)
    }
  }, [updatedFilters])

  useEffect(() => {
    // Open callout if URL has openCallout query parameter
    const queryParams = window.location.search
    if (queryParams.includes('openCallout')) {
      setSearchModalOpened(true)
    }

    if (searchToModify) {
      updateUpdatedFilters(true)
    }
  }, [location])

  useEffect(() => {
    if (!_.isEqual(activeFilters, savedFilters) && searchToModify) {
      setIsSavedFilterChanged(true)
    } else {
      setIsSavedFilterChanged(false)
    }
  }, [activeFilters])

  const isInitialFilterApplied = useMemo(() => {
    return _.isEqual(activeFilters, initialActiveFilters)
  }, [activeFilters])

  const currentDataEmpty =
    !!currentData && 'data' in currentData && currentData.data.length === 0

  const isCompListEmpty =
    currentDataEmpty &&
    isInitialFilterApplied &&
    activeTabHeader !== 'compFinder'

  const rightSideBar = (
    <CompFinderRightSidebar
      isOpen={isOpenSidebar}
      handleOpen={handleOpenRightSidebar}
      handleClose={handleCloseRightSidebar}
      isSavedSearchChanged={isSavedFilterChanged}
      setIsSavedSearchChanged={setIsSavedFilterChanged}
      handleAddProject={openRecentUpdatedProject}
      resetSavedSearch={resetSavedSearch}
      activeFilters={activeFilters}
      searchToModify={searchToModify}
      setUpdatedFilters={handleSetFilters}
      setSearchModalOpened={setSearchModalOpened}
      resetFilters={resetFilters}
    />
  )

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-grey-2'
      wrapperChildrenClassName='h-[calc(100vh_-_44px)] flex flex-col'
      rightSidebar={rightSideBar}
    >
      <CompFinderHeader
        setActiveTabHeader={setActiveTabHeader}
        activeTabHeader={activeTabHeader}
        setProjectsNav={setProjectsNav}
        projectsNav={projectsNav}
        isSidebarOpened={isOpenSidebar}
        showCallout={showCallout}
        setShowCallout={setShowCallout}
        filtersOpened={filtersOpened}
        setFiltersOpened={setFiltersOpened}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        updatedFilters={updatedFilters}
        setUpdatedFilters={handleSetFilters}
        initialActiveFilters={initialActiveFilters}
        setSearchModalOpened={setSearchModalOpened}
        handleAddProject={openRecentUpdatedProject}
        searchToModify={searchToModify}
        isSavedFilterChanged={isSavedFilterChanged}
        resetSavedSearch={resetSavedSearch}
      />
      <div className={'flex justify-between px-3 pt-3'}>
        <div className='z-10 flex'>
          {tabsOptions.map((tab, index) => (
            <div
              key={index}
              onClick={() => setActiveTableTab(tab)}
              className={twMerge(
                `min-w-[136px] cursor-pointer border-b-2 border-b-grey-5 p-2 text-center text-sm
                text-primary-grey transition-colors duration-300`,
                activeTableTab === tab &&
                  'border-b-primary-blue font-medium text-primary-blue',
              )}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className={'flex gap-2'}>
          {activeTabHeader === 'compFinder' ? (
            <FilterSearchButton
              name='Add to Project'
              leftIcon={<SquarePlus fill={Colors.BASE_ICON} />}
              isComponentVisible={isComponentVisible}
              disabled={selectedRows.length === 0}
              setIsComponentVisible={() =>
                setIsComponentVisible(prevState => !prevState)
              }
              filterWrapperClassName={'text-left overflow-visible'}
              trianglePosition='right'
              containerMenuClassName={twMerge('w-[312px] right-0')}
              ref={ref}
            >
              <div className={'max-h-[460px] overflow-auto p-2'}>
                <ProjectsListSearch
                  onSelectProject={project => {
                    handleAddToProject(
                      project,
                      selectedRows.map(v => Number(v)),
                    )
                  }}
                />
              </div>
            </FilterSearchButton>
          ) : (
            <div className={'flex'}>
              <FilterSearchButton
                name='Add movie'
                leftIcon={<SquarePlus fill={Colors.BASE_ICON} />}
                isComponentVisible={showSearchMovie}
                filterWrapperClassName={'text-left'}
                disabled={selectedRows.length !== 0}
                setIsComponentVisible={() =>
                  setShowSearchMovie(prevState => !prevState)
                }
                trianglePosition='center'
                ref={refMovie}
                containerMenuClassName={twMerge(
                  'left-1/2 -translate-x-1/2 w-[312px]',
                )}
              >
                <div>
                  <CompFinderFilmLookup
                    onSelectFilm={filmId => {
                      handleAddMovie(filmId, activeTabHeader)
                    }}
                    onDeleteFilm={() => {
                      setSearchFilmId(undefined)
                    }}
                    className='border-b border-b-primary-black/10 p-3'
                  />
                </div>
              </FilterSearchButton>
              <div
                className={twMerge(
                  'flex gap-1 px-2 py-1 text-xs font-semibold',
                  selectedRows.length === 0
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer',
                )}
                onClick={() => {
                  if (selectedRows.length > 0) {
                    setOpenWarningPopup(true)
                  }
                }}
              >
                <Trash fill={Colors.BASE_ICON} width={16} height={16} />
                <p>Remove from List</p>
              </div>
            </div>
          )}

          <div className='relative'>
            <CompFinderFilmLookup
              projectId={
                activeTabHeader === 'compFinder' ? undefined : activeTabHeader
              }
              onSelectFilm={filmId => {
                setSearchFilmId(filmId)
              }}
              onDeleteFilm={() => {
                setSearchFilmId(undefined)
              }}
            />
          </div>
        </div>
      </div>
      <CompFinderTable
        isCompListEmpty={isCompListEmpty}
        columns={CompFinderColumns}
        data={filteredFilms}
        isLoading={isLoading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalFilmsCount={totalFilmsCount}
        onSort={setSortingBY}
        onSortOrder={setSortingOrder}
        sortingBY={sortingBY}
        sortingOrder={sortingOrder}
        selectAllChecked={selectAllChecked}
        setSelectAllChecked={setSelectAllChecked}
        filteredFilms={filteredFilms}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        handleCheckboxChange={handleCheckboxChange}
        activeTableTab={activeTableTab}
        isSidebarOpened={isOpenSidebar}
        generateDefaultMovieList={generateDefaultMovieList}
      />
      <DeleteModal
        isOpen={openWarningPopup}
        handleClose={() => setOpenWarningPopup(false)}
        title={'Remove comp(s) from this list?'}
        description={'Don’t worry, you can always add the removed comp back.'}
        onDelete={() => {
          handleConfirmDelete()
        }}
        deleteTitle={'Remove'}
        zIndex={900}
        descriptionClassName={'text-center'}
        titleClassName={'text-center mb-1'}
        icon={<Trash fill={Colors.ICON_NEGATIVE_RED} width={40} height={40} />}
      />
      <NewSearchCompFinderModal
        handleSaveCompFinderSearch={searchTitle => {
          saveSearchPreset({
            name: searchTitle,
            searchParameters: activeFilters,
            type: 'comp',
          })
            .then(data => {
              setUpdatedFilters(handleSetFilters)
              updateUpdatedFilters()
              refetchCompFinderData()
              toast.success(
                <p>
                  New Search added to <b>My Searches: {searchTitle}</b>
                </p>,
                {
                  ...commonToastStyles,
                  bodyClassName: 'flex  px-0 py-0 items-start w-[288px]',
                },
              )
              localStorage.setItem(
                'dataToUpdateSearchList',
                data.data?.search_id || '',
              )
            })
            .catch(error => {
              toast.error(`Failed to add New Search. Error: ${error}`)
            })
        }}
        isOpen={searchModalOpenend}
        handleClose={() => {
          setSearchModalOpened(false)
        }}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            Your personal search filters are <strong>private</strong>, and can
            be used and edited by only you.
          </span>
        }
      />
    </LayoutContentWrapper>
  )
}
