import { apiURL } from '../store/api/api'
import { UsersTableProps } from '../components'
import { formatDate, getUserName } from 'utils'
import { AwsAmplifyRestApiUtils } from '../store/api/utils'

export const fetchUsersData = async (companyId: string) => {
  const authData = await AwsAmplifyRestApiUtils.getAuthorizationHeader(true)
  try {
    const response = await fetch(apiURL + '/user/' + companyId, {
      method: 'GET',
      headers: {
        Accept: '*/*',
        Authorization: authData.Authorization,
      },
    })
    const data = await response.json()
    if (data.message === 'Unauthorized') {
      localStorage.removeItem('access_token')
      localStorage.setItem('auth', 'false')
      window.location.reload()
    }

    if (data) {
      const usersData: UsersTableProps[] = data.map(
        (user: {
          id: string
          name: string
          first_name: string | null
          last_name: string
          role_id: number
          company_id: string
          email: string
          created_at: string
          updated_at: string
          last_login_date: string | null
          last_logout_date: string | null
          status: 'active' | 'paused'
          user_type: string
          access_level: string
        }) => {
          return {
            id: user.id,
            name: getUserName(user.name, user.first_name, user.last_name),
            email: user.email,
            access_level: user.access_level,
            user_type: user.user_type,
            last_login_date: user.last_login_date,
            last_logout_date: user.last_logout_date,
            status: user.status,
            date_joined: formatDate(user.created_at, 'MMM D, YYYY'),
            projects: [],
            module_access: [2, 3, 4, 5],
          }
        },
      )
      return usersData
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
