import { ReactNode, useCallback } from 'react'
import {
  MgmtTableCell,
  MgmtTable,
  MgmtTableRow,
} from 'modules/management/shared'
import { type AdminManagementTableTypes } from './types'
import { AdminMgmtUser, AdminMgmtColKey } from '../../types'
import { twMerge } from 'tailwind-merge'
import { useToggle } from 'hooks'
import { columns } from './columns'
import { ActionsCell } from './ActionsCell'
import { AdminMgmtPauseUserDialog } from '../AdminMgmtPauseUserDialog'
import { AdminMgmtActivateUserDialog } from '../AdminMgmtActivateUserDialog'
import { AdminLastLoginStatus } from './AdminLastLoginStatus'

type AdminMgmtTableProps = {
  rows: AdminMgmtUser[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: AdminManagementTableTypes['SortConfig']
  onSortChange: AdminManagementTableTypes['OnSortChange']
  onRowClick: (user: AdminMgmtUser) => void
}

export const AdminMgmtTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
  onRowClick,
}: AdminMgmtTableProps) => {
  const [actionsCellState, actionsCellStateControls] =
    useToggle<AdminMgmtUser>()
  const [pauseDialogState, pauseDialogStateControls] =
    useToggle<AdminMgmtUser>()
  const [activateDialogState, activateDialogStateControls] =
    useToggle<AdminMgmtUser>()

  const rowRenderer: AdminManagementTableTypes['RowRenderer'] = useCallback(
    ({ rowContent, row }) => {
      return (
        <MgmtTableRow
          className='cursor-pointer'
          onClick={() => onRowClick(row)}
        >
          {rowContent}
        </MgmtTableRow>
      )
    },
    [onRowClick],
  )
  const cellRenderer: AdminManagementTableTypes['CellRenderer'] = useCallback(
    ({ column, row, cellContent }) => {
      if (column.key === AdminMgmtColKey.Actions) {
        return (
          <ActionsCell
            row={row}
            column={column}
            cellState={actionsCellState}
            cellStateControls={actionsCellStateControls}
            onClick={() => actionsCellStateControls.activate(row)}
            onPause={() => pauseDialogStateControls.activate(row)}
            onActivate={() => activateDialogStateControls.activate(row)}
          />
        )
      }

      return (
        <MgmtTableCell
          column={column}
          className={twMerge(
            'px-3 py-5',
            column.key === 'name' && 'font-medium',
          )}
        >
          {cellContent}
        </MgmtTableCell>
      )
    },
    [actionsCellState],
  )

  const rowKeyGetter: AdminManagementTableTypes['RowKeyGetter'] = useCallback(
    ({ row }) => row.id,
    [],
  )

  const cellContentGetter: AdminManagementTableTypes['CellContentGetter'] =
    useCallback(({ column, row, getCellValue }) => {
      switch (column.key) {
        case AdminMgmtColKey.UserType: {
          return 'GL Super Admin'
        }
        case AdminMgmtColKey.LastLogin: {
          return (
            <AdminLastLoginStatus
              status={row.status}
              lastLoginDate={row.last_login_date}
              lastLogoutDate={row.last_logout_date}
            />
          )
        }
        case AdminMgmtColKey.Actions: {
          return null
        }
        default:
          return getCellValue(column.key)
      }
    }, [])

  return (
    <>
      <MgmtTable
        rows={rows}
        columns={columns}
        footer={footer}
        loading={loading}
        fetching={fetching}
        sortConfig={sortConfig}
        onSortChange={onSortChange}
        rowRenderer={rowRenderer}
        cellRenderer={cellRenderer}
        rowKeyGetter={rowKeyGetter}
        cellContentGetter={cellContentGetter}
      />
      <AdminMgmtPauseUserDialog
        dialogState={pauseDialogState}
        dialogStateControls={pauseDialogStateControls}
      />
      <AdminMgmtActivateUserDialog
        dialogState={activateDialogState}
        dialogStateControls={activateDialogStateControls}
      />
    </>
  )
}
