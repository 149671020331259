import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { PaginationChangeParams, PaginationToolbar } from 'components'
import { managementRowsPerPageOptions } from 'constnants'
import { useReleaseCalendarControllerGetEventQuery } from 'store/api'
import {
  ReleaseCalendarEventsTable,
  SportingEventEditDialog,
  type ReleaseCalendarEventsTableTypes,
} from '../../components'
import { releaseCalendarMgmtSelectors } from '../../slices'
import { useReleaseCalendarMgmtActions } from '../../hooks'
import { useToggle } from 'hooks'
import { ReleaseCalendarEvent } from '../../types'

interface ReleaseCalendarSportingEventsViewProps {
  searchTerm: string
}

export const ReleaseCalendarSportingEventsView = ({
  searchTerm,
}: ReleaseCalendarSportingEventsViewProps) => {
  const [editDialogState, editDialogStateControls] =
    useToggle<ReleaseCalendarEvent>()

  const actions = useReleaseCalendarMgmtActions()

  const { pagination, sorting } = useSelector(
    releaseCalendarMgmtSelectors.sportingEventsTab,
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) =>
      actions.setSportingEventsTabPagination({
        pageNumber: params.page,
        pageSize: params.pageSize,
      }),
    [actions],
  )

  const handleSortChange: ReleaseCalendarEventsTableTypes['OnSortChange'] =
    useCallback(
      ({ sortConfig }) => actions.setSportingEventsTabSorting(sortConfig),
      [actions],
    )

  const { events, totalPages, totalRows, isFetching, isLoading } =
    useReleaseCalendarControllerGetEventQuery(
      {
        eventType: 'sportEvents',
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        sortBy: sorting.by,
        sortOrder: sorting.order,
        title: searchTerm.length ? searchTerm : undefined,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          return {
            ...rest,
            events: data?.list ?? [],
            totalPages: data?.totalPages ?? 0,
            totalRows: data?.totalRecords ?? 0,
          }
        },
      },
    )

  const paginationBar = (
    <PaginationToolbar
      totalPages={totalPages}
      totalRows={totalRows}
      currentPage={pagination.pageNumber}
      currentPageSize={pagination.pageSize}
      pageSizeOptions={managementRowsPerPageOptions}
      onChange={handlePagination}
    />
  )

  return (
    <>
      <ReleaseCalendarEventsTable
        rows={events}
        footer={paginationBar}
        loading={isLoading}
        fetching={isFetching}
        sortConfig={sorting}
        onSortChange={handleSortChange}
        editDialogStateControls={editDialogStateControls}
      />
      <SportingEventEditDialog
        dialogState={editDialogState}
        dialogStateControls={editDialogStateControls}
      />
    </>
  )
}
