import { ReactNode, useCallback } from 'react'
import { MgmtTableCell, MgmtTable } from 'modules/management/shared'
import { type TVManagementTableTypes } from './types'
import { TVManagementColKey, type TVManagementData } from '../../types'
import { twMerge } from 'tailwind-merge'
import { columns } from './columns'
import { EditEntryCellContent } from '../cellContent'
import {
  tVGenreLabelByType,
  tVNetworkLabelByType,
  tVNetworkTypeLabelByType,
  tVSubtypeLabelByType,
  tVTypeLabelByType,
} from '../../config/tVNetworkTypeLabelMapping'
import { useToggle } from 'hooks'
import { TVManagementEditDialog } from '../TVManagementEditDialog'

type TVManagementTableProps = {
  rows: TVManagementData[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: TVManagementTableTypes['SortConfig']
  onSortChange: TVManagementTableTypes['OnSortChange']
}

export const TVManagementTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
}: TVManagementTableProps) => {
  const [editDialogState, editDialogStateControls] =
    useToggle<TVManagementData>()
  const cellRenderer: TVManagementTableTypes['CellRenderer'] = useCallback(
    ({ column, cellContent }) => (
      <MgmtTableCell
        column={column}
        className={twMerge(column.key === 'title' && 'font-medium')}
      >
        {cellContent}
      </MgmtTableCell>
    ),
    [],
  )

  const rowKeyGetter: TVManagementTableTypes['RowKeyGetter'] = useCallback(
    ({ row }) => row.id,
    [],
  )

  const cellContentGetter: TVManagementTableTypes['CellContentGetter'] =
    useCallback(({ column, row, getCellValue }) => {
      switch (column.key) {
        case TVManagementColKey.EditEntry: {
          return (
            <EditEntryCellContent
              onClick={() => editDialogStateControls.activate(row)}
            />
          )
        }
        case TVManagementColKey.TvType: {
          return getCellValue(column.key, value => tVTypeLabelByType[value])
        }
        case TVManagementColKey.TvSubtype: {
          console.log('column.key', row)
          return getCellValue(column.key, value => tVSubtypeLabelByType[value])
        }
        case TVManagementColKey.NetworkType: {
          return getCellValue(
            column.key,
            value => tVNetworkTypeLabelByType[value],
          )
        }
        case TVManagementColKey.Network: {
          return getCellValue(column.key)
        }
        case TVManagementColKey.Genre: {
          return getCellValue(column.key, value => tVGenreLabelByType[value])
        }
        default:
          return getCellValue(column.key)
      }
    }, [])

  return (
    <>
      <MgmtTable
        rows={rows}
        columns={columns}
        footer={footer}
        loading={loading}
        fetching={fetching}
        sortConfig={sortConfig}
        onSortChange={onSortChange}
        cellRenderer={cellRenderer}
        rowKeyGetter={rowKeyGetter}
        cellContentGetter={cellContentGetter}
      />
      <TVManagementEditDialog
        dialogState={editDialogState}
        dialogStateControls={editDialogStateControls}
      />
    </>
  )
}
