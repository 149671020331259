import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { PaginationChangeParams, PaginationToolbar } from 'components'
import { managementRowsPerPageOptions } from 'constnants'
import { useReleaseCalendarControllerGetRevenueQuery } from 'store/api'
import {
  PastRevenueEditDialog,
  ReleaseCalendarRevenueTable,
  type ReleaseCalendarRevenueTableTypes,
} from '../../components'
import { releaseCalendarMgmtSelectors } from '../../slices'
import { useReleaseCalendarMgmtActions } from '../../hooks'
import { ReleaseCalendarRevenue } from '../../types'
import { useToggle } from 'hooks'

export const ReleaseCalendarPastRevenueView = () => {
  const [editDialogState, editDialogStateControls] =
    useToggle<ReleaseCalendarRevenue>()

  const actions = useReleaseCalendarMgmtActions()

  const { pagination, sorting, yearFilter } = useSelector(
    releaseCalendarMgmtSelectors.pastRevenueTab,
  )

  const handlePagination = useCallback(
    (params: PaginationChangeParams) =>
      actions.setPastRevenueTabPagination({
        pageNumber: params.page,
        pageSize: params.pageSize,
      }),
    [actions],
  )

  const handleSortChange: ReleaseCalendarRevenueTableTypes['OnSortChange'] =
    useCallback(
      ({ sortConfig }) => actions.setPastRevenueTabSorting(sortConfig),
      [actions],
    )

  const { pastRevenueList, totalPages, totalRows, isFetching, isLoading } =
    useReleaseCalendarControllerGetRevenueQuery(
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        sortBy: sorting.by,
        sortOrder: sorting.order,
        year: yearFilter,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          return {
            ...rest,
            pastRevenueList: data?.list ?? [],
            totalPages: data?.totalPages ?? 0,
            totalRows: data?.totalRecords ?? 0,
          }
        },
      },
    )

  const paginationBar = (
    <PaginationToolbar
      totalPages={totalPages}
      totalRows={totalRows}
      currentPage={pagination.pageNumber}
      currentPageSize={pagination.pageSize}
      pageSizeOptions={managementRowsPerPageOptions}
      onChange={handlePagination}
    />
  )

  return (
    <>
      <ReleaseCalendarRevenueTable
        rows={pastRevenueList}
        footer={paginationBar}
        loading={isLoading}
        fetching={isFetching}
        sortConfig={sorting}
        onSortChange={handleSortChange}
        editDialogStateControls={editDialogStateControls}
      />
      <PastRevenueEditDialog
        dialogState={editDialogState}
        dialogStateControls={editDialogStateControls}
      />
    </>
  )
}
