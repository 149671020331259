import { ColumnsProjectTable } from 'types'
import { twMerge } from 'tailwind-merge'
import { formatDate, getUserLastLoginStatus } from 'utils'

interface UsersTableCellProps {
  column: ColumnsProjectTable
  row: {
    id: string | number
    name: string
    email: string
    access_level: string
    last_login_date: string | null
    last_logout_date: string | null
    status: 'active' | 'paused'
  }
  isLoading?: boolean
}

export const UsersTableCell = ({
  column,
  row,
  isLoading = false,
}: UsersTableCellProps) => {
  const renderLastLoginStatus = () => {
    const lastLoginStatus = getUserLastLoginStatus(
      row.status,
      row.last_login_date,
      row.last_logout_date,
    )
    if (lastLoginStatus === 'Online') {
      return (
        <>
          <span className='h-2 w-2 rounded-full bg-green-6'></span>
          Online
        </>
      )
    }

    if (lastLoginStatus === 'Inactive') {
      return (
        <>
          <span className='h-2 w-2 rounded-full bg-red-10'></span>
          Inactive
        </>
      )
    }

    if (lastLoginStatus === 'Pending') {
      return (
        <>
          <span className='h-2 w-2 rounded-full bg-tertiary-inverse'></span>
          Pending
        </>
      )
    }

    return lastLoginStatus
  }

  switch (column.value) {
    case 'name':
      return (
        <span
          className={twMerge(
            'text-xs font-medium text-primary-black',
            isLoading ? 'bg-gray-500 text-transparent' : '-mt-0.5 block',
          )}
        >
          {row.name}
        </span>
      )
    case 'email':
      return (
        <span
          className={twMerge(
            'text-xs',
            row.email === 'complete' ? 'text-green-6' : 'text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.email}
        </span>
      )
    case 'last_login':
      return (
        <span
          className={twMerge(
            'flex items-baseline gap-1 text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {renderLastLoginStatus()}
        </span>
      )
    case 'access_level':
      return (
        <span
          className={twMerge(
            'text-xs capitalize text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.access_level === 'superadmin'
            ? 'Organization Owner'
            : row.access_level === 'moduleadmin'
              ? 'Department Owner'
              : !row.access_level
                ? 'N/A'
                : row.access_level}
        </span>
      )
    default:
      return <span></span>
  }
}
