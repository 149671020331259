import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { dateToUTC } from 'utils'

const requiredNumber = (fieldName: string) =>
  z
    .union([z.string(), z.number()], {
      required_error: `${fieldName} is required`,
    })
    .refine(
      value => {
        const parsedValue =
          typeof value === 'string' ? parseFloat(value) : value
        return Number.isInteger(parsedValue)
      },
      {
        message: `${fieldName} must be a whole number`,
      },
    )
    .transform(value =>
      typeof value === 'string' ? parseInt(value, 10) : value,
    )

const requiredDateString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)
    .refine(str => /^\d{2}\/\d{2}\/\d{4}$/.test(str), {
      message: `${fieldName} must be in mm/dd/yyyy format`,
    })
    .refine(
      str => {
        const date = dayjs(str, 'MM/DD/YYYY', true)
        return date.isValid() && date.format('MM/DD/YYYY') === str
      },
      {
        message: `${fieldName} is not valid (entered date does not exist)`,
      },
    )
    .transform(str =>
      dateToUTC(dayjs(str, 'MM/DD/YYYY', true).toDate()).toISOString(),
    )

export const releaseCalendarRevenueFormSchema = z.object({
  date: requiredDateString('Date'),
  revenue: requiredNumber('Revenue'),
})

export const releaseCalendarRevenueFormResolver = zodResolver(
  releaseCalendarRevenueFormSchema,
)

export type ReleaseCalendarRevenueFormData = z.infer<
  typeof releaseCalendarRevenueFormSchema
>
