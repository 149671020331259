import { AdminMgmtUser } from '../../types'
import { ArrowLeft } from 'components'
import { ToggleHookStateControls, useToggle } from 'hooks'
import { AdminMgmtUserDetails, AdminMgmtUserEdit } from '../../components'

interface AdminMgmtUserViewProps {
  user: AdminMgmtUser
  viewControls: ToggleHookStateControls<AdminMgmtUser>
}

export const AdminMgmtUserView = ({
  user,
  viewControls,
}: AdminMgmtUserViewProps) => {
  const [editFormState, editStateFormControls] = useToggle()

  return (
    <div className={'flex h-full flex-col overflow-auto pb-4'}>
      <div
        className={
          'mb-12 flex items-center gap-4 border-b border-b-primary-black/10 px-4 py-3'
        }
      >
        <ArrowLeft
          fill={'currentColor'}
          width={24}
          height={24}
          onClick={viewControls.deactivate}
          className='cursor-pointer text-grey-7 hover:text-primary-red'
        />
        <div>
          <p className={'text-xs font-semibold opacity-50'}>Admin</p>
          <h1 className='text-2xl font-semibold text-primary-black'>
            {user.name}
          </h1>
        </div>
      </div>

      {editFormState.active ? (
        <AdminMgmtUserEdit
          user={user}
          onClose={editStateFormControls.deactivate}
          onSubmitted={viewControls.activate}
        />
      ) : (
        <AdminMgmtUserDetails
          user={user}
          onEdit={editStateFormControls.activate}
        />
      )}
    </div>
  )
}
