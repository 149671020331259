import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Input } from 'components'
import { MgmtEntryForm } from 'modules/management/shared'

import {
  adminMgmtInviteUserFormResolver,
  type AdminMgmtInviteUserFormData,
} from './adminMgmtInviteUserFormSchema'

interface AdminManagementInviteUserFormProps {
  onSubmit: (data: AdminMgmtInviteUserFormData) => void
  onClose: () => void
}

export const AdminManagementInviteUserForm = ({
  onSubmit,
  onClose,
}: AdminManagementInviteUserFormProps) => {
  const form = useForm<AdminMgmtInviteUserFormData>({
    resolver: adminMgmtInviteUserFormResolver,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form

  const renderControlledInput = (
    name: keyof AdminMgmtInviteUserFormData,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? undefined}
          inputClassName='h-8 px-3'
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          error={errors[name]?.message}
          isError={!!errors[name]}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )

  return (
    <FormProvider {...form}>
      <MgmtEntryForm
        submitButtonLabel='Invite'
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
      >
        {renderControlledInput('name', 'Full Name')}
        {renderControlledInput('email', 'Email Address')}
      </MgmtEntryForm>
    </FormProvider>
  )
}
