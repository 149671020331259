import { useCallback, useState } from 'react'
import { PaginationChangeParams, PaginationToolbar } from 'components'
import { managementRowsPerPageOptions } from 'constnants'
import { MgmtLayout } from 'modules/management/shared'

import { useSelector } from 'react-redux'
import { useTalentManagementActions } from '../../hooks/useTalentManagementActions'
import { talentManagementSelectors } from '../../slices'
import {
  TalentManagementTable,
  TalentManagementTableTypes,
} from '../../components/TalentManagementTable'
import { useGetTalentManagementDataQuery } from 'store/api/mock-api'
import { TalentManagementToolbar } from '../../components/TalentManagementToolbar'
import { TalentManagementTab } from '../../types'

export const TalentManagementPage = () => {
  const actions = useTalentManagementActions()
  const { pageConfig, sorting } = useSelector(talentManagementSelectors.root)
  const [searchTerm, setSearchTerm] = useState('')

  const activeTab = useSelector(talentManagementSelectors.activeTab)

  const handlePagination = useCallback(
    (params: PaginationChangeParams) =>
      actions.setPageConfig({
        pageNumber: params.page,
        pageSize: params.pageSize,
      }),
    [actions],
  )
  const handleSortChange: TalentManagementTableTypes['OnSortChange'] =
    useCallback(({ sortConfig }) => actions.setSorting(sortConfig), [actions])

  const {
    TalentManagement: talentManagementMockData,
    totalPages,
    totalRows,
    isFetching,
    isLoading,
  } = useGetTalentManagementDataQuery(
    {
      pageSize: pageConfig.pageSize,
      pageNumber: pageConfig.pageNumber,
      sortBy: sorting.by,
      sortOrder: sorting.order,
      searchTerm: searchTerm.length ? searchTerm : undefined,
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        return {
          ...rest,
          TalentManagement: data?.data ?? [],
          totalPages: data?.totalPages ?? 0,
          totalRows: data?.totalRecords ?? 0,
        }
      },
    },
  )

  const paginationBar = (
    <PaginationToolbar
      totalPages={totalPages}
      totalRows={totalRows}
      currentPage={pageConfig.pageNumber}
      currentPageSize={pageConfig.pageSize}
      pageSizeOptions={managementRowsPerPageOptions}
      onChange={handlePagination}
    />
  )
  const activeTalents = talentManagementMockData.filter(
    talent => talent.isActive,
  )
  const archivedTalents = talentManagementMockData.filter(
    talent => !talent.isActive,
  )

  return (
    <MgmtLayout>
      <div className='flex h-full flex-col'>
        <TalentManagementToolbar onSearchChange={setSearchTerm} />

        {activeTab === TalentManagementTab.Active && (
          <TalentManagementTable
            rows={activeTalents}
            footer={paginationBar}
            loading={isLoading}
            fetching={isFetching}
            sortConfig={sorting}
            onSortChange={handleSortChange}
          />
        )}

        {activeTab === TalentManagementTab.Archive && (
          <TalentManagementTable
            rows={archivedTalents}
            footer={paginationBar}
            loading={isLoading}
            fetching={isFetching}
            sortConfig={sorting}
            onSortChange={handleSortChange}
          />
        )}
      </div>
    </MgmtLayout>
  )
}
