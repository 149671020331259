import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useState, useEffect } from 'react'

interface UseEntitySearchProps {
  onSearch: (searchTerm: string) => void
}

export const useEntitySearchByField = ({ onSearch }: UseEntitySearchProps) => {
  const [searchTerm, setSearchTerm] = useState('')

  const searchHandler = useCallback(
    async (term: string) => {
      if (term.length >= 3 || term.length === 0) {
        onSearch(term)
      }
    },
    [onSearch],
  )

  const debouncedLoadData = useCallback(debounce(searchHandler, 750), [
    searchHandler,
  ])

  useEffect(() => () => debouncedLoadData.cancel(), [debouncedLoadData])

  const handleInputChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(ev.target.value)
      debouncedLoadData(ev.target.value)
    },
    [debouncedLoadData],
  )

  return {
    searchTerm,
    setSearchTerm,
    handleInputChange,
  }
}
