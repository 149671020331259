import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface AdminMgmtUserDetailsRowProps {
  label: string
  children: ReactNode
  isEditable?: boolean
  hasBorder?: boolean
}

export const AdminMgmtUserDetailsRow = ({
  label,
  children,
  isEditable = false,
  hasBorder = true,
}: AdminMgmtUserDetailsRowProps) => (
  <div
    className={twMerge(
      `flex items-center px-4 py-3 pr-3`,
      hasBorder && 'border-b',
    )}
  >
    <p className='w-24 text-sm font-medium lg:w-48'>{label}</p>
    <div className='flex flex-grow items-center justify-between'>
      {isEditable ? (
        children
      ) : (
        <div className='w-full text-sm leading-6'>{children}</div>
      )}
    </div>
  </div>
)
