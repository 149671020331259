import type { AdminMgmtUserResolvedStatus } from '../../types'

interface AdminMgmtResolvedStatusIndicatorProps {
  status: AdminMgmtUserResolvedStatus
}

export const AdminMgmtResolvedStatusIndicator = ({
  status,
}: AdminMgmtResolvedStatusIndicatorProps) => {
  if (status === 'pending') {
    return <span className='h-2 w-2 rounded-full bg-tertiary-inverse'></span>
  }

  if (status === 'paused') {
    return <span className='h-2 w-2 rounded-full bg-red-10'></span>
  }

  if (status === 'online') {
    return <span className='h-2 w-2 rounded-full bg-green-6'></span>
  }

  return null
}
