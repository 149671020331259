import React, { useState, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { Close, Help, Logo } from 'components/ui/icons'
import { Colors } from '../../constnants'
import { EditCurrentUser } from '../EditCurrentUser'
import { useClickOutsideComponent } from '../../hooks'
import { useGetCurrentUserQuery, useUpdateLoginInfoMutation } from 'store/api'
import { handleSignOut } from 'utils/amplify/auth-amplify'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from 'context'
import { api } from 'store/api/api'
import { useDispatch } from 'react-redux'

interface AvatarProps {
  initialsSize?: 'sm' | 'md' | 'lg'
  enableClick?: boolean
  justify?: 'left' | 'right' | 'center'
  className?: string
}

export const Avatar = ({
  initialsSize = 'sm',
  enableClick = false,
  justify = 'center',
  className,
}: AvatarProps) => {
  const { auth } = useAuthContext()
  const disaptch = useDispatch()
  const navigate = useNavigate()
  const [updateLoginInfo] = useUpdateLoginInfoMutation()

  const { data: user, error } = useGetCurrentUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const initials = useMemo(() => {
    if (user) {
      const { first_name, last_name, name } = user

      if (first_name || last_name)
        return first_name.charAt(0) + last_name?.charAt(0) //todo fix name to take 2 characters

      return name?.charAt(0)
    }
    return ''
  }, [user])

  const handleResetStore = () => disaptch(api.util.resetApiState())

  // if (auth.isSignOut && error && error?.data?.message === 'Unauthorized') {
  //   navigate('/login')
  // }

  const handleSignOutClick = async () => {
    await updateLoginInfo({
      lastLogoutDate: new Date().toISOString(),
    }).unwrap()
    await handleSignOut()
    handleResetStore()
    navigate('/login')
  }
  return (
    <div
      className={twMerge(
        `flex items-center justify-${justify} px-1 hover:rounded-md hover:bg-primary-white/20`,
        enableClick && 'relative',
        className,
      )}
    >
      <div
        className={twMerge(
          'flex h-6 w-6 items-center justify-center rounded-full bg-green-1 hover:cursor-pointer',
          initialsSize === 'md' && 'h-8 w-8',
        )}
        onClick={() => {
          setIsComponentVisible(!isComponentVisible)
        }}
      >
        <span
          className={twMerge(
            'text-[10px] font-semibold uppercase leading-4 text-green-7',
            initialsSize === 'md' && 'text-sm',
          )}
        >
          {initials}
        </span>
      </div>
      {enableClick && isComponentVisible && (
        <div
          ref={ref}
          className='absolute right-0 top-full z-50 rounded-md bg-primary-white p-3 shadow-md'
        >
          <ul className='whitespace-nowrap'>
            <li
              className='rounded-md px-1 py-0.5 transition-colors hover:cursor-pointer hover:bg-green-2'
              onClick={() => {
                setIsModalVisible(true)
              }}
            >
              Your settings
            </li>
            <li
              className='rounded-md px-1 py-0.5 text-red-10 transition-colors hover:cursor-pointer hover:bg-green-2'
              onClick={handleSignOutClick}
            >
              Log out
            </li>
          </ul>
        </div>
      )}
      {enableClick && isModalVisible && (
        <div className='fixed left-0 top-0 z-[51] flex h-full w-full flex-col bg-primary-blue'>
          <div className='flex items-center justify-between p-1.5 pr-3'>
            <Logo width={30} height={32} />
            <div className='flex items-center gap-3 py-3'>
              <Help
                fill={Colors.NAV_ICON_ONE}
                width={20}
                height={20}
                className='hover:cursor-pointer'
              />
              <Close
                fill={Colors.NAV_ICON_ONE}
                width={20}
                height={20}
                className='hover:cursor-pointer'
                onClick={() => {
                  setIsModalVisible(false)
                }}
              />
            </div>
          </div>
          <div className='flex grow items-center justify-center'>
            <EditCurrentUser />
          </div>
        </div>
      )}
    </div>
  )
}
