import { useLocation } from 'react-router-dom'
import {
  DeleteModal,
  FilterButton,
  FilterColumnGroup,
  Input,
  InviteUserModal,
  LayoutContentWrapper,
  Select,
} from 'components'
import { usePageTitle } from '../../hooks'
import {
  CheckCircle,
  Pause,
  Play,
  Plus,
  Search,
  Trash,
} from '../../components/ui/icons'
import React, { useEffect, useState } from 'react'
import { UsersTable } from '../../components/UserManagementTable'
import { usersColumns, userTypesFilterOptions, Colors } from '../../constnants'
import { UserEditPage } from './UserEditPage'
import { SortByOptions, SortOrderOptions, userManagement } from '../../types'
import {
  useGetManagementUsersListQuery,
  useUpdatedManagementUserMutation,
  useGetCompaniesListQuery,
} from 'store/api'
import { formatDate, getUserName } from '../../utils'

interface warningData {
  title: string
  description: string
  deleteTitle: string
  onConfirm?: () => void
  type: string
  deleteClassName?: string
  userToEdit: string
}

const initialActiveFilters: {
  userType: string[]
  companyFilter: string
  search: string
} = {
  userType: [],
  companyFilter: '',
  search: '',
}

const userDataPlaceholder = {
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  jobTitle: '',
  userType: '',
  lastLogin: '',
  status: '',
}

const initialUserProfile: userManagement = userDataPlaceholder

const warningInitialData = {
  title: '',
  description: '',
  deleteTitle: '',
  type: '',
  deleteClassName: '',
  userToEdit: '',
}

export const UserManagementPage = () => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const preselectedCompany = urlParams.get('company')
  const [updatedUser] = useUpdatedManagementUserMutation()
  const [companyFilterOptions, setCompanyFilterOptions] = useState<
    { id: string; value: string; label: string }[]
  >([])

  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const [sortingBY, setSortingBY] = useState<SortByOptions>('first_name')
  const [sortingOrder, setSortingOrder] = useState<SortOrderOptions>('ASC')
  const [totalUsersCount, setTotalUsersCount] = useState(14)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [filteredUsers, setFilteredUsers] = useState<userManagement[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null,
  )
  const [userProfileOpen, setUserProfileOpen] =
    useState<userManagement>(initialUserProfile)
  const [openWarningPopup, setOpenWarningPopup] = useState<boolean>(false)
  const [warningModalData, setWarningModalData] =
    useState<warningData>(warningInitialData)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false)
  const pageTitle = usePageTitle()

  const {
    data: usersListResponse,
    error,
    isLoading,
  } = useGetManagementUsersListQuery({
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    sortBy: sortingBY,
    sortOrder: sortingOrder,
    userName: activeFilters.search,
    userTypeFilter: activeFilters.userType.join(','),
    companyId: activeFilters.companyFilter,
  })

  const { data: companiesList } = useGetCompaniesListQuery({})

  if (error) {
    if (error.data.message === 'Unauthorized') {
      localStorage.removeItem('access_token')
      localStorage.setItem('auth', 'false')
      window.location.assign('/login')
    } else {
      console.error('error', error) //eslint-disable-line
    }
  }

  useEffect(() => {
    if (companiesList && companiesList.length > 0) {
      setCompanyFilterOptions(
        companiesList.map(company => ({
          id: company.id,
          value: company.id,
          label: company.name,
        })),
      )
    }
  }, [companiesList])

  useEffect(() => {
    if (usersListResponse) {
      setTotalUsersCount(usersListResponse.total)
      setFilteredUsers(usersListResponse.data)
    }
  }, [usersListResponse, activeFilters, currentPage, rowsPerPage])

  useEffect(() => {
    if (preselectedCompany) {
      setActiveFilters({
        ...activeFilters,
        companyFilter: preselectedCompany,
      })
    }
  }, [preselectedCompany])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    const newTimeout = setTimeout(() => {
      setActiveFilters({ ...activeFilters, search: e.target.value })
    }, 500)
    setTypingTimeout(newTimeout)
  }
  const resetFilters = () => {
    setActiveFilters(initialActiveFilters)
    setSearchValue('')
  }

  const handleOpenWarning = (field: string, name?: string, userId?: string) => {
    const userName = name
      ? name
      : getUserName(
          userProfileOpen.name,
          userProfileOpen.firstName,
          userProfileOpen.lastName,
        )
    const userToEdit = userId ? userId : userProfileOpen.userId
    if (field === 'deactivate') {
      setWarningModalData({
        title: 'Are you sure you want to pause ' + userName + '?',
        description: 'This will pause the user from the company account.',
        deleteTitle: 'Pause user',
        type: 'deactivate',
        deleteClassName: 'bg-primary-red',
        userToEdit,
      })
    } else {
      setWarningModalData({
        title: 'Are you sure you want to reactivate ' + userName + '?',
        description: 'This will reactivate the user to the company account.',
        deleteTitle: 'Reactivate user',
        type: 'reactivate',
        deleteClassName: 'bg-primary-red',
        userToEdit,
      })
    }

    setOpenWarningPopup(true)
  }

  const successUpdated = () => {
    setOpenWarningPopup(false)
    setShowSuccessMessage(true)
    setUserProfileOpen(userDataPlaceholder)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
      setWarningModalData(warningInitialData)
    }, 3000)
  }

  const handleConfirm = () => {
    const status = warningModalData.type === 'deactivate' ? 'paused' : 'active'
    updatedUser({
      id: warningModalData.userToEdit,
      userData: { status },
    }).then(() => {
      successUpdated()
      setSuccessMessage(
        `User account ${status === 'paused' ? 'deactivated' : 'reactivated'}`,
      )
    })
  }

  const clearStatuses = () => {
    setShowSuccessMessage(true)
    setInviteUserModalOpen(false)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const handleInviteUser = () => {
    setSuccessMessage('New user invited')
    clearStatuses()
  }

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='h-full'
    >
      {userProfileOpen.userId ? (
        <UserEditPage
          userData={userProfileOpen}
          setUserProfileOpen={setUserProfileOpen}
          initialUserProfile={initialUserProfile}
          companyFilterOptions={companyFilterOptions}
        />
      ) : (
        <div className='p-3'>
          <div className='flex items-center justify-between'>
            <h1 className={'text-2xl font-semibold text-primary-black'}>
              {pageTitle}
            </h1>
            <div className={'flex items-center gap-4'}>
              <FilterButton
                name={'Filter'}
                containerMenuClassName='right-0'
                nameCLassName='font-normal text-sm items-center inline-flex'
                isMenuVisible={false}
                totalSelectedFilters={0}
                wrapperClasName='border-primary-black/20'
                wrapperMenuClassName='w-[300px] text-left'
                trianglePosition='right'
                innerClassName='py-0.5'
              >
                <div className='mb-5 flex items-center justify-between'>
                  <p className={'font-medium'}>Filter</p>
                  <span
                    className={'cursor-pointer text-red-9'}
                    onClick={() => resetFilters()}
                  >
                    Clear
                  </span>
                </div>
                <div className='mb-5 flex max-h-[400px] flex-row gap-10 overflow-auto'>
                  {
                    <FilterColumnGroup
                      key={'userTypes'}
                      category={'userTypes'}
                      title={'User Types'}
                      optionsFilter={userTypesFilterOptions}
                      isSelect={false}
                      selectedFilters={{ userTypes: activeFilters['userType'] }}
                      onChange={selected => {
                        setActiveFilters({
                          ...activeFilters,
                          userType: selected['userTypes'],
                        })
                      }}
                      allLabel={'All'}
                    />
                  }
                </div>
                <Select
                  label={'Filter by Company'}
                  labelClass={'uppercase text-xs text-primary-black'}
                  value={activeFilters['companyFilter']}
                  handleClick={option =>
                    setActiveFilters({
                      ...activeFilters,
                      companyFilter: option.value,
                    })
                  }
                  options={companyFilterOptions}
                  size='small'
                  placeholder={'All'}
                  defaultValue={'all'}
                  alignItems='start'
                  buttonClass={'whitespace-nowrap'}
                  hasRadioButton={false}
                  hasSearch={false}
                />
              </FilterButton>
              <Input
                containerWrapperClassName='w-[200px] h-6'
                placeholder='Search Name'
                value={searchValue}
                onChange={handleSearchChange}
                leftIcon={
                  <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
                }
              />
              <button
                className='inline-flex items-center gap-1 rounded-full bg-primary-red px-4 py-1.5 text-sm font-semibold text-primary-white transition-colors hover:bg-red-9'
                onClick={() => {
                  setInviteUserModalOpen(true)
                }}
              >
                New User
                <Plus fill={Colors.ICON_WHITE} width={20} height={20} />
              </button>
            </div>
          </div>
          <UsersTable
            columns={usersColumns}
            data={filteredUsers}
            onSort={setSortingOrder}
            onSortOrder={setSortingBY}
            isLoading={isLoading}
            sortingBY={sortingBY}
            totalUsersCount={totalUsersCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setUserProfileOpen={setUserProfileOpen}
            handleOpenWarning={handleOpenWarning}
          />
          {showSuccessMessage && (
            <div className='fixed bottom-12 right-4 rounded-md bg-primary-white p-3 shadow-md'>
              <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
                {warningModalData.type === 'pause' ? (
                  userProfileOpen.status === 'paused' ? (
                    <Play fill={Colors.BASE_ICON} width={19} height={19} />
                  ) : (
                    <Pause fill={Colors.BASE_ICON} width={19} height={19} />
                  )
                ) : warningModalData.type === 'delete' ? (
                  <Trash
                    fill={Colors.ICON_NEGATIVE_RED}
                    width={19}
                    height={19}
                  />
                ) : (
                  <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
                )}{' '}
                {successMessage}
              </p>
            </div>
          )}
          <DeleteModal
            isOpen={openWarningPopup}
            handleClose={() => setOpenWarningPopup(false)}
            title={warningModalData.title}
            description={warningModalData.description}
            onDelete={() => {
              handleConfirm()
            }}
            deleteTitle={warningModalData.deleteTitle}
            zIndex={900}
            descriptionClassName={'text-center'}
            titleClassName={'text-center mb-1'}
            icon={
              warningModalData.type === 'pause' ? (
                <Pause fill={Colors.BASE_ICON} width={19} height={19} />
              ) : warningModalData.type === 'reactivate' ? (
                <Play fill={Colors.BASE_ICON} width={40} height={40} />
              ) : (
                <Trash fill={Colors.ICON_NEGATIVE_RED} width={40} height={40} />
              )
            }
            deleteClassName={warningModalData.deleteClassName}
          />
          <InviteUserModal
            title={'Invite New Company User'}
            requestType={'addUserToCompany'}
            companyOptions={companyFilterOptions}
            isOpen={inviteUserModalOpen}
            handleClose={() => setInviteUserModalOpen(false)}
            onSubmit={() => {
              handleInviteUser()
            }}
            modalZIndex={900}
          />
          {showSuccessMessage && (
            <div className='fixed bottom-12 right-4 rounded-md bg-primary-white p-3 shadow-md'>
              <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
                <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
                {successMessage}
              </p>
            </div>
          )}
        </div>
      )}
    </LayoutContentWrapper>
  )
}
