import { Button } from 'components'

interface TalentCreationToolbarProps {
  title?: string
  onClose: () => void
}

export const TalentCreationToolbar = ({
  title = 'New Talent',
  onClose,
}: TalentCreationToolbarProps) => {
  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <h1 className={'mr-12 text-2xl font-semibold text-primary-black'}>
        {title}
      </h1>

      <div className='flex items-center gap-4'>
        <Button
          kind='text'
          size='medium'
          onClick={onClose}
          className='py-2.5'
          type='button'
        >
          <span className='px-1 text-sm font-semibold'>x</span>
        </Button>
      </div>
    </div>
  )
}
