import { useNavigate, useParams } from 'react-router-dom'
import { MgmtLayout } from 'modules/management/shared'
import { TalentManagementForm, TalentUpdateToolbar } from '../../components'
import {
  useGetTalentByIdManagementDataQuery,
  useUpdateTalentManagementMutation,
} from 'store/api/mock-api'
import { useCallback } from 'react'
import { TalentManagementFormData } from '../../components/TalentManagementForm/TalentManagementFormSchema'
import { fromInches, toInches } from 'utils'
import { TalentManagementData } from '../../types'

export const EditTalentPage = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { data: talent } = useGetTalentByIdManagementDataQuery({ id })

  const [updateTalent] = useUpdateTalentManagementMutation()

  const cancel = useCallback(() => navigate('/talentManagement'), [navigate])

  const updateTalentData = useCallback(
    async (updates: TalentManagementData) => {
      if (!id) return
      try {
        await updateTalent({ talentId: id, updates })
      } finally {
        cancel()
      }
    },
    [id, updateTalent, cancel],
  )

  const handleSubmit = useCallback(
    async (data: TalentManagementFormData) => {
      if (!id) {
        console.error('No talent ID found')
        return
      }
      const updates: TalentManagementData = {
        ...data,
        id: id,
        height: toInches(data.height.feet, data.height.inches),
      }
      await updateTalentData(updates)
    },
    [updateTalentData],
  )

  const handleStatusChange = useCallback(() => {
    if (!talent) return
    const updates = { ...talent, isActive: !talent.isActive }
    updateTalentData(updates)
  }, [talent, updateTalentData])

  if (!talent || !id) return null

  const defaultValues = {
    ...talent,
    height: fromInches(talent.height),
  }

  return (
    <MgmtLayout>
      <div className='flex h-full flex-col'>
        <TalentUpdateToolbar
          title={talent.fullName}
          isActive={talent.isActive}
          onStatusChange={handleStatusChange}
        />
        <div className='flex justify-center align-middle'>
          <TalentManagementForm
            defaultValues={defaultValues}
            submitButtonLabel='Edit Talent'
            onClose={cancel}
            onSubmit={handleSubmit}
            onStatusChange={handleStatusChange}
            isActive={talent.isActive}
          />
        </div>
      </div>
    </MgmtLayout>
  )
}
