import {
  AuthError,
  autoSignIn,
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updatePassword,
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
} from 'aws-amplify/auth'
import { AuthUpdatePasswordInput } from '@aws-amplify/auth/src/types'

type SignInParameters = {
  username: string
  password: string
}

export async function updateUserPassword({
  oldPassword,
  newPassword,
}: AuthUpdatePasswordInput) {
  try {
    const updatedUserPassword = await updatePassword({
      oldPassword,
      newPassword,
    })

    alert('Your password is changed successfully')
    return updatedUserPassword
  } catch (e) {
    console.log(e)
  }
}

export async function handleSignIn({ username, password }: SignInParameters) {
  try {
    const signInResponse = await signIn({ username, password })
    console.log('signInResponse', signInResponse)
    const { isSignedIn, nextStep } = signInResponse
    console.log('isSignedIn', isSignedIn, nextStep)
    if (nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      // alert('You need to confirm your sign in with new password')
    }
    if (nextStep?.signInStep === 'CONFIRM_SIGN_UP') {
      alert(
        'You need to confirm your sign up. Please check your email for the confirmation link.',
      )
    }
    if (isSignedIn) {
      localStorage.setItem('auth', 'true')
    } else {
      localStorage.removeItem('auth')
    }
    return { isSignedIn, nextStep }
  } catch (error) {
    if (error instanceof AuthError) {
      if (error?.name === 'UserAlreadyAuthenticatedException') {
        return { isSignedIn: true, nextStep: null }
      }

      if (
        error?.name === 'NotAuthorizedException' &&
        error.message === 'User is disabled.'
      ) {
        return { isSignedIn: false, nextStep: null, isDisabled: true }
      }

      console.error('AuthError:', error, error?.name)
      alert(error?.message)
    } else {
      console.error('Any Error:', error)
    }
    return { isSignedIn: false, nextStep: null }
  }
}

export async function handlePasswordChange(newPassword: string) {
  try {
    const updatePasswordResponse = await confirmSignIn({
      challengeResponse: newPassword,
    })
    const { isSignedIn, nextStep } = updatePasswordResponse
    console.log('isSignedIn', isSignedIn, nextStep)
    console.log('updatePasswordResponse', updatePasswordResponse)
    if (isSignedIn) {
      localStorage.setItem('auth', 'true')
    }
    return { isSignedIn, nextStep }
    // autoSignIn();
  } catch (err) {
    console.log(err)
  }
  return { isSignedIn: false, nextStep: null }
}
export async function handleSignOut() {
  try {
    await signOut()
    localStorage.removeItem('auth')
    console.log('sign out successful')
  } catch (error) {
    console.log('error signing out: ', error)
  }
}

export async function currentAuthenticatedUser() {
  try {
    const { username, userId, signInDetails } = await getCurrentUser()
    console.log(`The username: ${username}`)
    console.log(`The userId: ${userId}`)
    console.log(`The signInDetails: ${signInDetails}`)
    return { username, userId, signInDetails }
  } catch (err) {
    console.log(err)
    return null
  }
}

export async function currentSession() {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {}
    return idToken
  } catch (err) {
    console.log(err)
    return ''
  }
}

export async function handleForgotPassword(email: string) {
  try {
    const resetSteps = await resetPassword({ username: email })
    console.log('resetSteps', resetSteps)
    return resetSteps
  } catch (error) {
    console.log('error forgot password: ', error)
  }
  return null
}

export async function confirmNewPassword(
  email: string,
  confirmationCode: string,
  newPassword: string,
) {
  try {
    const confirmation = await confirmResetPassword({
      username: email,
      confirmationCode,
      newPassword,
    })
    console.log('confirmation', confirmation)
    return true
  } catch (error) {
    console.log('error confirm new password: ', error)
  }
  return null
}

export const sendEmailVerificationCode = async () => {
  try {
    return await sendUserAttributeVerificationCode({
      userAttributeKey: 'email',
    })
  } catch (error) {
    console.error('Error sending email verification code:', error)
    throw error
  }
}

export const confirmVerificationCode = async (code: string) => {
  try {
    return await confirmUserAttribute({
      userAttributeKey: 'email',
      confirmationCode: code,
    })
  } catch (error) {
    console.error('Error confirming verification code:', error)

    if (error.code === 'CodeMismatchException') {
      throw new Error('Invalid verification code. Please try again.')
    }

    throw new Error('An unexpected error occurred. Please try again later.')
  }
}
