import * as api from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'
import { Amplify } from 'aws-amplify'
export const apiName = 'GreenLightAPI'
interface JsonResponse<T> {
  headers: any
  body: T
}

type ApiRequest = {
  apiName: string
  path: string
  data?: any
  options?: {
    includeAuthorizationHeader?: boolean
    headers?: any
  }
}

export class AwsAmplifyRestApiUtils {
  static async getJson<T>(req: ApiRequest): Promise<JsonResponse<T>> {
    console.log('apiName:', Amplify.getConfig())
    const { apiName, path, ...rest } = req
    const headers = await AwsAmplifyRestApiUtils.getAuthorizationHeader(
      rest.options?.includeAuthorizationHeader || true,
    )

    const response = await api.get({
      apiName,
      path,
      options: {
        queryParams: rest.data,
        headers,
      },
    }).response

    const json = await response.body.json()

    return { headers: response.headers, body: json as T }
  }

  static async post(req: ApiRequest) {
    const { apiName, path, ...rest } = req
    const headers = await AwsAmplifyRestApiUtils.getAuthorizationHeader(
      rest.options?.includeAuthorizationHeader || true,
    )
    return api.post({
      apiName,
      path,
      options: { body: rest.data, headers },
    }).response
  }

  static async postJson<T>(req: ApiRequest): Promise<T> {
    const res = await AwsAmplifyRestApiUtils.post(req)
    const json = await res.body.json()
    return json as T
  }

  static async put(req: ApiRequest) {
    const { apiName, path, ...rest } = req
    const headers = await AwsAmplifyRestApiUtils.getAuthorizationHeader(
      rest.options?.includeAuthorizationHeader || true,
    )
    return api.put({
      apiName,
      path,
      options: { body: rest.data, headers },
    }).response
  }

  static async putJson<T>(req: ApiRequest): Promise<T> {
    const res = await AwsAmplifyRestApiUtils.put(req)
    const json = await res.body.json()
    return json as T
  }

  static async delete(req: ApiRequest) {
    const { apiName, path, ...rest } = req
    const headers = await AwsAmplifyRestApiUtils.getAuthorizationHeader(
      rest.options?.includeAuthorizationHeader || true,
    )
    return api.del({
      apiName,
      path,
      options: { headers },
    }).response
  }

  static async getAuthorizationHeader(include?: boolean): Promise<any> {
    const { idToken } = (await fetchAuthSession()).tokens ?? {}
    return include
      ? {
          Authorization: `Bearer ${idToken}`,
        }
      : {}
  }
}

export default AwsAmplifyRestApiUtils
