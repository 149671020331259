import React, { ReactNode } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { Colors } from 'constnants'
import { Home } from 'components/ui/icons'
import { formatBreadcrumbNavigation } from 'utils'
import { useGetAllTalentsQuery } from 'store/api'

export type BreadcrumbProps = {
  separator?: ReactNode
  isAdmin?: boolean
}

export const Breadcrumb = ({ separator, isAdmin = false }: BreadcrumbProps) => {
  const location = useLocation()
  const currentPath = location.pathname
  const pathNames = makePathNames()

  const showHomeText =
    /^\/(?:talent|film|conceptTesting|assetTesting|roleTesting)(\/[^/]+)?$/.test(
      currentPath,
    )
  const { data: talentsData } = useGetAllTalentsQuery()

  function makePathNames() {
    const pN = currentPath.split('/').filter(path => path)

    const isListDetails = pN.includes('listDetails')

    if (isListDetails) {
      pN.splice(1, 1)
      pN.pop()
    }

    return pN
  }

  const talentName = (id: string): string => {
    if (talentsData) {
      const talent = talentsData.find(
        talent => talent.talent_id.toString() === id,
      )
      return talent ? talent.talent_name : ''
    }

    return ''
  }

  return (
    <nav>
      <ol className='flex items-center space-x-1'>
        {!isAdmin && (
          <>
            <li
              className={twMerge(
                'flex place-content-center rounded px-1 text-xs font-medium text-green-1 hover:bg-white/20 hover:py-0.5',
              )}
            >
              <Link to='/'>
                {showHomeText ? (
                  <span className=''>Home</span>
                ) : (
                  <Home fill={Colors.NAV_ICON_ONE} width={16} height={16} />
                )}
              </Link>
            </li>

            {pathNames.length > 0 && <div>{separator}</div>}
          </>
        )}

        {pathNames.map((path, index) => {
          const to = `/${pathNames.slice(0, index + 1).join('/')}`
          const isLast = index === pathNames.length - 1
          const isTalentTitle =
            pathNames.includes('talent') && path !== 'talent'

          const isFilmTitle = pathNames.includes('film') && path !== 'film'
          const pathTitle = (isTalentTitle && talentName(path)) || path
          const breadcrumbLabel = formatBreadcrumbNavigation(pathTitle)

          return isLast ? (
            <li
              key={to}
              className={twMerge(
                'rounded px-1 text-xs font-medium text-green-1 hover:bg-white/20 hover:py-0.5',
              )}
              aria-current='page'
            >
              {breadcrumbLabel}
            </li>
          ) : (
            <li
              key={to}
              className={twMerge(
                'flex place-content-center rounded text-xs font-medium text-green-1 hover:bg-white/20 hover:py-0.5',
              )}
            >
              <Link to={to} className='px-1'>
                {breadcrumbLabel}
              </Link>
              {pathNames.length !== index + 1 && <div>{separator}</div>}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
