import { useState } from 'react'
import { useSelector } from 'react-redux'
import { MgmtLayout } from 'modules/management/shared'
import { ReleaseCalendarToolbar } from '../../components'
import {
  ReleaseCalendarSportingEventsView,
  ReleaseCalendarHolidayEventsView,
  ReleaseCalendarPastRevenueView,
} from '../../views'
import { releaseCalendarMgmtSelectors } from '../../slices'
import { ReleaseCalendarTab } from '../../types'

export const ReleaseCalendarMgmtPage = () => {
  const activeTab = useSelector(releaseCalendarMgmtSelectors.activeTab)
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <MgmtLayout>
      <div className='flex h-full flex-col'>
        <ReleaseCalendarToolbar onSearchChange={setSearchTerm} />

        {activeTab === ReleaseCalendarTab.SportingEvents && (
          <ReleaseCalendarSportingEventsView searchTerm={searchTerm} />
        )}

        {activeTab === ReleaseCalendarTab.HolidayEvents && (
          <ReleaseCalendarHolidayEventsView searchTerm={searchTerm} />
        )}

        {activeTab === ReleaseCalendarTab.PastRevenue && (
          <ReleaseCalendarPastRevenueView />
        )}
      </div>
    </MgmtLayout>
  )
}
