import { twMerge } from 'tailwind-merge'

export interface MgmtPageTab<TabId extends string> {
  id: TabId
  label: string
}

interface MgmtPageTabsProps<TabId extends string> {
  tabs: MgmtPageTab<TabId>[]
  activeTab: TabId
  onTabChange: (tabId: TabId) => void
}

export const MgmtPageTabs = <TabId extends string>({
  tabs,
  activeTab,
  onTabChange,
}: MgmtPageTabsProps<TabId>) => {
  return (
    <div className='-mb-3 flex grow gap-4'>
      {tabs.map(tab => (
        <div
          key={tab.id}
          className={twMerge(
            'mt-1 flex cursor-pointer flex-col border-b-2 border-b-blue-5/0 pt-2 text-center',
            activeTab === tab.id && 'border-b-blue-5 font-medium text-blue-5',
          )}
          onClick={() => onTabChange(tab.id)}
        >
          <span>{tab.label}</span>
          <span className='invisible h-0 font-medium'>{tab.label}</span>
        </div>
      ))}
    </div>
  )
}
