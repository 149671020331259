import { useCallback } from 'react'
import { Button } from 'components'
import { adminMgmtUserStatusTypeLabelMapping } from '../../config'
import { AdminMgmtUser, AdminMgmtUserAccountStatus } from '../../types'
import { AdminMgmtUserDetailsRow } from '../AdminMgmtUserDetailsRow'
import { AdminMgmtResolvedStatusIndicator } from '../AdminMgmtResolvedStatusIndicator'
import { useGaAdminProfileControllerResendAdminInviteMutation } from 'store/api/'

interface AdminMgmtAccountStatusRowProps {
  user: AdminMgmtUser
  accountStatus: AdminMgmtUserAccountStatus
}

export const AdminMgmtAccountStatusRow = ({
  user,
  accountStatus,
}: AdminMgmtAccountStatusRowProps) => {
  const accountStatusLabel = adminMgmtUserStatusTypeLabelMapping[accountStatus]
  const [resendInvite] = useGaAdminProfileControllerResendAdminInviteMutation()
  const handleResetInviteClick = useCallback(async () => {
    try {
      await resendInvite({ userId: user.id }).unwrap()
    } catch (e) {
      if (e instanceof Error) {
        throw e
      }
    }
  }, [])

  return (
    <AdminMgmtUserDetailsRow label='Account Status'>
      {accountStatus === 'pending' ? (
        <div className='flex w-full  items-center justify-between text-sm text-primary-black'>
          <div className='flex items-baseline gap-1'>
            <AdminMgmtResolvedStatusIndicator status={accountStatus} />
            <span>{accountStatusLabel}</span>
          </div>
          <Button
            type='button'
            size='small'
            kind='text'
            className='bg-primary-grey text-primary-white hover:bg-primary-grey/50'
            onClick={handleResetInviteClick}
          >
            Resend Invite
          </Button>
        </div>
      ) : (
        accountStatusLabel
      )}
    </AdminMgmtUserDetailsRow>
  )
}
