import { OptionFilter, ReleaseCalendarData, SelectedOptions } from '../../types'
import { groupBy } from 'lodash'
const EVENT_HEIGHT_STREAMER = 20
const EVENT_HEIGHT_BROADCAST = 20
const EVENT_HEIGHT_MINOR = 20
const EVENT_HEIGHT_MAJOR = 40
const EVENT_HEIGHT_OTHER = 20
const STREAMER_CLASS = 'truncate max-w-[123px]'
const BROADCAST_CLASS = 'truncate max-w-[123px]'
const MAJOR_CLASS = 'overflow-hidden text-ellipsis line-clamp-2'
const MINOR_CLASS = 'truncate max-w-[123px]'
const OTHER_CLASS = 'truncate max-w-[123px]'

const isFilterMatch = (
  options: OptionFilter<string>[],
  comparator: (item: OptionFilter<string>) => boolean,
) => (options?.length ? options.some(comparator) : true)

// Function to build the required structure for an array of objects
export const buildFilmCompanyReleases = (
  dataArray: any,
  selectedOptions: SelectedOptions,
): ReleaseCalendarData[] => {
  // data filtering logic based on selected options
  // selectedOptions.major?.some(option => option.value === companyType);
  const getValue = (item: any) => {
    // check release type_id and divide in tv or film
    const isFilm = item.release_type_key.startsWith('FilmRelease')
    if (isFilm) {
      return item.distributor_type?.toLowerCase()
    }
    let companyType = item.company_type?.toLowerCase()
    const splitCompanyType = companyType.split('/')
    // splitcompanytype distributor and return remaining value
    if (splitCompanyType.length > 1) {
      companyType = splitCompanyType[1]
    }
    if (companyType === 'cable') {
      companyType = 'broadcast'
    }
    return companyType
  }
  const filteredData = dataArray?.filter(data => {
    const {
      distributor_type,
      company_type,
      company_name,
      genre_id,
      film_group_id,
      release_type_key,
    } = data
    const distributorType = distributor_type?.toLowerCase()
    if (!distributor_type && !company_type) return true
    const isFilm = release_type_key.startsWith('FilmRelease')
    if (isFilm && distributor_type === 'Other') return true
    const companyName = company_name.toLowerCase()
    if (!isFilm) {
      const companyType = getValue(data)
      const genreFilter = isFilterMatch(
        selectedOptions?.allGenres,
        option => option.value === genre_id?.toString(),
      )
      if (!genreFilter) return genreFilter
      const releaseTypeFilter = isFilterMatch(
        selectedOptions?.allTVTypes,
        option => option.value === release_type_key,
      )
      if (!releaseTypeFilter) return releaseTypeFilter

      if (genreFilter && companyType.toLowerCase() === 'broadcast') {
        return isFilterMatch(
          selectedOptions.broadcast,
          option => option.value.toLowerCase() === companyName,
        )
      }
      if (genreFilter && companyType.toLowerCase() === 'streamer') {
        return isFilterMatch(
          selectedOptions.streamer,
          option => option.value.toLowerCase() === companyName,
        )
      }
      return genreFilter
    } else {
      if (film_group_id) {
        const groupFilter = isFilterMatch(
          selectedOptions?.allGroups,
          option => option.value === film_group_id?.toString(),
        )
        if (!groupFilter) return groupFilter
      }
      const releaseTypeFilter = isFilterMatch(
        selectedOptions?.wideLimited,
        option => option.value === release_type_key,
      )
      if (!releaseTypeFilter) return releaseTypeFilter
      if (distributorType === 'major') {
        return isFilterMatch(
          selectedOptions.major,
          option => option.value.toLowerCase() === companyName,
        )
      }
      if (distributorType === 'minor') {
        return isFilterMatch(
          selectedOptions.minor,
          option => option.value.toLowerCase() === companyName,
        )
      }
    }
    return true
  })
  // group data for by distirbutor type and companytype
  // Function to get the value for grouping

  const getReleaseType = (releaseType: string) => {
    switch (releaseType) {
      case 'FilmReleaseLimited':
        return 'L'
      case 'FilmReleaseWide':
        return 'W'
      default:
        return undefined
    }
  }
  const groupedData = groupBy(filteredData, getValue)
  const finalData = Object.keys(groupedData).reduce<ReleaseCalendarData[]>(
    (acc, key) => {
      const data = groupedData[key]
      console.warn('key', key, data)
      if (key === 'other') {
        acc.push({
          label: 'Other',
          value: 'other', // Assuming 'major' is a constant value
          id: 'Other',
          colorClass: 'bg-yellow-2',
          itemHeight: EVENT_HEIGHT_OTHER,
          nameClassName: OTHER_CLASS,
          events: data.map(item => ({
            name: `${item.film_name}`,
            startDay: formatDateId(item.start_date_id),
            endDay: formatDateId(item.end_date_id),
            distributorType: item.distributor_type,
            isOutline: true,
            abbreviation: item.abbreviation,
          })),
        })
      }
      if (key === 'broadcast') {
        acc.push({
          label: '',
          value: 'broadcast', // Assuming 'major' is a constant value
          id: 'Broadcast',
          colorClass: 'bg-red-10/50',
          itemHeight: EVENT_HEIGHT_BROADCAST,
          nameClassName: BROADCAST_CLASS,
          events: data.map(item => ({
            name: `${item.film_name}`,
            startDay: formatDateId(item.start_date_id),
            endDay: formatDateId(item.end_date_id),
            distributorType: item.distributor_type,
            isOutline: true,
            abbreviation: item.abbreviation,
          })),
        })
      }
      if (key === 'streamer') {
        acc.push({
          label: '',
          value: 'streamer', // Assuming 'major' is a constant value
          id: 'Streamer',
          colorClass: 'bg-red-10',
          itemHeight: EVENT_HEIGHT_STREAMER,
          nameClassName: STREAMER_CLASS,
          events: data.map(item => ({
            name: `${item.film_name}`,
            startDay: formatDateId(item.start_date_id),
            endDay: formatDateId(item.end_date_id),
            distributorType: item.distributor_type,
            isOutline: true,
            abbreviation: item.abbreviation,
          })),
        })
      }
      if (key === 'minor') {
        console.warn('minor... adding', data)
        acc.push({
          label: 'Minor',
          value: 'minor', // Assuming 'major' is a constant value
          id: 'Minor',
          colorClass: 'bg-yellow-5',
          itemHeight: EVENT_HEIGHT_MINOR,
          nameClassName: MINOR_CLASS,
          events: data.map(item => ({
            name: `${item.film_name}`,
            startDay: formatDateId(item.start_date_id),
            endDay: formatDateId(item.end_date_id),
            distributorType: item.distributor_type,
            isOutline: true,
            abbreviation: item.abbreviation,
          })),
        })
      }
      if (key === 'major') {
        const groupedData = groupBy(data, 'abbreviation')
        const majorData = Object.keys(groupedData).map(abbr => {
          const items = groupedData[abbr]

          // Create the merged event object for the current abbreviation
          return {
            label: abbr,
            value: 'major', // Assuming 'major' is a constant value
            id: `${items[0].company_name}(${abbr})`, // Using the company name from the first item
            colorClass: 'bg-yellow-2',
            itemHeight: EVENT_HEIGHT_MAJOR,
            nameClassName: MAJOR_CLASS,
            abbreviation: abbr,
            events: items.map(item => ({
              name: item.film_name,
              startDay: formatDateId(item.start_date_id),
              endDay: formatDateId(item.end_date_id),
              distributorType: item.distributor_type,
              releaseTypeKey: getReleaseType(item.release_type_key),
              hasBackground: item.release_type_key === 'FilmReleaseLimited',
              isOutline: item.release_type_key === 'FilmReleaseLimited',
            })),
          }
        })
        acc.push(...majorData)
      }
      return acc
    },
    [],
  )
  console.warn('finalData', finalData)
  return finalData
}
// Utility function to convert date_id (YYYYMMDD) to a 'YYYY-MM-DD' string format
const formatDateId = (dateId: number): string => {
  const year = Math.floor(dateId / 10000)
  const month = Math.floor((dateId % 10000) / 100)
  const day = dateId % 100
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
}
