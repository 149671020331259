import { OptionType } from 'types'

export function mapValuesToOptions<
  T extends string | number,
  R extends Record<T, string>,
>(sourceValues: Record<string, T> | T[], valueToLabelMap: R): OptionType<T>[] {
  const values = Array.isArray(sourceValues)
    ? sourceValues
    : Object.values(sourceValues)
  return values.map(value => ({
    id: String(value),
    value,
    label: valueToLabelMap[value],
  }))
}
