import { twMerge } from 'tailwind-merge'
import { UsersTableCellProps } from '../UsersTable.types'
import { getUserLastLoginStatus, getUserName } from 'utils'
import { retry } from '@reduxjs/toolkit/query'

export const UsersTableCell = ({
  column,
  row,
  isLoading = false,
}: UsersTableCellProps) => {
  const userTypeDisplayMap: { [key: string]: string } = {
    admin: 'Admin',
    superadmin: 'Organization Owner',
    moduleadmin: 'Department Owner',
    editor: 'Editor',
    viewer: 'Viewer',
  }

  const renderLastLoginStatus = () => {
    const lastLoginStatus = getUserLastLoginStatus(
      row.status,
      row.lastLogin,
      row.lastLogout,
    )
    if (lastLoginStatus === 'Online') {
      return (
        <>
          <span className='h-2 w-2 rounded-full bg-green-6'></span>
          Online
        </>
      )
    }

    if (lastLoginStatus === 'Inactive') {
      return (
        <>
          <span className='h-2 w-2 rounded-full bg-red-10'></span>
          Inactive
        </>
      )
    }

    if (lastLoginStatus === 'Pending') {
      return (
        <>
          <span className='h-2 w-2 rounded-full bg-tertiary-inverse'></span>
          Pending
        </>
      )
    }

    return lastLoginStatus
  }

  switch (column.value) {
    case 'first_name':
      return (
        <span
          className={twMerge(
            'flex text-xs font-medium text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {getUserName(row.name, row.firstName, row.lastName)}
        </span>
      )
    case 'email':
      return (
        <span
          className={twMerge(
            'flex',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          <span className='text-sm'>{row.email}</span>
        </span>
      )
    case 'company':
      return (
        <span
          className={twMerge(
            'flex items-center gap-1 text-xs font-medium capitalize',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.company}
        </span>
      )
    case 'job_title':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {row.jobTitle || '--'}
        </span>
      )
    case 'user_type':
      return (
        <span
          className={twMerge(
            'text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {userTypeDisplayMap[row.userType] || '--'}
        </span>
      )
    case 'last_login_date':
      return (
        <span
          className={twMerge(
            'flex items-baseline gap-1 text-xs text-primary-black',
            isLoading && 'bg-gray-500 text-transparent',
          )}
        >
          {renderLastLoginStatus()}
        </span>
      )
    default:
      return <span></span>
  }
}
