import {
  CastDto,
  FilmMetricDto,
  FilmMetricsControllerGetFilmTrackingApiArg,
  FilmMetricsControllerGetFilmMetricsByFilmIdApiResponse,
} from 'store/api'

export type FilmMetricsSortBy =
  FilmMetricsControllerGetFilmTrackingApiArg['sortBy']

export enum Theatergoers {
  ALL_RESPONDENTS = 'all',
  THEATERGOERS_ONLY = 'theatergoers',
}

export enum AudienceDemoGroup {
  BLACK = 'black',
  WHITE = 'white',
  LGBTQI = 'lgbtqi',
  GENERAL = 'general',
  HISPANIC_LATINO = 'hispanicLatino',
}

export type AudienceDemo =
  | AudienceGeneralDemo
  | AudienceRaceDemoBlack
  | AudienceRaceDemoWhite
  | AudienceRaceDemoHispLat
  | AudienceIdentityDemo

export enum AudienceGeneralDemo {
  ALL = 'ALL',
  MEN = 'GENDER_MALE',
  WOMEN = 'GENDER_FEMALE',
  BELOW_35 = 'AGE_SEGMENT_UNDER_35',
  ABOVE_35 = 'AGE_SEGMENT_35_PLUS',
  MEN_BELOW_35 = 'AGE_GENDER_SEGMENT_M_LESS',
  MEN_ABOVE_35 = 'AGE_GENDER_SEGMENT_M_PLUS',
  WOMEN_BELOW_35 = 'AGE_GENDER_SEGMENT_W_LESS',
  WOMEN_ABOVE_35 = 'AGE_GENDER_SEGMENT_W_PLUS',
}

export enum AudienceRaceDemoBlack {
  ALL = 'RACE_BLACK',
  MEN = 'RACE_BLACK_GENDER_MALE',
  WOMEN = 'RACE_BLACK_GENDER_FEMALE',
  BELOW_35 = 'RACE_BLACK_AGE_SEGMENT_UNDER_35',
  ABOVE_35 = 'RACE_BLACK_AGE_SEGMENT_35_PLUS',
}

export enum AudienceRaceDemoWhite {
  ALL = 'RACE_WHITE',
  MEN = 'RACE_WHITE_GENDER_MALE',
  WOMEN = 'RACE_WHITE_GENDER_FEMALE',
  BELOW_35 = 'RACE_WHITE_AGE_SEGMENT_UNDER_35',
  ABOVE_35 = 'RACE_WHITE_AGE_SEGMENT_35_PLUS',
}

export enum AudienceRaceDemoHispLat {
  ALL = 'RACE_HISPANIC',
  MEN = 'RACE_HISPANIC_GENDER_MALE',
  WOMEN = 'RACE_HISPANIC_GENDER_FEMALE',
  BELOW_35 = 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35',
  ABOVE_35 = 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS',
}

export enum AudienceIdentityDemo {
  ALL = 'IDENTITY_LGBT_QI',
}

export enum FilmMetricCategory {
  Awareness = 'awareness',
  Interest = 'interest',
  Location = 'location',
  PayToSee = 'paytosee',
  UnaidedAwareness = 'unaidedawareness',
  AwarenessInterest = 'awarenessinterest',
  Heat = 'heat',
  Plf = 'plf',
}

export type FilmMetric = FilmMetricDto
export type ColoredFilmMetric = {
  primaryColor: string
  secondaryColor: string
} & FilmMetric

export type FilmMetricsByFilmId =
  FilmMetricsControllerGetFilmMetricsByFilmIdApiResponse
export type Cast = CastDto

export interface FilmColors {
  [filmId: string]: string
}
