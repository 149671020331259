export const getUserName = (
  name: string | null,
  firstName: string | null,
  lastName: string | null | undefined,
) => {
  if (typeof name === 'string') {
    return name
  }

  return [firstName, lastName].filter(Boolean).join(' ')
}
