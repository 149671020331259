import { twMerge } from 'tailwind-merge'
import { MgmtTableCell } from 'modules/management/shared'
import { MoreVertical } from 'components'
import {
  ToggleHookState,
  ToggleHookStateControls,
  useClickOutside,
} from 'hooks'
import { MouseEventHandler, useRef } from 'react'
import { TalentManagementData } from '../../types'
import { TalentManagementTableTypes } from '.'

type ActionsCellProps = TalentManagementTableTypes['CellProps'] & {
  onArchiveClick?: MouseEventHandler<HTMLLIElement>
  onEditClick?: MouseEventHandler<HTMLLIElement>
  row: TalentManagementData
  cellState: ToggleHookState<TalentManagementData>
  cellStateControls: ToggleHookStateControls<TalentManagementData>
  onClick: () => void
}

export const ActionsCell = ({
  row,
  column,
  cellState,
  cellStateControls,
  onArchiveClick,
  onEditClick,
  onClick,
}: ActionsCellProps) => {
  const { active, data } = cellState
  const isActionsVisible = active && data?.id === row.id
  const cellRef = useRef<HTMLTableCellElement>(null)

  useClickOutside({
    ref: cellRef,
    onClose: cellStateControls.deactivate,
  })

  return (
    <MgmtTableCell
      column={column}
      className={twMerge(
        'relative w-12 cursor-pointer px-3 py-5 hover:text-red-10',
      )}
      onClick={event => {
        event.stopPropagation()
        onClick()
      }}
      ref={isActionsVisible ? cellRef : null}
    >
      <MoreVertical fill={'currentColor'} width={16} height={16} />
      {isActionsVisible && (
        <div className='absolute right-full top-1/2 z-50 -translate-y-1/2 transform rounded-md bg-primary-white p-3 text-base shadow-md'>
          <ul className='min-w-[96px] whitespace-nowrap'>
            <li
              className='rounded p-1 text-primary-black transition-colors hover:cursor-pointer hover:bg-primary-black/5'
              onClick={onEditClick}
            >
              Edit
            </li>
            <li
              className={twMerge(
                'rounded p-1  text-primary-black transition-colors hover:cursor-pointer hover:bg-primary-black/5',
                row.isActive && 'text-red-10',
              )}
              onClick={onArchiveClick}
            >
              {row.isActive ? 'Archive' : 'Reactivate'}
            </li>
          </ul>
        </div>
      )}
    </MgmtTableCell>
  )
}
