import { memo } from 'react'
import { MgmtTableSpinner } from './MgmtTableSpinner'

export const MgmtTableLoading = memo(() => (
  <div className='absolute inset-0 z-10 flex h-full items-center justify-center bg-black/40'>
    <MgmtTableSpinner />
  </div>
))

MgmtTableLoading.displayName = 'MgmtTableLoading'
