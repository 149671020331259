import { ChangeEvent, useEffect, useState } from 'react'
import { Button, LogLineCard, Select, Textarea } from '../../components'
import { OptionFilter, Project } from '../../types'
import { Colors } from 'constnants'
import { CheckCircle } from '../../components/ui/icons'
import { SingleAssetInput } from './SingleAssetInput'
import { DoubleAssetInput } from './DoubleAssetInput'
import { MultipleFilesInput } from './MultipleFilesInput'
import { submitTesting } from '../../hooks'
import { useGetProjectCompanyByIdQuery } from 'store/api'
import { useAuthContext } from '../../context'

interface AssetTemplateProps {
  returnBack: () => void
}

interface fieldsProps {
  projectId: string
  audience: string
  logLine: string
  test_type: string
  singleAssetType?: string
  singleAssetValue: { file?: string; fileDescription?: string }
  trailerAbTestValues: [
    { file1?: string; fileDescription1?: string },
    { file2?: string; fileDescription2?: string },
  ]
  nonTrailerAssetsType?: string
  nonTrailerAssets: [{ file: string; fileDescription: string }]
}

export const AssetTemplate = ({ returnBack }: AssetTemplateProps) => {
  const userAuthContext = useAuthContext()
  const companyId =
    userAuthContext?.auth?.userAttributes?.['custom:company_id'] || ''
  const { data } = useGetProjectCompanyByIdQuery(
    {
      id: companyId ?? '',
    },
    { skip: !companyId },
  )
  const [projectsList, setProjectsList] = useState<OptionFilter[]>([])
  useEffect(() => {
    if (data) {
      setProjectsList(
        data.map((project: Project) => ({
          id: project.company_project_id || '0',
          value: project.company_project_id || '0',
          label: project.name || '',
        })),
      )
    }
  }, [data])
  const [isSuccess, setIsSuccess] = useState(false)
  const [fields, setFields] = useState<fieldsProps>({
    projectId: '',
    audience: '',
    logLine: '',
    test_type: 'assets',
    singleAssetType: '',
    singleAssetValue: { file: '', fileDescription: '' },
    trailerAbTestValues: [
      { file1: '', fileDescription1: '' },
      { file2: '', fileDescription2: '' },
    ],
    nonTrailerAssetsType: '',
    nonTrailerAssets: [{ file: '', fileDescription: '' }],
  })

  const testTypeOptions: OptionFilter[] = [
    {
      id: '1',
      value: 'single_asset',
      label: 'Single Asset',
      description: 'Test a single file. (Trailer, TV Spot)',
    },
    {
      id: '2',
      value: 'trailer_ab_test',
      label: 'Trailer A/B Test',
      description: 'Test 2 trailer assets',
    },
    {
      id: '3',
      value: 'non_trailer_assets',
      label: 'Non Trailer Assets',
      description: 'Test multiple assets of a single type. (Posters, TV Spots)',
    },
  ]

  const singleAssetTypeOptions: OptionFilter[] = [
    {
      id: '1',
      value: 'trailer',
      label: 'Trailer',
      description: 'Acceptable file types: MP4, MOV, HEVC',
    },
    {
      id: '2',
      value: 'tv_spot',
      label: 'TV Spot',
      description: 'Acceptable file types: MP4, MOV, HEVC',
    },
  ]

  const multipleAssetTypeOptions: OptionFilter[] = [
    {
      id: '1',
      value: 'posters',
      label: 'Posters',
      description: 'Acceptable file types: JPG, PNG',
    },
    {
      id: '2',
      value: 'tv_spots',
      label: 'TV Spots',
      description: 'Acceptable file types: MP4, MOV, HEVC',
    },
  ]

  const handleSelectChange = (option: OptionFilter) => {
    setFields({ ...fields, projectId: option.value })
  }
  const handleTestTypeChange = (option: OptionFilter) => {
    setFields({ ...fields, test_type: option.value })
  }
  const handleSingleAssetTypeChange = (option: OptionFilter) => {
    setFields({ ...fields, singleAssetType: option.value })
  }

  const handleNonTrailerAssetsTypeChange = (option: OptionFilter) => {
    setFields({ ...fields, nonTrailerAssetsType: option.value })
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length <= 500) {
      setFields({ ...fields, logLine: value })
    }
  }

  const onInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    objectKey?: string,
  ) => {
    if (objectKey) {
      setFields(prevFields => ({
        ...prevFields,
        [key]: {
          ...prevFields[key],
          [objectKey]: e.target.value,
        },
      }))
    } else {
      setFields({ ...fields, [key]: e.target.value })
    }
  }
  const onDoubleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    index: number,
    objectKey?: string,
  ) => {
    setFields(prevFields => ({
      ...prevFields,
      [key]: prevFields[key].map((item: object, i: number) =>
        i === index ? { ...item, [objectKey]: e.target.value } : item,
      ),
    }))
  }

  const onMultipleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
    index: number,
    objectKey?: string,
  ) => {
    setFields(prevFields => ({
      ...prevFields,
      [key]: prevFields[key].map((item: object, i: number) =>
        i === index ? { ...item, [objectKey]: e.target.value } : item,
      ),
    }))
  }

  const handleAddNewFile = () => {
    setFields(prevFields => ({
      ...prevFields,
      nonTrailerAssets: [
        ...prevFields.nonTrailerAssets,
        { file: '', fileDescription: '' },
      ],
    }))
  }

  const removeFileRow = (index: number) => {
    setFields(prevFields => ({
      ...prevFields,
      nonTrailerAssets: prevFields.nonTrailerAssets.filter(
        (item, i) => i !== index,
      ),
    }))
  }

  useEffect(() => {
    validateForm()
  }, [fields])

  const validateForm = () => {
    const {
      projectId,
      logLine,
      test_type,
      singleAssetType,
      singleAssetValue,
      trailerAbTestValues,
      nonTrailerAssetsType,
      nonTrailerAssets,
    } = fields
    if (test_type === 'single_asset') {
      return (
        projectId &&
        logLine &&
        singleAssetType &&
        singleAssetValue.file &&
        singleAssetValue.fileDescription
      )
    } else if (test_type === 'trailer_ab_test') {
      return (
        projectId &&
        logLine &&
        ((trailerAbTestValues[0].file1 &&
          trailerAbTestValues[0].fileDescription1) ||
          (trailerAbTestValues[1].file2 &&
            trailerAbTestValues[1].fileDescription2))
      )
    } else if (test_type === 'non_trailer_assets') {
      return (
        projectId &&
        logLine &&
        nonTrailerAssetsType &&
        nonTrailerAssets
          .map(item => item.file && item.fileDescription)
          .every(Boolean)
      )
    }
    return false
  }

  const submitForm = () => {
    const dataToSend: {
      projectId: string
      audience: string
      logLine: string
      test_type: string
    } = {
      projectId: fields.projectId,
      audience: fields.audience,
      logLine: fields.logLine,
      test_type: 'assets',
    }
    submitTesting(
      'assets',
      dataToSend,
      setIsSuccess,
      returnBack,
      companyId,
    ).then(r => r)
  }

  return (
    <>
      {isSuccess && (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-primary-black/50'>
          <span
            className={
              'flex items-center rounded bg-primary-white px-3 py-2 shadow-md'
            }
          >
            <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
            <span className='pl-2'>Test created successfully</span>
          </span>
        </div>
      )}
      <div className='mb-8'>
        <p className={'text-lg font-medium text-primary-black'}>
          Select a Project and Audience
        </p>
        <p className={'text-sm text-primary-grey'}>
          Describe what a this test if about.
        </p>
      </div>
      <div className='w-1/2 min-w-[650px]'>
        <div className='mb-8'>
          <Select
            value={fields.projectId}
            handleClick={handleSelectChange}
            options={projectsList}
            containerClass='w-[400px]'
            placeholder='Select Project'
            label='Project'
            labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
            buttonClass='py-[9.4px]'
            size='medium'
            selectedLabelClass='text-sm text-grey-7 font-normal'
            hasRadioButton={false}
          />
        </div>
        <div className='mb-10'>
          <p
            className={
              'pb-0 text-sm font-semibold uppercase leading-6 text-grey-7'
            }
          >
            Your Custom Audience
          </p>
          <p className={'mb-3 text-sm text-primary-grey'}>
            In addition to the 30 general demographics we survey across, please
            provide other demographics you would like Greenlight to isolate.
          </p>
          <Textarea
            onChange={e => setFields({ ...fields, audience: e.target.value })}
            placeholder={
              'E.g. Fans of horror movies, Fans of John Carpenter, Video Gamers, People who watch the Olympics, Soccer Enthusiasts'
            }
          />
          <p className={'text-xs text-primary-grey'}>Optional</p>
        </div>
        <div className='mb-8 border-b pb-8'>
          <p
            className={
              'pb-0 text-sm font-semibold uppercase leading-6 text-grey-7'
            }
          >
            Survey Logline
          </p>
          <p className={'mb-3 text-sm text-primary-grey'}>
            This logline will be provided to surveyors as a description of the
            project.
          </p>
          <LogLineCard
            exampleLogline='“Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.”'
            value={fields.logLine}
            handleChange={handleChange}
            isStep
          />
        </div>

        <div className='mb-8'>
          <p className={'text-lg font-medium text-primary-black'}>
            What kind of test do you want to run?
          </p>
          <p className={'mb-8 text-sm text-primary-grey'}>
            Describe what this is.
          </p>
          <Select
            value={fields.test_type}
            handleClick={handleTestTypeChange}
            options={testTypeOptions}
            containerClass='w-[400px]'
            placeholder='Select Test Type'
            label='Test Type'
            labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
            buttonClass='py-[9.4px]'
            size='medium'
            selectedLabelClass='text-sm text-grey-7 font-normal'
            hasRadioButton={false}
          />
          {fields.test_type === 'single_asset' ? (
            <>
              <Select
                value={fields.singleAssetType || ''}
                handleClick={handleSingleAssetTypeChange}
                options={singleAssetTypeOptions}
                containerClass='w-[400px]'
                placeholder='Select Asset Type'
                label=''
                labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
                buttonClass='py-[9.4px] mt-1.5'
                size='medium'
                selectedLabelClass='text-sm text-grey-7 font-normal'
                hasRadioButton={false}
              />
              {fields.singleAssetType !== '' && (
                <SingleAssetInput
                  label={'File'}
                  introText={
                    'Upon submission, we’ll provide you with a secure location to upload the assets.'
                  }
                  listText={[
                    'Supported formats: MP4, MOV, HEVC',
                    'MOV Maximum size: 25MB',
                  ]}
                  fieldsLabel={'File'}
                  inputPlaceholder={'File Name'}
                  inputValue={fields.singleAssetValue.file || ''}
                  inputKey={'singleAssetValue'}
                  inputObjectKey={'file'}
                  textareaKey={'singleAssetValue'}
                  textareaObjectKey={'fileDescription'}
                  textareaPlaceholder={'File Description'}
                  onChangeHandler={onInputChange}
                />
              )}
            </>
          ) : fields.test_type === 'trailer_ab_test' ? (
            <DoubleAssetInput
              label={'Files'}
              introText={
                'Upon submission, we’ll provide you with a secure location to upload the assets.'
              }
              listText={[
                'Supported formats: MP4, MOV, HEVC',
                'MOV Maximum size: 25MB',
              ]}
              fields={[
                {
                  label: 'File 1',
                  inputPlaceholder: 'File Name',
                  textAreaPlaceholder: 'File Description',
                  inputValue: fields.trailerAbTestValues[0].file1 || '',
                  textAreaValue:
                    fields.trailerAbTestValues[0].fileDescription1 || '',
                  onChangeHandler: onDoubleInputChange,
                },
                {
                  label: 'File 2',
                  inputPlaceholder: 'File Name',
                  textAreaPlaceholder: 'File Description',
                  inputValue: fields.trailerAbTestValues[1].file2 || '',
                  textAreaValue:
                    fields.trailerAbTestValues[1].fileDescription2 || '',
                  onChangeHandler: onDoubleInputChange,
                },
              ]}
            />
          ) : (
            fields.test_type === 'non_trailer_assets' && (
              <>
                <Select
                  value={fields.nonTrailerAssetsType || ''}
                  handleClick={handleNonTrailerAssetsTypeChange}
                  options={multipleAssetTypeOptions}
                  containerClass='w-[400px]'
                  placeholder='Select Asset Type'
                  label=''
                  labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
                  buttonClass='py-[9.4px] mt-1.5'
                  size='medium'
                  selectedLabelClass='text-sm text-grey-7 font-normal'
                  hasRadioButton={false}
                />
                {fields.nonTrailerAssetsType !== '' && (
                  <MultipleFilesInput
                    introText={
                      'Upon submission, we’ll provide you with a secure location to upload the assets.'
                    }
                    listText={[
                      'Supported formats: JPG, PNG',
                      'MOV Maximum size: 25MB',
                    ]}
                    fields={fields.nonTrailerAssets}
                    onChangeHandler={onMultipleInputChange}
                    handleAddNewFile={handleAddNewFile}
                    removeFileRow={removeFileRow}
                  />
                )}
              </>
            )
          )}
        </div>

        <Button
          kind='filled'
          size='medium'
          className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10 disabled:bg-primary-red'
          onClick={submitForm}
          disabled={!validateForm()}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>Submit test</span>
          </div>
        </Button>
      </div>
    </>
  )
}
