import dayjs from 'dayjs'
import {
  subMonths,
  format,
  isValid,
  differenceInYears,
  isBefore,
} from 'date-fns'
import { parseDateId } from './parseDateId'

export const formatDate = (
  date: Date | string | number,
  format: string,
): string => {
  return dayjs(date).format(format)
}

export const formatDateId = (date: string | number, format: string): string => {
  return formatDate(parseDateId(date), format)
}

export const isFormattedDate = (date: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  return regex.test(date)
}

export const moveOneMonthBack = (dateString: string | null): string => {
  let date = new Date()
  if (dateString) {
    const year = parseInt(dateString.substring(0, 4), 10)
    const month = parseInt(dateString.substring(4, 6), 10) - 1 // 0-indexed month
    const day = parseInt(dateString.substring(6, 8), 10)

    date = new Date(year, month, day)
  }
  const newDate = subMonths(date, 1)
  return format(newDate, 'yyyyMMdd')
}

export const parseDateOfBirth = (dateOfBirth: string): Date | null => {
  if (!dateOfBirth) return null
  const [day, month, year] = dateOfBirth.split('-')
  if (!day || !month || !year) return null
  const date = new Date(Number(year), Number(month) - 1, Number(day))

  return date
}

export const calculateAge = (birthDate: Date) => {
  if (!(birthDate instanceof Date) || !isValid(birthDate)) {
    return null
  }

  return differenceInYears(new Date(), birthDate)
}
