import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const requiredString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)

export const adminMgmtInviteUserFormSchema = z.object({
  name: requiredString('Full Name'),
  email: requiredString('Email Address').email('Invalid email address format'),
})

export const adminMgmtInviteUserFormResolver = zodResolver(
  adminMgmtInviteUserFormSchema,
)

export type AdminMgmtInviteUserFormData = z.infer<
  typeof adminMgmtInviteUserFormSchema
>
