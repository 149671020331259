import { EntitySearchByField } from 'components'
import {
  MgmtCreateButton,
  MgmtPageTab,
  MgmtPageTabs,
} from 'modules/management/shared'

import { talentManagementSelectors } from '../../slices'
import { useSelector } from 'react-redux'
import { TalentManagementTab } from '../../types'
import { useTalentManagementActions } from '../../hooks/useTalentManagementActions'
import { useNavigate } from 'react-router-dom'

interface TalentManagementToolbarProps {
  onSearchChange: (value: string) => void
}
const tabs: MgmtPageTab<TalentManagementTab>[] = [
  {
    id: TalentManagementTab.Active,
    label: 'Active',
  },
  {
    id: TalentManagementTab.Archive,
    label: 'Archive',
  },
]

export const TalentManagementToolbar = ({
  onSearchChange,
}: TalentManagementToolbarProps) => {
  const activeTab = useSelector(talentManagementSelectors.activeTab)
  const actions = useTalentManagementActions()
  const navigate = useNavigate()
  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <h1 className={'mr-12 text-2xl font-semibold text-primary-black'}>
        Talent Management
      </h1>
      <MgmtPageTabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={actions.setActiveTab}
      />

      <div className='flex items-center gap-4'>
        <EntitySearchByField
          placeholder='Search Name'
          onSearch={onSearchChange}
          onClear={() => onSearchChange('')}
        />
        <MgmtCreateButton
          label='New Talent'
          onClick={() => navigate('create')}
        />
      </div>
    </div>
  )
}
