import { isBefore } from 'date-fns'
import type { AdminMgmtUserStatus, AdminMgmtUserLoginStatus } from '../types'
import { getAdminMgmtUserAccountStatus } from './getAdminMgmtUserAccountStatus'

export const getAdminMgmtUserLoginStatus = (
  status: AdminMgmtUserStatus,
  lastLoginDate: string | null,
  lastLogoutDate: string | null,
): AdminMgmtUserLoginStatus => {
  const accountStatus = getAdminMgmtUserAccountStatus(
    status,
    lastLoginDate,
    lastLogoutDate,
  )

  const isOnline =
    lastLoginDate &&
    (!lastLogoutDate || isBefore(lastLogoutDate, lastLoginDate))

  if (isOnline && accountStatus === 'active') {
    return 'online'
  }

  return accountStatus
}
