import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Input } from 'components'
import { MgmtEntryForm } from 'modules/management/shared'

import {
  releaseCalendarRevenueFormResolver,
  type ReleaseCalendarRevenueFormData,
} from './releaseCalendarRevenueFormSchema'

import { formatDate } from 'utils'
import { ReleaseCalendarDateInput } from '../ReleaseCalendarDateInput'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { NumericFormat } from 'react-number-format'

interface ReleaseCalendarRevenueFormProps {
  submitButtonLabel: string
  cancelButtonLabel?: string
  defaultValues?: ReleaseCalendarRevenueFormData
  onSubmit: (data: ReleaseCalendarRevenueFormData) => void
  onClose: () => void
}

const formatRevenueDate = (date: Date) => formatDate(date, 'MM/DD/YYYY')
const parseRevenueDate = (value: string) =>
  dayjs(value, 'MM/DD/YYYY', true).toDate()

export const ReleaseCalendarRevenueForm = ({
  submitButtonLabel,
  cancelButtonLabel,
  defaultValues,
  onSubmit,
  onClose,
}: ReleaseCalendarRevenueFormProps) => {
  const defaultValuesResolved = useMemo(() => {
    if (!defaultValues) {
      return undefined
    }

    return {
      ...defaultValues,
      date: formatRevenueDate(new Date(defaultValues.date)),
    }
  }, [defaultValues])

  const form = useForm<ReleaseCalendarRevenueFormData>({
    resolver: releaseCalendarRevenueFormResolver,
    defaultValues: defaultValuesResolved,
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form

  const renderControlledInput = (
    name: Extract<keyof ReleaseCalendarRevenueFormData, 'revenue'>,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <NumericFormat
          value={field.value}
          onValueChange={values => field.onChange(values.value)}
          thousandSeparator=','
          decimalScale={0}
          allowNegative={false}
          customInput={Input}
          inputClassName='h-8 px-3'
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          error={errors[name]?.message}
          isError={!!errors[name]}
        />
      )}
    />
  )

  const renderControlledDateInput = (
    name: Extract<keyof ReleaseCalendarRevenueFormData, 'date'>,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            <ReleaseCalendarDateInput
              {...field}
              label={label}
              placeholder='mm/dd/yyyy'
              formatDate={formatRevenueDate}
              parseDate={parseRevenueDate}
              error={errors[name]?.message}
              isError={!!errors[name]}
            />
          </>
        )
      }}
    />
  )

  return (
    <FormProvider {...form}>
      <MgmtEntryForm
        submitButtonLabel={submitButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
      >
        <div>
          {renderControlledDateInput('date', 'Date')}
          <p className='mt-1 text-xs text-primary-grey'>
            The system has provided the next Friday date to be entered, based on
            the tables most recent date.
          </p>
        </div>
        {renderControlledInput('revenue', 'Revenue')}
      </MgmtEntryForm>
    </FormProvider>
  )
}
