import React, {
  Fragment,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ClassNameValue, twMerge } from 'tailwind-merge'
import { Tooltip } from 'react-tooltip'

import {
  Checkbox,
  PaginationToolbar,
  VerticalTableText,
  FilmTitleCell,
  PaginationChangeParams,
  IconTabs,
  IconTab,
} from 'components'
import { CloseMenu, OpenMenu, TooltipIcon } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  ColumnsFilms,
  FilmsType,
  DirectionSort,
  FilmsTypeColumn,
  AudienceDemoGroup,
  AudienceDemo,
  AudienceGeneralDemo,
  AudienceIdentityDemo,
  FilmMetricsSortBy,
  AudienceRaceDemoBlack,
  AudienceRaceDemoWhite,
  AudienceRaceDemoHispLat,
} from 'types'
import { rowsPerPageOptions } from 'constnants/filmsTableData'
import { toast } from 'react-toastify'
import { commonToastStyles, parseDateId, formatDate } from 'utils'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FilmTrackingGuideTrigger } from './FilmTrackingGuideTrigger'

export interface FilmTrackingTableSorting {
  column: FilmMetricsSortBy
  direction: DirectionSort
}

interface FilmTrackingTableProps<T> {
  columns: ColumnsFilms[]
  totalPages: number
  totalRows: number
  hasCompareButtons?: boolean
  hasFooter?: boolean
  selectedRows?: string[]
  toggleSelectOne?: (rowId: string) => void
  toggleSelectAll?: () => void
  selectAllChecked?: boolean
  setCurrentPage: (page: number) => void
  currentPage?: number
  setRowsPerPage: (rows: number) => void
  rowsPerPage?: number
  requestSort?: (config: FilmTrackingTableSorting) => void
  sortConfig?: FilmTrackingTableSorting
  paginatedData: T[]
  setColumnIsActive?: (isActive: boolean) => void
  columnIsActive?: boolean
  visibleDemos?: Record<AudienceDemoGroup, string[]>
  getActiveFilterColumnName?: () => string | undefined
  allowExpandableView?: boolean
  interestType: 'all' | 'aware'
  onChangeInterestAware: (type: 'all' | 'aware') => void
}

const mapping: Record<FilmsTypeColumn, FilmMetricsSortBy> = {
  general_awareness: 'awareness',
  general_interest: 'interest',
  general_location: 'location',
  general_pay_to_see: 'paytosee',
  general_unaided_awareness: 'unaidedawareness',
  general_awareness_interest: 'awarenessinterest',
  general_heat: 'heat',
  general_plf: 'plf',
  date: 'latest_date_survey_id',
  film: 'film_name',
  dist: 'company_name',
  group: 'film_group_key',
  rating: 'rating',
}

const mapColumnKeyToSortBy = (
  column: FilmsTypeColumn,
): FilmMetricsSortBy | undefined => mapping[column]

const interestTypeTabs: IconTab<'all' | 'aware'>[] = [
  {
    value: 'all',
    icon: 'All',
  },
  {
    value: 'aware',
    icon: 'Aware',
  },
]

export const FilmTrackingTable = <T extends FilmsType>({
  columns,
  totalRows = 0,
  totalPages = 0,
  hasFooter = true,
  selectedRows,
  toggleSelectOne,
  toggleSelectAll,
  selectAllChecked,
  setCurrentPage,
  currentPage = 1,
  rowsPerPage = 1,
  setRowsPerPage,
  requestSort,
  sortConfig,
  paginatedData,
  setColumnIsActive,
  columnIsActive,
  visibleDemos,
  getActiveFilterColumnName,
  allowExpandableView = false,
  interestType,
  onChangeInterestAware,
}: FilmTrackingTableProps<T>) => {
  const [rowHoveredId, setRowHoveredId] = useState<string>('')
  const [activeTopItem, setActiveTopItem] = useState<ColumnsFilms | null>(null)
  const activePrimaryColor = activeTopItem?.primaryColor
  const activeSecondaryColor = activeTopItem?.secondaryColor

  const isColumnActive = useCallback(
    (key: string | undefined) =>
      activeTopItem && activeTopItem.accessor === key,
    [activeTopItem],
  )
  const isColumnExpanded = useCallback(
    (key: string | undefined) => allowExpandableView && isColumnActive(key),
    [isColumnActive],
  )

  const isDemoColumnVisible = (
    group: AudienceDemoGroup,
    columnKey: AudienceDemo,
  ) => {
    if (!visibleDemos) {
      return true
    }
    return visibleDemos[group].includes(columnKey)
  }

  const handleToggleSort = (column: FilmsTypeColumn) => {
    const sortByColumn = mapColumnKeyToSortBy(column)
    requestSort?.({
      column: sortByColumn,
      direction:
        sortConfig?.column === sortByColumn
          ? sortConfig?.direction === DirectionSort.DESC
            ? DirectionSort.ASC
            : DirectionSort.DESC
          : DirectionSort.DESC,
    })
  }

  const isSortedBy = (columnKey: FilmsTypeColumn) =>
    sortConfig?.column === mapColumnKeyToSortBy(columnKey)

  const handleSetActiveColumn = (isActive: boolean) => {
    if (!setColumnIsActive || columnIsActive === isActive) {
      return
    }

    setColumnIsActive(isActive)
    if (isActive) {
      toast.success(
        <>
          <p className='-mt-1 mb-0 font-bold'>
            Your Demos filter reset to default.
          </p>
          <p className='mb-0 text-xs'>
            The Demos filter is disabled when viewing an Attributes details, and
            <br />
            replaced with a visible demos menu. This allows to target the <br />
            demos important to you.
          </p>
        </>,
        {
          icon: (
            <TooltipIcon
              fill={Colors.ICON_POSITIVE_BLUE}
              width={16}
              height={16}
              data-tooltip-id={'activeColumnTooltip'}
            />
          ),
          ...commonToastStyles,
          toastId: 'Audience_Filter_Reset_Toast',
        },
      )
    }
  }

  const handlePagination = useCallback(
    (params: PaginationChangeParams) => {
      setCurrentPage(params.page)
      setRowsPerPage(params.pageSize)
    },
    [setCurrentPage, setRowsPerPage],
  )

  const cellsClasses =
    'bg-primary-white text-primary-black rounded h-7 ' +
    'min-w-28 w-[calc(100%/8-4px)] m-0.5 flex items-center justify-center shrink-0'

  const getCellStateClasses = (rowId: string | number) =>
    twMerge(
      selectedRows?.includes(rowId.toString()) && '!bg-grey-3',
      rowHoveredId == rowId && '!bg-red-2',
    )

  const columnsVisible = useMemo(() => {
    if (!visibleDemos) {
      return columns
    }
    return columns.map(it => {
      const { hiddenInfo, relatedParent } = it
      if (!relatedParent) {
        return it
      }
      return {
        ...it,
        title: getActiveFilterColumnName?.() ?? it.title,
        hiddenInfo: hiddenInfo?.map(group => {
          const visibleDemosByGroup = visibleDemos[group.key]
          if (!hiddenInfo) {
            return group
          }
          return {
            ...group,
            items: group.items.filter(
              it => !it.key || visibleDemosByGroup.includes(it.key),
            ),
          }
        }),
      }
    })
  }, [columns, visibleDemos, getActiveFilterColumnName])

  const hiddenElementsCount = () => {
    const needleEl = columnsVisible.filter(column => column.hiddenInfo)
    let count = 0
    if (needleEl[0].hiddenInfo) {
      const hiddenInfo = needleEl[0].hiddenInfo
      hiddenInfo.map(item => {
        count += item.items.length
      })
    }
    return count
  }

  const renderHidden = (
    row: any,
    content: string,
    classes?: ClassNameValue,
    hasColor = true,
  ) => {
    return (
      <div
        className={twMerge(
          'flex h-7 shrink-0 basis-8 items-center justify-center text-center',
          classes,
          getCellStateClasses(row.id),
        )}
        style={{
          backgroundColor: `${hasColor ? activeSecondaryColor : ''}`,
        }}
      >
        {content}
      </div>
    )
  }

  const renderHiddenGroup = (
    groupKey: AudienceDemoGroup,
    columns: Array<{
      key: AudienceDemo
      render: (className?: string) => ReactNode
    }>,
    firstItemClassName?: string,
    lastItemClassName?: string,
  ) => {
    return columns
      .filter(it => isDemoColumnVisible(groupKey, it.key))
      .map((it, index, arr) => {
        const isFirst = index === 0
        const isLast = index === arr.length - 1
        return (
          <Fragment key={index}>
            {it.render(
              twMerge(
                'first:rounded-l last:rounded-r',
                isFirst && firstItemClassName,
                isLast && lastItemClassName,
              ),
            )}
          </Fragment>
        )
      })
  }

  const hiddenInfoExtended = (row: any, key: string) => {
    const { hiddenInfo: info } = row[`general_${key}`]
    const partKey = key === 'interest_aware' ? 'interestawaretab' : key
    return (
      <>
        {renderHidden(row, info.change.value, 'rounded-l ', false)}
        {renderHidden(row, info.avg.value, 'rounded-r', false)}

        <div className='flex flex-shrink-0 flex-grow'>
          {renderHiddenGroup(
            AudienceDemoGroup.GENERAL,
            [
              {
                key: AudienceGeneralDemo.MEN,
                render: className =>
                  renderHidden(row, info[`${partKey}_men`].value, className),
              },
              {
                key: AudienceGeneralDemo.WOMEN,
                render: className =>
                  renderHidden(row, info[`${partKey}_women`].value, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.GENERAL,
            [
              {
                key: AudienceGeneralDemo.BELOW_35,
                render: className =>
                  renderHidden(row, info[`${partKey}_less_35`], className),
              },
              {
                key: AudienceGeneralDemo.ABOVE_35,
                render: className =>
                  renderHidden(row, info[`${partKey}_older_35`], className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.GENERAL,
            [
              {
                key: AudienceGeneralDemo.MEN_BELOW_35,
                render: className =>
                  renderHidden(
                    row,
                    info[`${partKey}_men_younger_35`].value,
                    className,
                  ),
              },
              {
                key: AudienceGeneralDemo.MEN_ABOVE_35,
                render: className =>
                  renderHidden(
                    row,
                    info[`${partKey}_men_older_35`].value,
                    className,
                  ),
              },
              {
                key: AudienceGeneralDemo.WOMEN_BELOW_35,
                render: className =>
                  renderHidden(
                    row,
                    info[`${partKey}_women_younger_35`].value,
                    className,
                  ),
              },
              {
                key: AudienceGeneralDemo.WOMEN_ABOVE_35,
                render: className =>
                  renderHidden(
                    row,
                    info[`${partKey}_women_older_35`].value,
                    className,
                  ),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.BLACK,
            [
              {
                key: AudienceRaceDemoBlack.ALL,
                render: className => renderHidden(row, info.blk_all, className),
              },
              {
                key: AudienceRaceDemoBlack.MEN,
                render: className => renderHidden(row, info.blk_men, className),
              },
              {
                key: AudienceRaceDemoBlack.WOMEN,
                render: className =>
                  renderHidden(row, info.blk_women, className),
              },
              {
                key: AudienceRaceDemoBlack.BELOW_35,
                render: className =>
                  renderHidden(row, info.blk_less_35, className),
              },
              {
                key: AudienceRaceDemoBlack.ABOVE_35,
                render: className =>
                  renderHidden(row, info.blk_older_35, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.HISPANIC_LATINO,
            [
              {
                key: AudienceRaceDemoHispLat.ALL,
                render: className => renderHidden(row, info.hsp_all, className),
              },
              {
                key: AudienceRaceDemoHispLat.MEN,
                render: className => renderHidden(row, info.hsp_men, className),
              },
              {
                key: AudienceRaceDemoHispLat.WOMEN,
                render: className =>
                  renderHidden(row, info.hsp_women, className),
              },
              {
                key: AudienceRaceDemoHispLat.BELOW_35,
                render: className =>
                  renderHidden(row, info.hsp_less_35, className),
              },
              {
                key: AudienceRaceDemoHispLat.ABOVE_35,
                render: className =>
                  renderHidden(row, info.hsp_older_35, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.WHITE,
            [
              {
                key: AudienceRaceDemoWhite.ALL,
                render: className => renderHidden(row, info.wht_all, className),
              },
              {
                key: AudienceRaceDemoWhite.MEN,
                render: className => renderHidden(row, info.wht_men, className),
              },
              {
                key: AudienceRaceDemoWhite.WOMEN,
                render: className =>
                  renderHidden(row, info.wht_women, className),
              },
              {
                key: AudienceRaceDemoWhite.BELOW_35,
                render: className =>
                  renderHidden(row, info.wht_less_35, className),
              },
              {
                key: AudienceRaceDemoWhite.ABOVE_35,
                render: className =>
                  renderHidden(row, info.wht_older_35, className),
              },
            ],
            'ml-0.5',
          )}

          {renderHiddenGroup(
            AudienceDemoGroup.LGBTQI,
            [
              {
                key: AudienceIdentityDemo.ALL,
                render: className => renderHidden(row, info.lgbt, className),
              },
            ],
            'ml-0.5',
          )}
        </div>
      </>
    )
  }

  const renderExpandableRow = (row: any, key: string, dataViewKey?: string) => {
    const metricKey = `general_${key}`
    const isActive = isColumnActive(metricKey)
    const isExpanded = isColumnExpanded(metricKey)
    const dataKey = dataViewKey ?? key
    const metricDataKey = `general_${dataKey}`
    const column = columns.find(it => it.accessor === metricKey)
    return (
      <>
        <div
          className={twMerge(cellsClasses, getCellStateClasses(row.id))}
          style={{
            backgroundColor: isActive
              ? activePrimaryColor
              : column?.secondaryColor,
          }}
        >
          {row[metricDataKey]?.general}
        </div>
        {isExpanded ? (
          <div
            className='m-0.5 flex shrink-0 flex-grow'
            style={{
              flexBasis: getExpandedPartWidth(),
            }}
          >
            {hiddenInfoExtended(row, dataKey)}
          </div>
        ) : null}
      </>
    )
  }

  const renderStaticRow = (row: any, key: string) => {
    const metricKey = `general_${key}`
    const isActive = isColumnActive(metricKey)
    return (
      <div
        className={twMerge(cellsClasses, getCellStateClasses(row.id))}
        style={isActive ? { backgroundColor: activePrimaryColor } : undefined}
      >
        {row[metricKey]}
      </div>
    )
  }

  const getExpandedPartWidth = () => 32 * hiddenElementsCount() + 6 * 2

  const tableLeftPart = useRef<HTMLTableSectionElement>(null)

  const handleTableScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (tableLeftPart.current && event.target instanceof HTMLElement) {
        const { scrollTop } = event.target
        tableLeftPart.current.style.transform = `translateY(-${scrollTop}px)`
      }
    },
    [],
  )

  const footerHeight = 40

  return (
    <div className='-mt-2 flex flex-auto flex-grow'>
      <AutoSizer>
        {({ height, width }) => (
          (height = hasFooter ? height - footerHeight : height),
          (
            <div style={{ width }} className='bg-white'>
              <div className={'flex overflow-hidden text-xs'}>
                <div
                  style={{
                    height,
                  }}
                  className='films-table__left-part flex h-max min-h-full w-[calc(100vw_-_60vw)] min-w-[calc(600px)] flex-shrink-0 flex-col'
                >
                  <table className='border-spacing-y-2 bg-grey-4'>
                    <thead className='sticky top-0 z-10 h-14 border-b border-primary-black/20 bg-grey-2'>
                      <tr>
                        <th className='w-9 py-2 pl-2.5 align-bottom'>
                          <Checkbox
                            kind='small'
                            checked={selectAllChecked}
                            onChange={toggleSelectAll}
                            isNotAllChecked={
                              !selectAllChecked &&
                              selectedRows &&
                              selectedRows?.length > 0
                            }
                          />
                        </th>
                        {columnsVisible.map((column, i) => {
                          if (i < 5) {
                            return (
                              <th
                                key={column.accessor}
                                className={twMerge(
                                  'align-bottom font-normal',
                                  isSortedBy(column.accessor) &&
                                    'selected-column',
                                  i === 0 && 'w-1/3',
                                  i === 3 && 'w-1/4',
                                )}
                              >
                                <VerticalTableText
                                  vertical={true}
                                  title={column.title}
                                  selectItem={isSortedBy(column.accessor)}
                                  directionSort={sortConfig?.direction}
                                  hasIcon
                                  containerClass={
                                    'justify-start items-end py-2  hover:cursor-pointer'
                                  }
                                  onClick={() =>
                                    handleToggleSort(column.accessor)
                                  }
                                />
                              </th>
                            )
                          }
                          return null
                        })}
                      </tr>
                    </thead>
                    <tbody ref={tableLeftPart}>
                      {paginatedData.map(row => (
                        <tr
                          key={row.id}
                          className='border-b border-primary-black/20'
                          onMouseOver={() => setRowHoveredId(row.id)}
                          onMouseLeave={() => setRowHoveredId('')}
                        >
                          <td
                            className={twMerge(
                              'h-8 bg-primary-white pl-2.5',
                              getCellStateClasses(row.id),
                            )}
                          >
                            <Checkbox
                              checked={selectedRows?.includes(
                                row.id.toString(),
                              )}
                              onChange={() => {
                                toggleSelectOne && toggleSelectOne(row.id)
                              }}
                              kind='small'
                            />
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white font-medium text-primary-black hover:cursor-pointer',
                              selectedRows?.includes(row.id.toString()) &&
                                'bg-grey-3',
                              rowHoveredId == row.id && 'bg-red-2 text-red-9',
                            )}
                          >
                            <FilmTitleCell row={row} />
                          </td>
                          <td
                            className={twMerge(
                              'items-center bg-primary-white text-primary-black',
                              getCellStateClasses(row.id),
                            )}
                          >
                            {formatDate(parseDateId(row.date), 'MM/DD/YY')}
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                              getCellStateClasses(row.id),
                            )}
                          >
                            {row.dist.abbr}
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                              getCellStateClasses(row.id),
                            )}
                          >
                            {row.group.name}
                          </td>
                          <td
                            className={twMerge(
                              'bg-primary-white text-primary-grey',
                              getCellStateClasses(row.id),
                            )}
                          >
                            {row.rating ? row.rating : '--'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  style={{ height }}
                  className={twMerge(
                    'films-table__right-part flex flex-grow flex-col overflow-y-auto bg-grey-4',
                  )}
                  onScroll={handleTableScroll}
                >
                  <div className='sticky top-0  flex items-center bg-grey-2 pl-1.5'>
                    {columnsVisible.map((column, i) => {
                      if (i > 4) {
                        const isExpandableColumn =
                          column.isExpandable && allowExpandableView
                        const isExpandedColumn = isColumnExpanded(
                          column.accessor,
                        )

                        return (
                          <React.Fragment key={column.accessor}>
                            <div
                              className={twMerge(
                                'flex h-14 items-end justify-center bg-grey-2 font-normal' +
                                  ' w-auto' +
                                  ' min-w-[calc(116px)] shrink-0 basis-[calc(100%/8)] p-0.5',
                                isSortedBy(column.accessor) &&
                                  'selected-column',
                              )}
                            >
                              <div className='flex flex-1 flex-col items-center'>
                                {column.accessor === 'general_interest' &&
                                  !isColumnExpanded('general_interest') && (
                                    <IconTabs
                                      tabs={interestTypeTabs}
                                      kind='outline'
                                      size='small'
                                      onChange={tab => {
                                        if (tab.value) {
                                          onChangeInterestAware(tab.value)
                                        }
                                      }}
                                      selectedTab={interestTypeTabs.find(
                                        it => it.value === interestType,
                                      )}
                                      classList='text-primary-black text-xss'
                                    />
                                  )}
                                <div
                                  className={twMerge(
                                    'flex w-full items-end justify-center py-2 hover:cursor-pointer',
                                    isExpandableColumn && 'justify-between',
                                  )}
                                  onClick={e => {
                                    if (
                                      e.target instanceof Element &&
                                      e.target.getAttribute('data-tooltip-id')
                                    ) {
                                      return
                                    }

                                    handleToggleSort(column.accessor)

                                    if (!isExpandableColumn) {
                                      const isActive = isColumnActive(
                                        column.accessor,
                                      )

                                      if (
                                        isActive &&
                                        (!column.isExpandable ||
                                          !allowExpandableView)
                                      ) {
                                        return
                                      }

                                      setActiveTopItem(column)
                                      handleSetActiveColumn(
                                        !!column.isExpandable && !isActive,
                                      )
                                    }
                                  }}
                                >
                                  <div className='flex items-end'>
                                    {column.tooltipText && (
                                      <>
                                        <Tooltip
                                          id={column.accessor}
                                          content={column.tooltipText}
                                          openOnClick
                                          closeEvents={{ click: true }}
                                          globalCloseEvents={{
                                            clickOutsideAnchor: true,
                                          }}
                                          place='bottom'
                                          variant='dark'
                                          className='z-50 whitespace-pre-line'
                                          style={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            padding: '4px 8px',
                                            width: 286,
                                          }}
                                        />

                                        <TooltipIcon
                                          className='flex-shrink-0'
                                          fill={Colors.BASE_ICON}
                                          width={16}
                                          height={16}
                                          data-tooltip-id={column.accessor}
                                        />
                                      </>
                                    )}
                                    <VerticalTableText
                                      containerClass='ml-1 items-end'
                                      vertical={true}
                                      title={column.title}
                                      selectItem={isSortedBy(column.accessor)}
                                      directionSort={sortConfig?.direction}
                                      hasIcon
                                    />
                                  </div>
                                  {isExpandableColumn && (
                                    <span
                                      className='mr-1'
                                      onClick={e => {
                                        e.stopPropagation()
                                        const isActive = isColumnActive(
                                          column.accessor,
                                        )

                                        if (!isActive) {
                                          requestSort?.({
                                            column: mapColumnKeyToSortBy(
                                              column.accessor,
                                            ),
                                            direction: DirectionSort.DESC,
                                          })
                                        }

                                        setActiveTopItem(
                                          isActive ? null : column,
                                        )
                                        handleSetActiveColumn(
                                          !!column.isExpandable && !isActive,
                                        )
                                      }}
                                    >
                                      {isExpandedColumn ? (
                                        <CloseMenu
                                          className='flex-shrink-0'
                                          fill={column.primaryColor}
                                          width={16}
                                          height={16}
                                        />
                                      ) : (
                                        <>
                                          <Tooltip
                                            id={column.accessor + 'expand'}
                                            content={`Expand to see\ndemographics`}
                                            closeEvents={{ mouseleave: true }}
                                            globalCloseEvents={{
                                              clickOutsideAnchor: true,
                                            }}
                                            place='bottom'
                                            variant='dark'
                                            className='z-50 whitespace-pre-line'
                                            style={{
                                              fontSize: '12px',
                                              fontWeight: 500,
                                              padding: '4px 8px',
                                              width: 94,
                                            }}
                                          />
                                          <OpenMenu
                                            className='flex-shrink-0'
                                            fill={column.primaryColor}
                                            width={16}
                                            height={16}
                                            data-tooltip-id={
                                              column.accessor + 'expand'
                                            }
                                          />
                                        </>
                                      )}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {column.hiddenInfo && (
                              <div
                                className={`pointer-events-none flex h-full flex-grow bg-grey-2 ${isColumnExpanded(column.relatedParent) ? 'ml-0.5 shrink-0' : 'w-0 overflow-hidden'}`}
                                style={{
                                  flexBasis: isColumnExpanded(
                                    column.relatedParent,
                                  )
                                    ? getExpandedPartWidth()
                                    : '0',
                                }}
                              >
                                {column.hiddenInfo.map((info, key: number) => {
                                  return (
                                    info.items &&
                                    info.items.map((item, index: number) => (
                                      <div
                                        key={`${i}_${key}_${index}`}
                                        className={twMerge(
                                          'primary-white shrink-0 basis-8 hover:cursor-pointer' +
                                            ' flex  flex-col items-center justify-end py-2 text-center text-xss',
                                          `${[4, 6, 10, 15, 20, 25].includes(index) ? 'ml-0.5' : ''}`,
                                        )}
                                      >
                                        {info.title &&
                                          index ===
                                            (info.key === 'general'
                                              ? 2
                                              : 0) && (
                                            <span className='-mt-3 block font-medium leading-none'>
                                              {info.title}
                                            </span>
                                          )}
                                        <span>{item.label}</span>
                                      </div>
                                    ))
                                  )
                                })}
                              </div>
                            )}
                          </React.Fragment>
                        )
                      }
                      return null
                    })}
                  </div>
                  <div className='flex flex-col'>
                    {paginatedData?.map(row => (
                      <div
                        key={row.id}
                        className='flex px-1.5 text-center font-medium'
                        onMouseOver={() => setRowHoveredId(row.id)}
                        onMouseLeave={() => setRowHoveredId('')}
                      >
                        {renderExpandableRow(row, 'awareness')}
                        {renderExpandableRow(
                          row,
                          'interest',
                          interestType ? 'interest_aware' : 'interest',
                        )}
                        {renderExpandableRow(row, 'location')}
                        {renderExpandableRow(row, 'pay_to_see')}
                        {renderStaticRow(row, 'unaided_awareness')}
                        {renderStaticRow(row, 'awareness_interest')}
                        {renderStaticRow(row, 'heat')}
                        {renderStaticRow(row, 'plf')}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {hasFooter && (
                <PaginationToolbar
                  totalRows={totalRows}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  currentPageSize={rowsPerPage}
                  pageSizeOptions={rowsPerPageOptions}
                  onChange={handlePagination}
                  startContent={<FilmTrackingGuideTrigger />}
                />
              )}
            </div>
          )
        )}
      </AutoSizer>
    </div>
  )
}
