import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
} from 'react-hook-form'
import { OptionType } from 'types'
import { Input, Select } from 'components'
import { MgmtEntryForm } from 'modules/management/shared'
import {
  tvFilmCompanyFormResolver,
  type TVManagementFormData,
} from './TVManagementFormSchema'
import {
  tvGenreOptionsByTVType,
  tvNetworkOptionsByNetworkType,
  tVNetworkTypeOptions,
  tvSubtypeOptionsByTVType,
  tVTypeOptions,
} from '../../config/tVNetworkTypeOptions'
import { TVType } from '../../types'

interface TVManagementFormProps {
  submitButtonLabel: string
  cancelButtonLabel?: string
  defaultValues?: TVManagementFormData
  onSubmit: (data: TVManagementFormData) => void
  onClose: () => void
}

export const TVManagementForm = ({
  submitButtonLabel,
  cancelButtonLabel,
  defaultValues,
  onSubmit,
  onClose,
}: TVManagementFormProps) => {
  const form = useForm<TVManagementFormData>({
    resolver: tvFilmCompanyFormResolver,
    defaultValues,
  })

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form

  const [networkType] = watch(['networkType'])
  const [tvType] = watch(['tvType'])

  const renderControlledInput = (
    name: keyof TVManagementFormData,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? undefined}
          inputClassName='h-8 px-3'
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          error={errors[name]?.message}
          isError={!!errors[name]}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )

  const renderControlledSelect = (
    name: keyof TVManagementFormData,
    label: string,
    options: OptionType[],
    onChange: (
      filed: ControllerRenderProps<TVManagementFormData, typeof name>,
      option: OptionType,
    ) => void = (field, option) => field.onChange(option.value),
    isDisabled = false,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          value={field.value ?? undefined}
          size='small'
          alignItems='start'
          errorClass='pt-1.5 text-xs'
          labelClass='pb-1 text-sm font-semibold text-primary-black'
          buttonClass='h-8 items-center whitespace-nowrap'
          hasRadioButton={false}
          label={label}
          isError={!!errors[name]}
          error={errors[name]?.message}
          options={options}
          disabled={isDisabled}
          handleClick={option => onChange(field, option)}
        />
      )}
    />
  )

  return (
    <FormProvider {...form}>
      <MgmtEntryForm
        submitButtonLabel={submitButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
      >
        {renderControlledInput('title', 'Title')}
        {renderControlledInput('imdbId', 'IMDB ID')}
        {renderControlledInput('date', 'Date')}
        {renderControlledSelect(
          'networkType',
          'Network Type',
          tVNetworkTypeOptions,
        )}
        {renderControlledSelect(
          'network',
          'Network',
          tvNetworkOptionsByNetworkType[networkType],
          undefined,
          !networkType,
        )}
        {renderControlledSelect(
          'tvType',
          'Type',
          tVTypeOptions,
          (field, option) => {
            field.onChange(option.value)
            if (option.value === TVType.LiveEventsSpecials) {
              setValue('tvSubtype', null)
              setValue('genre', null)
            }
          },
        )}
        {renderControlledSelect(
          'tvSubtype',
          'Subtype',
          tvSubtypeOptionsByTVType[tvType],
          undefined,
          !tvType,
        )}
        {renderControlledSelect(
          'genre',
          'Genre',
          tvGenreOptionsByTVType[tvType],
          undefined,
          !tvType,
        )}
      </MgmtEntryForm>
    </FormProvider>
  )
}
