import { api } from '../api'
export const addTagTypes = [
  'FilmMetrics',
  'GA Admin Profiles Management',
  'GA Admin TV & Film companies Management',
  'GA Admin TV Management',
  'GA Admin release calendar',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: build => ({
      filmMetricsControllerGetFilmTracking: build.query<
        FilmMetricsControllerGetFilmTrackingApiResponse,
        FilmMetricsControllerGetFilmTrackingApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortBy: queryArg.sortBy,
            sortOrder: queryArg.sortOrder,
            audienceFilter: queryArg.audienceFilter,
            theatergoersOnly: queryArg.theatergoersOnly,
            dist: queryArg.dist,
            groups: queryArg.groups,
            filmName: queryArg.filmName,
            filmIds: queryArg.filmIds,
            metricsType: queryArg.metricsType,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmMetricsByFilmId: build.query<
        FilmMetricsControllerGetFilmMetricsByFilmIdApiResponse,
        FilmMetricsControllerGetFilmMetricsByFilmIdApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/film/${queryArg.filmId}/metrics`,
          params: {
            metricsType: queryArg.metricsType,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmTrackingDistributors: build.query<
        FilmMetricsControllerGetFilmTrackingDistributorsApiResponse,
        FilmMetricsControllerGetFilmTrackingDistributorsApiArg
      >({
        query: () => ({ url: `/filmMetrics/distributors` }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmTrackingGroups: build.query<
        FilmMetricsControllerGetFilmTrackingGroupsApiResponse,
        FilmMetricsControllerGetFilmTrackingGroupsApiArg
      >({
        query: () => ({ url: `/filmMetrics/groups` }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetFilmTrackingFilters: build.query<
        FilmMetricsControllerGetFilmTrackingFiltersApiResponse,
        FilmMetricsControllerGetFilmTrackingFiltersApiArg
      >({
        query: () => ({ url: `/filmMetrics/filters` }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetSearchFilm: build.query<
        FilmMetricsControllerGetSearchFilmApiResponse,
        FilmMetricsControllerGetSearchFilmApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/films`,
          params: {
            filmName: queryArg.filmName,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetChartData: build.query<
        FilmMetricsControllerGetChartDataApiResponse,
        FilmMetricsControllerGetChartDataApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/charts`,
          params: {
            attribute: queryArg.attribute,
            filmIds: queryArg.filmIds,
            metricsType: queryArg.metricsType,
            audienceFilter: queryArg.audienceFilter,
          },
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetBoxOffice: build.query<
        FilmMetricsControllerGetBoxOfficeApiResponse,
        FilmMetricsControllerGetBoxOfficeApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/boxOffice/${queryArg.filmId}`,
        }),
        providesTags: ['FilmMetrics'],
      }),
      filmMetricsControllerGetOpeningWeekendProjections: build.query<
        FilmMetricsControllerGetOpeningWeekendProjectionsApiResponse,
        FilmMetricsControllerGetOpeningWeekendProjectionsApiArg
      >({
        query: queryArg => ({
          url: `/filmMetrics/owProjections/${queryArg.filmId}`,
        }),
        providesTags: ['FilmMetrics'],
      }),
      gaAdminProfileControllerGetAllAdmins: build.query<
        GaAdminProfileControllerGetAllAdminsApiResponse,
        GaAdminProfileControllerGetAllAdminsApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/admins`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortBy: queryArg.sortBy,
            sortOrder: queryArg.sortOrder,
            fullName: queryArg.fullName,
            adminType: queryArg.adminType,
          },
        }),
        providesTags: ['GA Admin Profiles Management'],
      }),
      gaAdminProfileControllerGetAdminById: build.query<
        GaAdminProfileControllerGetAdminByIdApiResponse,
        GaAdminProfileControllerGetAdminByIdApiArg
      >({
        query: queryArg => ({ url: `/ga-admin/admins/${queryArg.adminId}` }),
        providesTags: ['GA Admin Profiles Management'],
      }),
      gaAdminProfileControllerUpdateAdmin: build.mutation<
        GaAdminProfileControllerUpdateAdminApiResponse,
        GaAdminProfileControllerUpdateAdminApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/admins/${queryArg.adminId}`,
          method: 'PUT',
          body: queryArg.updateGaAdminDto,
        }),
        invalidatesTags: ['GA Admin Profiles Management'],
      }),
      gaAdminProfileControllerDeleteAdmin: build.mutation<
        GaAdminProfileControllerDeleteAdminApiResponse,
        GaAdminProfileControllerDeleteAdminApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/admins/${queryArg.adminId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GA Admin Profiles Management'],
      }),
      gaAdminProfileControllerInviteAdmin: build.mutation<
        GaAdminProfileControllerInviteAdminApiResponse,
        GaAdminProfileControllerInviteAdminApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/admins/invite`,
          method: 'POST',
          body: queryArg.createGaAdminDto,
        }),
        invalidatesTags: ['GA Admin Profiles Management'],
      }),
      gaAdminProfileControllerResendAdminInvite: build.mutation<
        GaAdminProfileControllerResendAdminInviteApiResponse,
        GaAdminProfileControllerResendAdminInviteApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/admins/${queryArg.userId}/resend-invite`,
          method: 'POST',
        }),
        invalidatesTags: ['GA Admin Profiles Management'],
      }),
      tvFilmCompanyControllerGetCompanies: build.query<
        TvFilmCompanyControllerGetCompaniesApiResponse,
        TvFilmCompanyControllerGetCompaniesApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tvFilm`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortOrder: queryArg.sortOrder,
            sortBy: queryArg.sortBy,
            companyName: queryArg.companyName,
            companyType: queryArg.companyType,
            distributorType: queryArg.distributorType,
          },
        }),
        providesTags: ['GA Admin TV & Film companies Management'],
      }),
      tvFilmCompanyControllerCreateTvFilmCompany: build.mutation<
        TvFilmCompanyControllerCreateTvFilmCompanyApiResponse,
        TvFilmCompanyControllerCreateTvFilmCompanyApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tvFilm`,
          method: 'POST',
          body: queryArg.createTvFilmCompaniesDto,
        }),
        invalidatesTags: ['GA Admin TV & Film companies Management'],
      }),
      tvFilmCompanyControllerGetCompaniesList: build.query<
        TvFilmCompanyControllerGetCompaniesListApiResponse,
        TvFilmCompanyControllerGetCompaniesListApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tvFilm/list`,
          params: {
            companyType: queryArg.companyType,
          },
        }),
        providesTags: ['GA Admin TV & Film companies Management'],
      }),
      tvFilmCompanyControllerUpdateCompany: build.mutation<
        TvFilmCompanyControllerUpdateCompanyApiResponse,
        TvFilmCompanyControllerUpdateCompanyApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tvFilm/${queryArg.tvFilmCompanyId}`,
          method: 'PUT',
          body: queryArg.updateTvFilmCompanyDto,
        }),
        invalidatesTags: ['GA Admin TV & Film companies Management'],
      }),
      tvFilmCompanyControllerDelete: build.mutation<
        TvFilmCompanyControllerDeleteApiResponse,
        TvFilmCompanyControllerDeleteApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tvFilm/${queryArg.tvFilmCompanyId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GA Admin TV & Film companies Management'],
      }),
      tvControllerGetAllTvReleases: build.query<
        TvControllerGetAllTvReleasesApiResponse,
        TvControllerGetAllTvReleasesApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tv`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortBy: queryArg.sortBy,
            sortOrder: queryArg.sortOrder,
            title: queryArg.title,
          },
        }),
        providesTags: ['GA Admin TV Management'],
      }),
      tvControllerCreateTvRelease: build.mutation<
        TvControllerCreateTvReleaseApiResponse,
        TvControllerCreateTvReleaseApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tv`,
          method: 'POST',
          body: queryArg.createTvDto,
        }),
        invalidatesTags: ['GA Admin TV Management'],
      }),
      tvControllerUpdateTvFilm: build.mutation<
        TvControllerUpdateTvFilmApiResponse,
        TvControllerUpdateTvFilmApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tv/${queryArg.filmId}`,
          method: 'PUT',
          body: queryArg.updateTvDto,
        }),
        invalidatesTags: ['GA Admin TV Management'],
      }),
      tvControllerDeleteTvFilm: build.mutation<
        TvControllerDeleteTvFilmApiResponse,
        TvControllerDeleteTvFilmApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/tv/${queryArg.filmId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GA Admin TV Management'],
      }),
      releaseCalendarControllerPutRevenue: build.mutation<
        ReleaseCalendarControllerPutRevenueApiResponse,
        ReleaseCalendarControllerPutRevenueApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/revenue/${queryArg.revenueId}`,
          method: 'PUT',
          body: queryArg.updateRevenueDto,
        }),
        invalidatesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerDeleteRevenue: build.mutation<
        ReleaseCalendarControllerDeleteRevenueApiResponse,
        ReleaseCalendarControllerDeleteRevenueApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/revenue/${queryArg.revenueId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerPutEvent: build.mutation<
        ReleaseCalendarControllerPutEventApiResponse,
        ReleaseCalendarControllerPutEventApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/event/${queryArg.eventId}`,
          method: 'PUT',
          body: queryArg.updateEventDto,
        }),
        invalidatesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerDeleteEvent: build.mutation<
        ReleaseCalendarControllerDeleteEventApiResponse,
        ReleaseCalendarControllerDeleteEventApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/event/${queryArg.eventId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerGetRevenue: build.query<
        ReleaseCalendarControllerGetRevenueApiResponse,
        ReleaseCalendarControllerGetRevenueApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/revenue`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortOrder: queryArg.sortOrder,
            sortBy: queryArg.sortBy,
            year: queryArg.year,
          },
        }),
        providesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerCreateRevenue: build.mutation<
        ReleaseCalendarControllerCreateRevenueApiResponse,
        ReleaseCalendarControllerCreateRevenueApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/revenue`,
          method: 'POST',
          body: queryArg.createRevenueDto,
        }),
        invalidatesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerGetEvent: build.query<
        ReleaseCalendarControllerGetEventApiResponse,
        ReleaseCalendarControllerGetEventApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/event/${queryArg.eventType}`,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            sortOrder: queryArg.sortOrder,
            sortBy: queryArg.sortBy,
            title: queryArg.title,
          },
        }),
        providesTags: ['GA Admin release calendar'],
      }),
      releaseCalendarControllerCreateEvent: build.mutation<
        ReleaseCalendarControllerCreateEventApiResponse,
        ReleaseCalendarControllerCreateEventApiArg
      >({
        query: queryArg => ({
          url: `/ga-admin/releaseCalendar/event/${queryArg.eventType}`,
          method: 'POST',
          body: queryArg.createEventDto,
        }),
        invalidatesTags: ['GA Admin release calendar'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as enhancedApi }
export type FilmMetricsControllerGetFilmTrackingApiResponse =
  /** status 200 Retrieve paginated list of films */ PaginatedFilmMetricsResponse
export type FilmMetricsControllerGetFilmTrackingApiArg = {
  pageSize?: number
  pageNumber?: number
  sortBy?:
    | 'awareness'
    | 'interestawaretab'
    | 'interest'
    | 'location'
    | 'paytosee'
    | 'unaidedawareness'
    | 'awarenessinterest'
    | 'heat'
    | 'plf'
    | 'latest_date_survey_id'
    | 'film_name'
    | 'company_name'
    | 'film_group_key'
    | 'rating'
  sortOrder?: 'ASC' | 'DESC'
  audienceFilter?:
    | 'ALL'
    | 'GENDER_FEMALE'
    | 'GENDER_MALE'
    | 'AGE_SEGMENT_35_PLUS'
    | 'AGE_SEGMENT_UNDER_35'
    | 'AGE_GENDER_SEGMENT_M_PLUS'
    | 'AGE_GENDER_SEGMENT_M_LESS'
    | 'AGE_GENDER_SEGMENT_W_PLUS'
    | 'AGE_GENDER_SEGMENT_W_LESS'
    | 'IDENTITY_LGBT_QI'
    | 'RACE_BLACK'
    | 'RACE_BLACK_GENDER_MALE'
    | 'RACE_BLACK_GENDER_FEMALE'
    | 'RACE_BLACK_AGE_SEGMENT_UNDER_35'
    | 'RACE_BLACK_AGE_SEGMENT_35_PLUS'
    | 'RACE_WHITE'
    | 'RACE_WHITE_GENDER_MALE'
    | 'RACE_WHITE_GENDER_FEMALE'
    | 'RACE_WHITE_AGE_SEGMENT_UNDER_35'
    | 'RACE_WHITE_AGE_SEGMENT_35_PLUS'
    | 'RACE_HISPANIC'
    | 'RACE_HISPANIC_GENDER_MALE'
    | 'RACE_HISPANIC_GENDER_FEMALE'
    | 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35'
    | 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS'
  theatergoersOnly?: boolean
  /** array of Company ids to filter */
  dist?: number[]
  /** array of group ids to filter */
  groups?: number[]
  filmName?: string
  /** array of film ids */
  filmIds?: number[]
  metricsType?: 'PERCENTAGE' | 'RANK' | 'COUNT'
}
export type FilmMetricsControllerGetFilmMetricsByFilmIdApiResponse =
  /** status 200 Retrieve paginated list of films */ FilmMetricsDetailResponseDto
export type FilmMetricsControllerGetFilmMetricsByFilmIdApiArg = {
  filmId: number
  metricsType?: 'PERCENTAGE' | 'RANK' | 'COUNT'
}
export type FilmMetricsControllerGetFilmTrackingDistributorsApiResponse =
  unknown
export type FilmMetricsControllerGetFilmTrackingDistributorsApiArg = void
export type FilmMetricsControllerGetFilmTrackingGroupsApiResponse = unknown
export type FilmMetricsControllerGetFilmTrackingGroupsApiArg = void
export type FilmMetricsControllerGetFilmTrackingFiltersApiResponse =
  /** status 200 Retrieve film filters group, distributors */ FilmFilterResponse
export type FilmMetricsControllerGetFilmTrackingFiltersApiArg = void
export type FilmMetricsControllerGetSearchFilmApiResponse =
  /** status 200 Search Film by Name */ FilmSearchByName[]
export type FilmMetricsControllerGetSearchFilmApiArg = {
  filmName: string
}
export type FilmMetricsControllerGetChartDataApiResponse =
  /** status 200 Retrieve Chart data by film ids and attribute */ FilmChartData[]
export type FilmMetricsControllerGetChartDataApiArg = {
  attribute:
    | 'awareness'
    | 'interest'
    | 'location'
    | 'paytosee'
    | 'unaidedawareness'
    | 'awarenessinterest'
    | 'heat'
    | 'plf'
  /** array of film ids */
  filmIds: number[]
  metricsType?: 'PERCENTAGE' | 'RANK' | 'COUNT'
  audienceFilter?:
    | 'ALL'
    | 'GENDER_FEMALE'
    | 'GENDER_MALE'
    | 'AGE_SEGMENT_35_PLUS'
    | 'AGE_SEGMENT_UNDER_35'
    | 'AGE_GENDER_SEGMENT_M_PLUS'
    | 'AGE_GENDER_SEGMENT_M_LESS'
    | 'AGE_GENDER_SEGMENT_W_PLUS'
    | 'AGE_GENDER_SEGMENT_W_LESS'
    | 'IDENTITY_LGBT_QI'
    | 'RACE_BLACK'
    | 'RACE_BLACK_GENDER_MALE'
    | 'RACE_BLACK_GENDER_FEMALE'
    | 'RACE_BLACK_AGE_SEGMENT_UNDER_35'
    | 'RACE_BLACK_AGE_SEGMENT_35_PLUS'
    | 'RACE_WHITE'
    | 'RACE_WHITE_GENDER_MALE'
    | 'RACE_WHITE_GENDER_FEMALE'
    | 'RACE_WHITE_AGE_SEGMENT_UNDER_35'
    | 'RACE_WHITE_AGE_SEGMENT_35_PLUS'
    | 'RACE_HISPANIC'
    | 'RACE_HISPANIC_GENDER_MALE'
    | 'RACE_HISPANIC_GENDER_FEMALE'
    | 'RACE_HISPANIC_AGE_SEGMENT_UNDER_35'
    | 'RACE_HISPANIC_AGE_SEGMENT_35_PLUS'
}
export type FilmMetricsControllerGetBoxOfficeApiResponse =
  /** status 200 Response box-office universe data for the given filmId */ FilmBoxOfficeResponse
export type FilmMetricsControllerGetBoxOfficeApiArg = {
  filmId: number
}
export type FilmMetricsControllerGetOpeningWeekendProjectionsApiResponse =
  /** status 200 Response opening weekend projection table data */ FilmProjectionResponse
export type FilmMetricsControllerGetOpeningWeekendProjectionsApiArg = {
  filmId: number
}
export type GaAdminProfileControllerGetAllAdminsApiResponse =
  /** status 200 Successfully fetched all admin profiles */ GetAdminsResponse
export type GaAdminProfileControllerGetAllAdminsApiArg = {
  pageSize?: number
  pageNumber?: number
  sortBy?: 'name' | 'email' | 'user_type' | 'last_login_date'
  sortOrder?: 'ASC' | 'DESC'
  fullName?: string
  adminType?: 'super_admin' | 'admin'
}
export type GaAdminProfileControllerGetAdminByIdApiResponse =
  /** status 200 Successfully retrieved the admin profile */ AdminProfileResponse
export type GaAdminProfileControllerGetAdminByIdApiArg = {
  /** UUID */
  adminId: string
}
export type GaAdminProfileControllerUpdateAdminApiResponse =
  /** status 200 Successfully updated the admin profile */ AdminProfileResponse
export type GaAdminProfileControllerUpdateAdminApiArg = {
  /** UUID */
  adminId: string
  updateGaAdminDto: UpdateGaAdminDto
}
export type GaAdminProfileControllerDeleteAdminApiResponse =
  /** status 200 Admin successfully deleted */ DeleteAdminResponse
export type GaAdminProfileControllerDeleteAdminApiArg = {
  /** UUID */
  adminId: string
}
export type GaAdminProfileControllerInviteAdminApiResponse =
  /** status 200 Successfully invited the admin */ AdminProfileResponse
export type GaAdminProfileControllerInviteAdminApiArg = {
  createGaAdminDto: CreateGaAdminDto
}
export type GaAdminProfileControllerResendAdminInviteApiResponse =
  /** status 200 Invitation resend to admin */ ResentInviteAdmin
export type GaAdminProfileControllerResendAdminInviteApiArg = {
  /** UUID */
  userId: string
}
export type TvFilmCompanyControllerGetCompaniesApiResponse =
  /** status 200 List of TV & Film companies retrieved successfully. */ GetTvFilmCompaniesResponse
export type TvFilmCompanyControllerGetCompaniesApiArg = {
  pageSize?: number
  pageNumber?: number
  sortOrder?: 'ASC' | 'DESC'
  sortBy?: 'name' | 'companyType' | 'distributorType'
  /** search by company name */
  companyName?: string
  /** filter by companyType */
  companyType?:
    | ('filmDistributor' | 'tvBroadcast' | 'tvCable' | 'tvStreamer')
    | null
  /** filter by Dist type */
  distributorType?: ('major' | 'minor' | 'other') | null
}
export type TvFilmCompanyControllerCreateTvFilmCompanyApiResponse =
  /** status 200 TV & Film companies created result. */ TvFilmCompanyDetails
export type TvFilmCompanyControllerCreateTvFilmCompanyApiArg = {
  createTvFilmCompaniesDto: CreateTvFilmCompaniesDto
}
export type TvFilmCompanyControllerGetCompaniesListApiResponse =
  /** status 200 get List of TV & Film companies name and id */ GetTvFilmCompaniesListResponse
export type TvFilmCompanyControllerGetCompaniesListApiArg = {
  /** filter by companyType */
  companyType: 'filmDistributor' | 'tvBroadcast' | 'tvCable' | 'tvStreamer'
}
export type TvFilmCompanyControllerUpdateCompanyApiResponse =
  /** status 200 TV & Film companies update result. */ TvFilmCompanyDetails
export type TvFilmCompanyControllerUpdateCompanyApiArg = {
  /** Number */
  tvFilmCompanyId: number
  updateTvFilmCompanyDto: UpdateTvFilmCompanyDto
}
export type TvFilmCompanyControllerDeleteApiResponse =
  /** status 200 TV & Film companies delete result. */ DeleteTvFilmCompanyResponse
export type TvFilmCompanyControllerDeleteApiArg = {
  /** Number */
  tvFilmCompanyId: number
}
export type TvControllerGetAllTvReleasesApiResponse =
  /** status 200 List of Film with pagination metadata */ PaginatedTvReleasesDto
export type TvControllerGetAllTvReleasesApiArg = {
  pageSize?: number
  pageNumber?: number
  sortBy?: 'imdbId' | 'title' | 'date'
  sortOrder?: 'ASC' | 'DESC'
  title?: string
}
export type TvControllerCreateTvReleaseApiResponse =
  /** status 200 Film created result. */ TvReleaseDto
export type TvControllerCreateTvReleaseApiArg = {
  createTvDto: CreateTvDto
}
export type TvControllerUpdateTvFilmApiResponse =
  /** status 200 Film updated result. */ TvReleaseDto
export type TvControllerUpdateTvFilmApiArg = {
  /** Number */
  filmId: number
  updateTvDto: UpdateTvDto
}
export type TvControllerDeleteTvFilmApiResponse =
  /** status 200 Record successfully deleted */ DeleteTvResponse
export type TvControllerDeleteTvFilmApiArg = {
  /** Number */
  filmId: number
}
export type ReleaseCalendarControllerPutRevenueApiResponse = unknown
export type ReleaseCalendarControllerPutRevenueApiArg = {
  /** Unique identifier for the revenue */
  revenueId: number
  updateRevenueDto: UpdateRevenueDto
}
export type ReleaseCalendarControllerDeleteRevenueApiResponse =
  /** status 200 revenue and event delete result. */ DeleteRecordRcResponse
export type ReleaseCalendarControllerDeleteRevenueApiArg = {
  /** Unique identifier for the revenue */
  revenueId: number
}
export type ReleaseCalendarControllerPutEventApiResponse = unknown
export type ReleaseCalendarControllerPutEventApiArg = {
  /** Unique identifier for the event */
  eventId: number
  updateEventDto: UpdateEventDto
}
export type ReleaseCalendarControllerDeleteEventApiResponse =
  /** status 200 revenue and event delete result. */ DeleteRecordRcResponse
export type ReleaseCalendarControllerDeleteEventApiArg = {
  /** Unique identifier for the event */
  eventId: number
}
export type ReleaseCalendarControllerGetRevenueApiResponse =
  /** status 200 Revenue get result. */ GetRevenueResponse
export type ReleaseCalendarControllerGetRevenueApiArg = {
  pageSize?: number
  pageNumber?: number
  sortOrder?: 'ASC' | 'DESC'
  sortBy?: 'date' | 'revenue'
  /** year YYYY format */
  year?: number
}
export type ReleaseCalendarControllerCreateRevenueApiResponse =
  /** status 200 revenue Create result. */ RevenueDetails
export type ReleaseCalendarControllerCreateRevenueApiArg = {
  createRevenueDto: CreateRevenueDto
}
export type ReleaseCalendarControllerGetEventApiResponse =
  /** status 200 Event get result. */ GetEventsResponse
export type ReleaseCalendarControllerGetEventApiArg = {
  /** Type of the event to get */
  eventType: 'holidaySchool' | 'sportEvents'
  pageSize?: number
  pageNumber?: number
  sortOrder?: 'ASC' | 'DESC'
  sortBy?: 'title' | 'startDate' | 'endDate'
  title?: string
}
export type ReleaseCalendarControllerCreateEventApiResponse =
  /** status 200 Event create result. */ EventDetails
export type ReleaseCalendarControllerCreateEventApiArg = {
  /** Type of the event to be created */
  eventType: 'holidaySchool' | 'sportEvents'
  createEventDto: CreateEventDto
}
export type Distribution = {
  /** Name of the distribution company */
  name: string | null
  /** Abbreviation for the distributor */
  abbr: string | null
  /** Value associated with this distributor */
  value: number | null
}
export type Group = {
  /** Name of the group */
  name: string | null
  /** Abbreviation for the group */
  abbr: string | null
  /** Value associated with this group */
  value: number | null
}
export type ChangeInfo = {
  /** Abbreviation for the change information */
  abbr: string | null
  /** Value of the change */
  value: string | null
}
export type Demographic = {
  /** Abbreviation */
  abbr: string | null
  /** Value for this demographic group */
  value: number | null
}
export type HiddenInfo = {
  /** Change information */
  change: ChangeInfo | null
  /** Average information */
  avg: Demographic | null
  /** Location for men */
  location_men: Demographic | null
  /** Location for women */
  location_women: Demographic | null
}
export type GeneralData = {
  /** General value */
  general: number | null
  /** Additional hidden demographic information */
  hiddenInfo: HiddenInfo | null
}
export type FilmData = {
  /** Film ID */
  id: number
  /** Film name */
  film: string | null
  /** Film release date in YYYYMMDD format */
  date: number | null
  /** Distribution information */
  dist: Distribution | null
  /** Group information */
  group: Group | null
  /** Film rating */
  rating: number | null
  /** General heat metric */
  general_heat: number | null
  /** General PLF metric */
  general_plf: number | null
  /** General location metrics */
  general_location: GeneralData | null
  /** General interest metrics */
  general_interest: GeneralData | null
  /** General interest aware tab metrics */
  general_interest_aware: GeneralData | null
  /** General pay-to-see metrics */
  general_pay_to_see: GeneralData | null
  /** General awareness metrics */
  general_awareness: GeneralData | null
  /** General awareness interest metric */
  general_awareness_interest: number | null
  /** General unaided awareness metric */
  general_unaided_awareness: number | null
}
export type PaginatedFilmMetricsResponse = {
  /** Total records count */
  totalRecords: number | null
  /** Total pages count */
  totalPages: number | null
  /** Current page number */
  currentPage: number | null
  /** Page size */
  pageSize: number | null
  /** List of film data for this page */
  data: FilmData[] | null
}
export type CastDto = {
  /** Name of the cast member */
  name: string
  /** Unique key for the cast member */
  key: string
  /** URL of the cast member's image */
  img?: string | null
  /** Id of the cast member */
  id: number | null
}
export type CompanyDataDto = {
  /** ID of the company */
  company_id: number
  /** Name of the company */
  company_name: string
}
export type FilmMetricDto = {
  /** Metric key (e.g., awareness, interest) */
  key: string
  /** Metric label for display purposes */
  label: string
  /** Value of the metric */
  value: number
}
export type FilmMetricsDetailResponseDto = {
  /** Unique ID of the film */
  film_id: number
  /** Unique key for the film */
  film_key: string | null
  /** Name of the film */
  film_name: string | null
  /** Aggregate rating of the film */
  aggregate_rating?: number | null
  /** Cast members of the film */
  cast: CastDto[] | null
  /** Countries of origin of the film */
  countries_of_origin?: string | null
  /** External links related to the film */
  external_links?: string | null
  /** IMDb URL for the film */
  imdb_url?: string | null
  /** Indicates if the film is for adult audiences */
  is_adult?: boolean | null
  /** Plot of the film */
  plot?: string | null
  /** Primary image URL for the film */
  primary_image_url?: string | null
  /** Rating of the film */
  rating?: number | null
  /** Reason for the film's rating */
  rating_reason?: string | null
  /** Release day of the film */
  release_day?: number | null
  /** Release month of the film */
  release_month?: number | null
  /** Release year of the film */
  release_year?: number | null
  /** Runtime of the film in minutes */
  runtime?: number | null
  /** Subgenres of the film */
  subgenres?: string | null
  /** Total vote count for the film */
  vote_count?: string | null
  /** ID of the film's group */
  film_group_id?: number | null
  /** ID of the film's Genre */
  genre_id?: number | null
  /** Type of the film */
  film_type?: string | null
  /** Company data related to the film */
  companies_data: CompanyDataDto[] | null
  /** List of film metrics */
  film_metrics: FilmMetricDto[] | null
  /** Film Group key */
  film_group_key?: string | null
  /** Film Group name */
  film_group_name?: string | null
  /** Film Genre key */
  genre_key?: string | null
  /** Film Genre name */
  genre_name?: string | null
}
export type FilmGroup = {
  /** Unique identifier for the film group */
  film_group_id: number
  /** Key for the film group */
  film_group_key: string | null
  /** Name of the film group */
  film_group_name: string | null
}
export type Distributor = {
  /** Name of the company */
  company_name: string | null
  /** Unique identifier for the company */
  company_id: number
  /** Type of distributor */
  distributor_type: string | null
  /** Abbreviation for the distributor */
  abbreviation: string | null
  /** Company type */
  company_type: string | null
  /** Company key identifier */
  company_key: string | null
}
export type FilmFilterResponse = {
  /** List of film groups */
  groups: FilmGroup[] | null
  /** List of distributors */
  distributors: Distributor[] | null
}
export type FilmSearchByName = {
  /** Unique identifier for the film group */
  film_id: string
  /** Name of the film */
  film_name: string
}
export type ChartData = {
  /** Date of the data value in YYYYMMDD format */
  date_survey_id: number
  /** The recorded value for this date */
  value: number
  /** days before release */
  days_before_release: number
}
export type FilmChartData = {
  /** Unique identifier for the film */
  film_id: number
  /** Name of the film film */
  film_name: string
  /** Release date of the film YYYYMMDD format */
  release_date?: number | null
  /** Array of data values by date for the film */
  data: ChartData[]
}
export type FilmBoxOfficeData = {
  /** The unique identifier of the film */
  film_id: number
  /** The rating of the film */
  rating: string
  /** The name of the film */
  film_name: string
  /** The opening weekend revenue of the film */
  open_weekend: number | null
  /** The adjusted opening weekend revenue of the film */
  ow_adjust: number
  /** The number of theatres for the film */
  ow_theatres: number | null
  /** The total gross revenue of the film */
  total_gross: number | null
  /** The distribution company or companies */
  dist: string
  /** Date of release value in YYYYMMDD format */
  release_date: number
}
export type FilmBoxOfficeResponse = {
  /** The box office data for the film */
  data: FilmBoxOfficeData[]
  /** Box office note for current film */
  boxOfficeNote: string | null
}
export type FilmProjectionRow = {
  row_num: number
  row_value: string
  c42: boolean
  c35: boolean
  c28: boolean
  c21: boolean
  c14: boolean
  c7: boolean
  c0: boolean
}
export type FilmProjectionResponse = {
  data: FilmProjectionRow[]
  currentProjection: string | null
}
export type AdminProfileResponse = {
  id: string
  name: string | null
  first_name: string | null
  last_name: string | null
  role_id: number
  company_id: string
  email: string
  job_title: string
  user_image: string
  status: Status
  last_login_date: string | null
  last_logout_date: string | null
  is_greenlight_admin: boolean
  created_at: string
  updated_at: string
  is_deleted: boolean
}
export type GetAdminsResponse = {
  data: AdminProfileResponse[]
  /** Total number of records available. */
  total: number
  /** Total number of pages available based on page size. */
  totalPages: number
  /** The current page number. */
  currentPage: number
  /** Number of records per page. */
  pageSize: number
}
export type UpdateGaAdminDto = {
  fullName?: string
  email?: string
  status?: Status
}
export type DeleteAdminResponse = {
  message: string
}
export type CreateGaAdminDto = {
  fullName: string
  email: string
  adminType: AdminType
}
export type ResentInviteAdmin = {
  message: string
}
export type TvFilmCompanyDetails = {
  /** Unique identifier of the company. */
  id: number
  /** IMDB key for the company. */
  imdbId: string
  /** Name of the company. */
  name: string
  /** Abbreviation for the company. */
  abbr: string
  /** Type of company */
  companyType: CompanyType
  /** Distributor type (e.g., Film, TV). */
  distributorType: DistributorType | null
}
export type GetTvFilmCompaniesResponse = {
  /** Total number of records available. */
  totalRecords: number
  /** Total number of pages available based on page size. */
  totalPages: number
  /** The current page number. */
  currentPage: number
  /** Number of records per page. */
  pageSize: number
  /** List of TV & Film companies. */
  list: TvFilmCompanyDetails[]
}
export type CreateTvFilmCompaniesDto = {
  /** IMDB key of company. */
  imdbId: string
  /** The full name of the company. */
  name: string
  /** An optional abbreviation for the company name. */
  abbr: string
  /** The type of the company. */
  companyType: CompanyType
  /** The type of distributor. */
  distributorType?: DistributorType | null
}
export type TvFilmCompanyListDetails = {
  /** Unique identifier of the company. */
  id: number
  /** Name of the company. */
  name: string
  /** Abbreviation for the company. */
  abbr: string
}
export type GetTvFilmCompaniesListResponse = {
  /** List of TV & Film companies. */
  list: TvFilmCompanyListDetails[]
}
export type UpdateTvFilmCompanyDto = {
  /** IMDB key of company. */
  imdbId?: string
  /** The full name of the company. */
  name?: string
  /** An optional abbreviation for the company name. */
  abbr?: string
  /** The type of the company. */
  companyType: CompanyType
  /** The type of distributor. */
  distributorType?: DistributorType | null
}
export type DeleteTvFilmCompanyResponse = {
  /** Result message for the delete operation. */
  message: string
}
export type TvReleaseDto = {
  /** Unique identifier for the film. */
  filmId: number
  /** IMDB ID of the film */
  imdbId: string
  /** Title of the film */
  title: string
  /** Start date of the release */
  date: string
  /** Type of the network */
  networkType: NetworkType
  /** Abbreviation of the network */
  network: string
  /** COMP12345 */
  networkImdbId: string
  /** Series */
  tvType: TvType
  /** Subtype of the TV release */
  tvSubtype: TvSubtype
  /** Genre of the TV release */
  genre: Genre
}
export type PaginatedTvReleasesDto = {
  /** List of TV releases */
  list: TvReleaseDto[]
  /** Total number of records available. */
  totalRecords: number
  /** Total number of pages available based on page size. */
  totalPages: number
  /** The current page number. */
  currentPage: number
  /** Number of records per page. */
  pageSize: number
}
export type CreateTvDto = {
  title: string
  imdbId: string
  date: string
  companyId: number
  tvType?: TvType
  genre?: Genre
  tvSubtype?: TvSubtype
}
export type UpdateTvDto = {
  title?: string
  imdbId?: string
  date?: string
  companyId?: number
  tvType?: TvType
  genre?: Genre
  tvSubtype?: TvSubtype
}
export type DeleteTvResponse = {
  message: string
}
export type UpdateRevenueDto = {
  date?: string
  revenue?: number
}
export type DeleteRecordRcResponse = {
  /** Result message for the delete operation. */
  message: string
}
export type UpdateEventDto = {
  title?: string
  startDate: string
  endDate?: string | null
}
export type RevenueDetails = {
  /** Unique identifier of the revenue */
  id: number
  /** Revenue of record. */
  revenue: number
  /** revenue end date */
  date: string
  /** number of weeks from first friday of year */
  weeks: number
}
export type GetRevenueResponse = {
  /** Total number of records available. */
  totalRecords: number
  /** Total number of pages available based on page size. */
  totalPages: number
  /** The current page number. */
  currentPage: number
  /** Number of records per page. */
  pageSize: number
  /** List revenue records */
  list: RevenueDetails[]
}
export type CreateRevenueDto = {
  date: string
  revenue: number
}
export type EventDetails = {
  /** Unique identifier of the Event */
  id: number
  /** event title. */
  title: string
  /** event start date */
  startDate: string
  /** event end date */
  endDate?: string
}
export type GetEventsResponse = {
  /** Total number of records available. */
  totalRecords: number
  /** Total number of pages available based on page size. */
  totalPages: number
  /** The current page number. */
  currentPage: number
  /** Number of records per page. */
  pageSize: number
  /** List Events */
  list: EventDetails[]
}
export type CreateEventDto = {
  title: string
  startDate: string
  endDate?: string | null
}
export enum Status {
  Active = 'active',
  Paused = 'paused',
}
export enum AdminType {
  SuperAdmin = 'super_admin',
  Admin = 'admin',
}
export enum CompanyType {
  FilmDistributor = 'filmDistributor',
  TvBroadcast = 'tvBroadcast',
  TvCable = 'tvCable',
  TvStreamer = 'tvStreamer',
}
export enum DistributorType {
  Major = 'major',
  Minor = 'minor',
  Other = 'other',
}
export enum NetworkType {
  FilmDistributor = 'filmDistributor',
  TvBroadcast = 'tvBroadcast',
  TvCable = 'tvCable',
  TvStreamer = 'tvStreamer',
}
export enum TvType {
  $1 = 1,
  $2 = 2,
  $3 = 3,
}
export enum TvSubtype {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
}
export enum Genre {
  $1 = 1,
  $2 = 2,
  $3 = 3,
  $4 = 4,
  $5 = 5,
  $6 = 6,
  $7 = 7,
  $8 = 8,
  $9 = 9,
  $10 = 10,
  $11 = 11,
  $12 = 12,
}
export const {
  useFilmMetricsControllerGetFilmTrackingQuery,
  useLazyFilmMetricsControllerGetFilmTrackingQuery,
  useFilmMetricsControllerGetFilmMetricsByFilmIdQuery,
  useLazyFilmMetricsControllerGetFilmMetricsByFilmIdQuery,
  useFilmMetricsControllerGetFilmTrackingDistributorsQuery,
  useLazyFilmMetricsControllerGetFilmTrackingDistributorsQuery,
  useFilmMetricsControllerGetFilmTrackingGroupsQuery,
  useLazyFilmMetricsControllerGetFilmTrackingGroupsQuery,
  useFilmMetricsControllerGetFilmTrackingFiltersQuery,
  useLazyFilmMetricsControllerGetFilmTrackingFiltersQuery,
  useFilmMetricsControllerGetSearchFilmQuery,
  useLazyFilmMetricsControllerGetSearchFilmQuery,
  useFilmMetricsControllerGetChartDataQuery,
  useLazyFilmMetricsControllerGetChartDataQuery,
  useFilmMetricsControllerGetBoxOfficeQuery,
  useLazyFilmMetricsControllerGetBoxOfficeQuery,
  useFilmMetricsControllerGetOpeningWeekendProjectionsQuery,
  useLazyFilmMetricsControllerGetOpeningWeekendProjectionsQuery,
  useGaAdminProfileControllerGetAllAdminsQuery,
  useLazyGaAdminProfileControllerGetAllAdminsQuery,
  useGaAdminProfileControllerGetAdminByIdQuery,
  useLazyGaAdminProfileControllerGetAdminByIdQuery,
  useGaAdminProfileControllerUpdateAdminMutation,
  useGaAdminProfileControllerDeleteAdminMutation,
  useGaAdminProfileControllerInviteAdminMutation,
  useGaAdminProfileControllerResendAdminInviteMutation,
  useTvFilmCompanyControllerGetCompaniesQuery,
  useLazyTvFilmCompanyControllerGetCompaniesQuery,
  useTvFilmCompanyControllerCreateTvFilmCompanyMutation,
  useTvFilmCompanyControllerGetCompaniesListQuery,
  useLazyTvFilmCompanyControllerGetCompaniesListQuery,
  useTvFilmCompanyControllerUpdateCompanyMutation,
  useTvFilmCompanyControllerDeleteMutation,
  useTvControllerGetAllTvReleasesQuery,
  useLazyTvControllerGetAllTvReleasesQuery,
  useTvControllerCreateTvReleaseMutation,
  useTvControllerUpdateTvFilmMutation,
  useTvControllerDeleteTvFilmMutation,
  useReleaseCalendarControllerPutRevenueMutation,
  useReleaseCalendarControllerDeleteRevenueMutation,
  useReleaseCalendarControllerPutEventMutation,
  useReleaseCalendarControllerDeleteEventMutation,
  useReleaseCalendarControllerGetRevenueQuery,
  useLazyReleaseCalendarControllerGetRevenueQuery,
  useReleaseCalendarControllerCreateRevenueMutation,
  useReleaseCalendarControllerGetEventQuery,
  useLazyReleaseCalendarControllerGetEventQuery,
  useReleaseCalendarControllerCreateEventMutation,
} = injectedRtkApi
