import { AdminProfileResponse } from 'store/api'

export type AdminMgmtUser = AdminProfileResponse & {
  last_login_date: string | null
}

export enum AdminMgmtColKey {
  Name = 'name',
  Email = 'email',
  UserType = 'user_type',
  LastLogin = 'last_login_date',
  Actions = 'actions',
}

export type AdminMgmtSortColKey =
  | AdminMgmtColKey.Name
  | AdminMgmtColKey.Email
  | AdminMgmtColKey.LastLogin

export type AdminMgmtUserStatus = AdminMgmtUser['status']

export type AdminMgmtUserAccountStatus = AdminMgmtUserStatus | 'pending'

export type AdminMgmtUserLoginStatus = AdminMgmtUserAccountStatus | 'online'

export type AdminMgmtUserResolvedStatus =
  | AdminMgmtUserAccountStatus
  | AdminMgmtUserLoginStatus
