import { twMerge } from 'tailwind-merge'

import { MgmtTableCell } from 'modules/management/shared'

import { AdminManagementTableTypes } from './types'
import { MoreVertical } from 'components'
import {
  ToggleHookState,
  ToggleHookStateControls,
  useClickOutside,
} from 'hooks'
import { AdminMgmtUser } from '../../types'
import { useRef } from 'react'
import { getAdminMgmtUserAccountStatus } from '../../utils'

type ActionsCellProps = AdminManagementTableTypes['CellProps'] & {
  row: AdminMgmtUser
  cellState: ToggleHookState<AdminMgmtUser>
  cellStateControls: ToggleHookStateControls<AdminMgmtUser>
  onClick: () => void
  onPause: () => void
  onActivate: () => void
}

export const ActionsCell = ({
  row,
  column,
  cellState,
  cellStateControls,
  onClick,
  onPause,
  onActivate,
}: ActionsCellProps) => {
  const { active, data } = cellState
  const isActionsVisible = active && data?.id === row.id
  const cellRef = useRef<HTMLTableCellElement>(null)

  useClickOutside({
    ref: cellRef,
    onClose: cellStateControls.deactivate,
  })

  const accountStatus = getAdminMgmtUserAccountStatus(
    row.status,
    row.last_login_date,
    row.last_logout_date,
  )

  if (accountStatus === 'pending') {
    return <MgmtTableCell column={column} />
  }

  return (
    <MgmtTableCell
      column={column}
      className={twMerge(
        'relative w-12 cursor-pointer px-3 py-5 hover:text-red-10',
      )}
      onClick={event => {
        event.stopPropagation()
        onClick()
      }}
      ref={isActionsVisible ? cellRef : null}
    >
      <MoreVertical fill={'currentColor'} width={16} height={16} />
      {isActionsVisible && (
        <div className='absolute right-full top-1/2 z-50 -translate-y-1/2 transform rounded-md bg-primary-white p-3 text-base shadow-md'>
          <ul className='min-w-[120px] whitespace-nowrap'>
            <li
              className='rounded px-1 py-0.5 text-primary-black transition-colors hover:cursor-pointer hover:bg-primary-black/5'
              onClick={event => {
                event.stopPropagation()
                cellStateControls.deactivate()
                if (row.status === 'active') {
                  onPause()
                } else if (row.status === 'paused') {
                  onActivate()
                }
              }}
            >
              {row.status === 'active' ? 'Pause' : 'Reactivate'}
            </li>
          </ul>
        </div>
      )}
    </MgmtTableCell>
  )
}
