export enum Urls {
  HOME = '/',
  MY_PROJECTS = '/myProjects',
  TALENT = '/talent',
  TALENT_DETAIL = '/talent/:id',
  TALENT_LIST_DETAIL = '/talent/listDetails/:listName/:listId',
  COMPARE = '/compare',
  COMP_FINDER = '/compFinder',
  RELEASE_CALENDAR = '/releaseCalendar',
  CONCEPT_TESTING = '/conceptTesting',
  ROLE_TESTING = '/roleTesting',
  ASSET_TESTING = '/assetTesting',
  UI_ELEMENTS = '/uiElements',
  HELP = '/help',
  DETAIL_PROJECT = '/myProjects/:id',
  FILM = '/film',
  FILM_DETAIL = '/film/:id',
  COMPANY_MANAGEMENT = '/companyManagement',
  USER_MANAGEMENT = '/userManagement',
  ADMIN_MANAGEMENT = '/adminManagement',
  TESTING_MODULES = '/testingModules',
  MANAGEMENT_TV_FILM_COMPANIES = '/tvFilmCompanies',
  TV_MANAGEMENT = '/tvManagement',
  RELEASE_CALENDAR_MANAGEMENT = '/releaseCalendarManagement',
  TALENT_MANAGEMENT = '/talentManagement',
  TALENT_CREATION = '/talentManagement/create',
  TALENT_UPDATE = '/talentManagement/update/:id',
}
