import { Info } from 'components'
import { Colors } from 'constnants'

export const AudienceFilterInfo = () => {
  return (
    <div className='flex items-start bg-grey-3 p-4'>
      <Info fill={Colors.BASE_ICON} width={20} height={20} />
      <p className='ml-2 w-[596px] text-left text-sm text-gray-700'>
        Selecting an <span className='font-semibold'>audience segment</span>{' '}
        will automatically disable the{' '}
        <span className='font-semibold'>Theatergoers filter</span>, as our
        current database only tracks{' '}
        <span className='font-semibold'>Theatergoing</span> behavior for the{' '}
        <span className='font-semibold'>General:All</span> segment. Additional
        audience segments will be available soon.
      </p>
    </div>
  )
}
