import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { RouteType, routes } from 'constnants'
import { PrivateRoute } from 'components/PrivateRoute'
import {
  SignUp,
  Login,
  ForgotPassword,
  ResetPassword,
  PasswordResetEmailSent,
  Layout,
  VerifyEmail,
  PasswordChange,
  AccountPaused,
} from 'components'
import { useGetCurrentUserQuery } from 'store/api'
import { useAdminData, useHotjar } from 'hooks'
import { AuthProvider } from 'context'

function App() {
  useHotjar()
  const { data: user, isLoading } = useGetCurrentUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const [isPasswordResetEmailSent, setIsPasswordResetEmailSent] =
    useState(false)

  const { isAdminPath } = useAdminData()
  const isAdmin = user?.is_greenlight_admin && isAdminPath

  return (
    <AuthProvider>
      <Layout isAdmin={isAdmin} isLoading={isLoading}>
        <Routes>
          <Route path='/signup' element={<SignUp />} />
          <Route path='/login' element={<Login />} />
          <Route
            path='/forgot-password'
            element={
              isPasswordResetEmailSent ? (
                <PasswordResetEmailSent />
              ) : (
                <ForgotPassword
                  setIsPasswordResetEmailSent={setIsPasswordResetEmailSent}
                />
              )
            }
          />
          <Route path='/verify-email' element={<VerifyEmail />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/password-change' element={<PasswordChange />} />
          <Route path='/account-paused' element={<AccountPaused />} />
          {routes.map((route: RouteType, index: number) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={<PrivateRoute>{route.component}</PrivateRoute>}
              />
            )
          })}
        </Routes>
      </Layout>
    </AuthProvider>
  )
}

export default App
