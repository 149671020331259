import { useCallback, useState } from 'react'
import { PaginationChangeParams, PaginationToolbar } from 'components'
import { managementRowsPerPageOptions } from 'constnants'
import { useGaAdminProfileControllerGetAllAdminsQuery } from 'store/api'
import { AdminMgmtTable, AdminMgmtToolbar } from '../../components'
import type { AdminManagementTableTypes } from '../../components'
import { useSelector } from 'react-redux'
import { adminManagementSelectors } from '../../slices'
import { useAdminManagementActions } from '../../hooks'
import { AdminMgmtUser } from '../../types'

interface AdminManagementPageProps {
  onOpenUserDetails: (user: AdminMgmtUser) => void
}

export const AdminMgmtUsersView = ({
  onOpenUserDetails,
}: AdminManagementPageProps) => {
  const actions = useAdminManagementActions()

  const { pageConfig, sorting } = useSelector(adminManagementSelectors.root)

  const [searchTerm, setSearchTerm] = useState('')

  const handlePagination = useCallback(
    (params: PaginationChangeParams) =>
      actions.setPageConfig({
        pageNumber: params.page,
        pageSize: params.pageSize,
      }),
    [actions],
  )

  const handleSortChange: AdminManagementTableTypes['OnSortChange'] =
    useCallback(({ sortConfig }) => actions.setSorting(sortConfig), [actions])

  const { adminUsers, totalPages, totalRows, isFetching, isLoading } =
    useGaAdminProfileControllerGetAllAdminsQuery(
      {
        pageSize: pageConfig.pageSize,
        pageNumber: pageConfig.pageNumber,
        sortBy: sorting.by,
        sortOrder: sorting.order,
        fullName: searchTerm.length ? searchTerm : undefined,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          return {
            ...rest,
            adminUsers: data?.data ?? [],
            totalPages: data?.totalPages ?? 0,
            totalRows: data?.total ?? 0,
          }
        },
      },
    )

  const paginationBar = (
    <PaginationToolbar
      totalPages={totalPages}
      totalRows={totalRows}
      currentPage={pageConfig.pageNumber}
      currentPageSize={pageConfig.pageSize}
      pageSizeOptions={managementRowsPerPageOptions}
      onChange={handlePagination}
    />
  )

  return (
    <div className='flex h-full flex-col'>
      <AdminMgmtToolbar onSearchChange={setSearchTerm} />
      <AdminMgmtTable
        rows={adminUsers}
        footer={paginationBar}
        loading={isLoading}
        fetching={isFetching}
        sortConfig={sorting}
        onSortChange={handleSortChange}
        onRowClick={onOpenUserDetails}
      />
    </div>
  )
}
