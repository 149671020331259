import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import {
  defineCreateTVManagementEndpoint,
  defineGetTVManagementDataEndpoint,
  defineUpdateTVManagementEndpoint,
} from './mock-data/tVManagementMockData'
import {
  defineCreateTalentManagementEndpoint,
  defineGetTalentByIdManagementDataEndpoint,
  defineGetTalentManagementDataEndpoint,
  defineUpdateTalentManagementEndpoint,
} from './mock-data/talentManagementMockData'

const noOpBaseQuery: BaseQueryFn = async () => {
  return { data: null }
}

export const mockApi = createApi({
  reducerPath: 'mockApi',
  baseQuery: noOpBaseQuery,
  endpoints: builder => ({
    getTVManagementData: defineGetTVManagementDataEndpoint(builder),
    addTVManagement: defineCreateTVManagementEndpoint(builder),
    updateTVManagement: defineUpdateTVManagementEndpoint(builder),

    getTalentManagementData: defineGetTalentManagementDataEndpoint(builder),
    getTalentByIdManagementData:
      defineGetTalentByIdManagementDataEndpoint(builder),
    addTalentManagement: defineCreateTalentManagementEndpoint(builder),
    updateTalentManagement: defineUpdateTalentManagementEndpoint(builder),
  }),
})

export const {
  useGetTVManagementDataQuery,
  useAddTVManagementMutation,
  useUpdateTVManagementMutation,

  useGetTalentManagementDataQuery,
  useGetTalentByIdManagementDataQuery,
  useAddTalentManagementMutation,
  useUpdateTalentManagementMutation,
} = mockApi
