export const AccountPaused = () => {
  return (
    <div className='flex h-[96vh] items-center justify-center'>
      <div className='w-[352px] rounded-lg bg-white p-8 text-center'>
        <h1 className='text-2xl font-bold text-gray-900'>
          Your account is paused
        </h1>
        <p className='mt-2 text-sm text-primary-grey'>
          Please contact{' '}
          <a href='#' className='text-red-9 underline'>
            customer support
          </a>{' '}
          for help.
        </p>
      </div>
    </div>
  )
}
