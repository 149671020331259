import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import {
  ReleaseCalendarEventColKey,
  ReleaseCalendarEventSortColKey,
  ReleaseCalendarRevenueColKey,
  ReleaseCalendarRevenueSortColKey,
  ReleaseCalendarTab,
} from '../types'

interface TabSorting<SortKey extends string> {
  order: 'ASC' | 'DESC'
  by: SortKey
}

interface TabPagination {
  pageSize: number
  pageNumber: number
}

interface TabState<SortKey extends string> {
  pagination: TabPagination
  sorting: TabSorting<SortKey>
}

interface RevenueTabFilters {
  yearFilter: number | undefined
}

interface RevenueTabState<SortKey extends string>
  extends TabState<SortKey>,
    RevenueTabFilters {}

interface TabsState {
  [ReleaseCalendarTab.SportingEvents]: TabState<ReleaseCalendarEventSortColKey>
  [ReleaseCalendarTab.HolidayEvents]: TabState<ReleaseCalendarEventSortColKey>
  [ReleaseCalendarTab.PastRevenue]: RevenueTabState<ReleaseCalendarRevenueSortColKey>
}
interface PageState {
  activeTab: ReleaseCalendarTab
  tabs: TabsState
}

const paginationInitialState: TabPagination = {
  pageSize: 50,
  pageNumber: 1,
}

const eventsTabSortingInitialState: TabSorting<ReleaseCalendarEventSortColKey> =
  {
    order: 'DESC',
    by: ReleaseCalendarEventColKey.StartDate,
  }

const eventsTabInitialState: TabState<ReleaseCalendarEventSortColKey> = {
  pagination: paginationInitialState,
  sorting: eventsTabSortingInitialState,
}

const revenueTabSortingInitialState: TabSorting<ReleaseCalendarRevenueSortColKey> =
  {
    order: 'DESC',
    by: ReleaseCalendarRevenueColKey.Date,
  }

const revenueTabInitialState: RevenueTabState<ReleaseCalendarRevenueSortColKey> =
  {
    pagination: paginationInitialState,
    sorting: revenueTabSortingInitialState,
    yearFilter: undefined,
  }

const tabsInitialState: TabsState = {
  [ReleaseCalendarTab.SportingEvents]: eventsTabInitialState,
  [ReleaseCalendarTab.HolidayEvents]: eventsTabInitialState,
  [ReleaseCalendarTab.PastRevenue]: revenueTabInitialState,
}

const initialState: PageState = {
  activeTab: ReleaseCalendarTab.SportingEvents,
  tabs: tabsInitialState,
}

function updateTabState<
  T extends ReleaseCalendarTab,
  K extends keyof TabsState[T],
>(state: Draft<PageState>, tab: T, key: K, config: TabsState[T][K]) {
  state.tabs[tab][key] = config
}

export const releaseCalendarMgmtSlice = createSlice({
  name: 'releaseCalendarMgmt',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<PageState['activeTab']>) => {
      state.activeTab = action.payload
    },

    setSportingEventsTabPagination: (
      state,
      action: PayloadAction<TabPagination>,
    ) => {
      updateTabState(
        state,
        ReleaseCalendarTab.SportingEvents,
        'pagination',
        action.payload,
      )
    },

    setHolidayEventsTabPagination: (
      state,
      action: PayloadAction<TabPagination>,
    ) => {
      updateTabState(
        state,
        ReleaseCalendarTab.HolidayEvents,
        'pagination',
        action.payload,
      )
    },

    setPastRevenueTabPagination: (
      state,
      action: PayloadAction<TabPagination>,
    ) => {
      updateTabState(
        state,
        ReleaseCalendarTab.PastRevenue,
        'pagination',
        action.payload,
      )
    },

    setSportingEventsTabSorting: (
      state,
      action: PayloadAction<Partial<typeof eventsTabSortingInitialState>>,
    ) => {
      updateTabState(state, ReleaseCalendarTab.SportingEvents, 'sorting', {
        ...tabsInitialState[ReleaseCalendarTab.SportingEvents]['sorting'],
        ...action.payload,
      })
    },

    setHolidayEventsTabSorting: (
      state,
      action: PayloadAction<Partial<typeof eventsTabSortingInitialState>>,
    ) => {
      updateTabState(state, ReleaseCalendarTab.HolidayEvents, 'sorting', {
        ...tabsInitialState[ReleaseCalendarTab.HolidayEvents]['sorting'],
        ...action.payload,
      })
    },

    setPastRevenueTabSorting: (
      state,
      action: PayloadAction<Partial<typeof revenueTabSortingInitialState>>,
    ) => {
      updateTabState(state, ReleaseCalendarTab.PastRevenue, 'sorting', {
        ...tabsInitialState[ReleaseCalendarTab.PastRevenue]['sorting'],
        ...action.payload,
      })
    },

    setPastRevenueTabYearFilter: (
      state,
      action: PayloadAction<RevenueTabFilters['yearFilter']>,
    ) => {
      state.tabs[ReleaseCalendarTab.PastRevenue].yearFilter = action.payload
    },
  },
  selectors: {
    root: state => state,
    activeTab: state => state.activeTab,
    sportingEventsTab: state => state.tabs[ReleaseCalendarTab.SportingEvents],
    holidayEventsTab: state => state.tabs[ReleaseCalendarTab.HolidayEvents],
    pastRevenueTab: state => state.tabs[ReleaseCalendarTab.PastRevenue],
    pastRevenueTabYearFilter: state =>
      state.tabs[ReleaseCalendarTab.PastRevenue].yearFilter,
  },
})

export const {
  actions: releaseCalendarMgmtActions,
  selectors: releaseCalendarMgmtSelectors,
} = releaseCalendarMgmtSlice
