import { useSelector } from 'react-redux'
import { EntitySearchByField, Select } from 'components'
import { OptionType } from 'types'
import {
  MgmtCreateButton,
  MgmtPageTabs,
  type MgmtPageTab,
} from 'modules/management/shared'
import { ReleaseCalendarTab } from '../../types'
import { useReleaseCalendarMgmtActions } from '../../hooks'
import { releaseCalendarMgmtSelectors } from '../../slices'
import { pastRevenueYearFilterOptions } from '../../config'
import { SportingEventAddDialog } from '../SportingEventAddDialog'
import { HolidayEventAddDialog } from '../HolidayEventAddDialog'
import { PastRevenueAddDialog } from '../PastRevenueAddDialog'

interface ReleaseCalendarToolbarProps {
  onSearchChange: (value: string) => void
}

const tabs: MgmtPageTab<ReleaseCalendarTab>[] = [
  {
    id: ReleaseCalendarTab.SportingEvents,
    label: 'Sporting Events',
  },
  {
    id: ReleaseCalendarTab.HolidayEvents,
    label: 'Holidays',
  },
  {
    id: ReleaseCalendarTab.PastRevenue,
    label: 'Past Revenue',
  },
]

const renderYearFilter = (
  options: OptionType<number | undefined>[],
  value: number | undefined,
  onChange: (value: number | undefined) => void,
  disabled = false,
) => (
  <Select
    size='small'
    alignItems='start'
    buttonClass='whitespace-nowrap'
    wrapperOptionClass='w-[170px] max-h-[375px]'
    hasRadioButton={false}
    value={value}
    options={options}
    disabled={disabled}
    handleClick={option => onChange(option.value)}
  />
)

export const ReleaseCalendarToolbar = ({
  onSearchChange,
}: ReleaseCalendarToolbarProps) => {
  const activeTab = useSelector(releaseCalendarMgmtSelectors.activeTab)
  const pastRevenueYear = useSelector(
    releaseCalendarMgmtSelectors.pastRevenueTabYearFilter,
  )
  const actions = useReleaseCalendarMgmtActions()
  const isPastRevenueTaB = activeTab === ReleaseCalendarTab.PastRevenue
  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <h1 className='mr-12 text-2xl font-semibold text-primary-black'>
        Release Calendar
      </h1>

      <MgmtPageTabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={actions.setActiveTab}
      />

      <div className='flex items-center gap-4'>
        {isPastRevenueTaB ? (
          renderYearFilter(
            pastRevenueYearFilterOptions,
            pastRevenueYear,
            actions.setPastRevenueTabYearFilter,
          )
        ) : (
          <EntitySearchByField
            placeholder='Search Name'
            onSearch={onSearchChange}
            onClear={() => onSearchChange('')}
          />
        )}

        {activeTab === ReleaseCalendarTab.SportingEvents && (
          <SportingEventAddDialog
            renderTrigger={dialogControls => (
              <MgmtCreateButton
                label={'New Event'}
                onClick={() => dialogControls.activate()}
              />
            )}
          />
        )}

        {activeTab === ReleaseCalendarTab.HolidayEvents && (
          <HolidayEventAddDialog
            renderTrigger={dialogControls => (
              <MgmtCreateButton
                label={'New Event'}
                onClick={() => dialogControls.activate()}
              />
            )}
          />
        )}

        {activeTab === ReleaseCalendarTab.PastRevenue && (
          <PastRevenueAddDialog
            renderTrigger={dialogControls => (
              <MgmtCreateButton
                label={'New Entry'}
                onClick={() => dialogControls.activate()}
              />
            )}
          />
        )}
      </div>
    </div>
  )
}
