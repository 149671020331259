import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  Button,
  LogLineCard,
  Select as CustomSelect,
  Textarea,
} from '../../components'
import Select, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MenuListProps,
} from 'react-select'
import { OptionFilter, Project, TalentSaveSearchResponce } from '../../types'
import { Colors } from 'constnants'
import { submitTesting } from '../../hooks'
import {
  useGetProjectCompanyByIdQuery,
  useGetTelentsListsQuery,
} from 'store/api'
import {
  CheckCircle,
  ChevronDown,
  Search,
  TooltipIcon,
} from '../../components/ui/icons'
import { JSX } from 'react/jsx-runtime'
import './styles.css'
import { twMerge } from 'tailwind-merge'
import { useGetProjectCompanyDetailsByProjectIdQuery } from '../../store/api'
import { useAuthContext } from '../../context'

interface RoleTemplateProps {
  returnBack: () => void
}

interface Talent {
  name: string
}

interface Role {
  roleName?: string
  talents: Talent[]
  roleListOptions?: OptionFilter[]
}

interface fieldsProps {
  projectId: string
  audience: string
  logLine: string
  role1: Role
  role2: Role
  role3: Role
}

export const RoleTemplate = ({ returnBack }: RoleTemplateProps) => {
  const userAuthContext = useAuthContext()
  const companyId =
    userAuthContext?.auth?.userAttributes?.['custom:company_id'] || ''
  const { data } = useGetProjectCompanyByIdQuery(
    {
      id: companyId ?? '',
    },
    { skip: !companyId },
  )
  const [projectsList, setProjectsList] = useState<OptionFilter[]>([])
  useEffect(() => {
    if (data) {
      setProjectsList(
        data
          .map((project: Project) => ({
            id: project?.company_project_id || '0',
            value: project?.company_project_id || '0',
            label: project?.name || '',
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
    }
  }, [data])
  const [rolesDropdownOptionsHidden, setRolesDropdownOptionsHidden] = useState<
    string[]
  >([''])
  const [talentsDropdownToShow, setTalentsDropdownToShow] =
    useState<string>('all')
  const [isSuccess, setIsSuccess] = useState(false)
  const [openedTalentsDropdownId, setOpenedTalentsDropdownId] =
    useState<number>(0)
  const refs = useRef<(HTMLDivElement | null)[]>([])
  const [fields, setFields] = useState<fieldsProps>({
    projectId: '',
    audience: '',
    logLine: '',
    role1: {
      talents: [{ name: '' }, { name: '' }, { name: '' }],
      roleName: '',
      roleListOptions: [],
    },
    role2: {
      talents: [{ name: '' }, { name: '' }, { name: '' }],
      roleName: '',
      roleListOptions: [],
    },
    role3: {
      talents: [{ name: '' }, { name: '' }, { name: '' }],
      roleName: '',
      roleListOptions: [],
    },
  })
  const [allTalentsOptions, setAllTalentsOptions] = useState<OptionFilter[]>([])
  const [roleNameOptionsByProject, setRoleNameOptionsByProject] = useState<
    OptionFilter[]
  >([])
  const { data: getAllTalents } = useGetTelentsListsQuery({
    projectId: null,
    roleId: null,
  })

  const { data: projectDetailsData } =
    useGetProjectCompanyDetailsByProjectIdQuery(
      { id: Number(fields.projectId) },
      { skip: !fields.projectId },
    )
  useEffect(() => {
    if (getAllTalents) {
      setAllTalentsOptions(
        getAllTalents
          .map((list: TalentSaveSearchResponce) => ({
            id: list.list_id?.toString() || '0',
            value: list.list_name || ' ',
            label: list.list_name || 'N/A',
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
    }
  }, [getAllTalents])

  useEffect(() => {
    if (fields.projectId && projectDetailsData) {
      projectDetailsData.map((project: Project) => {
        if (project.project_roles) {
          setRoleNameOptionsByProject(
            project.project_roles.map((role: { id: string; name: string }) => ({
              id: role.id || '0',
              value: role.name || '0',
              label: role.name || '',
            })),
          )
        }
      })
    }
  }, [fields.projectId, projectDetailsData])
  const handleClickOutsideTalentDropdown = (event: MouseEvent) => {
    if (
      refs.current &&
      !refs.current.some(ref => ref && ref.contains(event.target as Node))
    ) {
      setOpenedTalentsDropdownId(0)
      setTalentsDropdownToShow('all')
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideTalentDropdown, true)
    return () => {
      document.removeEventListener(
        'click',
        handleClickOutsideTalentDropdown,
        true,
      )
    }
  }, [])

  const handleSelectChange = (option: OptionFilter) => {
    setFields({ ...fields, projectId: option.value })
  }
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length <= 500) {
      setFields({ ...fields, logLine: value })
    }
  }

  const onInputChange = (
    e: {
      value: string
      label: string
    },
    roleKey: 'role1' | 'role2' | 'role3',
    fieldKey: 'roleName' | 'talents',
    index?: number,
    talentField?: 'name' | 'description',
  ) => {
    setFields(prevFields => ({
      ...prevFields,
      [roleKey]: {
        ...prevFields[roleKey],
        [fieldKey]:
          fieldKey === 'talents' && index !== undefined && talentField
            ? prevFields[roleKey][fieldKey].map((talent, i) =>
                i === index ? { ...talent, [talentField]: e.value } : talent,
              )
            : e.value,
      },
    }))
    setTimeout(() => {
      setOpenedTalentsDropdownId(0)
      setTalentsDropdownToShow('all')
    }, 0)
  }

  const handleSelect = (
    option: OptionFilter,
    roleKey: 'role1' | 'role2' | 'role3',
    fieldKey: 'roleName' | 'talents',
    index?: number,
    talentField?: 'name' | 'description',
  ) => {
    const talentsListByRoleOptions = getAllTalents
      .filter(talent => talent.role_id === option.id)
      .map((talent: { id: string; name: string }) => ({
        id: talent.list_id || '0',
        value: talent.list_name || '0',
        label: talent.list_name || '',
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    setFields(prevFields => ({
      ...prevFields,
      [roleKey]: {
        ...prevFields[roleKey],
        [fieldKey]:
          fieldKey === 'talents' && index !== undefined && talentField
            ? prevFields[roleKey][fieldKey].map((talent, i) =>
                i === index ? { ...talent, [talentField]: option } : talent,
              )
            : option.value,
        roleListOptions: talentsListByRoleOptions,
      },
    }))
  }

  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes &
      DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Search fill={Colors.BASE_ICON} width={12} height={12} />
        </components.DropdownIndicator>
      )
    )
  }

  const CustomMenu = (props: MenuProps) => {
    return (
      <div>
        <div className='flex items-center gap-2 pt-3.5 text-sm'>
          <p
            onClick={() => setTalentsDropdownToShow('all')}
            className={twMerge(
              'cursor-pointer pb-1 ',
              talentsDropdownToShow === 'all' &&
                'text-underline border-b border-b-primary-red font-medium text-primary-red',
            )}
          >
            All Talent
          </p>
          <p
            onClick={() => setTalentsDropdownToShow('project_role_list')}
            className={twMerge(
              'cursor-pointer pb-1 ',
              talentsDropdownToShow === 'project_role_list' &&
                'text-underline border-b border-b-primary-red font-medium text-primary-red',
            )}
          >
            Talent from Project Role lists
          </p>
        </div>
        <components.Menu {...props} />
      </div>
    )
  }

  const CustomMenuList = (props: MenuListProps) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <div
          className={
            'flex items-center justify-between bg-primary-black/5 p-4 text-sm text-primary-grey'
          }
        >
          {talentsDropdownToShow === 'all' ? (
            <>
              <div className={'flex items-center gap-2'}>
                <TooltipIcon fill={Colors.BASE_ICON} width={16} height={16} />
                <p>
                  Don’t see the Talent you want to test. Contact us and we’ll
                  add it to the system.
                </p>
              </div>
              <a
                href='#'
                className={
                  'border-b border-b-primary-red/20 text-xs text-primary-red'
                }
              >
                Contact Us
              </a>
            </>
          ) : (
            <div className={'flex items-center gap-2'}>
              <TooltipIcon fill={Colors.BASE_ICON} width={16} height={16} />
              <p>
                This menu comprises of all the talent assigned to Project Role
                Lists for the Role you’ve selected above.
              </p>
            </div>
          )}
        </div>
      </components.MenuList>
    )
  }

  useEffect(() => {
    const fieldsToMap = [fields.role1, fields.role2, fields.role3]
    const values = fieldsToMap
      .filter(role => role.roleName !== '')
      .map(role => role.roleName)
    setRolesDropdownOptionsHidden(values as string[])
    validateForm()
  }, [fields])

  const validateForm = () => {
    const { projectId, logLine } = fields
    return (
      projectId &&
      logLine &&
      fields.role1.roleName &&
      fields.role1.talents[0].name
    )
  }

  const dataToSubmit = {
    projectId: fields.projectId,
    audience: fields.audience,
    logLine: fields.logLine,
    role1: {
      talents: fields.role1.talents.map(talent => ({ name: talent.name })),
      role_name: fields.role1.roleName,
    },
    role2: {
      talents: fields.role2.talents.map(talent => ({ name: talent.name })),
      role_name: fields.role2.roleName,
    },
    role3: {
      talents: fields.role3.talents.map(talent => ({ name: talent.name })),
      role_name: fields.role3.roleName,
    },
  }

  const submitForm = () => {
    submitTesting('role', dataToSubmit, setIsSuccess, returnBack).then(r => r)
  }

  const reactSelect = (
    idToOpen: number,
    labelNumber: number,
    row: 'role1' | 'role2' | 'role3',
    talentRow: number,
  ) => {
    return (
      <div
        className={'relative'}
        onClick={() => setOpenedTalentsDropdownId(idToOpen)}
        ref={el => (refs.current[idToOpen] = el)}
      >
        <div
          className='mt-1 flex h-8 w-full items-center justify-between rounded border border-primary-black/10
                  bg-primary-white px-2 py-1 pl-3 text-xs leading-6 text-primary-grey duration-300
                  hover:cursor-pointer hover:bg-primary-black/5'
        >
          <p>
            {fields[row]?.talents[talentRow].name || 'Talent ' + labelNumber}
          </p>
          <ChevronDown fill={Colors.BASE_ICON} width={20} height={20} />
        </div>
        {openedTalentsDropdownId === idToOpen && (
          <Select
            className='films-to-compare-select talent-search-dropdown absolute top-[calc(100%_+_4px)] p-4 pb-0'
            classNamePrefix='talent-search-dropdown-options'
            onChange={(val: { value: string; label: string }) => {
              onInputChange(val, row, 'talents', talentRow, 'name')
            }}
            placeholder={'Search'}
            options={
              talentsDropdownToShow === 'all'
                ? allTalentsOptions
                : fields[row].roleListOptions
            }
            isSearchable={true}
            isMulti={false}
            isClearable={false}
            closeMenuOnSelect={false}
            defaultMenuIsOpen={true}
            menuIsOpen={true}
            components={{
              DropdownIndicator,
              Menu: CustomMenu,
              MenuList: CustomMenuList,
            }}
            noOptionsMessage={() => 'No Talents found!'}
            isFocused={true}
          />
        )}
      </div>
    )
  }

  return (
    <>
      {isSuccess && (
        <div className='fixed bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center bg-primary-black/50'>
          <span
            className={
              'flex items-center rounded bg-primary-white px-3 py-2 shadow-md'
            }
          >
            <CheckCircle fill={Colors.SUCCESS} width={20} height={20} />
            <span className='pl-2'>Test created successfully</span>
          </span>
        </div>
      )}
      <div className='mb-8'>
        <p className={'text-lg font-medium text-primary-black'}>
          Select a Project and Audience
        </p>
        <p className={'text-sm text-primary-grey'}>
          Describe what a this test if about.
        </p>
      </div>
      <div className='w-1/2 min-w-[650px]'>
        <div className='mb-8'>
          <CustomSelect
            value={fields.projectId}
            handleClick={handleSelectChange}
            options={projectsList}
            containerClass='w-[400px]'
            placeholder='Select Project'
            label='Project'
            labelClass='text-sm font-semibold uppercase leading-6 text-grey-7 pb-0'
            buttonClass='py-[9.4px]'
            size='medium'
            selectedLabelClass='text-sm text-grey-7 font-normal'
            hasRadioButton={false}
          />
        </div>
        <div className='mb-10'>
          <p
            className={
              'pb-0 text-sm font-semibold uppercase leading-6 text-grey-7'
            }
          >
            Your Custom Audience
          </p>
          <p className={'mb-3 text-sm text-primary-grey'}>
            In addition to the 30 general demographics we survey across, please
            provide other demographics you would like Greenlight to isolate.
          </p>
          <Textarea
            onChange={e => setFields({ ...fields, audience: e.target.value })}
            placeholder={
              'E.g. Fans of horror movies, Fans of John Carpenter, Video Gamers, People who watch the Olympics, Soccer Enthusiasts'
            }
          />
          <p className={'text-xs text-primary-grey'}>Optional</p>
        </div>
        <div className='mb-8'>
          <p className={'text-sm font-semibold uppercase text-grey-7'}>
            Survey Logline
          </p>
          <p className={'mb-4 text-sm text-primary-grey'}>
            This logline will be provided to surveyors as a description of the
            project.
          </p>
          <LogLineCard
            exampleLogline='“Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra potestate sunt infirmi, servilis, licet, lex pertinet.”'
            value={fields.logLine}
            handleChange={handleChange}
            isStep
          />
        </div>

        <div className={'mt-8 border-t pt-8'}>
          <p className={'text-lg font-medium text-primary-black'}>
            Select the Roles you want to test
          </p>
          <p className={'mb-8 text-sm text-primary-grey'}>
            Describe what this is.
          </p>
          <div
            className={twMerge(
              'my-8 rounded bg-grey-3 px-6 py-4 pr-8',
              fields.projectId === '' && 'pointer-events-none opacity-50',
            )}
          >
            <div className={'mb-4 text-sm text-grey-7'}>
              <p className={'font-semibold uppercase '}>Role 1 (required)</p>
            </div>
            <div>
              <CustomSelect
                id={'role1'}
                disabled={false}
                value={fields.role1?.roleName || ''}
                handleClick={option => {
                  handleSelect(option, 'role1', 'roleName')
                }}
                buttonClass='h-8 items-center pl-3 text-sm'
                selectedLabelClass='font-normal'
                options={roleNameOptionsByProject}
                size='medium'
                placeholder='Role Name'
                hasRadioButton={false}
                optionsHidden={rolesDropdownOptionsHidden}
              />
              {reactSelect(11, 1, 'role1', 0)}
              {reactSelect(12, 2, 'role1', 1)}
              {reactSelect(13, 3, 'role1', 2)}
            </div>
          </div>
          <div
            className={twMerge(
              'my-8 rounded bg-grey-3 px-6 py-4 pr-8',
              fields.projectId === '' && 'pointer-events-none opacity-50',
            )}
          >
            <div className={'mb-4 text-sm text-grey-7'}>
              <p className={'font-semibold uppercase '}>Role 2 (optional)</p>
            </div>
            <div>
              <CustomSelect
                id={'role2'}
                disabled={false}
                value={fields.role2?.roleName || ''}
                handleClick={option => {
                  handleSelect(option, 'role2', 'roleName')
                }}
                selectedLabelClass='font-normal'
                buttonClass='h-8 items-center pl-3 text-sm'
                options={roleNameOptionsByProject}
                size='medium'
                placeholder='Role Name'
                hasRadioButton={false}
                optionsHidden={rolesDropdownOptionsHidden}
              />
              {reactSelect(21, 1, 'role2', 0)}
              {reactSelect(22, 2, 'role2', 1)}
              {reactSelect(23, 3, 'role2', 2)}
            </div>
          </div>
          <div
            className={twMerge(
              'my-8 rounded bg-grey-3 px-6 py-4 pr-8',
              fields.projectId === '' && 'pointer-events-none opacity-50',
            )}
          >
            <div className={'mb-4 text-sm text-grey-7'}>
              <p className={'font-semibold uppercase '}>Role 3 (optional)</p>
            </div>
            <div>
              <CustomSelect
                id={'role3'}
                disabled={false}
                value={fields.role3?.roleName || ''}
                handleClick={option => {
                  handleSelect(option, 'role3', 'roleName')
                }}
                buttonClass='h-8 items-center pl-3 text-sm'
                selectedLabelClass='font-normal'
                options={roleNameOptionsByProject}
                size='medium'
                placeholder='Role Name'
                hasRadioButton={false}
                optionsHidden={rolesDropdownOptionsHidden}
              />
              {reactSelect(31, 1, 'role3', 0)}
              {reactSelect(32, 2, 'role3', 1)}
              {reactSelect(33, 3, 'role3', 2)}
            </div>
          </div>
        </div>

        <Button
          kind='filled'
          size='medium'
          className='bg-primary-red px-4 py-2 hover:bg-red-9 active:bg-red-10 disabled:bg-primary-red'
          onClick={submitForm}
          disabled={!validateForm()}
        >
          <div className='flex items-center'>
            <span className='pr-1 text-sm font-semibold'>Submit test</span>
          </div>
        </Button>
      </div>
    </>
  )
}
