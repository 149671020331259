import { isBefore } from 'date-fns'
import { formatDate } from './formatDate'

export function getUserLastLoginStatus(
  status: 'active' | 'paused',
  lastLoginDate: string | null,
  lastLogoutDate: string | null,
) {
  const isActive = status === 'active'
  const isPending = !lastLoginDate && !lastLogoutDate

  const isOnline =
    lastLoginDate &&
    (!lastLogoutDate || isBefore(lastLogoutDate, lastLoginDate))

  if (isPending) {
    return 'Pending'
  }

  if (!isActive) {
    return 'Inactive'
  }

  if (isOnline) {
    return 'Online'
  }

  return lastLoginDate ? formatDate(lastLoginDate, 'MM/DD/YYYY, h:mm A') : '--'
}
