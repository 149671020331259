import { OptionType } from 'types'

export const pastRevenueYearFilterOptions: OptionType<number | undefined>[] = [
  { id: 'all', value: undefined, label: 'All' },
  { id: '2024', value: 2024, label: '2024' },
  { id: '2023', value: 2023, label: '2023' },
  { id: '2022', value: 2022, label: '2022' },
  { id: '2021', value: 2021, label: '2021' },
  { id: '2020', value: 2020, label: '2020' },
  { id: '2019', value: 2019, label: '2019' },
]
