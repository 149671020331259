import { AdminMgmtColKey } from '../../types'
import { type AdminManagementCol } from './types'

export const columns: AdminManagementCol[] = [
  {
    key: AdminMgmtColKey.Name,
    sortKey: AdminMgmtColKey.Name,
    title: 'Name',
  },
  {
    key: AdminMgmtColKey.Email,
    sortKey: AdminMgmtColKey.Email,
    title: 'Email',
  },
  { key: AdminMgmtColKey.UserType, title: 'User type' },
  {
    key: AdminMgmtColKey.LastLogin,
    sortKey: AdminMgmtColKey.LastLogin,
    title: 'Last Login',
  },

  { key: AdminMgmtColKey.Actions, width: 48 },
]
