import { BaseModal } from 'components'
import { ToggleHookStateControls, useToggle } from 'hooks'
import { ReactNode, useCallback } from 'react'
import { useAddTVManagementMutation } from 'store/api/mock-api'
import { TVManagementForm } from '../TVManagementForm'
import { TVManagementFormData } from '../TVManagementForm/TVManagementFormSchema'

interface TVManagementAddDialogProps {
  renderTrigger: (dialogControls: ToggleHookStateControls) => ReactNode
}

export const TVManagementAddDialog = ({
  renderTrigger,
}: TVManagementAddDialogProps) => {
  const [dialogState, dialogStateControls] = useToggle()
  const [createTv] = useAddTVManagementMutation()

  const handleSubmit = useCallback(
    async (data: TVManagementFormData) => {
      try {
        await createTv(data)
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [createTv, dialogStateControls],
  )

  return (
    <>
      {renderTrigger(dialogStateControls)}
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'New TV Event'}
        maxWidth='450px'
      >
        <TVManagementForm
          submitButtonLabel='Add Event'
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
