import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DateRange as PickerDateRange } from 'react-day-picker'
import { SortQueryParams } from 'hooks/useTalent'

import {
  TalentCompareGraphControls,
  CompareWindowHeader,
  LayoutContentWrapper,
  BaseTable,
  IconButton,
  CompareTalentListMenu,
  TalentMetricsChart,
} from 'components'
import { useChartData } from 'hooks'
import { columnsTalent } from 'constnants/talentTableData'
import {
  AttributeType,
  SelectedFilters,
  SocialSearchFilters,
  SortOrderKey,
  TalentColors,
  TalentDateRange,
  Urls,
} from 'types'
import { twMerge } from 'tailwind-merge'
import useTalentById from '../../hooks/useTalentById'
import useSerializeTalentData from '../../hooks/useSerializeTalentData'
import { useGetTalentsChartDataQuery } from 'store/api'
import { Colors, TalentColorPalette, topOptions } from 'constnants'
import {
  optionAttributes,
  optionSocialMedia,
  optionSearch,
} from 'constnants/optionAttributes'
import { Talent } from 'components/ui/icons'
import { buildParams } from 'utils'

const socialSearchFilters: SocialSearchFilters = {
  social: ['allFollowers'],
  search: ['wikipediaPageViews'],
}

const allMetricOptions = [
  ...optionAttributes,
  ...optionSocialMedia,
  ...optionSearch,
]

export const CompareWindowPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [showCompareTalentList, setShowCompareTalentList] = useState(false)
  const [sortOrder, setSortOrder] = useState<SortOrderKey>('DESC')
  const [sortBy, setSortBy] = useState<string>('AWARENESS')
  const [talentIds, setTalentIds] = useState<string[]>([])
  const [contentTypeView, setContentTypeView] =
    useState<AttributeType>('percentage')
  const [top2Box, setTop2Box] = useState(topOptions[1].value)
  const [selectedRange, setSelectedRange] = useState<
    PickerDateRange | undefined
  >(undefined)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const onShowCompareTalentList = () => setShowCompareTalentList(true)
  const onHideCompareTalentList = () => setShowCompareTalentList(false)

  const [dataDateRange, setDataDateRange] = useState<TalentDateRange>('YEAR')
  const [selectedAttributeDropdown, setSelectedAttributeDropdown] =
    useState<SelectedFilters>({
      attribute: ['awareness'],
    })
  const [audienceFilter, setAudienceFilter] = useState<string>('')
  const selectedAttributeType = selectedAttributeDropdown['attribute'][0]
  const { talentList, getTalentsByIds } = useTalentById()

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const labelAttributeType = useMemo(() => {
    const metric = allMetricOptions.find(
      option => option.id === selectedAttributeType,
    )
    setSortBy(metric?.key as string)

    return metric
  }, [selectedAttributeDropdown])

  const { talentList: prepareTalentsList, onSerializeTalent } =
    useSerializeTalentData()

  const handleSortChange = (sortParams: SortQueryParams) => {
    console.log('%c sortParams:', 'color: #7dd3fc', sortParams) //eslint-disable-line
    setSortOrder(sortParams.sortOrder)
    setSortBy(sortParams.sortBy)
  }

  const {
    data: talentsChartData,
    isError: isChartError,
    refetch: refetchTalentsChartData,
  } = useGetTalentsChartDataQuery(
    {
      talentIds,
      metricsType: contentTypeView.toUpperCase(),
      attribute: labelAttributeType?.key ? labelAttributeType.key : 'AWARENESS',
      duration: 'ALL',
      top2Box: top2Box === 'topTwoBox' ? true : false,
      audienceFilter,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: talentIds?.length === 0,
    },
  )

  const { onPrepareTalentsDataChart, compareDetailsList } = useChartData()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const ids = searchParams.getAll('talentId')
    setTalentIds(ids)
    console.log('selectedFiltersAudience', audienceFilter)
    void getTalentsByIds(ids, top2Box, audienceFilter)
  }, [location.search, top2Box, audienceFilter])

  useEffect(() => {
    if (talentIds.length > 0) {
      const params = {
        sortOrder,
        sortBy,
        contentTypeView,
      }
      talentList && onSerializeTalent(talentList, contentTypeView, params)
    }
  }, [
    talentIds,
    contentTypeView,
    talentList,
    sortOrder,
    sortBy,
    contentTypeView,
  ])

  useEffect(() => {
    if (talentList.length > 0) {
      onPrepareTalentsDataChart({
        data: talentList,
        selectedAttributeDropdown,
        contentType: contentTypeView,
        isUniqueLabels: false,
      })
    }
  }, [talentList, selectedAttributeDropdown, contentTypeView, selectedRange])

  const chartHeight = 443

  const handleRemoveTalentFromCompare = (id: string) => {
    const params = new URLSearchParams(location.search)
    const talentsIdsQueryParams = params.getAll('talentId')
    const deleteSelectedId = talentsIdsQueryParams.filter(
      talentId => talentId !== id,
    )
    const queryParameters = buildParams({ talentId: deleteSelectedId })

    navigate(`${Urls.TALENT}${Urls.COMPARE}?${queryParameters}`)
  }

  const onUpdateChartData = () => {
    onPrepareTalentsDataChart({
      data: talentList,
      selectedAttributeDropdown,
      contentType: contentTypeView,
      isUniqueLabels: false,
    })
  }

  const handleAudienceFilterChange = (filters: string) => {
    setAudienceFilter(filters)
  }

  const talentColors = useMemo(() => {
    const { talents } = talentsChartData ?? {}
    if (!talents?.length) {
      return {}
    }

    return talentIds.reduce((colorsMapping, id, index) => {
      const talent = talents.find(talent => talent.talent_id.toString() === id)
      if (talent) {
        colorsMapping[talent.talent_id] = TalentColorPalette[index]
      }
      return colorsMapping
    }, {} as TalentColors)
  }, [talentsChartData])

  return (
    <LayoutContentWrapper wrapperClassName='flex'>
      <div className='flex h-full flex-col'>
        <div className='mb-[16px] max-w-full'>
          <CompareWindowHeader
            talents={prepareTalentsList}
            onRemoveTalentFromCompare={handleRemoveTalentFromCompare}
            selectedIds={talentIds}
            selectedRows={selectedRows}
          />

          <div className='px-5'>
            <TalentCompareGraphControls
              handleSelectChange={setSelectedAttributeDropdown}
              selectedAttribute={selectedAttributeDropdown}
              updateChartData={onUpdateChartData}
              onChangeContentTypeView={(typeView: string) =>
                setContentTypeView(typeView as AttributeType)
              }
              onChangeTopBox={top2Box => {
                setTop2Box(top2Box)
              }}
              onToggleDateRange={(rangeKey, customRange) => {
                {
                  setDataDateRange(rangeKey)
                  setSelectedRange(customRange)
                }
              }}
              onAudienceFilterChange={handleAudienceFilterChange}
            />
            <div className='relative'>
              {talentsChartData && (
                <TalentMetricsChart
                  chartHeight={chartHeight}
                  data={isChartError ? {} : talentsChartData}
                  isPercentage={
                    labelAttributeType?.isSocial
                      ? labelAttributeType.isPercentage
                      : contentTypeView === 'percentage'
                  }
                  isRank={
                    labelAttributeType?.isSocial
                      ? false
                      : contentTypeView === 'rank'
                  }
                  talentDateRange={dataDateRange}
                  selectedDateRange={selectedRange}
                  talentColors={talentColors}
                />
              )}
              {talentsChartData &&
                (!showCompareTalentList ? (
                  <IconButton
                    className={twMerge(
                      'absolute bottom-12 left-4 h-6 w-6 rounded bg-primary-grey hover:cursor-pointer',
                      // talentIds.length > 1 && 'bottom-[90px]',     // TODO: check this behavior
                    )}
                    onClick={onShowCompareTalentList}
                    disabled={compareDetailsList.length === 1}
                  >
                    <Talent
                      fill={Colors.ICON_WHITE}
                      width={20}
                      height={20}
                      className='mx-auto'
                    />
                  </IconButton>
                ) : (
                  <>
                    <CompareTalentListMenu
                      talents={compareDetailsList}
                      talentColors={talentColors}
                      onClose={onHideCompareTalentList}
                      isShowDeleteTaletnBtn={true}
                      handleDelete={talendId => {
                        handleRemoveTalentFromCompare(talendId)
                        onHideCompareTalentList()
                      }}
                      wrapperClassName='absolute bottom-12 left-4'
                    />
                  </>
                ))}
            </div>
          </div>
        </div>
        <BaseTable
          columns={columnsTalent}
          data={prepareTalentsList}
          socialSearchFilters={socialSearchFilters}
          hasCompareButtons={false}
          hasFooter={false}
          contentTypeView={contentTypeView}
          error=''
          handleSortChange={handleSortChange}
          sortBy={sortBy}
          sortOrder={sortOrder}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </LayoutContentWrapper>
  )
}
