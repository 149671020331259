import { BaseQueryFn, EndpointBuilder } from '@reduxjs/toolkit/query/react'
import { v4 as uuidv4 } from 'uuid'

import {
  type TalentManagementData,
  type TalentManagementSortColKey,
} from 'modules/management'

import { Race, Gender } from 'modules/management/data/types'

type PaginatedRequest = {
  pageSize: number
  pageNumber: number
  searchTerm: string | undefined
  sortBy: TalentManagementSortColKey
  sortOrder: 'ASC' | 'DESC'
}

type PaginatedResponse = {
  totalRecords: number
  totalPages: number
  currentPage: number
  pageSize: number
  data: TalentManagementData[]
}
type TalentManagementCreateRequest = {
  imdbId: string
  fullName: string
  dob: string
  race: Race
  gender: Gender
  height: number
  bio: string
  similarTalent1: string
  similarTalent2: string
  similarTalent3: string
  similarTalent4: string

  wikipedia?: string
  instagramHandle?: string
  instagramUrl?: string
  xHandle?: string
  xUrl?: string
  hashtags?: string
  isActive?: boolean
}

function simulatePaginatedApi(
  mockData: TalentManagementData[],
  delay: number,
  request: PaginatedRequest,
): Promise<PaginatedResponse> {
  const { pageSize, pageNumber, sortOrder, sortBy, searchTerm } = request

  return new Promise(resolve => {
    setTimeout(() => {
      // Filter data by `Title`
      const filteredData = mockData.filter(item => {
        const matchesSearchTerm = searchTerm
          ? item.fullName.toLowerCase().includes(searchTerm.toLowerCase())
          : true

        return matchesSearchTerm
      })

      const totalRecords = filteredData.length

      const sortedData = [...filteredData].sort((a, b) => {
        if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
          const comparison = (a[sortBy] as string).localeCompare(
            b[sortBy] as string,
          )
          return sortOrder === 'ASC' ? comparison : -comparison
        }
        if (typeof a[sortBy] === 'number' && typeof b[sortBy] === 'number') {
          const comparison = (a[sortBy] as number) - (b[sortBy] as number)
          return sortOrder === 'ASC' ? comparison : -comparison
        }
        return 0
      })

      const totalPages = Math.ceil(totalRecords / pageSize)
      const startIndex = (pageNumber - 1) * pageSize
      const endIndex = Math.min(startIndex + pageSize, totalRecords)
      const paginatedData = sortedData.slice(startIndex, endIndex)

      const response: PaginatedResponse = {
        totalRecords,
        totalPages,
        currentPage: pageNumber,
        pageSize,
        data: paginatedData,
      }

      resolve(response)
    }, delay)
  })
}

export function defineGetTalentManagementDataEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TalentManagement', 'mockApi'>,
) {
  return builder.query<PaginatedResponse, PaginatedRequest>({
    queryFn: async request => {
      try {
        const response = await simulatePaginatedApi(
          talentManagementMockData,
          2000,
          request,
        )
        return { data: response }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error fetching paginated data' },
        }
      }
    },
    providesTags: [{ type: 'TalentManagement', id: 'LIST' }],
  })
}
export function defineGetTalentByIdManagementDataEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TalentManagement', 'mockApi'>,
) {
  return builder.query<TalentManagementData | undefined, { id: string }>({
    queryFn: async request => {
      try {
        const lookup = talentManagementMockData.find(
          item => item.id === request.id,
        )

        return { data: lookup }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error fetching paginated data' },
        }
      }
    },
    providesTags: [{ type: 'TalentManagement', id: 'LIST' }],
  })
}

export function defineCreateTalentManagementEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TalentManagement', 'mockApi'>,
) {
  return builder.mutation<
    TalentManagementCreateRequest & { id: string },
    TalentManagementCreateRequest
  >({
    queryFn: async newData => {
      try {
        const newEntry = { ...newData, id: uuidv4(), isActive: true }
        talentManagementMockData.push(newEntry)
        return { data: newEntry }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error creating new company' },
        }
      }
    },
    invalidatesTags: [{ type: 'TalentManagement', id: 'LIST' }],
  })
}

export function defineUpdateTalentManagementEndpoint(
  builder: EndpointBuilder<BaseQueryFn, 'TalentManagement', 'mockApi'>,
) {
  return builder.mutation<
    TalentManagementCreateRequest & { id: string },
    { talentId: string; updates: Partial<TalentManagementCreateRequest> }
  >({
    queryFn: async ({ talentId, updates }) => {
      try {
        const index = talentManagementMockData.findIndex(
          item => item.id === talentId,
        )

        if (index === -1) {
          return {
            error: {
              status: 404,
              data: `Event with id ${talentId} not found`,
            },
          }
        }

        talentManagementMockData[index] = {
          ...talentManagementMockData[index],
          ...updates,
        }

        return { data: talentManagementMockData[index] }
      } catch (error) {
        return {
          error: { status: 500, data: 'Error updating the company' },
        }
      }
    },
    invalidatesTags: [{ type: 'TalentManagement', id: 'LIST' }],
  })
}

export const talentManagementMockData: TalentManagementData[] = [
  {
    id: '1',
    imdbId: 'nm0000001',
    fullName: 'John Doe',
    dob: '1990-01-01',
    race: Race.Asian,
    gender: Gender.Male,
    height: 70.1,
    similarTalent1: 'Jane Doe',
    similarTalent2: 'Mark Smith',
    similarTalent3: 'Anna Johnson',
    similarTalent4: 'Tom Brown',
    bio: 'An accomplished actor known for diverse roles.',
    wikipedia: 'https://wikipedia.org/JohnDoe',
    instagramHandle: '@johndoe',
    instagramUrl: 'https://instagram.com/johndoe',
    xHandle: '@johndoe',
    xUrl: 'https://x.com/johndoe',
    hashtags: '#actor #talent',
    isActive: false,
  },
  {
    id: '2',
    imdbId: 'nm0000002',
    fullName: 'Jane Smith',
    dob: '1985-05-12',
    race: Race.Asian,
    gender: Gender.Female,
    height: 71.2,
    similarTalent1: 'Lisa Wong',
    similarTalent2: 'Emily Tan',
    similarTalent3: 'Sophia Kim',
    similarTalent4: 'Nina Park',
    bio: 'A versatile actress with an impressive career.',
    wikipedia: 'https://wikipedia.org/JaneSmith',
    instagramHandle: '@janesmith',
    instagramUrl: 'https://instagram.com/janesmith',
    xHandle: '@janesmith',
    xUrl: 'https://x.com/janesmith',
    hashtags: '#actress #star',
    isActive: true,
  },
  {
    id: '3',
    imdbId: 'nm0000003',
    fullName: 'Mark Johnson',
    dob: '1988-03-22',
    race: Race.Black,
    gender: Gender.Male,
    height: 72,
    similarTalent1: 'Chris Brown',
    similarTalent2: 'Michael Lee',
    similarTalent3: 'Aaron Davis',
    similarTalent4: 'Paul Green',
    bio: 'Known for his captivating performances in drama.',
    wikipedia: 'https://wikipedia.org/MarkJohnson',
    instagramHandle: '@markjohnson',
    instagramUrl: 'https://instagram.com/markjohnson',
    xHandle: '@markjohnson',
    xUrl: 'https://x.com/markjohnson',
    hashtags: '#drama #talent',
    isActive: false,
  },
  {
    id: '4',
    imdbId: 'nm0000004',
    fullName: 'Emily Davis',
    dob: '1992-07-19',
    race: Race.Hispanic,
    gender: Gender.Female,
    height: 73,
    similarTalent1: 'Sofia Lopez',
    similarTalent2: 'Maria Sanchez',
    similarTalent3: 'Isabella Gomez',
    similarTalent4: 'Carmen Diaz',
    bio: 'A rising star in the world of cinema.',
    wikipedia: 'https://wikipedia.org/EmilyDavis',
    instagramHandle: '@emilydavis',
    instagramUrl: 'https://instagram.com/emilydavis',
    xHandle: '@emilydavis',
    xUrl: 'https://x.com/emilydavis',
    hashtags: '#cinema #talent',
    isActive: true,
  },
  {
    id: '5',
    imdbId: 'nm0000005',
    fullName: 'Chris Brown',
    dob: '1995-02-11',
    race: Race.MiddleEastern,
    gender: Gender.Male,
    height: 74,
    similarTalent1: 'Ali Khan',
    similarTalent2: 'Omar Sheikh',
    similarTalent3: 'Hassan Ahmed',
    similarTalent4: 'Zaid Malik',
    bio: 'An actor with a strong presence on screen.',
    wikipedia: 'https://wikipedia.org/ChrisBrown',
    instagramHandle: '@chrisbrown',
    instagramUrl: 'https://instagram.com/chrisbrown',
    xHandle: '@chrisbrown',
    xUrl: 'https://x.com/chrisbrown',
    hashtags: '#screenpresence #actor',
    isActive: false,
  },
  {
    id: '6',
    imdbId: 'nm0000006',
    fullName: 'Sophia Kim',
    dob: '1993-11-15',
    race: Race.Asian,
    gender: Gender.Female,
    height: 75,
    similarTalent1: 'Emily Tan',
    similarTalent2: 'Lisa Wong',
    similarTalent3: 'Nina Park',
    similarTalent4: 'Anna Lee',
    bio: 'A skilled actress with a passion for storytelling.',
    wikipedia: 'https://wikipedia.org/SophiaKim',
    instagramHandle: '@sophiakim',
    instagramUrl: 'https://instagram.com/sophiakim',
    xHandle: '@sophiakim',
    xUrl: 'https://x.com/sophiakim',
    hashtags: '#storyteller #actress',
    isActive: true,
  },
  {
    id: '7',
    imdbId: 'nm0000007',
    fullName: 'Michael Lee',
    dob: '1987-09-05',
    race: Race.Black,
    gender: Gender.NonBinary,
    height: 76,
    similarTalent1: 'Chris Brown',
    similarTalent2: 'Aaron Davis',
    similarTalent3: 'Paul Green',
    similarTalent4: 'Tom White',
    bio: 'An actor renowned for his charismatic roles.',
    wikipedia: 'https://wikipedia.org/MichaelLee',
    instagramHandle: '@michaellee',
    instagramUrl: 'https://instagram.com/michaellee',
    xHandle: '@michaellee',
    xUrl: 'https://x.com/michaellee',
    hashtags: '#charisma #talent',
    isActive: false,
  },
  {
    id: '8',
    imdbId: 'nm0000008',
    fullName: 'Anna Johnson',
    dob: '1991-03-12',
    race: Race.White,
    gender: Gender.Female,
    height: 77,
    similarTalent1: 'Jane Doe',
    similarTalent2: 'Emily Davis',
    similarTalent3: 'Sophia Kim',
    similarTalent4: 'Lisa Wong',
    bio: 'A dynamic performer with a love for the arts.',
    wikipedia: 'https://wikipedia.org/AnnaJohnson',
    instagramHandle: '@annajohnson',
    instagramUrl: 'https://instagram.com/annajohnson',
    xHandle: '@annajohnson',
    xUrl: 'https://x.com/annajohnson',
    hashtags: '#dynamic #arts',
    isActive: true,
  },
  {
    id: '9',
    imdbId: 'nm0000009',
    fullName: 'Aaron Davis',
    dob: '1989-06-30',
    race: Race.Black,
    gender: Gender.Male,
    height: 78,
    similarTalent1: 'Mark Johnson',
    similarTalent2: 'Michael Lee',
    similarTalent3: 'Paul Green',
    similarTalent4: 'Tom Brown',
    bio: 'A powerhouse actor with numerous accolades.',
    wikipedia: 'https://wikipedia.org/AaronDavis',
    instagramHandle: '@aarondavis',
    instagramUrl: 'https://instagram.com/aarondavis',
    xHandle: '@aarondavis',
    xUrl: 'https://x.com/aarondavis',
    hashtags: '#powerhouse #actor',
    isActive: false,
  },
  {
    id: '10',
    imdbId: 'nm0000010',
    fullName: 'Nina Park',
    dob: '1994-08-25',
    race: Race.Asian,
    gender: Gender.Female,
    height: 79,
    similarTalent1: 'Sophia Kim',
    similarTalent2: 'Emily Tan',
    similarTalent3: 'Lisa Wong',
    similarTalent4: 'Anna Lee',
    bio: 'A fresh face in the industry with great potential.',
    wikipedia: 'https://wikipedia.org/NinaPark',
    instagramHandle: '@ninapark',
    instagramUrl: 'https://instagram.com/ninapark',
    xHandle: '@ninapark',
    xUrl: 'https://x.com/ninapark',
    hashtags: '#freshface #talent',
    isActive: true,
  },
  {
    id: '11',
    imdbId: 'nm0000011',
    fullName: 'Tom White',
    dob: '1986-12-20',
    race: Race.NativeAmerican,
    gender: Gender.Male,
    height: 80,
    similarTalent1: 'Chris Brown',
    similarTalent2: 'Mark Johnson',
    similarTalent3: 'Aaron Davis',
    similarTalent4: 'Michael Lee',
    bio: 'A seasoned actor with a commanding presence.',
    wikipedia: 'https://wikipedia.org/TomWhite',
    instagramHandle: '@tomwhite',
    instagramUrl: 'https://instagram.com/tomwhite',
    xHandle: '@tomwhite',
    xUrl: 'https://x.com/tomwhite',
    hashtags: '#seasonedactor #presence',
    isActive: false,
  },
  {
    id: '12',
    imdbId: 'nm0000012',
    fullName: 'Lisa Wong',
    dob: '1990-10-10',
    race: Race.Asian,
    gender: Gender.Female,
    height: 81,
    similarTalent1: 'Sophia Kim',
    similarTalent2: 'Emily Tan',
    similarTalent3: 'Anna Lee',
    similarTalent4: 'Nina Park',
    bio: 'A beloved actress with a flair for comedy.',
    wikipedia: 'https://wikipedia.org/LisaWong',
    instagramHandle: '@lisawong',
    instagramUrl: 'https://instagram.com/lisawong',
    xHandle: '@lisawong',
    xUrl: 'https://x.com/lisawong',
    hashtags: '#comedy #actress',
    isActive: true,
  },
  {
    id: '13',
    imdbId: 'nm0000013',
    fullName: 'Emily Tan',
    dob: '1992-04-14',
    race: Race.MiddleEastern,
    gender: Gender.Female,
    height: 82,
    similarTalent1: 'Sophia Kim',
    similarTalent2: 'Lisa Wong',
    similarTalent3: 'Nina Park',
    similarTalent4: 'Anna Lee',
    bio: 'A talented performer with a passion for drama.',
    wikipedia: 'https://wikipedia.org/EmilyTan',
    instagramHandle: '@emilytan',
    instagramUrl: 'https://instagram.com/emilytan',
    xHandle: '@emilytan',
    xUrl: 'https://x.com/emilytan',
    hashtags: '#drama #actress',
    isActive: false,
  },
  {
    id: '14',
    imdbId: 'nm0000014',
    fullName: 'Paul Green',
    dob: '1991-02-28',
    race: Race.Black,
    gender: Gender.Male,
    height: 83,
    similarTalent1: 'Mark Johnson',
    similarTalent2: 'Aaron Davis',
    similarTalent3: 'Michael Lee',
    similarTalent4: 'Tom White',
    bio: 'A rising star known for his action-packed roles.',
    wikipedia: 'https://wikipedia.org/PaulGreen',
    instagramHandle: '@paulgreen',
    instagramUrl: 'https://instagram.com/paulgreen',
    xHandle: '@paulgreen',
    xUrl: 'https://x.com/paulgreen',
    hashtags: '#action #actor',
    isActive: true,
  },
  {
    id: '15',
    imdbId: 'nm0000015',
    fullName: 'Anna Lee',
    dob: '1995-07-08',
    race: Race.Asian,
    gender: Gender.Female,
    height: 84,
    similarTalent1: 'Sophia Kim',
    similarTalent2: 'Emily Tan',
    similarTalent3: 'Lisa Wong',
    similarTalent4: 'Nina Park',
    bio: 'An up-and-coming actress with a bright future.',
    wikipedia: 'https://wikipedia.org/AnnaLee',
    instagramHandle: '@annalee',
    instagramUrl: 'https://instagram.com/annalee',
    xHandle: '@annalee',
    xUrl: 'https://x.com/annalee',
    hashtags: '#brightfuture #talent',
    isActive: false,
  },
]
