export function getUserAccountStatus(
  status: 'active' | 'paused',
  lastLoginDate: string | null,
  lastLogoutDate: string | null,
) {
  const isActive = status === 'active'
  const isPending = !lastLoginDate && !lastLogoutDate

  if (isPending) {
    return 'Pending'
  }

  return isActive ? 'Active' : 'Inactive'
}
