import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { talentManagementActions } from '../slices'

export const useTalentManagementActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(talentManagementActions, dispatch),
    [dispatch],
  )
}
