import { useEffect } from 'react'

interface UseClickOutsideParams<RefElement extends HTMLElement> {
  ref: React.RefObject<RefElement>
  hideOnScroll?: boolean
  onClose: () => void
}

export const useClickOutside = <RefElement extends HTMLElement>({
  ref,
  hideOnScroll,
  onClose,
}: UseClickOutsideParams<RefElement>) => {
  useEffect(() => {
    const handleClickOutside = ref
      ? (event: DocumentEventMap['click']) => {
          if (
            ref?.current &&
            !ref.current.contains(event?.target as Node) &&
            ref?.current !== event?.target
          ) {
            onClose()
          }
        }
      : null

    const handleScroll =
      ref && hideOnScroll
        ? () => {
            if (hideOnScroll) {
              onClose()
            }
          }
        : null

    if (handleClickOutside) {
      document.addEventListener('click', handleClickOutside, true)
    }

    if (handleScroll) {
      document.addEventListener('wheel', handleScroll, {
        passive: true,
        capture: true,
      })
    }

    return () => {
      if (handleClickOutside) {
        document.removeEventListener('click', handleClickOutside, true)
      }

      if (handleScroll) {
        document.removeEventListener('wheel', handleScroll, true)
      }
    }
  }, [hideOnScroll])
}
