import { BaseModal, Help } from 'components'
import { Colors } from 'constnants'
import { useToggle } from 'hooks'
import { memo } from 'react'

export const CompFinderGuideTrigger = memo(() => {
  const [guideState, guideStateControls] = useToggle()
  return (
    <>
      <button
        className='flex gap-1 text-xs font-semibold text-primary-grey'
        onClick={guideStateControls.activate}
      >
        <Help fill={Colors.BASE_ICON} width={16} height={16} />
        Comp Finder Guide
      </button>
      <BaseModal
        isOpen={guideState.active}
        handleClose={guideStateControls.deactivate}
        title='Comp Finder Guide'
        titleClass='text-4xl font-extrabold text-gray-900'
        maxWidth='562px'
        wrapperClass='p-16pt-12 pr-14 pb-10 pl-12'
      >
        <div className='mx-auto'>
          <div className='mt-6'>
            <p className='mb-6 text-sm text-gray-800'>
              The <span className='font-extrabold'>Comp Finder</span> is a tool
              designed to analyze comparable films for market research,
              strategic planning, and project alignment. It allows users to
              filter and review films based on specific attributes, track
              performance data, and organize findings for targeted insights.
            </p>

            <h2 className='mb-4 text-2xl font-bold text-gray-800'>Use Cases</h2>
            <ol className='list-outside list-decimal pl-5 text-sm'>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Comparable Analysis:{' '}
                </span>
                Identify films similar to a current project based on genre,
                budget, and performance metrics to inform strategy.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Market Research:{' '}
                </span>
                Evaluate the historical performance of similar films to predict
                trends and set benchmarks.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Project Alignment:{' '}
                </span>
                Organize comparable films into project-specific lists for
                focused analysis and presentations.
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Strategic Insights:{' '}
                </span>
                Filter results to assess competition, track trends, and refine
                box office expectations.
              </li>
            </ol>
          </div>

          <div className='mt-6'>
            <h2 className='mb-4 text-2xl font-bold text-gray-800'>
              Key Features
            </h2>
            <h3 className='mb-2 text-xl font-bold text-gray-800'>Filters</h3>
            <ul className='list-outside list-disc pl-5 text-sm'>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Comprehensive Search Options{' '}
                </span>
                - Narrow down results by:
                <ul
                  className='list-outside pl-5 text-sm'
                  style={{ listStyleType: 'circle' }}
                >
                  <li>
                    Distributor, release month/year, hybrid status, and budget.
                  </li>
                  <li>
                    Rating, franchise, universe, primary/secondary genres, and
                    group.
                  </li>
                  <li>
                    Metrics like opening weekend (OW) theaters, box office
                    revenue, and total gross.
                  </li>
                </ul>
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Platform Movie Toggle:
                </span>{' '}
                Option to include or exclude platform-specific movies for
                tailored analysis.
              </li>
            </ul>

            <h3 className='mb-2 mt-6 text-xl font-bold text-gray-800'>
              Project Management
            </h3>
            <ul className='list-outside list-disc pl-5 text-sm'>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Project Comp Lists:
                </span>{' '}
                Save films to specific project lists for organized tracking
                (e.g., “Garfield 2,” “Horror Films”).
              </li>
              <li>
                <span className='font-extrabold text-gray-900'>
                  Saved Searches:
                </span>{' '}
                Store and retrieve customized search presets for efficiency and
                repeatability.
              </li>
            </ul>
          </div>
        </div>
      </BaseModal>
    </>
  )
})

CompFinderGuideTrigger.displayName = 'CompFinderGuideTrigger'
