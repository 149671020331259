import { memo } from 'react'
import { MgmtTableSpinner } from './MgmtTableSpinner'

export const MgmtTableFetching = memo(() => (
  <div className='mt-3 flex flex-1 flex-col items-center justify-center'>
    <MgmtTableSpinner />
  </div>
))

MgmtTableFetching.displayName = 'MgmtTableFetching'
