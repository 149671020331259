import { twMerge } from 'tailwind-merge'
import slugify from 'slugify'
import { useNavigate, useParams } from 'react-router-dom'
import { Key, useEffect, useState, useRef, useMemo } from 'react'
import { buildParams, commonToastStyles, getTalentSelectedSearch } from 'utils'
import { Trash } from 'components/ui/icons'

import {
  useDeleteSavedSearchPresetMutation,
  useDeleteSelectedTelentMutation,
  useGetAllTalentsSerchesQuery,
  useGetTelentsListsQuery,
  useGetProjectCompanyDetailsByProjectIdQuery,
  useGetProjectsQuery,
} from 'store/api'

import { mapObjectTalentsLists } from 'utils'

import {
  DeleteModal,
  IconButton,
  MyListMenu,
  NewPersonalTalentModal,
  EditTalentsListModal,
  SaveSearchResultsTalentModal,
  EditSearchResultsTalentModal,
  ProjectRoleMenu,
  TalentRightItem,
  TalentRightItemCard,
} from 'components'
import {
  ChevronLeft,
  ChevronRight,
  Folder,
  Lists,
  Searches,
} from 'components/ui/icons'
import {
  useIsMobile,
  useKeyPress,
  useScrollLock,
  useSearchFilter,
  useTalentsList,
} from 'hooks'
import { Colors } from 'constnants'
import {
  OptionType,
  TalentSaveSearchResponce,
  Urls,
  Roles,
  TalentSaveSearchQueryParams,
  TalentSelectedSearch,
} from 'types'
import { toast } from 'react-toastify'
import { useAuthContext } from '../../context'

const initialRole = {
  id: '',
  value: '',
  label: '',
  roleList: { searchPresets: [], talentList: [] },
}

interface TalentRightSidebarProps {
  isOpen?: boolean
  handleOpen: () => void
  handleClose: () => void
  selectedSavedSearch: TalentSelectedSearch | undefined
  isSavedSearchChanged?: boolean
  setIsSavedSearchChanged?: (isChanged: boolean) => void
  clearTalentFilters?: () => void
  onSelectSavedSearch: (search: TalentSelectedSearch) => void
  onSelectNewSavedSearch: (
    newSearch: TalentSaveSearchResponce | undefined,
  ) => void
  onResetSavedSearch: () => void
}

const getTalentSelectedSearchFromJSON = (
  search: OptionType | undefined,
  selectedProject?: string,
  selectedRole?: string,
) => {
  const searchParameters = JSON.parse(
    search?.search_parameters ?? '{}',
  ) as TalentSaveSearchQueryParams

  return getTalentSelectedSearch({
    searchId: search?.id,
    searchName: search?.value,
    selectedRole,
    selectedProject,
    searchParameters,
  })
}

export const TalentRightSidebar = ({
  isOpen,
  handleOpen,
  handleClose,
  selectedSavedSearch,
  isSavedSearchChanged,
  setIsSavedSearchChanged,
  clearTalentFilters,
  onSelectSavedSearch,
  onSelectNewSavedSearch,
  onResetSavedSearch,
}: TalentRightSidebarProps) => {
  const navigate = useNavigate()
  const userAuthContext = useAuthContext()
  const companyId =
    userAuthContext?.auth?.userAttributes?.['custom:company_id'] || ''
  const { listId } = useParams()
  const { lockScroll, unlockScroll } = useScrollLock()
  const isMobile = useIsMobile()
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [isOpenRoleTalentListEditModal, setIsOpenRoleTalentListEditModal] =
    useState<boolean>(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [isOpenDeletePrivateSearchModal, setOpenDeletePrivateSearchModal] =
    useState<boolean>(false)
  const [isOpenRoleDeleteSearchModal, setIsOpenRoleDeleteSearchModal] =
    useState<boolean>(false)
  useState<boolean>(false)
  const [isOpenRoleDeleteTalentListModal, setIsOpenRoleDeleteTalentListModal] =
    useState<boolean>(false)
  const [isOpenEditSearchModal, setIsOpenEditSearchModal] =
    useState<boolean>(false)
  const selectedPresetSearch = useRef<{ id: string; value?: string }>({
    id: '',
    value: '',
  })
  const selectedTelent = useRef<{ id: string; value?: string }>({
    id: '',
    value: '',
  })
  const [selectedRoleList, setSelectedRoleList] = useState<Roles>(initialRole)
  const [selectProject, setSelectProject] = useState<OptionType>({
    id: '',
    value: '',
    label: '',
  })

  const [isOpenSaveSearchModal, setIsOpenSaveSearchModal] =
    useState<boolean>(false)
  const [isOpenSaveAsSearchModal, setIsOpenSaveAsSearchModal] =
    useState<boolean>(false)
  const [isOpenRoleSaveSearchModal, setIsRoleOpenSaveSearchModal] =
    useState<boolean>(false)
  const [isOpenCreateListModal, setIsOpenCreateListModal] =
    useState<boolean>(false)
  const [isOpenCreateRoleListModal, setIsOpenCreateRoleListModal] =
    useState<boolean>(false)
  const [initialSelectTab, setInitialSelectTab] = useState<Key | null>('first')

  const { data: dataForPresetSearch } = useGetAllTalentsSerchesQuery({
    projectId: undefined,
    roleId: undefined,
  })
  const { data: dataForRolePresetSearch } = useGetAllTalentsSerchesQuery({
    projectId: selectProject.id ?? null,
    roleId: selectedRoleList.id ?? null,
  })
  const { data: getDataForTelentsLists, isError: isErrorTelentsLists } =
    useGetTelentsListsQuery({ projectId: null, roleId: null })
  const { data: getDataForRoleTalentsLists } = useGetTelentsListsQuery({
    projectId: selectProject.id ?? null,
    roleId: selectedRoleList.id ?? null,
  })

  const dataForTelentsLists = isErrorTelentsLists ? [] : getDataForTelentsLists
  const urlParams = new URLSearchParams(window.location.search)
  const queryProjectId = urlParams.get('selectedProject')
  const [selectedRole, setSelectedRole] = useState<string>(
    urlParams.get('selectedRole') ?? '',
  )
  const projectId = Number(selectProject.id)
  const { data: myProjectsData } = useGetProjectsQuery(
    {
      pageSize: 1000,
      pageNumber: 1,
    },
    { skip: !companyId },
  )

  const { data: projectsData } = useGetProjectCompanyDetailsByProjectIdQuery(
    {
      id: projectId,
    },
    { skip: projectId === 0 },
  )

  useEffect(() => {
    queryProjectId &&
      setSelectProject({
        id: queryProjectId,
        value: '',
        label: '',
      })
  }, [])

  useEffect(() => {
    if (projectsData && projectsData.length > 0) {
      setSelectProject({
        id: projectsData[0].company_project_id?.toString() ?? '',
        value: projectsData[0].name ?? '',
        label: projectsData[0].name ?? '',
      })
    }
  }, [projectsData])

  const handleSelectProject = (project: OptionType) => {
    setSelectProject(project)
  }

  const projects: OptionType[] =
    myProjectsData?.projects.map(project => ({
      id: project.id?.toString() ?? '',
      value: project.name,
      label: project.name,
    })) || []

  const handleSelectTab = (tab: Key | null) => {
    setInitialSelectTab(tab)
  }
  const [deleteSavedSearchPreset] = useDeleteSavedSearchPresetMutation()
  const [deleteSelectedTelent] = useDeleteSelectedTelentMutation()

  const handleOpenCurrentTab = (tab: Key | null) => {
    handleSelectTab(tab)
    handleOpen()
  }

  const mappedPresetSearch: OptionType[] = useMemo(() => {
    return (
      dataForPresetSearch
        ?.filter(search => !search.project_id && !search.project_id)
        .map(mapObjectPresetSearch) ?? []
    )
  }, [dataForPresetSearch])

  const mappedRolePresetSearch: OptionType[] = useMemo(() => {
    return dataForRolePresetSearch?.map(mapObjectPresetSearch) ?? []
  }, [dataForRolePresetSearch])

  const mappedTalentsLists: OptionType[] = useMemo(() => {
    return (
      dataForTelentsLists
        ?.filter(item => {
          if (!item.project_id && !item.role_id) {
            return item
          }
        })
        .map(mapObjectTalentsLists) ?? []
    )
  }, [dataForTelentsLists])

  const mappedRoleTalentsLists: OptionType[] = useMemo(() => {
    return (
      getDataForRoleTalentsLists
        ?.filter(item => {
          if (item.project_id && item.role_id) {
            return item
          }
        })
        .map(mapObjectTalentsLists) ?? []
    )
  }, [getDataForRoleTalentsLists])

  const { setSearchTerm: setSearchAllTerm } = useSearchFilter()

  const { setSearchTerm: setAllSearchTerm } = useSearchFilter()

  const {
    searchTerm: searchTermPresetSearch,
    filteredOptions: filteredPresetSearch,
    handleSaveSearchPreset,
    handleEditSearchPreset,
    handleSearchChange: handleSearchChangePresetSearch,
  } = useSearchFilter(mappedPresetSearch)

  const handleClickItemPresetSearchList = (search?: OptionType) => {
    onSelectSavedSearch(
      getTalentSelectedSearchFromJSON(search, selectProject.id, selectedRole),
    )
  }

  const handleClickItemPresetMySearchList = (search?: OptionType) => {
    onSelectSavedSearch(getTalentSelectedSearchFromJSON(search))
  }

  const onSelectTalentsList = (talentList: OptionType) => {
    const slugifiedValue = slugify(talentList.value, { lower: true })
    const urlParams = new URLSearchParams()
    urlParams.set('selectedProject', selectProject.id)
    if (selectedRole) {
      urlParams.set('selectedRole', selectedRole)
    }
    const paramsArray = Array.from(urlParams.entries())
    const paramsObject = Object.fromEntries(paramsArray)
    const builtParams = buildParams(paramsObject)
    const url = `/talent/listDetails/${slugifiedValue}/${talentList.id}?${builtParams}`
    navigate(url)
  }

  const {
    searchTerm: searchTermMyLists,
    handleSaveTelentList,
    handleEditTalentsList,
    filteredOptions: filteredTalentsLists,
    handleSearchChange: handleSearchChangeTalentsLists,
  } = useTalentsList(mappedTalentsLists, onSelectTalentsList)

  const { handleEditTalentsList: handleRoleEditTalentsList } = useTalentsList(
    mappedRoleTalentsLists,
    onSelectTalentsList,
  )

  function mapObjectPresetSearch(
    original: TalentSaveSearchResponce,
  ): OptionType {
    return {
      id: original.search_id,
      label: original.name,
      value: original.name,
      search_parameters: JSON.stringify(original.search_parameters),
    }
  }

  const preparedMyRoles = useMemo(() => {
    return (
      projectsData?.flatMap(
        project =>
          project.project_roles?.map(role => ({
            id: role.id,
            value: role.name,
            label: role.name,
            roleList: {
              searchPresets: mappedPresetSearch ?? [],
              talentList: filteredTalentsLists ?? [],
            },
          })) || [],
      ) || []
    )
  }, [projectsData, mappedPresetSearch, filteredTalentsLists])

  const resetSearchTerm = () => {
    setSearchAllTerm('')
    setAllSearchTerm('')
  }

  const handleEditList = (id: string, value: string) => {
    selectedTelent.current = { id, value }
    setIsOpenEditModal(true)
  }

  const handleRoleTalentEditList = (id: string, value: string) => {
    selectedTelent.current = { id, value }
    setIsOpenRoleTalentListEditModal(true)
  }

  const handleDeleteList = (id: string, value: string) => {
    setIsOpenDeleteModal(true)
    selectedTelent.current = { id, value }
  }

  const handleRoleDeleteList = (id: string, value: string) => {
    selectedTelent.current = { id, value }
    setIsOpenRoleDeleteTalentListModal(true)
  }

  const handleDeletePresetSearchList = (id: string, value: string) => {
    selectedPresetSearch.current = { id, value }
    setIsOpenRoleDeleteSearchModal(true)
  }

  const handleDeletePrivateSearch = (id: string, value: string) => {
    console.log('handleDeletePrivateSearch', value)
    selectedPresetSearch.current = { id, value }
    setOpenDeletePrivateSearchModal(true)
  }

  const handleEditPresetSearchList = (id: string, value: string) => {
    selectedPresetSearch.current = { id, value }
    setIsOpenEditSearchModal(true)
  }

  const handleCreateList = () => {
    setIsOpenCreateListModal(true)
  }

  const handleSaveSearchResults = () => {
    setIsOpenSaveSearchModal(true)
  }

  useEffect(() => {
    if (isOpen) {
      handleOpen()
      lockScroll()
    } else {
      handleClose()
      resetSearchTerm()
      unlockScroll()
    }
  }, [isOpen])

  useKeyPress(() => {
    if (isOpen) {
      handleClose()
      resetSearchTerm()
    }
  }, ['Escape'])

  const toggleSidebar = () => {
    if (isOpen) {
      handleClose()
      resetSearchTerm()
    } else {
      handleOpen()
    }
  }

  const handleSaveTelentListChange = (listName: string) => {
    handleSaveTelentList(
      listName,
      Number(selectProject.id) ?? null,
      selectedRoleList.id ?? null,
      <>
        New Talent list added to <b>{selectProject.label}</b> project:
        <br />
        <b>{listName}</b>
      </>,
    )
  }

  const handleSaveSearchPresetChange = (searchName: string) => {
    handleSaveSearchPreset(
      searchName,
      Number(selectProject.id) ?? null,
      selectedRoleList.id ?? null,
      <>
        New Search added to <b>{selectProject.label}</b> project:
        <br />
        <b>{searchName}</b>
      </>,
    )
  }

  const [activeSavedSearchName, activeSavedSearchId] = useMemo(
    () => (selectedSavedSearch?.presetSearch || '').split('_'),
    [selectedSavedSearch],
  )

  return (
    <div
      className={twMerge(
        'sticky top-0 float-right flex h-[calc(100vh-44px)]  flex-col place-items-start gap-2 bg-primary-blue-medium p-2 transition-all duration-300',
        isOpen && !isMobile ? 'w-[328px]' : 'w-[88px]',
      )}
    >
      <IconButton
        className='rounded bg-primary-grey p-0.5 hover:opacity-70'
        onClick={toggleSidebar}
      >
        {!isOpen ? (
          <ChevronLeft width={16} height={16} fill={Colors.ICON_WHITE} />
        ) : (
          <ChevronRight width={16} height={16} fill={Colors.ICON_WHITE} />
        )}
      </IconButton>

      <div
        className={twMerge(
          'h-full flex-col gap-2 transition-opacity duration-300',
          isOpen ? 'hidden opacity-0' : 'flex opacity-100',
        )}
      >
        <TalentRightItem
          kind='base'
          icon={<Folder fill={Colors.BASE_ICON} />}
          title='Project Role lists'
          handleOpenClick={() => handleOpenCurrentTab('first')}
        />
        <div>
          <TalentRightItem
            kind='up'
            title='Searches'
            icon={<Searches fill={Colors.BASE_ICON} />}
            handleOpenClick={() => handleOpenCurrentTab('second')}
          />
          <div className='border-b border-green-4' />
          <TalentRightItem
            kind='down'
            title='Lists'
            icon={<Lists fill={Colors.BASE_ICON} />}
            handleOpenClick={() => handleOpenCurrentTab('first')}
          />
        </div>
      </div>

      <div
        className={twMerge(
          'flex h-full min-w-[312px] flex-col gap-2 overflow-y-auto transition-opacity duration-300',
          isOpen ? 'flex opacity-100' : 'hidden opacity-0',
        )}
      >
        <TalentRightItemCard
          icon={<Folder fill={Colors.BASE_ICON} />}
          title='Project Role lists'
          wrapperClass='h-1/2'
          isSimple
          contentProjectRole={
            <ProjectRoleMenu
              handleSelectProject={handleSelectProject}
              setSelectedRole={setSelectedRole}
              selectedRole={selectedRole}
              projectValue={selectProject.value}
              projectRoleOptions={projects}
              roles={preparedMyRoles}
              talentsLists={mappedRoleTalentsLists}
              searchPresets={mappedRolePresetSearch}
              selectedRoleList={selectedRoleList}
              handleOpenCreateList={() => setIsOpenCreateRoleListModal(true)}
              handleOpenSearch={() => setIsRoleOpenSaveSearchModal(true)}
              handleClickSearch={handleClickItemPresetSearchList}
              handleClickItemTalentsLists={onSelectTalentsList}
              onEditPresetSearchList={handleEditPresetSearchList}
              handleDeletePresetSearchList={handleDeletePresetSearchList}
              setSelectedRoleList={setSelectedRoleList}
              handleOpenEditModal={handleRoleTalentEditList}
              handleDeleteModal={handleRoleDeleteList}
            />
          }
        />

        <TalentRightItemCard
          wrapperClass='h-1/2'
          selectTab={initialSelectTab}
          handleSelectTab={handleSelectTab}
          contentMyLists={
            <MyListMenu
              titleButton='Create new list'
              allLists={filteredTalentsLists}
              value={searchTermMyLists}
              handleOpenEditModal={handleEditList}
              handleOpenDeleteModal={handleDeleteList}
              onChange={handleSearchChangeTalentsLists}
              handleChange={handleCreateList}
              onClickItem={onSelectTalentsList}
            />
          }
          contentMySearches={
            <MyListMenu
              titleButton='Save search preset'
              value={searchTermPresetSearch}
              allLists={filteredPresetSearch}
              handleOpenEditModal={handleEditPresetSearchList}
              handleOpenDeleteModal={handleDeletePrivateSearch}
              handleChange={handleSaveSearchResults}
              isSavedSearchChanged={isSavedSearchChanged}
              setIsSavedSearchChanged={setIsSavedSearchChanged}
              activeSavedSearch={activeSavedSearchId}
              onChange={handleSearchChangePresetSearch}
              onClickItem={handleClickItemPresetMySearchList}
              setSearchModalOpened={setIsOpenSaveAsSearchModal}
              resetSavedSearch={onResetSavedSearch}
              onUpdateSearchPreset={() => {
                handleEditSearchPreset(
                  activeSavedSearchId,
                  activeSavedSearchName,
                  undefined,
                  <>
                    Updated <b>My Search:</b> <b>{activeSavedSearchName}</b>
                  </>,
                  true,
                )
                setIsSavedSearchChanged?.(false)
              }}
            />
          }
        />
      </div>

      {/* RIGHT PANEL TOP PART MODALS */}
      <NewPersonalTalentModal
        title='Create new project talent list'
        isOpen={isOpenCreateRoleListModal}
        handleClose={() => setIsOpenCreateRoleListModal(false)}
        handleSaveTelentList={handleSaveTelentListChange}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            All lists are <strong>public</strong> when saved to a{' '}
            <strong>project</strong>, and can be edited by other organization
            members.
          </span>
        }
      />
      <EditTalentsListModal
        isOpen={isOpenRoleTalentListEditModal}
        handleClose={() => setIsOpenRoleTalentListEditModal(false)}
        currentListId={listId}
        handleEdit={(id: string, name: string, currentListId?: string) => {
          const toastText = (
            <>
              Renamed talent list <b>{name}</b>
            </>
          )
          handleRoleEditTalentsList(id, name, currentListId, toastText, true)
        }}
        value={{ ...selectedTelent.current }}
      />
      <DeleteModal
        isOpen={isOpenRoleDeleteSearchModal}
        handleClose={() => setIsOpenRoleDeleteSearchModal(false)}
        title='Delete search from project role?'
        description='Warning: this action cannot be undone!'
        deleteTitle='Delete saved search'
        onDelete={() => {
          const savedSearchName = selectedPresetSearch.current.value
          deleteSavedSearchPreset({
            id: selectedPresetSearch.current.id,
          })
            .unwrap()
            .then(() => clearTalentFilters?.())
            .then(() => {
              toast.success(
                <>
                  Saved search deleted from
                  <br />
                  <b>{selectProject.label}</b> project: <b>{savedSearchName}</b>
                </>,
                {
                  icon: (
                    <Trash
                      fill={Colors.ICON_NEGATIVE_RED}
                      width={24}
                      height={24}
                    />
                  ),
                  ...commonToastStyles,
                },
              )
              handleClickItemPresetSearchList()
            })
            .catch(error => {
              toast.error(<>Failed to delete search from project role</>)
            })
          selectedPresetSearch.current = { id: '' }
          setIsOpenRoleDeleteSearchModal(false)
        }}
      />
      <DeleteModal
        isOpen={isOpenRoleDeleteTalentListModal}
        handleClose={() => setIsOpenRoleDeleteTalentListModal(false)}
        title='Delete list from your project role?'
        description='Warning: this action cannot be undone!'
        deleteTitle='Delete list'
        onDelete={() => {
          const deletedTalentListId = selectedTelent.current.id
          const listName = selectedTelent.current.value
          deleteSelectedTelent({
            id: selectedTelent.current.id,
          })
            .unwrap()
            .then(() => clearTalentFilters?.())
            .then(() => {
              toast.success(
                <>
                  List deleted from <b>{selectProject.label}</b> project:
                  <br />
                  <b>{listName}</b>
                </>,
                {
                  icon: (
                    <Trash
                      fill={Colors.ICON_NEGATIVE_RED}
                      width={24}
                      height={24}
                    />
                  ),
                  ...commonToastStyles,
                },
              )

              if (listId === deletedTalentListId) {
                navigate(Urls.TALENT)
              }
            })
            .catch(error => {
              toast.error(<>Failed to delete List from project role</>)
            })

          selectedTelent.current = { id: '' }
          setIsOpenRoleDeleteTalentListModal(false)
        }}
      />
      <SaveSearchResultsTalentModal
        title='Create new project search filter'
        isOpen={isOpenRoleSaveSearchModal}
        handleClose={() => setIsRoleOpenSaveSearchModal(false)}
        handleSaveSearchFilters={handleSaveSearchPresetChange}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            All search presets are <strong>public</strong> when saved to a{' '}
            <strong>project</strong>, and can be edited by other organization
            members.
          </span>
        }
      />

      {/* RIGHT PANEL BOTTOM PART MODALS */}
      <NewPersonalTalentModal
        isOpen={isOpenCreateListModal}
        handleClose={() => setIsOpenCreateListModal(false)}
        handleSaveTelentList={listName => {
          handleSaveTelentList(
            listName,
            null,
            null,
            <>
              New Talent list added to <b>My Lists:</b>
              <br />
              <b>{listName}</b>
            </>,
          )
        }}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            Your personal search filters are <strong>private</strong>, and can
            be used and edited by only you.
          </span>
        }
      />
      <SaveSearchResultsTalentModal
        title='Create new personal search filter'
        isOpen={isOpenSaveSearchModal}
        handleClose={() => setIsOpenSaveSearchModal(false)}
        handleSaveSearchFilters={(searchName: string) => {
          handleSaveSearchPreset(
            searchName,
            undefined,
            undefined,
            <>
              New Search added to <b>My Searches: </b>
              <br />
              <b>{searchName}</b>
            </>,
          )
        }}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            Your personal search filters are <strong>private</strong>, and can
            be used and edited by only you.
          </span>
        }
      />

      <SaveSearchResultsTalentModal
        title='Create new personal search filter'
        isOpen={isOpenSaveAsSearchModal}
        handleClose={() => setIsOpenSaveAsSearchModal(false)}
        handleSaveSearchFilters={async searchName => {
          const newSearch = await handleSaveSearchPreset(
            searchName,
            undefined,
            undefined,
            <>
              New Search added to <b>My Searches: </b>
              <br />
              <b>{searchName}</b>
            </>,
          )

          onSelectNewSavedSearch(newSearch)
          setIsSavedSearchChanged?.(false)
        }}
        footer={
          <span className='text-sm leading-[20px] text-primary-black'>
            Your personal search filters are <strong>private</strong>, and can
            be used and edited by only you.
          </span>
        }
      />
      <EditTalentsListModal
        isOpen={isOpenEditModal}
        handleClose={() => setIsOpenEditModal(false)}
        handleEdit={(id, name, currentListId) => {
          const toastText = (
            <>
              Renamed talent list from <b>My Lists</b>
            </>
          )
          handleEditTalentsList(id, name, currentListId, toastText, true)
        }}
        currentListId={listId}
        value={{ ...selectedTelent.current }}
      />
      <EditSearchResultsTalentModal
        isOpen={isOpenEditSearchModal}
        handleClose={() => setIsOpenEditSearchModal(false)}
        handleEdit={(id, name) => {
          handleEditSearchPreset(
            id,
            name,
            undefined,
            <>
              Updated <b>My Search:</b> <b>{name}</b>
            </>,
            true,
          )
        }}
        value={{ ...selectedPresetSearch.current }}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        handleClose={() => setIsOpenDeleteModal(false)}
        title='Delete list from your My Lists?'
        description='Warning: this action cannot be undone!'
        deleteTitle='Delete saved list'
        onDelete={() => {
          const listName = selectedTelent.current.value
          deleteSelectedTelent({
            id: selectedTelent.current.id,
          })
            .unwrap()
            .then(() => {
              toast.success(
                <>
                  List deleted from <b>My Lists:</b>
                  <br />
                  <b>{listName}</b>
                </>,
                {
                  icon: (
                    <Trash
                      fill={Colors.ICON_NEGATIVE_RED}
                      width={24}
                      height={24}
                    />
                  ),
                  ...commonToastStyles,
                },
              )
            })
            .catch(error => {
              toast.error(<>Failed to remove Talent from list</>)
            })

          const isListDetailsPageForCurrentList =
            listId === selectedTelent.current.id

          if (isListDetailsPageForCurrentList) {
            navigate(Urls.TALENT)
          }

          selectedTelent.current = { id: '' }

          setIsOpenDeleteModal(false)
        }}
      />
      <DeleteModal
        isOpen={isOpenDeletePrivateSearchModal}
        handleClose={() => setOpenDeletePrivateSearchModal(false)}
        title='Delete search from your My Searches?'
        description='Warning: this action cannot be undone!'
        deleteTitle='Delete saved search'
        onDelete={() => {
          deleteSavedSearchPreset({
            id: selectedPresetSearch.current.id,
          })
            .unwrap()
            .then(() => {
              console.log('selectedPresetSearch', selectedPresetSearch)
              toast.success(
                <>
                  Saved search deleted from <b>My Searches:</b>{' '}
                  <b>{selectedPresetSearch.current.value}</b>
                </>,
                {
                  icon: (
                    <Trash
                      fill={Colors.ICON_NEGATIVE_RED}
                      width={24}
                      height={24}
                    />
                  ),
                  ...commonToastStyles,
                },
              )
              handleClickItemPresetMySearchList()
              selectedPresetSearch.current = { id: '' }
              setOpenDeletePrivateSearchModal(false)
            })
            .catch(error => {
              toast.error(<>Failed to delete Saved search from My Searches</>)
            })
        }}
      />
    </div>
  )
}
