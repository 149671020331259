import { twJoin, twMerge } from 'tailwind-merge'
import dayjs from 'dayjs'
import { DashedLines } from './DashedLines'
import { getDotColorClass } from '../utils'
import { FilledWeekItem } from './FilledWeekItem'
import { EVENT_HEIGHT_SPORT_HOLIDAY, WIDTH_WEEK } from '../../../types'

interface rowTemplateProps {
  item: any
  rowIndex?: number
  colorClass?: string
  updatedLabel?: string
  maxOverlap?: number
  isSportOrHoliday?: boolean
  isOtherItem?: boolean
  releaseWeeks: {
    title: string
    value: string
    startDate: string
    endDate: string
  }[]
  isLastChild: boolean
}

export const RowTemplate = ({
  item,
  rowIndex = 0,
  colorClass = '',
  updatedLabel = '',
  maxOverlap = 0,
  isSportOrHoliday = false,
  isOtherItem = false,
  releaseWeeks,
  isLastChild = false,
}: rowTemplateProps) => {
  const rowHeight = `${Math.max(
    maxOverlap * ((item.itemHeight || EVENT_HEIGHT_SPORT_HOLIDAY) + 0.5),
    40,
  )}px`

  return (
    <div
      key={`${item.label}_${rowIndex}`}
      className={twMerge(
        'flex w-fit',
        isLastChild && item.value !== 'major' && 'flex-1',
      )}
    >
      <div className={twMerge('colored-border h-full w-2', colorClass)} />
      <div className='film-row flex items-center border-b border-primary-black/10 first:border-b-0 first:border-t'>
        <div className='calendar-release-abbr-col flex h-full w-12 items-center border-r border-r-primary-black/10 bg-grey-2 pl-2 text-xs font-medium text-grey-7'>
          {updatedLabel}
        </div>

        <div
          className={twMerge(
            'relative flex overflow-hidden',
            isLastChild && item.value !== 'major' && 'min-h-full',
          )}
          style={{
            height: rowHeight,
          }}
        >
          {releaseWeeks.map(week => {
            const weekStart = dayjs(week.startDate)
            const weekEvents = item.groupedWeeks[week.value] || []
            const sortedEvents = weekEvents.sort((a, b) =>
              dayjs(a.startDay).isBefore(dayjs(b.startDay)) ? -1 : 1,
            )

            return (
              <div
                key={week.startDate}
                className={twMerge(
                  'relative flex border-r border-r-primary-black/10',
                  `w-[${WIDTH_WEEK}px]`,
                )}
              >
                <DashedLines />
                {sortedEvents.map((weekItem, columnIndex) => {
                  if (weekItem.dummy) return null
                  const originalStart = dayjs(weekItem.startDay)
                  const originalEnd = dayjs(weekItem.endDay)
                  const fullTotalDays =
                    originalEnd.diff(originalStart, 'day') + 1
                  const fullWidth = (fullTotalDays * WIDTH_WEEK) / 7
                  const fullLeftOffset =
                    (originalStart.diff(weekStart, 'day') * WIDTH_WEEK) / 7

                  const colorDotClass = getDotColorClass(item.value)
                  const hasLine =
                    weekItem.hasLine ||
                    (weekItem.startDay !== weekItem.endDay && isSportOrHoliday)
                  const height = `${item.itemHeight}px`

                  const top =
                    weekEvents.length > 1
                      ? columnIndex *
                        ((item.itemHeight || EVENT_HEIGHT_SPORT_HOLIDAY) + 0.5)
                      : 0
                  return (
                    <div
                      key={weekItem.name && weekItem.name + columnIndex}
                      className='absolute'
                      style={{
                        left: `${fullLeftOffset}px`,
                        width: `${fullWidth}px`,
                        height: height,
                        zIndex: columnIndex + 1,
                        top: `${top}px`,
                      }}
                    >
                      <FilledWeekItem
                        name={weekItem.name}
                        wrapperClassName={twMerge(
                          'h-full w-full',
                          weekItem.isOutline &&
                            'border-2 bg-primary-white border-yellow-5',
                          !weekItem.isOutline &&
                            !hasLine &&
                            !isSportOrHoliday &&
                            'border-0 bg-yellow-5 border-r',
                          weekItem.hasBackground && 'bg-transparent min-w-max ',
                          isOtherItem && 'border-0 min-w-max bg-transparent',
                        )}
                        dotClassName={twJoin(
                          !weekItem.isOutline && 'bg-primary-white w-1.5 h-1.5',
                          weekItem.isOutline && 'bg-yellow-5 w-1.5 h-1.5',
                          colorDotClass,
                        )}
                        hasLine={hasLine}
                        lineClassName={twMerge(colorClass)}
                        nameClassName={item?.nameClassName ?? ''}
                        releaseType={weekItem.releaseTypeKey}
                        abbreviation={weekItem.abbreviation}
                        eventTotalDays={fullTotalDays}
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
