import { FormEventHandler, ReactNode } from 'react'
import { Button } from 'components'
import { useFormContext } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

interface MgmtEntryPageFormProps {
  children: ReactNode
  submitButtonLabel: string
  cancelButtonLabel?: string
  onSubmit: FormEventHandler<HTMLFormElement>
  onClose: () => void
  onStatusChange: () => void
  isActive: boolean
  footerClassName?: string
}

export const MgmtEntryPageForm = ({
  children,
  submitButtonLabel,
  cancelButtonLabel = 'Cancel',
  onSubmit,
  onClose,
  onStatusChange,
  isActive,
  footerClassName = 'justify-end',
}: MgmtEntryPageFormProps) => {
  const { formState } = useFormContext()

  return (
    <form onSubmit={onSubmit}>
      <div className='space-y-4 py-6'>{children}</div>

      <div className={twMerge('flex justify-between gap-2', footerClassName)}>
        <div>
          <Button
            kind='filled'
            size='medium'
            className='bg-primary-red py-2.5 hover:bg-red-9 active:bg-primary-red/50'
            type='submit'
            loading={formState.isSubmitting}
          >
            <span className='px-2 text-sm font-semibold'>
              {submitButtonLabel}
            </span>
          </Button>

          <Button
            kind='text'
            size='medium'
            onClick={onClose}
            className='py-2.5'
            type='button'
          >
            <span className='px-1 text-sm font-semibold'>
              {cancelButtonLabel}
            </span>
          </Button>
        </div>

        <div>
          <Button
            kind='text'
            size='medium'
            onClick={onStatusChange}
            className={twMerge(
              'py-2.5 text-white',
              isActive ? 'bg-red-10' : 'bg-primary-grey',
            )}
            type='button'
          >
            <span className='px-1 text-sm font-semibold'>
              {isActive ? 'Archive Talent' : 'Activate Talent'}
            </span>
          </Button>
        </div>
      </div>
    </form>
  )
}
