import { combineReducers } from 'redux'
import { api } from './api/api'
import {
  projectReducer,
  myProjectReducer,
  releaseCalendarReducer,
  talentReducer,
  filmTrackingPageSlice,
  filmTrackingComparePageSlice,
} from 'slices'
import { mockApi } from './api/mock-api'
import {
  releaseCalendarMgmtSlice,
  talentManagementSlice,
  tvFilmCompaniesSlice,
  adminManagementSlice,
} from 'modules/management'

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [mockApi.reducerPath]: mockApi.reducer,
  [filmTrackingPageSlice.reducerPath]: filmTrackingPageSlice.reducer,
  [filmTrackingComparePageSlice.reducerPath]:
    filmTrackingComparePageSlice.reducer,
  [tvFilmCompaniesSlice.reducerPath]: tvFilmCompaniesSlice.reducer,
  [releaseCalendarMgmtSlice.reducerPath]: releaseCalendarMgmtSlice.reducer,
  [adminManagementSlice.reducerPath]: adminManagementSlice.reducer,
  [talentManagementSlice.reducerPath]: talentManagementSlice.reducer,
  projectReducer,
  myProjectReducer,
  releaseCalendarReducer,
  talentReducer,
})
