import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { BaseModal, Button, Select } from 'components'
import { Input } from '../ui'
import {
  updateInviteCompanyModalProps,
  NewCompanyData,
} from './CompanyModal.types'
import {
  useNewAdminCompanyMutation,
  useUpdatedAdminCompanyMutation,
} from '../../store/api'

import { stateOptions } from '../../constnants'
import { toastError } from '../../utils'

const initialCompanyData: NewCompanyData = {
  id: '',
  companyName: '',
  companyType: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  zip_code: '',
  primaryContact: '',
  email: '',
  accountStatus: '',
}

export const CompanyModal = ({
  isOpen,
  handleClose,
  title,
  description,
  onSubmit,
  cancelTitle = 'Cancel',
  saveTitle = 'Invite',
  maxWidth,
  modalZIndex,
  initialData,
  requestType,
}: updateInviteCompanyModalProps) => {
  const [companyData, setCompanyData] = useState(initialCompanyData)
  const [validation, setValidation] = useState({
    validName: false,
    companyType: false,
    address_line_1: false,
    city: false,
    state: false,
    zip_code: false,
    primaryContact: false,
    validEmail: false,
    accountStatus: false,
  })

  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (initialData) {
      setCompanyData(initialData)
    }
  }, [initialData])
  const [newCompany] = useNewAdminCompanyMutation()
  const [updateCompany] = useUpdatedAdminCompanyMutation()

  const regExpEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const validateEmail = (email: string) => ({
    validEmail: regExpEmail.test(String(email.toLowerCase())),
  })

  useEffect(() => {
    setValidation({
      validName: companyData.companyName.length > 0,
      companyType: companyData.companyType.length > 0,
      address_line_1: companyData.address_line_1.length > 0,
      city: companyData.city.length > 0,
      state: companyData.state.length > 0,
      zip_code: companyData.zip_code.length > 0,
      primaryContact: companyData.primaryContact.length > 0,
      ...validateEmail(companyData.email),
      accountStatus: companyData.accountStatus.length > 0,
    })
  }, [companyData])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target
    setCompanyData(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSelect = (
    option: { id: string; value: string; label: string },
    key: string,
  ) => {
    setCompanyData(prevState => ({ ...prevState, [key]: option.value }))
    if (key === 'userType') {
      setCompanyData(prevState => ({ ...prevState, accessLevel: '' }))
    }
  }

  const isValidation = Object.values(validation).every(value => value)
  const validateForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isValidation) {
      setShowError(true)
      return
    }
    setIsLoading(true)
    try {
      if (requestType === 'POST') {
        const data = await newCompany({
          name: companyData.companyName,
          type: companyData.companyType,
          email: companyData.email,
          isGuestLinkAllowed: false,
          address: {
            address_line_1: companyData.address_line_1,
            address_line_2: companyData.address_line_2,
            city: companyData.city,
            state: companyData.state,
            zip_code: companyData.zip_code,
          },
          status: companyData.accountStatus,
          primaryContact: companyData.primaryContact,
        })

        if (data.error) {
          const { error, data: errorData } = data.error as {
            data?: any
            error?: string
          }
          toastError(errorData, error)
          return
        }
        onSubmit()
      } else if (requestType === 'PUT') {
        const newCompanyData = {
          id: companyData.id ?? '',
          data: {
            name: companyData.companyName,
            type: companyData.companyType,
            isGuestLinkAllowed: false,
            address: {
              address_line_1: companyData.address_line_1,
              address_line_2: companyData.address_line_2,
              city: companyData.city,
              state: companyData.state,
              zip_code: companyData.zip_code,
            },
            status: companyData.accountStatus,
            primaryContact: companyData.primaryContact,
          },
        }
        if (initialData?.email !== companyData.email) {
          newCompanyData.data.email = companyData.email
        }
        const data = await updateCompany(newCompanyData)
        if (data.error) {
          const { error, data: errorData } = data.error as {
            data?: any
            error?: string
          }
          toastError(errorData, error)
          return
        }
        onSubmit()
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      titleClass='pb-2'
      maxWidth={maxWidth}
      modalZIndex={modalZIndex}
      wrapperClass='overflow-auto max-h-[90vh]'
    >
      {description && <div className={'mb-5'}>{description}</div>}
      <form onSubmit={e => validateForm(e)}>
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='text'
          label='Company Name'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'Company Name is required'}
          isError={showError && !validation.validName}
          value={companyData.companyName}
          onChange={e => handleChange(e, 'companyName')}
        />
        <Select
          label={'Company Type'}
          id={'companyType'}
          labelClass={'pb-1 text-sm font-semibold text-primary-black'}
          containerClass='w-full mb-4'
          buttonClass='h-8 items-center'
          value={companyData.companyType}
          handleClick={option => {
            handleSelect(option, 'companyType')
          }}
          options={[
            {
              id: '1',
              value: 'MajorFilmStudio',
              label: 'Major Film Studio',
            },
            {
              id: '2',
              value: 'MinorFilmStudio',
              label: 'Minor Film Studio',
            },
            {
              id: '3',
              value: 'MajorTalentAgency',
              label: 'Major Talent Agency',
            },
            {
              id: '4',
              value: 'MinorTalentAgency',
              label: 'Minor Talent Agency',
            },
            {
              id: '5',
              value: 'Streamer',
              label: 'Streamer',
            },
            {
              id: '6',
              value: 'MajorProductionCompany',
              label: 'Major Production Company',
            },
            {
              id: '7',
              value: 'MinorProductionCompany',
              label: 'Minor Production Company',
            },
            {
              id: '8',
              value: 'ManagementCompany',
              label: 'Management Company',
            },
            {
              id: '9',
              value: 'Exhibition',
              label: 'Exhibition',
            },
            {
              id: '10',
              value: 'Brand',
              label: 'Brand',
            },
            {
              id: '10',
              value: 'Other',
              label: 'Other',
            },
          ]}
          size='small'
          hasRadioButton={false}
          isError={showError && !validation.companyType}
          error={'Company Type is required'}
          errorClass={'pt-1.5 text-xs'}
        />
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='text'
          label='Address Line 1'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'Address Line 1 is required'}
          isError={showError && !validation.address_line_1}
          value={companyData.address_line_1}
          onChange={e => handleChange(e, 'address_line_1')}
        />
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='text'
          label={
            <>
              Address Line 2 <span className='font-normal'>(optional)</span>
            </>
          }
          labelClassName='text-sm font-semibold text-primary-black'
          value={companyData.address_line_2}
          onChange={e => handleChange(e, 'address_line_2')}
        />
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='text'
          label='City'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'City is required'}
          isError={showError && !validation.city}
          value={companyData.city}
          onChange={e => handleChange(e, 'city')}
        />
        <div className={'mb-4 flex gap-1 border-b border-b-primary-black/10'}>
          <Select
            label={'State'}
            id={'companyState'}
            labelClass={'pb-1 text-sm font-semibold text-primary-black'}
            containerClass='w-full mb-4'
            buttonClass='h-8 items-center'
            value={companyData.state}
            handleClick={option => {
              handleSelect(option, 'state')
            }}
            options={stateOptions}
            size='small'
            hasRadioButton={false}
            isError={showError && !validation.state}
            error={'State is required'}
            errorClass={'pt-1.5 text-xs'}
          />
          <Input
            containerWrapperClassName='pb-4'
            inputClassName='h-8 px-3'
            type='text'
            label='Zip Code'
            labelClassName='text-sm font-semibold text-primary-black'
            error={'Zip Code is required'}
            isError={showError && !validation.zip_code}
            value={companyData.zip_code}
            onChange={e => handleChange(e, 'zip_code')}
          />
        </div>
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='text'
          label='Primary Contact'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'Primary Contact is required'}
          isError={showError && !validation.primaryContact}
          value={companyData.primaryContact}
          onChange={e => handleChange(e, 'primaryContact')}
        />
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='email'
          label='Email'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'Invalid email address format'}
          isError={showError && !validation.validEmail}
          value={companyData.email}
          onChange={e => handleChange(e, 'email')}
        />
        <Select
          label={'Account Status'}
          id={'accountStatus'}
          labelClass={'pb-1 text-sm font-semibold text-primary-black'}
          containerClass='w-full mb-4'
          buttonClass='h-8 items-center'
          value={companyData.accountStatus}
          handleClick={option => {
            handleSelect(option, 'accountStatus')
          }}
          options={[
            {
              id: '1',
              value: 'active',
              label: 'Active',
            },
            {
              id: '2',
              value: 'inactive',
              label: 'Inactive',
            },
          ]}
          size='small'
          hasRadioButton={false}
          isError={showError && !validation.accountStatus}
          error={'Account Status is required'}
          errorClass={'pt-1.5 text-xs'}
        />
        <div className='flex justify-end gap-2'>
          <Button
            kind='text'
            size='medium'
            onClick={handleClose}
            className='py-2.5'
            type={'button'}
          >
            <span className='px-1 text-sm font-semibold'>{cancelTitle}</span>
          </Button>

          <Button
            kind='filled'
            size='medium'
            className='bg-primary-red py-2.5 hover:bg-red-9 active:bg-primary-red/50'
            type='submit'
            loading={isLoading}
          >
            <span className='px-2 text-sm font-semibold'>{saveTitle}</span>
          </Button>
        </div>
      </form>
    </BaseModal>
  )
}
