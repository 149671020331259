import { useAuthContext } from 'context'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Hub } from 'aws-amplify/utils'
import { AuthUser, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth'

export const useAuth = () => {
  const { auth, updateAuth } = useAuthContext()
  const dispatch = useDispatch()
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      getUser(
        payload.event === 'signInWithRedirect' || payload.event === 'signedIn',
      )
    })

    getUser(true)

    return () => {
      unsubscribe()
    }
  }, [])

  const getUser = async (isFetchUserAttributes?: boolean): Promise<void> => {
    updateAuth({
      isLoading: true,
    })
    try {
      const currentUser: AuthUser = await getCurrentUser()

      let userAttributes: any
      if (currentUser) {
        try {
          if (isFetchUserAttributes) {
            userAttributes = await fetchUserAttributes()
          }
        } catch {}
      }
      updateAuth({
        isSignIn: true,
        isSignOut: false,
        isLoading: false,
        isInvitesChecked: false,
        user: currentUser,
        userAttributes,
      })
    } catch (error) {
      updateAuth({
        isSignIn: false,
        isSignOut: true,
        isLoading: false,
        user: null,
        userAttributes: null,
        isInvitesChecked: false,
      })
    }
  }
  return { auth }
}
