import PageLoader from 'components/PageLoader'
import { useAuthContext } from 'context'
import React, { ReactNode } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAdminData } from '../../hooks'
import { useGetCurrentUserQuery } from '../../store/api'

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { auth } = useAuthContext()
  const {
    data: user,
    isLoading,
    isFetching,
  } = useGetCurrentUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })
  const loading = isLoading || isFetching
  const { isAdminPath } = useAdminData()
  const isAdmin = user?.is_greenlight_admin
  const navigate = useNavigate()
  if (!auth.isLoading && auth?.userAttributes?.email_verified === 'False') {
    navigate('/verify-email')
  }
  if (auth?.isLoading || loading) return <PageLoader hasOpaquePrimaryBg />
  if (!auth.user?.userId) return <Navigate to='/login' replace />
  if (isAdminPath && !isAdmin) return <Navigate to='/talent' replace />
  if (!isAdminPath && isAdmin)
    return <Navigate to='/companyManagement' replace />
  return <>{children}</>
}
