import { TVNetworkType, TVType, TVSubtype, TVGenre, TVNetwork } from '../types'

export const tVNetworkTypeLabelByType: Record<TVNetworkType, string> = {
  [TVNetworkType.TVCable]: 'Cable',
  [TVNetworkType.TVStreamer]: 'Streamer',
}

export const tVNetworkLabelByType: Record<TVNetwork, string> = {
  [TVNetwork.Cinemax]: 'Cinemax',
  [TVNetwork.Max]: 'Max',
  [TVNetwork.ApplePlus]: 'APL+',
  [TVNetwork.DisneyPlus]: 'DSN+',
  [TVNetwork.Netflix]: 'NFX',
}

export const tVTypeLabelByType: Record<TVType, string> = {
  '1': 'Series',
  '2': 'Film',
  '3': 'Movie',
}

export const tVSubtypeLabelByType: Record<TVSubtype, string> = {
  [TVSubtype.StreamingOriginal]: 'Streaming Original',
  [TVSubtype.StreamingPremiere]: 'Streaming Premiere',
  [TVSubtype.SeriesPremiere]: 'Series Premiere',
  [TVSubtype.SeasonPremiere]: 'Season Premiere',
}

export const tVGenreLabelByType: Record<TVGenre, string> = {
  [TVGenre.Comedy]: 'Comedy',
  [TVGenre.Drama]: 'Drama',
  [TVGenre.Action]: 'Action',
  [TVGenre.Animation]: 'Animation',
  [TVGenre.FamilyLiveAction]: 'Family LiveAction',
  [TVGenre.Horror]: 'Horror',
  [TVGenre.Cooking]: 'Cooking',
  [TVGenre.Documentary]: 'Documentary',
  [TVGenre.GameShow]: 'GameShow',
  [TVGenre.HomeDIY]: 'HomeDIY',
  [TVGenre.LimitedAnthologySeries]: 'LimitedAnthologySeries',
  [TVGenre.RealityCompetition]: 'RealityCompetition',
  [TVGenre.RealityDocuseries]: 'RealityDocuseries',
  [TVGenre.ScriptedVariety]: 'ScriptedVariety',
  [TVGenre.Sports]: 'Sports',
  [TVGenre.TalkSeries]: 'TalkSeries',
}
