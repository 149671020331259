import { ReactNode, useCallback } from 'react'
import { MgmtTable } from 'modules/management/shared'
import { type ReleaseCalendarRevenueTableTypes } from './types'
import {
  ReleaseCalendarRevenueColKey,
  type ReleaseCalendarRevenue,
} from '../../types'
import { EditEntryCellContent } from '../cellContent'
import { columns } from './columns'
import { formatDate, formatCurrency } from 'utils'
import { ToggleHookStateControls } from 'hooks'

type ReleaseCalendarRevenueTableProps = {
  rows: ReleaseCalendarRevenue[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: ReleaseCalendarRevenueTableTypes['SortConfig']
  onSortChange: ReleaseCalendarRevenueTableTypes['OnSortChange']
  editDialogStateControls: ToggleHookStateControls<ReleaseCalendarRevenue>
}

export const ReleaseCalendarRevenueTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
  editDialogStateControls,
}: ReleaseCalendarRevenueTableProps) => {
  const rowKeyGetter: ReleaseCalendarRevenueTableTypes['RowKeyGetter'] =
    useCallback(({ row }) => row.id, [])

  const cellContentGetter: ReleaseCalendarRevenueTableTypes['CellContentGetter'] =
    useCallback(
      ({ column, row, getCellValue }) => {
        switch (column.key) {
          case ReleaseCalendarRevenueColKey.EditEntry: {
            return (
              <EditEntryCellContent
                onClick={() => editDialogStateControls.activate(row)}
              />
            )
          }
          case ReleaseCalendarRevenueColKey.Date:
            return getCellValue(column.key, dateStr =>
              formatDate(dateStr, 'MM/DD/YYYY'),
            )
          case ReleaseCalendarRevenueColKey.Revenue:
            return getCellValue(column.key, formatCurrency)
          default:
            return getCellValue(column.key)
        }
      },
      [editDialogStateControls],
    )

  return (
    <MgmtTable
      rows={rows}
      columns={columns}
      footer={footer}
      loading={loading}
      fetching={fetching}
      sortConfig={sortConfig}
      onSortChange={onSortChange}
      rowKeyGetter={rowKeyGetter}
      cellContentGetter={cellContentGetter}
    />
  )
}
