export type Feet = {
  feet: number
  inches: number
}

export const toInches = (feet: number, inches: number) => feet * 12 + inches

export const fromInches = (inches: number) => ({
  feet: Math.floor(inches / 12),
  inches: inches % 12,
})

export const inchesToString = (value: number): string => {
  const { feet, inches } = fromInches(value)
  return `${feet}' ${inches.toFixed(0)}"`
}
