import { twMerge } from 'tailwind-merge'
import { ReactNode } from 'react'
import { IconTabsKind } from './IconTabs'

export type IconTab<T> = {
  value?: T
  label?: string
  icon?: ReactNode
}

export type IconTabProps<T> = {
  value: string
  label?: string
  icon: ReactNode | string
  onChange: () => void
  selectedTab?: IconTab<T>
  kind: IconTabsKind
  size: 'small' | 'medium'
  wrapperClasses?: string
}

export const IconTab = <T extends string>({
  value,
  label,
  icon,
  onChange,
  kind,
  size,
  selectedTab,
  wrapperClasses,
}: IconTabProps<T>) => {
  return (
    <div
      onClick={onChange}
      className={twMerge(
        'bg-primary-white hover:cursor-pointer hover:bg-primary-black/10',
        kind === 'outline'
          ? 'border-r border-r-primary-black/10 last:border-r-0'
          : 'rounded',

        selectedTab?.value === value && 'bg-primary-black/5 font-medium',
        wrapperClasses,
      )}
    >
      {label && <span>{label}</span>}
      {icon && (
        <div className={twMerge('p-1', size === 'small' && 'p-0.5')}>
          {icon}
        </div>
      )}
    </div>
  )
}
