export type TalentManagementData = {
  id: string
  imdbId: string
  fullName: string
  dob: string
  race: Race
  gender: Gender
  height: number
  bio: string
  similarTalent1: string
  similarTalent2: string
  similarTalent3: string
  similarTalent4: string
  wikipedia?: string
  instagramHandle?: string
  instagramUrl?: string
  xHandle?: string
  xUrl?: string
  hashtags?: string
  isActive: boolean
}

export enum TalentManagementColKey {
  ImdbId = 'imdbId',
  FullName = 'fullName',
  Dob = 'dob',
  Race = 'race',
  Gender = 'gender',
  Height = 'height',
  SimilarTalent1 = 'similarTalent1',
  SimilarTalent2 = 'similarTalent2',
  SimilarTalent3 = 'similarTalent3',
  SimilarTalent4 = 'similarTalent4',
  Bio = 'bio',
  Wikipedia = 'wikipedia',
  InstagramHandle = 'instagramHandle',
  InstagramUrl = 'instagramUrl',
  XHandle = 'xHandle',
  XUrl = 'xUrl',
  Hashtags = 'hashtags',
  Actions = 'actions',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  NonBinary = 'nonBinary',
}

export enum Race {
  Black = 'black',
  Asian = 'asian',
  SouthAsian = 'southAsian',
  Hispanic = 'hispanic',
  MiddleEastern = 'middleEastern',
  Multiracial = 'multiracial',
  NativeAmerican = 'nativeAmerican',
  Nhpi = 'nhpi',
  White = 'white',
}

export type TalentManagementSortColKey =
  | TalentManagementColKey.ImdbId
  | TalentManagementColKey.FullName
  | TalentManagementColKey.Gender

export enum TalentManagementTab {
  Active = 'active',
  Archive = 'archive',
}
