import {
  ColumnsFilms,
  ColumnsFilmsHiddenInfo,
  AudienceDemoGroup,
  ColumnsFilmsHiddenInfoItem,
  AudienceGeneralDemo,
  AudienceRaceDemoBlack,
  AudienceRaceDemoWhite,
  AudienceRaceDemoHispLat,
  AudienceIdentityDemo,
  AudienceDemo,
  OptionType,
} from 'types'

export const rowsPerPageOptions: OptionType<number>[] = [
  { id: '1', value: 25, label: '25' },
  { id: '2', value: 50, label: '50' },
  { id: '3', value: 100, label: '100' },
]

const generalHiddenColumnItems: ColumnsFilmsHiddenInfoItem[] = [
  { key: AudienceGeneralDemo.MEN, label: 'M' },
  { key: AudienceGeneralDemo.WOMEN, label: 'W' },
  { key: AudienceGeneralDemo.BELOW_35, label: '<35' },
  { key: AudienceGeneralDemo.ABOVE_35, label: '35+' },
  { key: AudienceGeneralDemo.MEN_BELOW_35, label: 'M<35' },
  { key: AudienceGeneralDemo.MEN_ABOVE_35, label: 'M35+' },
  { key: AudienceGeneralDemo.WOMEN_BELOW_35, label: 'W<35' },
  { key: AudienceGeneralDemo.WOMEN_ABOVE_35, label: 'W35+' },
]

const blackHiddenColumnItems: ColumnsFilmsHiddenInfoItem[] = [
  { key: AudienceRaceDemoBlack.ALL, label: 'All' },
  { key: AudienceRaceDemoBlack.MEN, label: 'M' },
  { key: AudienceRaceDemoBlack.WOMEN, label: 'W' },
  { key: AudienceRaceDemoBlack.BELOW_35, label: '<35' },
  { key: AudienceRaceDemoBlack.ABOVE_35, label: '35+' },
]

const hispLatHiddenColumnItems: ColumnsFilmsHiddenInfoItem[] = [
  { key: AudienceRaceDemoHispLat.ALL, label: 'All' },
  { key: AudienceRaceDemoHispLat.MEN, label: 'M' },
  { key: AudienceRaceDemoHispLat.WOMEN, label: 'W' },
  { key: AudienceRaceDemoHispLat.BELOW_35, label: '<35' },
  { key: AudienceRaceDemoHispLat.ABOVE_35, label: '35+' },
]

const whiteHiddenColumnItems: ColumnsFilmsHiddenInfoItem[] = [
  { key: AudienceRaceDemoWhite.ALL, label: 'All' },
  { key: AudienceRaceDemoWhite.MEN, label: 'M' },
  { key: AudienceRaceDemoWhite.WOMEN, label: 'W' },
  { key: AudienceRaceDemoWhite.BELOW_35, label: '<35' },
  { key: AudienceRaceDemoWhite.ABOVE_35, label: '35+' },
]

const identityHiddenColumnItems: ColumnsFilmsHiddenInfoItem[] = [
  { key: AudienceIdentityDemo.ALL, label: 'All' },
]

export const audienceHiddenColumns: ColumnsFilmsHiddenInfo[] = [
  {
    key: AudienceDemoGroup.GENERAL,
    title: 'GEN',
    items: [{ label: 'CHG' }, { label: 'AVG' }, ...generalHiddenColumnItems],
  },
  {
    key: AudienceDemoGroup.BLACK,
    title: 'BLK',
    items: blackHiddenColumnItems,
  },
  {
    key: AudienceDemoGroup.HISPANIC_LATINO,
    title: 'HSP',
    items: hispLatHiddenColumnItems,
  },
  {
    key: AudienceDemoGroup.WHITE,
    title: 'WHT',
    items: whiteHiddenColumnItems,
  },
  {
    key: AudienceDemoGroup.LGBTQI,
    title: 'LGB',
    items: identityHiddenColumnItems,
  },
]

export const getHiddenGroupAndColumnName = (key: AudienceDemo) => {
  for (const column of audienceHiddenColumns) {
    const item = column.items.find(item => item.key === key)
    if (item) {
      return column.title ? `${column.title} ${item.label}` : item.label
    }
  }
}

export const columnsFilms: ColumnsFilms[] = [
  { title: 'Film', accessor: 'film' },
  { title: 'Date', accessor: 'date' },
  { title: 'Dist.', accessor: 'dist' },
  { title: 'Group', accessor: 'group' },
  { title: 'Rating', accessor: 'rating' },
  {
    title: 'Awareness',
    accessor: 'general_awareness',
    relatedParent: 'general_awareness',
    tooltipText:
      'Percent of respondents who are aware of the film when presented with artwork and a logline.',
    primaryColor: '#FFA500',
    secondaryColor: '#FFF6E6',
    isExpandable: true,
    hiddenInfo: audienceHiddenColumns,
  },
  {
    title: 'Interest',
    accessor: 'general_interest',
    relatedParent: 'general_interest',
    tooltipText: `Percent of respondents who rated their interest in a film with a 5 or higher on a scale from 1 to 7.\n\nAware switch:\nWhen turning the ‘Aware’ switch ON, this column displays the percent of respondents who rated their interest with a 5 or higher on a scale from 1 to 7 among those aware of the film. `,
    primaryColor: '#2C5684',
    secondaryColor: '#EDF4FC',
    isExpandable: true,
    hiddenInfo: audienceHiddenColumns,
  },
  {
    title: 'Location',
    accessor: 'general_location',
    relatedParent: 'general_location',
    tooltipText:
      'Percent of respondents who would watch the film in a theater as opposed to at home or not at all.',
    primaryColor: '#99292C',
    secondaryColor: '#FFECED',
    isExpandable: true,
    hiddenInfo: audienceHiddenColumns,
  },
  {
    title: 'Pay To See',
    accessor: 'general_pay_to_see',
    relatedParent: 'general_pay_to_see',
    tooltipText:
      'Percent of respondents who would be willing to pay to see the film in a theater or at home through VOD or subscribing to a new streaming service to watch.',
    primaryColor: '#006C2B',
    secondaryColor: '#E6F8ED',
    isExpandable: true,
    hiddenInfo: audienceHiddenColumns,
  },
  {
    title: 'Unaided Awareness',
    accessor: 'general_unaided_awareness',
    tooltipText:
      'Percent of respondents who are aware of the film through an open-ended question without the aid of a list, artwork, or a logline. ',
    primaryColor: '#CFC266',
  },
  {
    title: 'Awareness + Interest',
    accessor: 'general_awareness_interest',
    tooltipText: `The sum of a film's awareness and interest.`,
    primaryColor: '#FF9E83',
  },
  {
    title: 'Heat',
    accessor: 'general_heat',
    tooltipText:
      'Percent of respondents who rated their interest in a film as a 7 on a scale from 1 to 7.',
    primaryColor: '#FFB7D8',
  },
  {
    title: 'PLF',
    accessor: 'general_plf',
    tooltipText:
      'The percentage of respondents who said they would see the film on a premium large-format screen at a higher price.',
    primaryColor: '#D49CFC',
  },
]
