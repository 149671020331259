import { useState, useMemo } from 'react'

export type ToggleHookStateControls<T = unknown> = {
  toggle: (data?: T) => void
  activate: (data?: T) => void
  deactivate: () => void
  setValue: React.Dispatch<React.SetStateAction<ToggleHookState<T>>>
  reset: () => void
}

export type ToggleHookState<T = undefined> = {
  active: boolean
  data?: T
}

type UseToggleHookResult<T = undefined> = [
  ToggleHookState<T>,
  ToggleHookStateControls<T>,
]

export const useToggle = <T = unknown>(
  initialState: ToggleHookState<T> = { active: false },
): UseToggleHookResult<T> => {
  const initialStateMemoized = useMemo(() => initialState, [])
  const [state, setState] = useState<ToggleHookState<T>>(initialState)

  const controls = useMemo<ToggleHookStateControls<T>>(
    () => ({
      toggle: (data?: T) => {
        setState(prev => ({
          active: !prev.active,
          data: !prev.active ? (data ?? prev.data) : undefined,
        }))
      },
      activate: (data?: T) => setState({ active: true, data }),
      deactivate: () => setState({ active: false, data: undefined }),
      setValue: newValue =>
        setState(prev =>
          typeof newValue === 'function' ? newValue(prev) : newValue,
        ),
      reset: () => setState(initialStateMemoized),
    }),
    [initialStateMemoized],
  )

  return [state, controls]
}
