import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  displayLabel?: boolean
  classCheckbox?: string
  wrapperClassName?: string
  classLabel?: string
  hasError?: boolean
  isNotAllChecked?: boolean
  kind?: 'small' | 'medium'
}

export const CheckboxInner = (
  {
    id,
    checked = false,
    classCheckbox,
    classLabel,
    isNotAllChecked,
    wrapperClassName,
    hasError,
    disabled,
    label,
    kind = 'medium',
    ...props
  }: CheckboxProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) => {
  return (
    <div className={twMerge(wrapperClassName)}>
      <label
        htmlFor={id}
        className={twMerge(
          'flex items-center gap-2 text-xs text-primary-grey hover:cursor-pointer',
          disabled ? 'cursor-default opacity-50' : '',
          classLabel,
        )}
      >
        <input
          ref={inputRef}
          type='checkbox'
          id={id}
          checked={checked}
          className={twMerge(
            `relative box-border h-4 w-4 
          cursor-pointer appearance-none 
          border border-primary-black/20 bg-primary-white    
          hover:border-primary-black/40 hover:bg-primary-black/5 
          disabled:cursor-default disabled:border-primary-black/20 disabled:bg-primary-white `,
            hasError && 'border-red-6',
            classCheckbox,
            isNotAllChecked
              ? 'after:absolute after:left-1 after:right-1 after:top-1/2 after:h-0.5 after:-translate-y-1/2 after:bg-grey-7 after:content-[""]'
              : 'checked:border-none checked:bg-primary-red checked:after:absolute checked:after:left-1/2 checked:hover:bg-red-9 checked:active:bg-red-10 checked:disabled:bg-primary-grey',
            "checked:after:top-1/2 checked:after:h-1 checked:after:w-1 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:rounded-full checked:after:bg-white checked:after:content-['']",
            kind === 'small' && 'h-3 w-3 checked:after:h-1 checked:after:w-1',
          )}
          disabled={disabled}
          {...props}
        />

        {label}
      </label>
    </div>
  )
}

export const Checkbox = forwardRef(CheckboxInner)
