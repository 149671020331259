import { BaseModal } from 'components'
import {
  ReleaseCalendarEventForm,
  type ReleaseCalendarEventFormData,
} from '../ReleaseCalendarEventForm'
import { ToggleHookState, ToggleHookStateControls } from 'hooks'
import { useCallback } from 'react'
import { useReleaseCalendarControllerPutEventMutation } from 'store/api'
import { ReleaseCalendarEvent } from '../../types'

interface SportingEventEditDialogProps {
  dialogState: ToggleHookState<ReleaseCalendarEvent>
  dialogStateControls: ToggleHookStateControls<ReleaseCalendarEvent>
}

export const SportingEventEditDialog = ({
  dialogState,
  dialogStateControls,
}: SportingEventEditDialogProps) => {
  const [updateEvent] = useReleaseCalendarControllerPutEventMutation()

  const { id: eventId } = dialogState.data ?? {}

  const handleSubmit = useCallback(
    async ({ title, startDate, endDate }: ReleaseCalendarEventFormData) => {
      if (!eventId) {
        return
      }
      try {
        await updateEvent({
          eventId,
          updateEventDto: { title, startDate, endDate },
        })
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [eventId, updateEvent, dialogStateControls],
  )

  return (
    <>
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'Edit Sporting Event Details'}
        maxWidth='450px'
      >
        <ReleaseCalendarEventForm
          submitButtonLabel='Save'
          defaultValues={dialogState.data}
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
