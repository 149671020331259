import React, { forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { LabeledInput } from './LabelInput'

interface HeightInputProps {
  onChange?: (height: { feet: number; inches: number }) => void
  label: string
  isError?: boolean
  error?: string
  errorClassName?: string
  value?: { feet: number; inches: number }
}

const HeightInputInner = ({
  onChange,
  label,
  error,
  isError,
  errorClassName,
  value,
}: HeightInputProps) => {
  const [feet, setFeet] = useState<number>(value ? value.feet : 0)
  const [inches, setInches] = useState<number>(
    value ? Number(value.inches.toFixed(0)) : 0,
  )

  const handleFeetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0
    setFeet(value)
    onChange?.({ feet: value, inches })
  }

  const handleInchesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0
    setInches(value)
    onChange?.({ feet, inches: value })
  }

  return (
    <div className='items-left flex w-full flex-col gap-4'>
      {label && (
        <label
          htmlFor='input-label'
          className={`pb-1 text-sm font-semibold text-primary-black`}
        >
          {label}
        </label>
      )}

      <div className='flex w-full  items-center gap-4'>
        <LabeledInput value={feet} label='Feet' onChange={handleFeetChange} />
        <LabeledInput
          value={inches}
          label='Inches'
          onChange={handleInchesChange}
        />
      </div>
      {isError && error && (
        <span
          className={twMerge(
            'pt-1.5 text-xs leading-4 text-red-500',
            errorClassName,
          )}
        >
          {error}
        </span>
      )}
    </div>
  )
}

export const HeightInput = forwardRef(HeightInputInner)
