import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import dayjs from 'dayjs'
import { dateToUTC } from 'utils'
import { isBefore, isSameDay } from 'date-fns'

const requiredString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)

const requiredDateString = (fieldName: string) =>
  requiredString(fieldName)
    .refine(str => /^\d{2}\/\d{2}\/\d{4}$/.test(str), {
      message: `${fieldName} must be in mm/dd/yyyy format`,
    })
    .refine(
      str => {
        const date = dayjs(str, 'MM/DD/YYYY', true)
        return date.isValid() && date.format('MM/DD/YYYY') === str
      },
      {
        message: `${fieldName} is not valid (entered date does not exist)`,
      },
    )
    .transform(str =>
      dateToUTC(dayjs(str, 'MM/DD/YYYY', true).toDate()).toISOString(),
    )

export const releaseCalendarEventFormSchema = z
  .object({
    title: requiredString('Event Title'),
    startDate: requiredDateString('Start Date'),
    endDate: requiredDateString('End Date').nullable().optional(),
    isOneDayEvent: z.boolean().optional(),
    isEndDateUnknown: z.boolean().optional(),
  })
  .superRefine(
    ({ isOneDayEvent, isEndDateUnknown, endDate, startDate }, ctx) => {
      if (!(isOneDayEvent || isEndDateUnknown) && !endDate?.trim()) {
        ctx.addIssue({
          code: 'custom',
          path: ['endDate'],
          message: 'End Date is required',
        })
      } else if (
        endDate &&
        startDate &&
        !isBefore(startDate, endDate) &&
        !isSameDay(startDate, endDate)
      ) {
        ctx.addIssue({
          code: 'custom',
          path: ['endDate'],
          message: 'End Date can not be before Start Date',
        })
      }
    },
  )

export const releaseCalendarEventFormResolver = zodResolver(
  releaseCalendarEventFormSchema,
)

export type ReleaseCalendarEventFormData = z.infer<
  typeof releaseCalendarEventFormSchema
>
