import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { useMemo } from 'react'
import { releaseCalendarMgmtActions } from '../slices'

export const useReleaseCalendarMgmtActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(releaseCalendarMgmtActions, dispatch),
    [dispatch],
  )
}
