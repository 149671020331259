import { FormEventHandler, ReactNode } from 'react'
import { Button } from 'components'
import { useFormContext } from 'react-hook-form'

interface MgmtEntryFormProps {
  children: ReactNode
  submitButtonLabel: string
  cancelButtonLabel?: string
  onSubmit: FormEventHandler<HTMLFormElement>
  onClose: () => void
  footerClassName?: string
}

export const MgmtEntryForm = ({
  children,
  submitButtonLabel,
  cancelButtonLabel = 'Cancel',
  onSubmit,
  onClose,
}: MgmtEntryFormProps) => {
  const { formState } = useFormContext()

  return (
    <form onSubmit={onSubmit}>
      <div className='space-y-4 py-6'>{children}</div>

      <div className='flex justify-end gap-2'>
        <Button
          kind='text'
          size='medium'
          onClick={onClose}
          className='py-2.5'
          type='button'
        >
          <span className='px-1 text-sm font-semibold'>
            {cancelButtonLabel}
          </span>
        </Button>

        <Button
          kind='filled'
          size='medium'
          className='bg-primary-red py-2.5 hover:bg-red-9 active:bg-primary-red/50'
          type='submit'
          loading={formState.isSubmitting}
        >
          <span className='px-2 text-sm font-semibold'>
            {submitButtonLabel}
          </span>
        </Button>
      </div>
    </form>
  )
}
