import { twMerge } from 'tailwind-merge'
import {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useMemo,
} from 'react'
import { useClickOutsideComponent } from 'hooks'
import { OptionType } from 'types'
import { SelectMenu } from './SelectMenu'
import { SelectSearchMenu } from 'components'
import { ChevronDown } from 'components/ui/icons'
import { Colors } from 'constnants'

type OptionValueConstraint = string | number | undefined
interface SelectProps<TOptionTypeValue extends OptionValueConstraint = string>
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: TOptionTypeValue | undefined
  handleClick: (option: OptionType<TOptionTypeValue>) => void
  options?: OptionType<TOptionTypeValue>[]
  placeholder?: string
  disabled?: boolean
  isError?: boolean
  error?: string
  label?: string
  id?: string
  containerClass?: string
  buttonClass?: string
  wrapperOptionClass?: string
  optionClass?: string
  labelClass?: string
  errorClass?: string
  leftIcon?: ReactNode
  leftIconClass?: string
  hasSearch?: boolean
  placeholderSearch?: string
  selectedLabelClass?: string
  size?: 'small' | 'medium'
  menuPosition?: 'top' | 'bottom'
  hasRadioButton?: boolean
  hasAdditionalValue?: boolean
  additionalValueClassName?: string
  alignItems?: 'center' | 'start'
  optionsHidden?: TOptionTypeValue[]
  withSeparator?: boolean
  separatorClass?: string
  formatActiveLabel?: (option: OptionType<TOptionTypeValue> | undefined) => void
}

const SelectInner = <TOptionTypeValue extends OptionValueConstraint>(
  {
    value,
    handleClick,
    options = [],
    placeholder,
    disabled,
    isError,
    error,
    label,
    id,
    containerClass,
    buttonClass,
    wrapperOptionClass,
    errorClass,
    optionClass,
    leftIcon,
    leftIconClass,
    labelClass,
    hasSearch = false,
    placeholderSearch,
    selectedLabelClass,
    menuPosition = 'bottom',
    hasRadioButton,
    size,
    hasAdditionalValue,
    additionalValueClassName,
    alignItems,
    optionsHidden,
    withSeparator = false,
    separatorClass,
    formatActiveLabel,
    ...rest
  }: SelectProps<TOptionTypeValue>,
  buttonRef: ForwardedRef<HTMLButtonElement>,
) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickOutsideComponent(false)

  const buttonStyles = twMerge(
    'flex w-full bg-primary-white px-2 hover:bg-primary-black/5 rounded border duration-300',
    disabled
      ? 'cursor-not-allowed border-primary-dark/10 bg-primary-black/[3%] hover:border-primary-black/10 hover:bg-primary-black/[3%]'
      : 'cursor-pointer border border-primary-black/10 bg-primary-white',
    isComponentVisible && 'focus:border-primary-black/80',
    isError &&
      'border-red-8 bg-red-2 hover:border-red-8 hover:bg-red-2 focus:border-red-8',
    size === 'small' ? 'py-0.5' : 'py-1',
    buttonClass,
  )

  const wrapperOptionStyles = twMerge(
    'absolute w-max border bg-primary-white',
    isComponentVisible && 'border-primary-black/80 z-20',
    options?.length > 5 && 'max-h-52 overflow-y-auto',
    menuPosition === 'bottom' ? 'top-[105%]' : 'bottom-[105%]',
    wrapperOptionClass,
  )

  const selectedOption = useMemo(
    () => options && options.find(option => option.value === value),
    [options, value],
  )

  const selectedLabel =
    typeof formatActiveLabel === 'function'
      ? formatActiveLabel(selectedOption)
      : selectedOption?.label

  const handleOptionClick = (option: OptionType<TOptionTypeValue>) => {
    handleClick(option)
    setIsComponentVisible(false)
  }

  return (
    <div className={twMerge('flex flex-col', containerClass)}>
      {label && (
        <label htmlFor={id} className={twMerge('pb-1', labelClass)}>
          {label}
        </label>
      )}

      <div ref={ref} className='relative'>
        <button
          {...rest}
          ref={buttonRef}
          id={id}
          type='button'
          disabled={disabled}
          onClick={() => setIsComponentVisible(prev => !prev)}
          className={buttonStyles}
        >
          <div className='flex w-full items-center justify-between'>
            {leftIcon && (
              <div className={twMerge(leftIconClass)}>
                <div>{leftIcon}</div>
              </div>
            )}

            <span
              className={twMerge(
                'px-0.5 text-xs font-semibold text-primary-grey',
                size === 'small' ? 'leading-4' : 'leading-6',
                placeholder && 'text-primary-grey',
                hasSearch && selectedLabel && selectedLabelClass,
                selectedLabelClass,
              )}
            >
              {selectedLabel ?? placeholder}
            </span>
            {withSeparator && (
              <span
                className={twMerge(
                  'absolute right-7 top-0 h-full w-[1px] bg-primary-black/10',
                  separatorClass,
                )}
              ></span>
            )}
            <ChevronDown fill={Colors.BASE_ICON} width={20} height={20} />
          </div>
        </button>

        {!hasSearch ? (
          <SelectMenu
            options={options}
            handleClick={handleOptionClick}
            wrapperOptionStyles={wrapperOptionStyles}
            optionClass={optionClass}
            isOpen={isComponentVisible}
            isError={isError}
            selectedValue={value}
            hasRadioButton={hasRadioButton}
            alignItems={alignItems}
            optionsHidden={optionsHidden}
          />
        ) : (
          <SelectSearchMenu
            options={options}
            handleClick={handleOptionClick}
            wrapperOptionStyles={wrapperOptionStyles}
            optionClass={optionClass}
            isOpen={isComponentVisible}
            isError={isError}
            placeholderSearch={placeholderSearch}
            additionalValueClassName={additionalValueClassName}
            hasAdditionalValue={hasAdditionalValue}
          />
        )}
      </div>

      {isError && error && (
        <p
          className={twMerge(
            'flex pt-1 text-red-8 duration-500 ease-in',
            isComponentVisible && 'opacity-0 duration-100',
            errorClass,
          )}
        >
          {error}
        </p>
      )}
    </div>
  )
}

SelectInner.displayName = 'Select'

export const Select = forwardRef(SelectInner) as <
  TOptionTypeValue extends OptionValueConstraint,
>(
  props: SelectProps<TOptionTypeValue> & {
    ref?: ForwardedRef<HTMLButtonElement>
  },
) => ReturnType<typeof SelectInner>
