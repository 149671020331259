import { TvFilmCompanyDetails, CompanyType, DistributorType } from 'store/api'

export {
  CompanyType as TVFilmCompanyType,
  DistributorType as TVFilmDistributorType,
}

export type TVFilmCompanyData = TvFilmCompanyDetails

export enum TVFilmCompanyColKey {
  Name = 'name',
  Abbr = 'abbr',
  ImdbId = 'imdbId',
  CompanyType = 'companyType',
  DistributorType = 'distributorType',
  EditEntry = 'editEntry',
}

export type TVFilmCompanySortColKey =
  | TVFilmCompanyColKey.Name
  | TVFilmCompanyColKey.CompanyType
  | TVFilmCompanyColKey.DistributorType

export type TVFilmDistributorTypeFilterValue = DistributorType | 'all'

export type TVFilmCompanyTypeFilterValue = CompanyType | 'all'
