import React, { ChangeEvent, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Avatar } from '../Avatar'
import { CheckCircle, Pencil } from 'components/ui/icons'
import { Colors } from 'constnants'
import { Input } from '../ui'
import { EditPasswordModal } from '../EditPasswordModal'
import { useGetCurrentUserQuery, useUpdateUserProfileMutation } from 'store/api'
import { useAuth } from '../../hooks/auth/useAuth'
import { getUserName } from 'utils'

const regExpEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validateEmail = (email: string) => ({
  validEmail: regExpEmail.test(String(email.toLowerCase())),
})

export const EditCurrentUser = () => {
  const { auth: authUser } = useAuth()
  const { data: user, error } = useGetCurrentUserQuery()
  const [updateUserProfile] = useUpdateUserProfileMutation()
  const [userName, setUserName] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')
  const [editField, setEditField] = useState<string>('')
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')
  const [validation, setValidation] = useState({
    validEmail: true,
  })
  const [inputValues, setInputValues] = useState({
    name: userName,
    email: userEmail,
  })

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof typeof inputValues,
  ) => {
    const { value } = e.target
    setInputValues(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSave = async (field: string) => {
    if (field === 'Name') {
      setUserName(inputValues.name)
    } else if (field === 'Email') {
      setUserEmail(inputValues.email)
    }

    try {
      await updateUserProfile({
        email: inputValues.email,
        name: inputValues.name,
        firstName: inputValues.name.split(' ')[0],
        lastName: inputValues.name.split(' ')[1],
      }).unwrap()
      setSuccessMessage(field)
      setEditField('')
      setShowSuccessMessage(true)
      setTimeout(() => {
        setShowSuccessMessage(false)
        setSuccessMessage('')
      }, 3000)
    } catch (error) {
      console.error('Error updating user:', error)
    }
  }

  useEffect(() => {
    setValidation(prevState => ({
      ...prevState,
      ...validateEmail(inputValues.email),
    }))
  }, [inputValues])

  useEffect(() => {
    if (user) {
      const name = getUserName(user.name, user.first_name, user.last_name)
      setUserName(name)
      setUserEmail(user.email)
      setInputValues({
        name: name,
        email: user.email,
      })
    }
  }, [user])

  if (error) {
    return <div>Api response error</div>
  }

  return (
    <div
      className={twMerge(
        'relative flex h-[90vh] w-[90vw] flex-col lg:h-[70vh] lg:w-[70vw] lg:flex-row',
      )}
    >
      <div className='w-full bg-grey-3 p-2 lg:w-52'>
        <Avatar initialsSize='md' justify={'left'} className={'mb-2'} />
        <p className='px-[10px] text-lg font-medium'>Your Settings</p>
        <ul className='mt-6'>
          <li className='rounded bg-primary-black/10 px-[10px] py-0.5'>
            Your Profile
          </li>
        </ul>
      </div>
      <div className='w-auto max-w-full grow overflow-auto bg-primary-white px-7 py-6'>
        <div className='flex justify-between'>
          <h1 className='text-2xl font-semibold text-primary-black'>
            {userName}
          </h1>
        </div>
        <div className='mt-8 rounded border border-primary-black/20'>
          <div className='flex items-center border-b px-4 py-3'>
            <p className='text-md w-24 font-medium lg:w-48'>Name</p>
            <div className='flex flex-grow items-center justify-between'>
              {editField === 'name' ? (
                <>
                  <Input
                    containerWrapperClassName='pr-5'
                    wrapperClassName='w-full h-8 rounded-md'
                    inputClassName='pl-3 text-md py-2'
                    value={inputValues.name}
                    onChange={e => handleInputChange(e, 'name')}
                  />
                  <button
                    className='px-[10px] text-xs font-semibold hover:text-primary-red'
                    onClick={() => {
                      setInputValues({
                        name: userName,
                        email: userEmail,
                      })
                      setEditField('')
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className='rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white'
                    onClick={() => {
                      handleSave('Name')
                    }}
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <p className='text-md px-3 py-1'>{userName}</p>
                  <div
                    className='flex items-center pl-8 pr-4 hover:cursor-pointer'
                    onClick={() => setEditField('name')}
                  >
                    <Pencil
                      fill={Colors.BASE_ICON}
                      width={16}
                      height={16}
                      className='hover:cursor-pointer'
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='text-md w-24 font-medium lg:w-48'>Email</p>
            <div className='flex flex-grow items-center justify-between'>
              {editField === 'email' ? (
                <>
                  <Input
                    containerWrapperClassName='pr-5'
                    wrapperClassName='w-full h-8 rounded-md'
                    inputClassName='pl-3 text-md py-2'
                    value={inputValues.email}
                    onChange={e => handleInputChange(e, 'email')}
                  />
                  <button
                    className='px-[10px] text-xs font-semibold hover:text-primary-red'
                    onClick={() => {
                      setInputValues({
                        name: userName,
                        email: userEmail,
                      })
                      setEditField('')
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={twMerge(
                      'rounded-full bg-primary-red px-[10px] py-1 text-xs font-semibold text-primary-white',
                      !validation?.validEmail && 'disabled:bg-gray-500',
                    )}
                    onClick={() => {
                      handleSave('Email')
                    }}
                    disabled={!validation?.validEmail}
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <p className='text-md px-3 py-1'>{userEmail}</p>
                  <div
                    className='flex items-center pl-8 pr-4 hover:cursor-pointer'
                    onClick={() => setEditField('email')}
                  >
                    <Pencil
                      fill={Colors.BASE_ICON}
                      width={16}
                      height={16}
                      className='hover:cursor-pointer'
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className='flex items-center p-3 px-4'>
            <p className='text-md w-24 font-medium lg:w-48'>Password</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='text-md px-3 py-1'>••••••••</p>
              <div
                className='flex items-center pl-8 pr-4 hover:cursor-pointer'
                onClick={() => setEditField('password')}
              >
                <Pencil
                  fill={Colors.BASE_ICON}
                  width={16}
                  height={16}
                  className='hover:cursor-pointer'
                />
              </div>
              <EditPasswordModal
                title={'Change your password'}
                description={
                  'You’ll be logged out after making the change. Use your new password to log back in.'
                }
                isOpen={editField === 'password'}
                handleClose={() => setEditField('')}
                onSubmit={() => {
                  handleSave('Password')
                }}
                modalZIndex={900}
                saveTitle={'Confirm'}
              />
            </div>
          </div>
          <div className='flex items-center border-b p-3 px-4'>
            <p className='text-md w-24 font-medium lg:w-48'>Email Verified</p>
            <div className='flex flex-grow items-center justify-between'>
              <p className='text-md px-3 py-1'>
                {authUser.userAttributes.email_verified === 'true' &&
                authUser.isLoading === false
                  ? 'Yes'
                  : 'No'}
              </p>
            </div>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div className='absolute bottom-4 right-4 rounded-md bg-primary-white p-3 shadow-md'>
          <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
            <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />{' '}
            {successMessage} updated
          </p>
        </div>
      )}
    </div>
  )
}
