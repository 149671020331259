import { ReleaseCalendarRevenueColKey } from '../../types'
import { type ReleaseCalendarRevenueCol } from './types'

export const columns: ReleaseCalendarRevenueCol[] = [
  {
    key: ReleaseCalendarRevenueColKey.Date,
    sortKey: ReleaseCalendarRevenueColKey.Date,
    title: 'Date',
  },
  {
    key: ReleaseCalendarRevenueColKey.Week,
    title: 'Week',
  },
  {
    key: ReleaseCalendarRevenueColKey.Revenue,
    sortKey: ReleaseCalendarRevenueColKey.Revenue,
    title: 'Revenue',
  },

  { key: ReleaseCalendarRevenueColKey.EditEntry, width: 48 },
]
