import { Search } from 'components'
import { Colors } from 'constnants'

export const MgmtTableNoData = () => {
  return (
    <div className='mt-3 flex flex-1 flex-col items-center justify-center'>
      <Search fill={Colors.GRAY_600} width={48} height={48} />
      <div className='mt-4 text-center text-primary-black'>
        <p className='text-lg font-medium'>No results found</p>
        <p className='mt-1.5 text-sm'>
          Please adjust your filters and try again.
        </p>
      </div>
    </div>
  )
}
