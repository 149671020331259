import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  BaseModal,
  Button,
  FilterButton,
  FilterColumnGroup,
  Select,
} from 'components'
import {
  useInviteUserMutation,
  useInviteManagementUserMutation,
} from 'store/api'
import { Input } from '../ui'
import { ChevronDown } from '../ui/icons'
import { Colors } from '../../constnants'
import { OptionFilter, InviteUserModalProps, NewUserData } from '../../types'
import { toastError } from '../../utils'
import { useAuthContext } from '../../context'

const initialNewUserData: NewUserData = {
  fullName: '',
  email: '',
  userType: '',
  accessLevel: '',
  moduleAccess: ['2', '3', '4', '5', '6', '7', '8'],
  company: '',
}

const regExpEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validateEmail = (email: string) => ({
  validEmail: regExpEmail.test(String(email.toLowerCase())),
})
const accessLevelOptions = [
  [
    {
      id: '1',
      value: 'editor',
      label: 'Editor',
    },
    {
      id: '2',
      value: 'viewer',
      label: 'Viewer',
    },
  ],
  [
    {
      id: '1',
      value: 'superadmin',
      label: 'Organization Owner',
    },
    {
      id: '2',
      value: 'moduleadmin',
      label: 'Department Owner',
    },
  ],
]

const modulesList: OptionFilter[] = [
  {
    id: 'talent',
    label: 'Talent',
    value: '2',
    isDisabled: true,
  },
  {
    id: 'comp_finder',
    label: 'Comp Finder',
    value: '3',
    isDisabled: true,
  },
  {
    id: 'release_calender',
    label: 'Release Calendar',
    value: '4',
    isDisabled: true,
  },
  {
    id: 'film_tracker',
    label: 'Film Tracker',
    value: '5',
    isDisabled: true,
  },
  {
    id: 'concept_testing',
    label: 'Concept Testing',
    value: '6',
  },
  {
    id: 'role_testing',
    label: 'Role Testing',
    value: '7',
  },
  {
    id: 'asset_testing',
    label: 'Asset Testing',
    value: '8',
  },
]

const userTypeOptionsDefault = [
  {
    id: '1',
    value: 'standard',
    label: 'Standard',
  },
  {
    id: '2',
    value: 'admin',
    label: 'Admin',
  },
]

export const InviteUserModal = ({
  isOpen,
  handleClose,
  title,
  description,
  onSubmit,
  cancelTitle = 'Cancel',
  saveTitle = 'Invite',
  maxWidth,
  modalZIndex,
  requestType = 'inviteUser',
  companyOptions,
}: InviteUserModalProps) => {
  const userAuthContext = useAuthContext()
  const companyId =
    userAuthContext?.auth?.userAttributes?.['custom:company_id'] || ''
  const [inviteUser] = useInviteUserMutation()
  const [inviteManagementUser] = useInviteManagementUserMutation()

  const [newUserData, setNewUserData] = useState(initialNewUserData)
  const [validation, setValidation] = useState({
    validEmail: false,
    validName: false,
    userType: false,
    accessLevel: false,
    company: false,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  useEffect(() => {
    setValidation({
      ...validateEmail(newUserData.email),
      validName: newUserData.fullName.length > 0,
      userType: newUserData.userType.length > 0,
      accessLevel: newUserData.accessLevel.length > 0,
      company:
        requestType === 'addUserToCompany'
          ? newUserData.company.length > 0
          : true,
    })
  }, [newUserData])

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target
    setNewUserData(prevState => ({ ...prevState, [key]: value }))
  }

  const handleSelect = (
    option: { id: string; value: string; label: string },
    key: string,
  ) => {
    setNewUserData(prevState => ({ ...prevState, [key]: option.value }))
    if (key === 'userType') {
      setNewUserData(prevState => ({ ...prevState, accessLevel: '' }))
    }
  }

  const isValidation = Object.values(validation).every(value => value)
  const validateForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isValidation) {
      setShowError(true)
      return
    }
    setIsLoading(true)
    try {
      if (requestType === 'inviteUser') {
        const data = await inviteUser({
          firstName: newUserData.fullName.split(' ')[0],
          lastName: newUserData.fullName.split(' ')[1],
          name: newUserData.fullName,
          email: newUserData.email,
          userType: newUserData.userType,
          accessLevel: newUserData.accessLevel,
          companyId,
          moduleAccessIds: newUserData.moduleAccess.map(module =>
            Number(module),
          ),
        })
        if (data.error) {
          const { error, data: errorData } = data.error as {
            data?: any
            error?: string
          }
          toastError(errorData, error)
          return
        }
        onSubmit()
      } else if (requestType === 'addUserToCompany') {
        const data = await inviteManagementUser({
          firstName: newUserData.fullName.split(' ')[0],
          lastName: newUserData.fullName.split(' ')[1],
          name: newUserData.fullName,
          email: newUserData.email,
          userType: newUserData.userType,
          accessLevel: newUserData.accessLevel,
          moduleAccessIds: newUserData.moduleAccess.map(module =>
            Number(module),
          ),
          companyId: newUserData.company ?? '',
        })
        if (data.error) {
          const { error, data: errorData } = data.error as {
            data?: any
            error?: string
          }
          toastError(errorData, error)
          return
        }
        onSubmit()
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheckboxChange = (selectedProjects: string[], key: string) => {
    setNewUserData(prevState => ({ ...prevState, [key]: selectedProjects }))
  }

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      titleClass='pb-2'
      maxWidth={maxWidth}
      modalZIndex={modalZIndex}
      // wrapperClass='overflow-auto max-h-[90vh]'
    >
      {description && <div className={'mb-5'}>{description}</div>}
      <form onSubmit={e => validateForm(e)}>
        {requestType === 'addUserToCompany' && companyOptions && (
          <div className='pb-4'>
            <Select
              label={'Company'}
              labelClass={'pb-1 text-sm font-semibold text-primary-black'}
              value={newUserData.company || ''}
              handleClick={option => handleSelect(option, 'company')}
              options={companyOptions}
              size='small'
              alignItems='start'
              buttonClass='h-8 items-center whitespace-nowrap'
              hasRadioButton={false}
              hasSearch={false}
              isError={showError && !validation.company}
              error={'Company is required'}
              errorClass={'pt-1.5 text-xs'}
            />
          </div>
        )}
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='text'
          label='Full Name'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'Full Name is required'}
          isError={showError && !validation.validName}
          onChange={e => handleChange(e, 'fullName')}
        />
        <Input
          containerWrapperClassName='pb-4'
          inputClassName='h-8 px-3'
          type='email'
          label='Email Address'
          labelClassName='text-sm font-semibold text-primary-black'
          error={'Invalid email address format'}
          isError={showError && !validation.validEmail}
          onChange={e => handleChange(e, 'email')}
        />

        <div className={'flex gap-1 pb-4'}>
          <Select
            label={'User Type'}
            id={'userType'}
            labelClass={'pb-1 text-sm font-semibold text-primary-black'}
            containerClass={'w-1/2'}
            buttonClass='h-8 items-center'
            value={newUserData.userType}
            handleClick={option => {
              handleSelect(option, 'userType')
            }}
            options={userTypeOptionsDefault}
            size='small'
            placeholder='User Type'
            defaultValue={'all'}
            hasRadioButton={false}
            isError={showError && !validation.userType}
            error={'User Type is required'}
            errorClass={'pt-1.5 text-xs'}
          />

          <Select
            label={'Access Level'}
            id={'accessLevel'}
            labelClass={'pb-1 text-sm font-semibold text-primary-black'}
            containerClass='w-1/2'
            disabled={newUserData.userType === ''}
            value={newUserData.accessLevel}
            handleClick={option => {
              handleSelect(option, 'accessLevel')
            }}
            buttonClass='h-8 items-center'
            options={
              newUserData.userType === 'standard'
                ? accessLevelOptions[0]
                : accessLevelOptions[1]
            }
            size='small'
            placeholder='Access Level'
            defaultValue={'all'}
            hasRadioButton={false}
            isError={showError && !validation.accessLevel}
            error={'Access Level is required'}
            errorClass={'pt-1.5 text-xs'}
          />
        </div>

        <div className='pb-4'>
          <p className='pb-1 text-sm font-semibold text-primary-black'>
            Module Access
          </p>
          <FilterButton
            name={
              newUserData.moduleAccess.length - 4 === 1
                ? '1 Module'
                : newUserData.moduleAccess.length - 4 > 1
                  ? newUserData.moduleAccess.length - 4 + ' Modules'
                  : ''
            }
            containerMenuClassName='left-0'
            nameCLassName='font-normal text-sm h-6 items-center inline-flex'
            isMenuVisible={false}
            leftIcon={false}
            disabled
            rightIcon={
              <ChevronDown
                fill={Colors.BASE_ICON}
                width={18}
                height={18}
                className='ml-auto pl-1'
              />
            }
            totalSelectedFilters={0}
            containerClassName='w-full'
            wrapperClasName='w-full border-primary-black/20'
            wrapperMenuClassName='pr-0'
            hideTriangle={true}
          >
            <div className='flex max-h-[400px] flex-row gap-10 overflow-auto pr-5'>
              {
                <FilterColumnGroup
                  key={'moduleAccess'}
                  category={'moduleAccess'}
                  optionsFilter={modulesList}
                  isSelect={false}
                  selectedFilters={{ moduleAccess: newUserData.moduleAccess }}
                  onChange={selected => {
                    handleCheckboxChange(
                      selected['moduleAccess'],
                      'moduleAccess',
                    )
                  }}
                  allLabel={'Select All'}
                />
              }
            </div>
          </FilterButton>
          <p className='text-xs text-primary-grey'>
            All users always have access to Talent, Comp Finder, Release
            Calendar, and Film Tracking modules.
          </p>
        </div>

        <div className='flex justify-end gap-2'>
          <Button
            kind='text'
            size='medium'
            onClick={handleClose}
            className='py-2.5'
            type={'button'}
          >
            <span className='px-1 text-sm font-semibold'>{cancelTitle}</span>
          </Button>

          <Button
            kind='filled'
            size='medium'
            className='bg-primary-red py-2.5 hover:bg-red-9 active:bg-primary-red/50'
            type='submit'
            loading={isLoading}
          >
            <span className='px-2 text-sm font-semibold'>{saveTitle}</span>
          </Button>
        </div>
      </form>
    </BaseModal>
  )
}
