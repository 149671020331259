import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import { EyeSlash } from 'components/ui/icons'
import { Colors } from 'constnants'
import {
  handlePasswordChange,
  sendEmailVerificationCode,
} from 'utils/amplify/auth-amplify'
import { ValidationIndicator } from 'components/ValidationIndicator'

const schema = z
  .object({
    password: z.string().min(1, 'Password is required'),
    confirmPassword: z.string(),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    const isPasswordValid = Object.values(validatePassword(password)).every(
      value => value,
    )

    if (isPasswordValid && password !== confirmPassword) {
      ctx.addIssue({
        code: 'custom',
        path: ['confirmPassword'],
        message: 'Passwords do not match',
      })
    }
  })

type PasswordChangeFormData = z.infer<typeof schema>

const validatePassword = (password: string) => ({
  hasLowercase: /[a-z]/.test(password),
  hasUppercase: /[A-Z]/.test(password),
  hasNumber: /\d/.test(password),
  hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  hasMinLength: password.length >= 8,
})

export const PasswordChange = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordChangeFormData>({
    resolver: zodResolver(schema),
  })
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()

  const password = watch('password', '')
  const validation = validatePassword(password)

  const onSubmit = async (data: PasswordChangeFormData) => {
    const { nextStep, isSignedIn } = await handlePasswordChange(data.password)
    if (isSignedIn) {
      sendEmailVerificationCode().then(() => navigate('/verify-email'))
    }
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className='flex h-[96vh] items-center justify-center'>
      <div className='flex flex-col items-center justify-center'>
        <div className='w-[400px] max-w-[90%] rounded-lg bg-white p-5'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className='text-center text-2xl font-semibold'>
              Password Change
            </h2>
            <div className='mt-4 flex flex-col'>
              <div className='mb-4'>
                <label htmlFor='password' className='mb-1 block font-semibold'>
                  New Password
                </label>
                <input
                  id='password'
                  className={`w-full rounded-md border p-1 ${errors.password ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                  {...register('password')}
                  placeholder='Minimum 8 characters'
                  autoComplete='off'
                />
                {errors.password && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.password.message?.toString()}
                  </p>
                )}

                <div className='pt-4'>
                  <ValidationIndicator
                    isValid={validation.hasLowercase}
                    message='One lowercase character'
                  />
                  <ValidationIndicator
                    isValid={validation.hasUppercase}
                    message='One uppercase character'
                  />
                  <ValidationIndicator
                    isValid={validation.hasNumber}
                    message='One number'
                  />
                  <ValidationIndicator
                    isValid={validation.hasSpecialChar}
                    message='One special character'
                  />
                  <ValidationIndicator
                    isValid={validation.hasMinLength}
                    message='8 characters minimum'
                  />
                </div>
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='confirmPassword'
                  className='mb-1 block font-semibold'
                >
                  Confirm Password
                </label>
                <div className='relative w-full'>
                  <input
                    id='confirmPassword'
                    autoComplete='off'
                    type={showPassword ? 'text' : 'password'}
                    className={`w-full rounded-md border p-1 ${errors.confirmPassword ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                    placeholder='Confirm Password'
                    {...register('confirmPassword')}
                  />

                  <button
                    type='button'
                    className='absolute right-2 top-2.5 transform cursor-pointer border-none bg-none focus:outline-none'
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <EyeSlash
                        fill={Colors.BASE_ICON}
                        width={16}
                        height={16}
                      />
                    ) : (
                      <EyeSlash
                        fill={Colors.BASE_ICON}
                        width={16}
                        height={16}
                      />
                    )}
                  </button>
                </div>
                {errors.confirmPassword && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.confirmPassword.message?.toString()}
                  </p>
                )}
              </div>

              <button
                type='submit'
                className='mt-4 w-full rounded-full bg-red-500 px-4 py-2 text-white'
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
