import { twMerge } from 'tailwind-merge'

interface LabeledInputProps {
  value: number
  label: string
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  isError?: boolean
}

export const LabeledInput = ({
  value,
  label,
  onChange,
  isError,
}: LabeledInputProps) => {
  return (
    <div
      className={twMerge(
        'flex overflow-hidden rounded-md border border-gray-300',
        isError && 'border-red-8 bg-red-2 hover:border-red-8',
      )}
    >
      <input
        id='input-label'
        type='text'
        value={value}
        onChange={onChange}
        className='w-16 p-2 pl-3 text-left focus:outline-none focus:ring-2 focus:ring-blue-500'
      />
      <span className='flex items-center bg-gray-100 px-3 py-1 text-gray-700'>
        {label}
      </span>
    </div>
  )
}
