import { EntitySearchByField } from 'components'
import { MgmtCreateButton } from 'modules/management/shared'
import { AdminMgmtInviteUserDialog } from '../AdminMgmtInviteUserDialog'

interface AdminMgmtToolbarProps {
  onSearchChange: (value: string) => void
}

export const AdminMgmtToolbar = ({ onSearchChange }: AdminMgmtToolbarProps) => {
  return (
    <div className='flex justify-between border-b border-primary-black/20 p-3'>
      <h1 className={'text-2xl font-semibold text-primary-black'}>
        Admin Management
      </h1>
      <div className='flex items-center gap-4'>
        <EntitySearchByField
          placeholder='Search Name'
          onSearch={onSearchChange}
          onClear={() => onSearchChange('')}
        />

        <AdminMgmtInviteUserDialog
          renderTrigger={dialogControls => (
            <MgmtCreateButton
              label='New User'
              onClick={() => dialogControls.activate()}
            />
          )}
        />
      </div>
    </div>
  )
}
