import { ReactNode, useCallback } from 'react'
import { BaseModal, CheckCircle } from 'components'
import { ToggleHookStateControls, useToggle } from 'hooks'
import { useGaAdminProfileControllerInviteAdminMutation } from 'store/api'
import {
  AdminManagementInviteUserForm,
  type AdminManagementInviteUserFormData,
} from '../AdminMgmtInviteUserForm'
import { toast } from 'react-toastify'
import { commonToastStyles } from 'utils'
import { Colors } from 'constnants'

interface AdminMgmtInviteUserDialogProps {
  renderTrigger: (dialogControls: ToggleHookStateControls) => ReactNode
}

export const AdminMgmtInviteUserDialog = ({
  renderTrigger,
}: AdminMgmtInviteUserDialogProps) => {
  const [dialogState, dialogStateControls] = useToggle()
  const [inviteAdminUser] = useGaAdminProfileControllerInviteAdminMutation()

  const handleSubmit = useCallback(
    async (data: AdminManagementInviteUserFormData) => {
      try {
        await inviteAdminUser({
          createGaAdminDto: {
            email: data.email,
            fullName: data.name,
            adminType: 'super_admin',
          },
        }).unwrap()

        dialogStateControls.deactivate()

        toast.success('New admin invited', {
          position: 'bottom-right',
          icon: <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />,
          ...commonToastStyles,
        })
      } catch (error) {
        console.error(error)
      }
    },
    [inviteAdminUser, dialogStateControls],
  )

  return (
    <>
      {renderTrigger(dialogStateControls)}
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title='Invite New Greenlight Admin'
        modalZIndex={900}
      >
        <AdminManagementInviteUserForm
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
