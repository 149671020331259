import { optional, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Gender, Race } from '../../types'

const requiredString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)

const requiredEnum = <T extends z.EnumLike>(enumObj: T, fieldName: string) =>
  z.nativeEnum(enumObj, { required_error: `${fieldName} is required` })

const requiredObject = (fieldName: string) => {
  return z.object(
    {
      feet: z.number(),
      inches: z.number(),
    },
    { required_error: `${fieldName} is required` },
  )
}

export const talentManagementFormSchema = z.object({
  fullName: requiredString('fullName'),
  imdbId: requiredString('IMDB ID'), // where to get this? any validation required
  dob: requiredString('dob'),
  race: requiredEnum(Race, 'race'),
  gender: requiredEnum(Gender, 'gender'),
  bio: requiredString('bio'),
  height: requiredObject('height'),
  similarTalent1: requiredString('similarTalent1'),
  similarTalent2: requiredString('similarTalent2'),
  similarTalent3: requiredString('similarTalent3'),
  similarTalent4: requiredString('similarTalent4'),

  wikipedia: requiredString('wikipedia').optional(),
  instagramHandle: requiredString('instagramHandle').optional(),
  instagramUrl: requiredString('instagramUrl').optional(),
  xHandle: requiredString('xHandle').optional(),
  xUrl: requiredString('xUrl').optional(),
  hashtags: requiredString('hashtags').optional(),
  isActive: z.boolean().optional().default(true),
})

export const talentManagementFormResolver = zodResolver(
  talentManagementFormSchema,
)

export type TalentManagementFormData = z.infer<
  typeof talentManagementFormSchema
>
