import { ReactNode, useCallback } from 'react'
import { MgmtTableCell, MgmtTable } from 'modules/management/shared'
import { type TalentManagementTableTypes } from './types'
import { TalentManagementColKey, type TalentManagementData } from '../../types'
import { twMerge } from 'tailwind-merge'
import { columns } from './columns'

import { ActionsCell } from './ActionsCell'
import { useToggle } from 'hooks'
import { inchesToString } from 'utils'
import { genderLabelByType, raceLabelByType } from '../../config'
import { useNavigate } from 'react-router-dom'
import { useUpdateTalentManagementMutation } from 'store/api/mock-api'

type TalentManagementTableProps = {
  rows: TalentManagementData[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: TalentManagementTableTypes['SortConfig']
  onSortChange: TalentManagementTableTypes['OnSortChange']
}

export const TalentManagementTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
}: TalentManagementTableProps) => {
  const navigate = useNavigate()
  const handleEdit = (talent: TalentManagementData) =>
    navigate(`update/${talent.id}`)
  const [actionsCellState, actionsCellStateControls] =
    useToggle<TalentManagementData>()
  const [updateTalent] = useUpdateTalentManagementMutation()

  const handleAction = useCallback(
    async (row: TalentManagementData) => {
      const talentData = {
        ...row,
        isActive: !row.isActive,
      }

      await updateTalent({ talentId: row.id, updates: talentData }).catch(e =>
        console.log(e),
      )
    },
    [updateTalent],
  )

  const cellRenderer: TalentManagementTableTypes['CellRenderer'] = useCallback(
    ({ column, row, cellContent }) => {
      if (column.key === TalentManagementColKey.Actions) {
        return (
          <ActionsCell
            row={row}
            column={column}
            cellState={actionsCellState}
            cellStateControls={actionsCellStateControls}
            onArchiveClick={() => handleAction(row)}
            onEditClick={() => handleEdit(row)}
            onClick={() => actionsCellStateControls.activate(row)}
          />
        )
      }
      const isFullNameColumn = column.key === TalentManagementColKey.FullName
      return (
        <MgmtTableCell
          column={column}
          className={twMerge(
            isFullNameColumn && 'font-medium hover:text-red-9 ',
          )}
          onClick={isFullNameColumn ? () => handleEdit(row) : () => void 0}
        >
          {cellContent}
        </MgmtTableCell>
      )
    },
    [actionsCellState],
  )

  const rowKeyGetter: TalentManagementTableTypes['RowKeyGetter'] = useCallback(
    ({ row }) => row.id,
    [],
  )

  const cellContentGetter: TalentManagementTableTypes['CellContentGetter'] =
    useCallback(
      ({ column, getCellValue }) => {
        switch (column.key) {
          case TalentManagementColKey.Actions: {
            return null
          }
          case TalentManagementColKey.Height: {
            return getCellValue(column.key, inchesToString)
          }
          case TalentManagementColKey.Gender: {
            return getCellValue(column.key, value => genderLabelByType[value])
          }
          case TalentManagementColKey.Race: {
            return getCellValue(column.key, value => raceLabelByType[value])
          }
          default:
            return getCellValue(column.key)
        }
      },
      [actionsCellState],
    )

  return (
    <MgmtTable
      rows={rows}
      columns={columns}
      footer={footer}
      loading={loading}
      fetching={fetching}
      sortConfig={sortConfig}
      onSortChange={onSortChange}
      cellRenderer={cellRenderer}
      rowKeyGetter={rowKeyGetter}
      cellContentGetter={cellContentGetter}
    />
  )
}
