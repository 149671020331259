import type { AdminMgmtUserStatus, AdminMgmtUserAccountStatus } from '../types'

export const getAdminMgmtUserAccountStatus = (
  status: AdminMgmtUserStatus,
  lastLoginDate: string | null,
  lastLogoutDate: string | null,
): AdminMgmtUserAccountStatus => {
  const isActive = status === 'active'
  const isPending = !lastLoginDate && !lastLogoutDate

  if (isPending) {
    return 'pending'
  }

  if (!isActive) {
    return 'paused'
  }

  return 'active'
}
