import { useMemo } from 'react'
import { Button } from 'components'
import type { AdminMgmtUser } from '../../types'
import {
  formatJoinedDate,
  formatLastLoginDate,
  getAdminMgmtUserAccountStatus,
} from '../../utils'
import { AdminMgmtUserDetailsRow } from '../AdminMgmtUserDetailsRow'
import { AdminMgmtAccountStatusRow } from '../AdminMgmtAccountStatusRow'

interface AdminMgmtUserDetailsProps {
  user: AdminMgmtUser
  onEdit: () => void
}

export const AdminMgmtUserDetails = ({
  user,
  onEdit,
}: AdminMgmtUserDetailsProps) => {
  const { name, email, created_at, last_login_date, last_logout_date, status } =
    user

  const accountStatus = useMemo(
    () =>
      getAdminMgmtUserAccountStatus(status, last_login_date, last_logout_date),
    [user, last_login_date, last_logout_date],
  )

  return (
    <div className='flex-1 overflow-auto pb-4'>
      <div className='mx-auto w-[800px] max-w-[90%]'>
        <div className='mb-3 flex items-center justify-between'>
          <p className={'text-sm font-medium text-primary-black'}>
            User Details
          </p>
          <Button
            type='button'
            size='small'
            kind='text'
            className='bg-primary-grey text-primary-white hover:bg-primary-grey/50'
            onClick={onEdit}
          >
            Edit Details
          </Button>
        </div>

        <div className='mb-[44px] rounded border border-primary-black/20'>
          <AdminMgmtUserDetailsRow label='Name'>{name}</AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='Email'>
            {email}
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='User Type'>
            GL Super Admin
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='Date Joined'>
            {formatJoinedDate(created_at)}
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='Last Log In' hasBorder={false}>
            {formatLastLoginDate(last_login_date) ?? '--'}
          </AdminMgmtUserDetailsRow>

          <AdminMgmtAccountStatusRow
            user={user}
            accountStatus={accountStatus}
          />
        </div>
      </div>
    </div>
  )
}
