import React, { ReactNode, useState } from 'react'
import { Header, Sidebar, LoginHeader, SettingsModal } from 'components'
import { useIsMobile } from 'hooks'
import { useLocation } from 'react-router-dom'
import { Close, Help, Logo } from '../ui/icons'
import { Colors } from '../../constnants'
import { twMerge } from 'tailwind-merge'
import { useAuth } from 'hooks/auth/useAuth'

interface LayoutProps {
  children: ReactNode
  isLoading: boolean
  isAdmin?: boolean
}

export const Layout = ({
  children,
  isLoading,
  isAdmin = false,
}: LayoutProps) => {
  const [isOpenSidenav, setIsOpenSidenav] = useState<boolean>(false)
  const [isSettingsOpened, setIsSettingsOpened] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const location = useLocation()
  const { auth } = useAuth()

  const closeSideNavigation = () => {
    setIsOpenSidenav(false)
  }

  const openSideNavigation = () => {
    setIsOpenSidenav(true)
  }

  const toggleSettings = () => {
    setIsSettingsOpened(!isSettingsOpened)
  }

  const isAuthRoute =
    location.pathname === '/signup' ||
    location.pathname === '/login' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/reset-password' ||
    location.pathname === '/account-paused'

  if (isLoading) {
    return (
      <div className='grid h-full w-full grid-rows-[auto_1fr_auto] bg-primary-blue'>
        <main className={twMerge('w-full pb-0 pl-12', isAdmin && 'pl-3')}>
          {children}
        </main>
      </div>
    )
  }

  return (
    <div className='grid h-full w-full grid-rows-[auto_1fr_auto] bg-primary-blue'>
      {isSettingsOpened && (
        <div className='fixed left-0 top-0 z-[51] flex h-full w-full flex-col bg-primary-blue'>
          <div className='flex items-center justify-between p-1.5 pr-3'>
            <Logo width={30} height={32} />
            <div className='flex items-center gap-3 py-3'>
              <Help
                fill={Colors.NAV_ICON_ONE}
                width={20}
                height={20}
                className='hover:cursor-pointer'
              />
              <Close
                fill={Colors.NAV_ICON_ONE}
                width={20}
                height={20}
                className='hover:cursor-pointer'
                onClick={() => {
                  setIsSettingsOpened(false)
                }}
              />
            </div>
          </div>
          <div className='flex grow items-center justify-center'>
            <SettingsModal />
          </div>
        </div>
      )}
      {isAuthRoute && <LoginHeader isOpenSidenav={isOpenSidenav} />}
      {!isAuthRoute && (
        <Header
          isOpenSidenav={isOpenSidenav}
          toggleSettings={toggleSettings}
          closeSideNavigation={closeSideNavigation}
          openSideNavigation={openSideNavigation}
          isAdmin={isAdmin}
        />
      )}

      {!isAuthRoute && !isMobile && (
        <Sidebar
          isOpenSidenav={isOpenSidenav}
          closeSideNavigation={closeSideNavigation}
          openSideNavigation={openSideNavigation}
          toggleSettings={toggleSettings}
          isAdmin={isAdmin}
        />
      )}
      <main className={twMerge('w-full pb-0 pl-12', isAdmin && 'pl-3')}>
        {children}
      </main>
    </div>
  )
}
