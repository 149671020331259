import { ButtonHTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export type ButtonKind = 'filled' | 'text' | 'outline'
export type ButtonSize = 'small' | 'medium'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  kind: ButtonKind
  size: ButtonSize
  loading?: boolean
  className?: string
}

export const Button = ({
  children,
  type = 'button',
  kind,
  size,
  disabled = false,
  onClick,
  className,
  loading = false,
  ...rest
}: ButtonProps) => {
  const isDisabled = disabled || loading
  const baseStyles =
    'font-semibold transition-all duration-150 ease-linear py-1 disabled:cursor-not-allowed hover:cursor-pointer relative overflow-hidden'

  const kindStyles = {
    filled:
      'bg-primary-grey text-primary-white rounded-full hover:bg-btnMedium-hover active:bg-btnMedium-pressed disabled:bg-primary-grey',
    text: 'bg-transparent text-primary-grey rounded-full hover:bg-primary-black/5 active:bg-primary-black/10 disabled:bg-primary-white',
    outline:
      'border border-primary-black/10 rounded hover:bg-primary-black/5 active:bg-primary-black/10 disabled:bg-primary-white',
  }

  const sizeStyles = {
    medium: 'px-[14px] text-sm',
    small: 'px-[10px] text-xs',
  }

  const combinedStyles = twMerge(
    baseStyles,
    kindStyles[kind],
    sizeStyles[size],
    isDisabled && 'opacity-50',
    className,
  )

  return (
    <button
      {...rest}
      type={type}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      className={combinedStyles}
    >
      {loading ? (
        <div className={twMerge(loading && 'invisible')}>{children}</div>
      ) : (
        children
      )}
      {loading && (
        <div className='absolute inset-0 flex items-center justify-center'>
          <div
            className='h-4 w-4 animate-spin rounded-full'
            style={{
              borderWidth: '2px',
              borderColor: 'transparent',
              borderTopColor: '#c0c1c3',
              borderLeftColor: '#c0c1c3',
            }}
          ></div>
        </div>
      )}
    </button>
  )
}
