import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { paramsSerializer, AwsAmplifyRestApiUtils } from './utils'
export const apiURL = process.env.REACT_APP_REST_ENDPOINT // 'https://ga-api-100724-staging.azurewebsites.net'
// export const apiURL = 'http://localhost:3001';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiURL,
    paramsSerializer,
    prepareHeaders: async headers => {
      const data = await AwsAmplifyRestApiUtils.getAuthorizationHeader(true)
      headers.set('Authorization', `${data.Authorization}`)
      headers.set('ngrok-skip-browser-warning', `true`)
      return headers
    },
  }),
  tagTypes: [
    'MyProject',
    'MyUser',
    'MyTalent',
    'MyCompany',
    'TalentMetrics',
    'MyManagementUser',
    'ProjectRole',
    'TalentMetricsChart',
    'ReleaseCalender',
    'CompFinder',
    'FilmMetrics',
    'AdminUsers',
  ],
  endpoints: () => ({}),
})
