import { BaseModal, Button } from 'components'
import { Pause, Play } from 'components/ui/icons'
import { Colors } from 'constnants'
import { ToggleHookState, ToggleHookStateControls } from 'hooks'
import { twMerge } from 'tailwind-merge'
import { AdminMgmtUser } from '../../types'
import { useGaAdminProfileControllerUpdateAdminMutation } from 'store/api'
import { toast } from 'react-toastify'
import { commonToastStyles } from 'utils'

interface AdminMgmtActivateUserDialogProps {
  dialogState: ToggleHookState<AdminMgmtUser>
  dialogStateControls: ToggleHookStateControls<AdminMgmtUser>
}

export const AdminMgmtActivateUserDialog = ({
  dialogState,
  dialogStateControls,
}: AdminMgmtActivateUserDialogProps) => {
  const { active, data } = dialogState

  const [updateAdminUser] = useGaAdminProfileControllerUpdateAdminMutation()

  const handleDelete = async () => {
    const adminId = data?.id
    if (!adminId) {
      return
    }
    try {
      await updateAdminUser({
        adminId,
        updateGaAdminDto: { status: 'active' },
      }).unwrap()

      dialogStateControls.deactivate()

      toast.success('User account reactivated', {
        position: 'bottom-right',
        icon: <Play fill={Colors.BASE_ICON} width={19} height={19} />,
        ...commonToastStyles,
      })
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <BaseModal
      isOpen={active}
      handleClose={dialogStateControls.deactivate}
      modalZIndex={900}
    >
      <div className='flex flex-col items-center justify-center'>
        <Pause fill={Colors.BASE_ICON} width={19} height={19} />
        <h3
          className={twMerge(
            'mb-1 pb-2 pt-6 text-center text-[17px] text-lg font-medium text-primary-black',
          )}
        >
          {`Are you sure you want to reactivate ${dialogState.data?.name}?`}
        </h3>
        <p className={twMerge('pb-1 text-center text-[13px] leading-[20px]')}>
          This will reactivate the user to the company account.
        </p>
      </div>
      <div className='flex w-full gap-2 pt-4'>
        <Button
          kind='text'
          size='medium'
          className='w-1/2 py-2.5'
          onClick={dialogStateControls.deactivate}
        >
          <span className='px-1 text-xs font-semibold'>Cancel</span>
        </Button>

        <Button
          kind='filled'
          size='medium'
          className={twMerge(
            'w-1/2 bg-primary-red  py-2.5 hover:bg-red-10/80 active:bg-red-10/60',
          )}
          onClick={handleDelete}
        >
          <span className='px-1 text-xs font-semibold'>Reactivate user</span>
        </Button>
      </div>
    </BaseModal>
  )
}
