import { useToggle } from 'hooks'
import { MgmtLayout } from 'modules/management/shared'
import { AdminMgmtUsersView, AdminMgmtUserView } from '../../views'
import { AdminMgmtUser } from '../../types'

export const AdminMgmtPage = () => {
  const [userViewState, userViewStateControls] = useToggle<AdminMgmtUser>()

  return (
    <MgmtLayout>
      {userViewState.active && userViewState.data ? (
        <AdminMgmtUserView
          user={userViewState.data}
          viewControls={userViewStateControls}
        />
      ) : (
        <AdminMgmtUsersView
          onOpenUserDetails={userViewStateControls.activate}
        />
      )}
    </MgmtLayout>
  )
}
