import { Gender, Race } from '../types'

export const genderLabelByType: Record<Gender, string> = {
  [Gender.Male]: 'Male',
  [Gender.Female]: 'Female',
  [Gender.NonBinary]: 'Non-binary',
}
export const raceLabelByType: Record<Race, string> = {
  [Race.Black]: 'Black',
  [Race.Asian]: 'Asian',
  [Race.SouthAsian]: 'South Asian',
  [Race.Hispanic]: 'Hispanic',
  [Race.MiddleEastern]: 'Middle Eastern',
  [Race.Multiracial]: 'Multiracial',
  [Race.NativeAmerican]: 'Native American',
  [Race.Nhpi]: 'NHPI',
  [Race.White]: 'White',
}
