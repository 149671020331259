import { ReactNode, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { MgmtTableCell, MgmtTable } from 'modules/management/shared'
import { type ReleaseCalendarEventsTableTypes } from './types'
import {
  ReleaseCalendarEventColKey,
  type ReleaseCalendarEvent,
} from '../../types'
import { EditEntryCellContent } from '../cellContent'
import { columns } from './columns'
import { formatDate } from 'utils'
import { ToggleHookStateControls } from 'hooks'

type ReleaseCalendarEventsTableProps = {
  rows: ReleaseCalendarEvent[]
  footer: ReactNode
  loading: boolean
  fetching: boolean
  sortConfig: ReleaseCalendarEventsTableTypes['SortConfig']
  onSortChange: ReleaseCalendarEventsTableTypes['OnSortChange']
  editDialogStateControls: ToggleHookStateControls<ReleaseCalendarEvent>
}

export const ReleaseCalendarEventsTable = ({
  rows,
  footer,
  loading,
  fetching,
  sortConfig,
  onSortChange,
  editDialogStateControls,
}: ReleaseCalendarEventsTableProps) => {
  const cellRenderer: ReleaseCalendarEventsTableTypes['CellRenderer'] =
    useCallback(
      ({ column, cellContent }) => (
        <MgmtTableCell
          column={column}
          className={twMerge(column.key === 'title' && 'font-medium')}
        >
          {cellContent}
        </MgmtTableCell>
      ),
      [],
    )

  const rowKeyGetter: ReleaseCalendarEventsTableTypes['RowKeyGetter'] =
    useCallback(({ row }) => row.id, [])

  const cellContentGetter: ReleaseCalendarEventsTableTypes['CellContentGetter'] =
    useCallback(
      ({ column, row, getCellValue }) => {
        switch (column.key) {
          case ReleaseCalendarEventColKey.EditEntry: {
            return (
              <EditEntryCellContent
                onClick={() => editDialogStateControls.activate(row)}
              />
            )
          }
          case ReleaseCalendarEventColKey.StartDate:
          case ReleaseCalendarEventColKey.EndDate:
            return getCellValue(column.key, dateStr =>
              formatDate(dateStr, 'MM/DD/YYYY'),
            )
          default:
            return getCellValue(column.key)
        }
      },
      [editDialogStateControls],
    )

  return (
    <>
      <MgmtTable
        rows={rows}
        columns={columns}
        footer={footer}
        loading={loading}
        fetching={fetching}
        sortConfig={sortConfig}
        onSortChange={onSortChange}
        cellRenderer={cellRenderer}
        rowKeyGetter={rowKeyGetter}
        cellContentGetter={cellContentGetter}
      />
    </>
  )
}
