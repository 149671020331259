import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useNavigate } from 'react-router-dom'
import {
  confirmVerificationCode,
  sendEmailVerificationCode,
} from 'utils/amplify/auth-amplify'
import { useUpdateLoginInfoMutation } from 'store/api'

const schema = z.object({
  verificationCode: z
    .string()
    .length(6, 'Verification code must be 6 digits')
    .regex(/^\d+$/, 'Verification code must be numeric'),
})

type VerifyEmailFormData = z.infer<typeof schema>

export const VerifyEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<VerifyEmailFormData>({
    resolver: zodResolver(schema),
  })
  const [wrongCode, setWrongCode] = useState('')
  const navigate = useNavigate()
  const [updateLoginInfo] = useUpdateLoginInfoMutation()

  const onSubmit = async (data: VerifyEmailFormData) => {
    const verificationCode = data.verificationCode

    try {
      await confirmVerificationCode(verificationCode)
      await updateLoginInfo({
        lastLoginDate: new Date().toISOString(),
      }).unwrap()
      navigate('/talent')
      window.location.reload()
    } catch (error) {
      if (error instanceof Error) {
        setWrongCode(error.message)
      } else {
        setWrongCode('An unexpected error occurred. Please try again later.')
      }
    }
  }

  const resetErrors = () => {
    setWrongCode('')
  }

  return (
    <div className='flex h-[96vh] items-center justify-center'>
      <div className='flex flex-col items-center justify-center'>
        <div className='w-[400px] max-w-[90%] rounded-lg bg-white p-5'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className='text-center text-2xl font-semibold'>
              Enter Verification Code
            </h2>
            <div className='mt-4 flex flex-col'>
              <div className='mb-4'>
                <label
                  htmlFor='verificationCode'
                  className='mb-1 block font-semibold'
                >
                  Verification Code
                </label>
                <input
                  id='verificationCode'
                  className={`w-full rounded-md border p-1 ${errors.verificationCode ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                  {...register('verificationCode')}
                  onChange={resetErrors}
                  autoComplete='off'
                />
                {errors.verificationCode && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.verificationCode.message?.toString()}
                  </p>
                )}
                {wrongCode && (
                  <p className='mt-1 text-xs text-red-500'>{wrongCode}</p>
                )}
              </div>
              <button onClick={() => sendEmailVerificationCode()}>
                Resend Code
              </button>
              <button
                type='submit'
                className='mt-4 w-full rounded-full bg-red-500 px-4 py-2 text-white'
              >
                Verify Code
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
