import type { ResourcesConfig } from '@aws-amplify/core'
import { Amplify } from 'aws-amplify'

export const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    //  Amazon Cognito User Pool ID
    userPoolId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || 'us-east-2_bUzmphNG1',
    userPoolClientId:
      process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID ||
      '70rilhl0me2kvvb4qeke4djqn7',
    signUpVerificationMethod: 'code', // 'code' | 'link'
    //   loginWith: {
    //       // OPTIONAL - Hosted UI configuration
    //       oauth: {
    //           domain: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_DOMAIN || '',
    //           scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //           // redirectSignIn: ['http://localhost:3000'],
    //           // redirectSignOut: ['http://localhost:3000'],
    //           redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN || ''],
    //           redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT || ''],
    //       },
    //       username: true,
    //       email: true,
    //   },
  },
}
export const amplifyConfig = {
  API: {
    REST: {
      GreenLightAPI: {
        endpoint: process.env.REACT_APP_REST_ENDPOINT,
        region: process.env.REACT_APP_REST_ENDPOINT_REGION,
      },
    },
  },
  Auth: authConfig,
  Storage: {
    S3: {
      bucket: process.env.REACT_APP_S3_BUCKET,
      region: process.env.REACT_APP_S3_BUCKET_REGION,
    },
  },
}
export const configureAmplify = () => {
  console.log('amplifyConfig', process.env)
  Amplify.configure(amplifyConfig as ResourcesConfig)
}
