import { BaseModal } from 'components'
import {
  type ReleaseCalendarRevenueFormData,
  ReleaseCalendarRevenueForm,
} from '../ReleaseCalendarRevenueForm'
import { ToggleHookStateControls, useToggle } from 'hooks'
import { ReactNode, useCallback } from 'react'
import { useReleaseCalendarControllerCreateRevenueMutation } from 'store/api'

interface PastRevenueAddDialogProps {
  renderTrigger: (dialogControls: ToggleHookStateControls) => ReactNode
}

export const PastRevenueAddDialog = ({
  renderTrigger,
}: PastRevenueAddDialogProps) => {
  const [dialogState, dialogStateControls] = useToggle()
  const [createEntry] = useReleaseCalendarControllerCreateRevenueMutation()

  const handleSubmit = useCallback(
    async (data: ReleaseCalendarRevenueFormData) => {
      try {
        await createEntry({
          createRevenueDto: data,
        })
      } finally {
        dialogStateControls.deactivate()
      }
    },
    [createEntry, dialogStateControls],
  )

  return (
    <>
      {renderTrigger(dialogStateControls)}
      <BaseModal
        isOpen={dialogState.active}
        handleClose={dialogStateControls.deactivate}
        title={'New Revenue Entry'}
        maxWidth='420px'
      >
        <ReleaseCalendarRevenueForm
          submitButtonLabel='Add Entry'
          onClose={dialogStateControls.deactivate}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    </>
  )
}
