import { IEvent, ReleaseCalendarData } from '../../types'
const EVENT_HEIGHT_SPORT_HOLIDAY = 30
const SPORTS_HOLIDAY_CLASS = 'truncate max-w-[123px]'
// Function to build the required structure from the data array
export const buildEventGroups = (dataArray: any[]): ReleaseCalendarData[] => {
  const sportingEvents: IEvent[] = []
  const holidayAndSchoolEvents: IEvent[] = []

  dataArray.forEach(eventData => {
    console.log(eventData)
    const event = {
      name: eventData.event_name,
      startDay: convertDateIdToDate(eventData.start_date_id),
      endDay: convertDateIdToDate(eventData.end_date_id),
      hasBackground: eventData.is_known,
      // hasLine: true,
    }

    // Add events to the respective groups
    if (eventData.event_type === 'Sport Events') {
      sportingEvents.push(event)
    } else if (eventData.event_type === 'Holiday School') {
      holidayAndSchoolEvents.push(event)
    }
  })

  return [
    {
      label: '',
      value: 'sportingEvents',
      id: 'sportingEvents',
      events: sportingEvents,
      hasLine: true,
      itemHeight: EVENT_HEIGHT_SPORT_HOLIDAY,
      nameClassName: SPORTS_HOLIDAY_CLASS,
      colorClass: 'bg-blue-6',
    },
    {
      label: '',
      value: 'holidayAndSchool',
      id: 'holidayAndSchool',
      events: holidayAndSchoolEvents,
      hasLine: true,
      itemHeight: EVENT_HEIGHT_SPORT_HOLIDAY,
      nameClassName: SPORTS_HOLIDAY_CLASS,
      colorClass: 'bg-green-6',
    },
  ]
}
// Utility function to convert date_id (number) to a date string in 'YYYY-MM-DD' format
const convertDateIdToDate = (dateId: number): string => {
  if (!dateId) return ''
  const dateStr = dateId.toString()
  const year = dateStr.slice(0, 4)
  const month = dateStr.slice(4, 6)
  const day = dateStr.slice(6, 8)
  return `${year}-${month}-${day}`
}
