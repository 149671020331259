import { bindActionCreators } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { adminManagementActions } from '../slices'
import { useMemo } from 'react'

export const useAdminManagementActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(adminManagementActions, dispatch),
    [dispatch],
  )
}
