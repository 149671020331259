import { TVManagementColKey } from '../../types'
import { type TVManagementCol } from './types'

export const columns: TVManagementCol[] = [
  {
    key: TVManagementColKey.ImdbId,
    sortKey: TVManagementColKey.ImdbId,
    title: 'IMDB ID',
  },
  {
    key: TVManagementColKey.Title,
    sortKey: TVManagementColKey.Title,
    title: 'Title',
  },
  { key: TVManagementColKey.Date, title: 'Date' },
  {
    key: TVManagementColKey.NetworkType,
    title: 'Network Type',
  },
  {
    key: TVManagementColKey.Network,
    title: 'Network',
  },
  {
    key: TVManagementColKey.NetworkImdbId,
    title: 'Network IMDB ID',
  },
  {
    key: TVManagementColKey.TvType,
    title: 'TV Type',
  },
  {
    key: TVManagementColKey.TvSubtype,
    title: 'TV Subtype',
  },
  {
    key: TVManagementColKey.Genre,
    title: 'Genre',
  },
  { key: TVManagementColKey.EditEntry, width: 48 },
]
