import { EventDetails, RevenueDetails } from 'store/api'

export type ReleaseCalendarEvent = EventDetails

export enum ReleaseCalendarEventColKey {
  Title = 'title',
  StartDate = 'startDate',
  EndDate = 'endDate',
  EditEntry = 'editEntry',
}

export type ReleaseCalendarEventSortColKey =
  | ReleaseCalendarEventColKey.Title
  | ReleaseCalendarEventColKey.StartDate
  | ReleaseCalendarEventColKey.EndDate

export type ReleaseCalendarRevenue = RevenueDetails

export enum ReleaseCalendarRevenueColKey {
  Date = 'date',
  Week = 'weeks',
  Revenue = 'revenue',
  EditEntry = 'editEntry',
}

export type ReleaseCalendarRevenueSortColKey =
  | ReleaseCalendarRevenueColKey.Date
  | ReleaseCalendarRevenueColKey.Revenue

export enum ReleaseCalendarTab {
  SportingEvents = 'sportingEvents',
  HolidayEvents = 'holidayEvents',
  PastRevenue = 'pastRevenue',
}
