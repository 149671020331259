import React, { useEffect, useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { Props, UsersTableProps } from '../UsersTable.types'
import { UsersTableCell } from './UsersTableCell'
import { Checkbox } from '../../ui'
import { MoreVertical } from '../../ui/icons'
import { getUserAccountStatus, getUserLastLoginStatus } from 'utils'

export const UsersTableBody = ({
  data,
  columns,
  isLoading,
  handleCheckboxChange,
  selectedRows,
  setUserProfileOpen,
  handleOpenWarning,
}: Props) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (
      refs.current &&
      !refs.current.some(ref => ref && ref.contains(event.target as Node))
    ) {
      setVisibleComponent(null)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const refs = useRef<(HTMLDivElement | null)[]>([])
  const [visibleComponent, setVisibleComponent] = useState<number | null>(null)
  const placeholderData: UsersTableProps[] = Array.from(
    { length: 20 },
    (_, index) => ({
      id: index,
      name: 'Name',
      email: 'Email',
      last_login_date: '2024-01-01T00:00:00.000Z',
      last_logout_date: '2024-01-02T00:00:00.000Z',
      status: 'active',
      date_joined: 'Date join',
      projects: [],
      module_access: [],
      access_level: 'Access level',
      user_type: 'User type',
    }),
  )

  const [dataToMap, setDataToMap] = useState(data)

  useEffect(() => {
    setDataToMap(isLoading ? placeholderData : data)
  }, [isLoading, data])

  return (
    <tbody>
      {dataToMap.length > 0 &&
        dataToMap.map((row, rowIndex) => {
          const accountStatus = getUserAccountStatus(
            row.status,
            row.last_login_date,
            row.last_logout_date,
          )
          return (
            <tr key={row.name + '_' + row.id}>
              <td
                className={
                  'w-8 border-t-[1px] border-primary-black/20 p-3 py-4 pr-0'
                }
              >
                <Checkbox
                  checked={selectedRows.includes(row.id)}
                  onChange={() => handleCheckboxChange(row.id)}
                  kind='small'
                />
              </td>
              {columns.map((column, index) => (
                <td
                  key={column.value}
                  className={twMerge(
                    'cursor-pointer border-t-[1px] border-primary-black/20 py-4',
                    index == 0 && 'w-[235px]',
                    isLoading && 'animate-pulse',
                  )}
                  onClick={() => setUserProfileOpen(row)}
                >
                  <UsersTableCell
                    row={row}
                    column={column}
                    isLoading={isLoading}
                  />
                </td>
              ))}

              <td
                className={twMerge(
                  'relative cursor-pointer border-t-[1px] border-primary-black/20 px-2.5 py-4 hover:text-red-10',
                  isLoading && 'animate-pulse',
                )}
                onClick={() => {
                  setVisibleComponent(rowIndex)
                }}
                ref={el => (refs.current[rowIndex] = el)}
              >
                <MoreVertical fill={'currentColor'} width={16} height={16} />
                {visibleComponent === rowIndex && (
                  <div className='absolute right-full top-1/2 z-50 -translate-y-1/2 transform rounded-md bg-primary-white p-3 shadow-md'>
                    <ul className='min-w-[120px] whitespace-nowrap'>
                      {accountStatus !== 'Pending' && (
                        <li
                          className='rounded px-1 py-0.5 text-primary-black transition-colors hover:cursor-pointer hover:bg-primary-black/5'
                          onClick={() =>
                            handleOpenWarning(
                              row.status !== 'paused' ? 'pause' : 'reactivate',
                              row.name,
                              row.id.toString(),
                            )
                          }
                        >
                          {row.status !== 'paused' ? 'Pause' : 'Reactivate'}
                        </li>
                      )}
                      <li
                        className='rounded px-1 py-0.5 text-red-10 transition-colors hover:cursor-pointer hover:bg-primary-black/5'
                        onClick={() =>
                          handleOpenWarning(
                            'delete',
                            row.name,
                            row.id.toString(),
                          )
                        }
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                )}
              </td>
            </tr>
          )
        })}
    </tbody>
  )
}
