import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const requiredString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)

export const adminMgmtUserEditFormSchema = z.object({
  name: requiredString('Name'),
  email: requiredString('Email').email('Invalid email address format'),
  status: z.enum(['active', 'paused'], {
    required_error: 'Account Status is required',
  }),
})

export const adminMgmtUserEditFormResolver = zodResolver(
  adminMgmtUserEditFormSchema,
)

export type AdminMgmtUserEditFormData = z.infer<
  typeof adminMgmtUserEditFormSchema
>
