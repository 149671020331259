import { OptionType } from 'types'
import { mapValuesToOptions } from 'utils'
import { TVNetwork, TVNetworkType, TVGenre, TVSubtype, TVType } from '../types'
import {
  tVNetworkLabelByType,
  tVNetworkTypeLabelByType,
  tVGenreLabelByType,
  tVSubtypeLabelByType,
  tVTypeLabelByType,
} from './tVNetworkTypeLabelMapping'

export const tVCableNetworkOptions: OptionType<TVNetwork>[] =
  mapValuesToOptions([TVNetwork.Cinemax, TVNetwork.Max], tVNetworkLabelByType)

export const tVStreamerNetworkOptions: OptionType<TVNetwork>[] =
  mapValuesToOptions(
    [TVNetwork.ApplePlus, TVNetwork.DisneyPlus, TVNetwork.Netflix],
    tVNetworkLabelByType,
  )

export const tVNetworkTypeOptions: OptionType<TVNetworkType>[] =
  mapValuesToOptions(TVNetworkType, tVNetworkTypeLabelByType)

export const tvNetworkOptionsByNetworkType = {
  [TVNetworkType.TVCable]: tVCableNetworkOptions,
  [TVNetworkType.TVStreamer]: tVStreamerNetworkOptions,
} satisfies Record<TVNetworkType, OptionType[]>

export const tVTypeOptions: OptionType<TVType>[] = mapValuesToOptions(
  TVType,
  tVTypeLabelByType,
)

export const tVFilmSubtypeOptions: OptionType<TVSubtype>[] = mapValuesToOptions(
  [TVSubtype.StreamingOriginal, TVSubtype.StreamingPremiere],
  tVSubtypeLabelByType,
)

export const tVSeriesSubtypeOptions: OptionType<TVSubtype>[] =
  mapValuesToOptions(
    [TVSubtype.SeriesPremiere, TVSubtype.SeasonPremiere],
    tVSubtypeLabelByType,
  )

export const tVFilmGenreOptions: OptionType<TVGenre>[] = mapValuesToOptions(
  [
    TVGenre.Comedy,
    TVGenre.Drama,
    TVGenre.Action,
    TVGenre.Horror,
    TVGenre.Animation,
    TVGenre.FamilyLiveAction,
  ],
  tVGenreLabelByType,
)

export const tVSeriesGenreOptions: OptionType<TVGenre>[] = mapValuesToOptions(
  [
    TVGenre.Comedy,
    TVGenre.Drama,
    TVGenre.LimitedAnthologySeries,
    TVGenre.RealityCompetition,
    TVGenre.ScriptedVariety,
    TVGenre.TalkSeries,
    TVGenre.GameShow,
    TVGenre.Documentary,
    TVGenre.HomeDIY,
    TVGenre.Cooking,
    TVGenre.Sports,
    TVGenre.RealityDocuseries,
  ],
  tVGenreLabelByType,
)

export const tvSubtypeOptionsByTVType: Record<TVType, OptionType[]> = {
  [TVType.Film]: tVFilmSubtypeOptions,
  [TVType.Series]: tVSeriesSubtypeOptions,
  [TVType.LiveEventsSpecials]: [],
}

export const tvGenreOptionsByTVType: Record<TVType, OptionType[]> = {
  [TVType.Film]: tVFilmGenreOptions,
  [TVType.Series]: tVSeriesGenreOptions,
  [TVType.LiveEventsSpecials]: [],
}
