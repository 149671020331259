import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  TVSubtype,
  TVNetwork,
  TVNetworkType,
  TVGenre,
  TVType,
} from '../../types'

const requiredString = (fieldName: string) =>
  z
    .string({ required_error: `${fieldName} is required` })
    .min(1, `${fieldName} is required`)

const requiredEnum = <T extends z.EnumLike>(enumObj: T, fieldName: string) =>
  z.nativeEnum(enumObj, { required_error: `${fieldName} is required` })

export const tVManagementFormSchema = z.object({
  title: requiredString('title'),
  imdbId: requiredString('IMDB ID'), // where to get this? any validation required
  date: requiredString('date'),
  networkType: requiredEnum(TVNetworkType, 'Network Type'),
  network: requiredEnum(TVNetwork, 'Network'),
  networkImdbId: requiredString('networkImdbId').nullable().optional(), // where to get this? any validation required
  tvType: requiredEnum(TVType, 'TV Type'),
  tvSubtype: requiredEnum(TVSubtype, 'TV Subtype').nullable(),
  genre: requiredEnum(TVGenre, 'TV Type').nullable(),
})

export const tvFilmCompanyFormResolver = zodResolver(tVManagementFormSchema)

export type TVManagementFormData = z.infer<typeof tVManagementFormSchema>
