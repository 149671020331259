import CryptoJS from 'crypto-js'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Navigate, useNavigate } from 'react-router-dom'
import { EyeSlash } from 'components/ui/icons'
import { Colors } from 'constnants'
import { apiURL } from '../../store/api/api'
import { useUpdateLoginInfoMutation } from '../../store/api'
import { handleSignIn } from 'utils/amplify/auth-amplify'
import { useAuthContext } from 'context'
import PageLoader from 'components/PageLoader'

const schema = z.object({
  email: z.string().email('Invalid email address format'),
  password: z.string(),
})

type LoginFormData = z.infer<typeof schema>

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({})
  const [showPassword, setShowPassword] = useState(false)
  const [apiError, setApiError] = useState(false)
  const [wrongEmail, setWrongEmail] = useState('')
  const [wrongPassword, setWrongPassword] = useState('')
  const navigate = useNavigate()
  const [updateLoginInfo] = useUpdateLoginInfoMutation()
  const { auth } = useAuthContext()
  const onSubmit = async (data: LoginFormData) => {
    const emailInput = document.getElementById('email') as HTMLInputElement
    const emailValue = emailInput.value
    const passwordInput = document.getElementById(
      'password',
    ) as HTMLInputElement
    const passwordValue = passwordInput.value

    const fetchData = {
      email: emailValue || data.email,
      password: passwordValue || data.password,
    }
    const password = passwordValue || data.password
    try {
      const { isSignedIn, isDisabled, nextStep } = await handleSignIn({
        username: fetchData.email,
        password: fetchData.password,
      })

      if (isSignedIn) {
        await updateLoginInfo({
          lastLoginDate: new Date().toISOString(),
        }).unwrap()
        navigate('/talent')
      }

      if (isDisabled) {
        navigate('/account-paused')
      }

      if (
        nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        const encryptedPassword = CryptoJS.AES.encrypt(
          password,
          fetchData.email,
        ).toString()
        localStorage.setItem('email', fetchData.email)
        localStorage.setItem('password', encryptedPassword)
        navigate('/password-change')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password')
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const resetErrors = (field: string) => {
    if (field === 'email') {
      setWrongEmail('')
    } else {
      setWrongPassword('')
    }
  }

  if (auth?.isLoading) return <PageLoader hasOpaquePrimaryBg />
  if (auth?.user?.userId) return <Navigate to='/talent' />

  return (
    <div className='flex h-[96vh] items-center justify-center'>
      <div className='flex flex-col items-center justify-center'>
        <div className='w-[400px] max-w-[90%] rounded-lg bg-white p-5'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className='text-center text-2xl font-semibold'>
              Welcome Back!
            </h2>
            <div className='mt-4 flex flex-col'>
              <div className='mb-4'>
                <label htmlFor='email' className='mb-1 block font-semibold'>
                  Email
                </label>
                <input
                  id='email'
                  className={`w-full rounded-md border p-1 ${errors.email ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                  {...register('email')}
                  onChange={() => resetErrors('email')}
                  autoComplete='off'
                />
                {errors.email && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.email.message?.toString()}
                  </p>
                )}
                {apiError && wrongEmail && (
                  <p className='mt-1 text-xs text-red-500'>{wrongEmail}</p>
                )}
              </div>
              <div className='mb-4'>
                <label htmlFor='password' className='mb-1 block font-semibold'>
                  Password
                </label>
                <div className='relative w-full'>
                  <input
                    id='password'
                    autoComplete='off'
                    type={showPassword ? 'text' : 'password'}
                    className={`w-full rounded-md border p-1 ${errors.password ? 'border-red-500 bg-red-100' : 'border-gray-300'}`}
                    {...register('password')}
                    onChange={() => resetErrors('password')}
                  />
                  {apiError && wrongPassword && (
                    <p className='mt-1 text-xs text-red-500'>{wrongPassword}</p>
                  )}
                  <button
                    type='button'
                    className='absolute right-2 top-2.5 transform cursor-pointer border-none bg-none focus:outline-none'
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? (
                      <EyeSlash
                        fill={Colors.BASE_ICON}
                        width={16}
                        height={16}
                      />
                    ) : (
                      <EyeSlash
                        fill={Colors.BASE_ICON}
                        width={16}
                        height={16}
                      />
                    )}
                  </button>
                </div>
                {errors.password && (
                  <p className='mt-1 text-xs text-red-500'>
                    {errors.password.message?.toString()}
                  </p>
                )}
              </div>
              <a
                href='#'
                className='mb-4 text-sm text-red-500'
                onClick={handleForgotPasswordClick}
              >
                Forgot Password?
              </a>
              <button
                type='submit'
                className='mt-4 w-full rounded-full bg-red-500 px-4 py-2 text-white'
              >
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
