import { OptionType } from 'types'

export const ageOptions: OptionType[] = [
  { id: '1', value: '12-17', label: '12-17' },
  { id: '2', value: '18-24', label: '18-24' },
  { id: '3', value: '25-34', label: '25-34' },
  { id: '4', value: '35-44', label: '35-44' },
  { id: '5', value: '45-59', label: '45-59' },
  { id: '6', value: '60+', label: '60+' },
]

export const genderOptions: OptionType[] = [
  { id: '1', value: 'Male', label: 'Male' },
  { id: '2', value: 'Female', label: 'Female' },
  { id: '3', value: 'Non-Binary', label: 'Non-binary' },
]

export const raceOptions: OptionType[] = [
  { id: '1', value: 'black', label: 'Black' },
  { id: '2', value: 'hispanic', label: 'Hispanic' },
  { id: '3', value: 'white', label: 'White' },
  { id: '4', value: 'asian', label: 'Asian' },
  { id: '5', value: 'nhpi', label: 'NHPI' },
  { id: '6', value: 'indigenous', label: 'Indigenous' },
  { id: '7', value: 'multiracial', label: 'Multiracial' },
  { id: '8', value: 'south asian', label: 'South Asian' },
  { id: '9', value: 'middle eastern', label: 'Middle Eastern' },
]

export const dataTalent = [
  { id: 'gender', name: 'Gender', options: genderOptions },
  { id: 'age', name: 'Age', options: ageOptions },
  { id: 'race', name: 'Race', options: raceOptions },
]
