import type { MgmtTableTypes } from 'modules/management/shared'
import { ForwardedRef, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export const MgmtTableCellInner = (
  { children, column, className, onClick }: MgmtTableTypes['CellProps'],
  ref: ForwardedRef<HTMLTableCellElement>,
) => (
  <td
    style={{ width: column.width }}
    ref={ref}
    className={twMerge('px-3 py-2 text-xs font-normal', className)}
    onClick={onClick}
  >
    {children}
  </td>
)

export const MgmtTableCell = forwardRef(MgmtTableCellInner)
