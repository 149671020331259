import {
  bindActionCreators,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { visibleDemosFilterDef } from 'constnants/audienceOptionsFilms'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  AudienceDemo,
  AudienceDemoGroup,
  AudienceGeneralDemo,
  DirectionSort,
  FilmMetricsSortBy,
  Theatergoers,
} from 'types'

interface PageFilters {
  groups: number[]
  distributors: number[]
  audience: AudienceDemo
  theatergoers: Theatergoers
}

interface PageSorting {
  direction: DirectionSort
  column: FilmMetricsSortBy
}

interface PageVisibleDemos {
  active: Record<AudienceDemoGroup, AudienceDemo[]>
  count: number
}

interface PageState {
  pageSize: number
  pageNumber: number
  selectedFilms: string[]
  filters: PageFilters
  sorting: PageSorting
  visibleDemos: PageVisibleDemos
  interestType: 'all' | 'aware'
}

const getVisibleDemoColumns = (key: AudienceDemoGroup) =>
  visibleDemosFilterDef.options
    .find(it => it.id === key)
    ?.options?.map(it => it.value) ?? []

const visibleDemoGroups: Record<AudienceDemoGroup, AudienceDemo[]> = {
  [AudienceDemoGroup.BLACK]: getVisibleDemoColumns(AudienceDemoGroup.BLACK),
  [AudienceDemoGroup.WHITE]: getVisibleDemoColumns(AudienceDemoGroup.WHITE),
  [AudienceDemoGroup.LGBTQI]: getVisibleDemoColumns(AudienceDemoGroup.LGBTQI),
  [AudienceDemoGroup.GENERAL]: getVisibleDemoColumns(AudienceDemoGroup.GENERAL),
  [AudienceDemoGroup.HISPANIC_LATINO]: getVisibleDemoColumns(
    AudienceDemoGroup.HISPANIC_LATINO,
  ),
}

const visibleDemosInitialState: PageVisibleDemos = {
  active: visibleDemoGroups,
  count: 0,
}

const filtersInitialState: PageFilters = {
  groups: [],
  distributors: [],
  theatergoers: Theatergoers.ALL_RESPONDENTS,
  audience: AudienceGeneralDemo.ALL,
}

const sortingInitialState: PageSorting = {
  direction: DirectionSort.ASC,
  column: 'latest_date_survey_id',
}

const initialState: PageState = {
  pageSize: 100,
  pageNumber: 1,
  selectedFilms: [],
  filters: filtersInitialState,
  sorting: sortingInitialState,
  visibleDemos: visibleDemosInitialState,
  interestType: 'all',
}

export const filmTrackingPageSlice = createSlice({
  name: 'filmTrackingPage',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<PageState['pageSize']>) => {
      state.pageSize = action.payload
      state.selectedFilms = []
    },
    setPageNumber: (state, action: PayloadAction<PageState['pageNumber']>) => {
      state.pageNumber = action.payload
      state.selectedFilms = []
    },
    setSelectedFilms: (
      state,
      action: PayloadAction<PageState['selectedFilms']>,
    ) => {
      state.selectedFilms = action.payload
    },
    setGroupFilter: (state, action: PayloadAction<PageFilters['groups']>) => {
      state.filters.groups = action.payload
      state.selectedFilms = []
    },
    setDistributorFilter: (
      state,
      action: PayloadAction<PageFilters['distributors']>,
    ) => {
      state.filters.distributors = action.payload
      state.selectedFilms = []
    },
    setAudienceFilter: (
      state,
      action: PayloadAction<PageFilters['audience']>,
    ) => {
      state.filters.audience = action.payload
      state.selectedFilms = []
      if (action.payload !== AudienceGeneralDemo.ALL) {
        state.filters.theatergoers = Theatergoers.ALL_RESPONDENTS
      }
    },
    setAudienceDemoFilter: (
      state,
      action: PayloadAction<{
        id: AudienceDemoGroup
        group: PageState['visibleDemos']['active'][AudienceDemoGroup]
      }>,
    ) => {
      state.visibleDemos.active[action.payload.id] = action.payload.group

      let all = 0
      let selected = 0
      for (const key of Object.keys(visibleDemoGroups) as AudienceDemoGroup[]) {
        all += visibleDemoGroups[key].length
        selected += state.visibleDemos.active[key].length
      }

      state.visibleDemos.count = all - selected
    },
    resetVisibleDemos: state => {
      state.visibleDemos.active = visibleDemoGroups
      state.visibleDemos.count = 0
    },
    setTheatergoersFilter: (
      state,
      action: PayloadAction<PageFilters['theatergoers']>,
    ) => {
      state.filters.theatergoers = action.payload
      state.selectedFilms = []
    },
    resetFilters: state => {
      state.filters = filtersInitialState
    },
    setSorting: (state, action: PayloadAction<PageSorting>) => {
      state.sorting = action.payload
      state.selectedFilms = []
    },
    resetSorting: state => {
      state.sorting = sortingInitialState
      state.selectedFilms = []
    },
    setInterestType: (
      state,
      action: PayloadAction<PageState['interestType']>,
    ) => {
      state.interestType = action.payload
    },
  },
  selectors: {
    root: state => state,
    filters: state => state.filters,
    visibleDemos: state => state.visibleDemos,
  },
})

export const {
  actions: filmTrackingPageActions,
  selectors: filmTrackingPageSelectors,
} = filmTrackingPageSlice

export const useFilmTrackingPageActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(filmTrackingPageActions, dispatch),
    [dispatch],
  )
}
