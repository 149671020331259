import { useCallback, useState } from 'react'
import { PaginationChangeParams, PaginationToolbar } from 'components'
import { managementRowsPerPageOptions } from 'constnants'
import { MgmtLayout } from 'modules/management/shared'
import { useTvFilmCompanyControllerGetCompaniesQuery } from 'store/api'
import { TVFilmCompaniesTable, TVFilmCompaniesToolbar } from '../../components'
import type { TVFilmCompaniesTableTypes } from '../../components'
import { useSelector } from 'react-redux'
import { tvFilmCompaniesSelectors } from '../../slices'
import { useTVFilmCompaniesActions } from '../../hooks'

export const TVFilmCompaniesPage = () => {
  const actions = useTVFilmCompaniesActions()

  const { pageConfig, sorting, filters } = useSelector(
    tvFilmCompaniesSelectors.root,
  )

  const [searchTerm, setSearchTerm] = useState('')

  const handlePagination = useCallback(
    (params: PaginationChangeParams) =>
      actions.setPageConfig({
        pageNumber: params.page,
        pageSize: params.pageSize,
      }),
    [actions],
  )

  const handleSortChange: TVFilmCompaniesTableTypes['OnSortChange'] =
    useCallback(({ sortConfig }) => actions.setSorting(sortConfig), [actions])

  const { tvFilmCompanies, totalPages, totalRows, isFetching, isLoading } =
    useTvFilmCompanyControllerGetCompaniesQuery(
      {
        pageSize: pageConfig.pageSize,
        pageNumber: pageConfig.pageNumber,
        sortBy: sorting.by,
        sortOrder: sorting.order,
        companyName: searchTerm.length ? searchTerm : undefined,
        companyType:
          filters.companyType !== 'all' ? filters.companyType : undefined,
        distributorType:
          filters.distributorType !== 'all'
            ? filters.distributorType
            : undefined,
      },
      {
        selectFromResult: ({ data, ...rest }) => {
          return {
            ...rest,
            tvFilmCompanies: data?.list ?? [],
            totalPages: data?.totalPages ?? 0,
            totalRows: data?.totalRecords ?? 0,
          }
        },
      },
    )

  const paginationBar = (
    <PaginationToolbar
      totalPages={totalPages}
      totalRows={totalRows}
      currentPage={pageConfig.pageNumber}
      currentPageSize={pageConfig.pageSize}
      pageSizeOptions={managementRowsPerPageOptions}
      onChange={handlePagination}
    />
  )

  return (
    <MgmtLayout>
      <div className='flex h-full flex-col'>
        <TVFilmCompaniesToolbar onSearchChange={setSearchTerm} />
        <TVFilmCompaniesTable
          rows={tvFilmCompanies}
          footer={paginationBar}
          loading={isLoading}
          fetching={isFetching}
          sortConfig={sorting}
          onSortChange={handleSortChange}
        />
      </div>
    </MgmtLayout>
  )
}
