import { useNavigate } from 'react-router-dom'
import { OptionType, Urls } from 'types'
import { Close, SquareMinus } from 'components/ui/icons'
import { IconButton, Button, FilmTrackingLookupButton } from 'components'
import { Colors } from 'constnants'
import { useCallback } from 'react'

interface PageHeaderProps {
  filmIds: string[]
  selectedFilmIds: string[]
  onRemoveFromCompare: () => void
  onAddToCompare: (filmId: string) => void
}

export const PageHeader = ({
  filmIds,
  selectedFilmIds,
  onRemoveFromCompare,
  onAddToCompare,
}: PageHeaderProps) => {
  const navigate = useNavigate()

  const amountTalents = `Comparing ${filmIds.length} films`
  const isRemoveFromCompareDisabled = selectedFilmIds.length === 0

  const filterFilmOptions = useCallback(
    (film: OptionType<string>) => !filmIds.includes(film.id),
    [filmIds],
  )

  return (
    <div className='flex h-fit w-full justify-between bg-primary-white px-3 py-2'>
      <div className='flex items-center space-x-2'>
        <span className='block text-xs font-medium text-primary-black'>
          {amountTalents}
        </span>
        <FilmTrackingLookupButton
          label='Add Film to Compare'
          disabled={filmIds.length > 3}
          filter={filterFilmOptions}
          onSelectFilm={onAddToCompare}
        />
        <Button
          className='flex gap-1'
          kind='text'
          size='small'
          disabled={isRemoveFromCompareDisabled}
          onClick={onRemoveFromCompare}
        >
          <SquareMinus fill={Colors.BASE_ICON} />
          <span>Remove Film</span>
        </Button>
      </div>

      <IconButton
        className='rounded hover:bg-opacityGrey-10'
        onClick={() => navigate(Urls.FILM)}
      >
        <Close fill={Colors.BASE_ICON} width={16} height={16} />
      </IconButton>
    </div>
  )
}
