import { TalentManagementColKey } from '../../types'
import { type TalentManagementCol } from './types'

export const columns: TalentManagementCol[] = [
  {
    key: TalentManagementColKey.ImdbId,
    sortKey: TalentManagementColKey.ImdbId,
    title: 'IMDB ID',
    width: 100,
  },
  {
    key: TalentManagementColKey.FullName,
    sortKey: TalentManagementColKey.FullName,
    title: 'Full Name',
    width: 128,
  },
  {
    key: TalentManagementColKey.Dob,
    title: 'Date of Birth',
    width: 120,
  },
  {
    key: TalentManagementColKey.Race,
    title: 'Race',
    width: 100,
  },
  {
    key: TalentManagementColKey.Gender,
    sortKey: TalentManagementColKey.Gender,
    title: 'Gender',
    width: 100,
  },
  {
    key: TalentManagementColKey.Height,
    title: 'Height',
    width: 100,
  },
  {
    key: TalentManagementColKey.SimilarTalent1,
    title: 'Similar Talent 1',
    width: 128,
  },
  {
    key: TalentManagementColKey.SimilarTalent2,
    title: 'Similar Talent 2',
    width: 128,
  },
  {
    key: TalentManagementColKey.SimilarTalent3,
    title: 'Similar Talent 3',
    width: 128,
  },
  {
    key: TalentManagementColKey.SimilarTalent4,
    title: 'Similar Talent 4',
    width: 128,
  },
  {
    key: TalentManagementColKey.Bio,
    title: 'Bio',
    width: 264,
  },
  {
    key: TalentManagementColKey.Wikipedia,
    title: 'Wikipedia',
    width: 250,
  },
  {
    key: TalentManagementColKey.InstagramHandle,
    title: 'Instagram Handle',
    width: 200,
  },
  {
    key: TalentManagementColKey.InstagramUrl,
    title: 'Instagram URL',
    width: 250,
  },
  {
    key: TalentManagementColKey.XHandle,
    title: 'X Handle',
    width: 200,
  },
  {
    key: TalentManagementColKey.XUrl,
    title: 'X URL',
    width: 250,
  },
  {
    key: TalentManagementColKey.Hashtags,
    title: 'Hashtags',
    width: 200,
  },
  { key: TalentManagementColKey.Actions, width: 48 },
]
