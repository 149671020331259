import { routeDefs, type RouteType } from './routes'

type RouteGroup = {
  title?: string
  routes: RouteType[]
}

export const adminRouteGroups: RouteGroup[] = [
  {
    title: 'Clients Mgmt',
    routes: [
      routeDefs.companyMgmt,
      routeDefs.userMgmt,
      routeDefs.testingModules,
    ],
  },
  {
    title: 'DATA MGMT',
    routes: [
      routeDefs.tvFilmCompanies,
      routeDefs.tvMgmt,
      routeDefs.releaseCalendarMgmt,
      routeDefs.talentManagement,
    ],
  },
  {
    title: 'Greenlight Analytics MGMT',
    routes: [routeDefs.adminMgmt],
  },
]

export const userRouteGroups: RouteGroup[] = [
  {
    routes: [routeDefs.home, routeDefs.myProjects],
  },
  {
    routes: [
      routeDefs.talent,
      routeDefs.compFinder,
      routeDefs.releaseCalendar,
      routeDefs.filmTracking,
    ],
  },
  {
    routes: [
      routeDefs.conceptTesting,
      routeDefs.roleTesting,
      routeDefs.assetTesting,
    ],
  },
  {
    routes: [routeDefs.settings],
  },
]
