import {
  formatLastLoginDate,
  getAdminMgmtUserLoginStatus,
} from 'modules/management/users/utils'
import { adminMgmtUserStatusTypeLabelMapping } from '../../config'
import { AdminMgmtResolvedStatusIndicator } from '../AdminMgmtResolvedStatusIndicator'

interface UserLastLoginStatusProps {
  status: 'active' | 'paused'
  lastLoginDate: string | null
  lastLogoutDate: string | null
}

export const AdminLastLoginStatus = ({
  status,
  lastLoginDate,
  lastLogoutDate,
}: UserLastLoginStatusProps) => {
  const loginStatus = getAdminMgmtUserLoginStatus(
    status,
    lastLoginDate,
    lastLogoutDate,
  )

  const renderStatus = () => {
    if (loginStatus !== 'active') {
      return (
        <>
          <AdminMgmtResolvedStatusIndicator status={loginStatus} />
          {adminMgmtUserStatusTypeLabelMapping[loginStatus]}
        </>
      )
    }

    return lastLoginDate ? formatLastLoginDate(lastLoginDate) : '--'
  }

  return (
    <div className='flex items-baseline gap-1 text-xs text-primary-black'>
      {renderStatus()}
    </div>
  )
}
