import { useMemo } from 'react'
import { routes } from '../constnants'
import { useLocation } from 'react-router-dom'

export const useAdminData = () => {
  const { pathname } = useLocation()

  const isAdminPath = useMemo(
    () =>
      routes.some(
        route =>
          route.isAdminNavigation &&
          pathname.startsWith(route.path.split('/:')[0]),
      ),
    [pathname],
  )

  return { isAdminPath }
}
