import { useNavigate } from 'react-router-dom'

export const PasswordResetEmailSent = () => {
  const navigate = useNavigate()

  const handleResetPasswordClick = () => {
    navigate('/reset-password')
  }

  return (
    <div className='flex h-screen items-center justify-center'>
      <div className='flex w-full max-w-md flex-col items-center justify-center gap-2 rounded-lg bg-white p-5 text-center'>
        <h2 className='text-2xl font-semibold text-primary-black'>
          Password reset email sent
        </h2>
        <p className='text-sm text-primary-grey'>
          If you don’t see an email from us in your inbox, be sure to check your
          Spam Folder.
        </p>
        <button
          onClick={handleResetPasswordClick}
          className='text-sm text-primary-red'
        >
          Go to password reset page
        </button>
        <a href='/login' className='text-sm text-primary-red'>
          Go back to log in
        </a>
      </div>
    </div>
  )
}
