import { Input, LayoutContentWrapper, Select } from 'components'
import { usePageTitle } from '../../hooks'
import { CheckCircle, Plus, Search } from '../../components/ui/icons'
import { Colors } from '../../constnants'
import { CompanyModal } from '../../components'
import React, { useEffect, useState } from 'react'
import { CompanyTable } from '../../components/CompanyManagementTable'
import {
  companiesColumns,
  statusFilterOptions,
  MIN_PAGE_SIZE,
} from '../../constnants'
import { useGetAdminCompaniesQuery } from '../../store/api'
import {
  CompaniesSortByOptions,
  CompaniesSortOrderOptions,
  CompaniesStatusFilterOptions,
  CompanyItem,
} from '../../types'
import { formatDate } from '../../utils'

const initialActiveFilters = {
  companyStatus: '',
  search: '',
}

export const CompanyManagementPage = () => {
  const pageTitle = usePageTitle()
  const [activeFilters, setActiveFilters] = useState(initialActiveFilters)
  const [sortingBY, setSortingBY] =
    useState<CompaniesSortByOptions>('created_at')
  const [sortingOrder, setSortingOrder] =
    useState<CompaniesSortOrderOptions>('ASC')
  const [totalCompaniesCount, setTotalCompaniesCount] = useState(6)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(MIN_PAGE_SIZE)
  const [filteredCompanies, setFilteredCompanies] = useState<CompanyItem[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null,
  )
  const [createCompanyModalVisible, setCreateCompanyModalVisible] =
    useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const {
    data: companiesListResponse,
    error,
    isLoading,
  } = useGetAdminCompaniesQuery({
    pageSize: rowsPerPage,
    pageNumber: currentPage,
    sortBy: sortingBY,
    sortOrder: sortingOrder,
    statusFilter: activeFilters.companyStatus as CompaniesStatusFilterOptions,
    companyName: activeFilters.search || '',
  })

  if (error) {
    if (error.data.message === 'Unauthorized') {
      localStorage.removeItem('access_token')
      localStorage.setItem('auth', 'false')
      window.location.assign('/login')
    } else {
      console.error('error', error) //eslint-disable-line
    }
  }

  useEffect(() => {
    if (companiesListResponse) {
      setTotalCompaniesCount(companiesListResponse.total)
      setFilteredCompanies(
        companiesListResponse.companies.map(company => {
          const createdDate: Date = new Date(company.created_at)
          const updatedDate: Date = new Date(company.updated_at)
          return {
            ...company,
            created_at: formatDate(createdDate, 'MM/DD/YY'),
            updated_at: formatDate(updatedDate, 'MM/DD/YY'),
          }
        }),
      )
    }
  }, [companiesListResponse, activeFilters, currentPage, rowsPerPage])

  const clearStatuses = () => {
    setShowSuccessMessage(true)
    setCreateCompanyModalVisible(false)
    setTimeout(() => {
      setShowSuccessMessage(false)
      setSuccessMessage('')
    }, 3000)
  }

  const handleCreateCompany = () => {
    setSuccessMessage('New Company Invited')
    clearStatuses()
    setCreateCompanyModalVisible(false)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }
    const newTimeout = setTimeout(() => {
      setActiveFilters({ ...activeFilters, search: e.target.value })
    }, 500)
    setTypingTimeout(newTimeout)
  }

  return (
    <LayoutContentWrapper
      wrapperClassName='bg-primary-white'
      wrapperChildrenClassName='h-full'
    >
      <div className='p-3'>
        <div className='flex items-center justify-between'>
          <h1 className='text-2xl font-semibold text-primary-black'>
            {pageTitle}
          </h1>
          <div className='flex items-center gap-4'>
            <Select
              value={activeFilters['companyStatus']}
              handleClick={option =>
                setActiveFilters({
                  ...activeFilters,
                  companyStatus:
                    option.value !== 'all'
                      ? (option.value as CompaniesStatusFilterOptions)
                      : '',
                })
              }
              options={statusFilterOptions}
              size='small'
              placeholder={'All'}
              defaultValue={'all'}
              alignItems='start'
              buttonClass={'whitespace-nowrap'}
              hasRadioButton={false}
            />
            <Input
              containerWrapperClassName='w-[200px] h-6'
              placeholder='Search Name'
              value={searchValue}
              onChange={handleSearchChange}
              leftIcon={
                <Search fill={Colors.NAV_ICON_THREE} width={20} height={20} />
              }
            />
            <button
              className='inline-flex items-center gap-1 rounded-full bg-primary-red px-4 py-1.5 text-sm font-semibold text-primary-white transition-colors hover:bg-red-9'
              onClick={() => {
                setCreateCompanyModalVisible(true)
              }}
            >
              Create new company
              <Plus fill={Colors.ICON_WHITE} width={20} height={20} />
            </button>
          </div>
        </div>
        <div className='-mx-3 mt-3 border-b border-b-primary-black/10'></div>
        <CompanyTable
          columns={companiesColumns}
          data={filteredCompanies}
          onSort={setSortingOrder}
          onSortOrder={setSortingBY}
          sortingBY={sortingBY}
          totalCompaniesCount={totalCompaniesCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          isLoading={isLoading}
        />
        {createCompanyModalVisible && (
          <CompanyModal
            title={'Add new company'}
            handleClose={() => setCreateCompanyModalVisible(false)}
            isOpen={createCompanyModalVisible}
            onSubmit={() => {
              handleCreateCompany()
            }}
            saveTitle={'Add new client'}
            requestType={'POST'}
          />
        )}
        {showSuccessMessage && (
          <div className='fixed bottom-12 right-4 rounded-md bg-primary-white p-3 shadow-md'>
            <p className='flex items-center gap-3 text-sm font-semibold text-primary-black'>
              <CheckCircle fill={Colors.SUCCESS} width={19} height={19} />
              {successMessage}
            </p>
          </div>
        )}
      </div>
    </LayoutContentWrapper>
  )
}
