import { api } from './api'
import {
  UserPermission,
  UsersManagementList,
  UsersManagementListQuery,
} from 'types'
import { prepareUrlParams } from '../../utils'

export const myManagementUserApi = api.injectEndpoints({
  endpoints: builder => ({
    getManagementUsersList: builder.query<
      UsersManagementList,
      UsersManagementListQuery
    >({
      query: queryParams => {
        const params = prepareUrlParams(queryParams)
        return {
          url: `/ga-admin/user${params}`,
          method: 'GET',
        }
      },
      providesTags: ['MyManagementUser'],
    }),
    getManagementUser: builder.query<UserPermission, { userID: string }>({
      query: ({ userID }) => ({
        url: `/ga-admin/user/${userID}`,
        method: 'GET',
      }),
      providesTags: ['MyManagementUser'],
    }),
    updatedManagementUser: builder.mutation<
      {
        name: string
        firstName: string
        lastName: string
        role: string
        phone: number
        userType: string
        accessLevel: string
        status: string
        lastLoginDate: string
        moduleAccessIds: number[]
      },
      {
        id: string
        userData: {
          name?: string
          firstName?: string
          lastName?: string
          email?: string
          userType?: string
          accessLevel?: string
          status?: string
          jobTitle?: string
          companyId?: string
          moduleAccessIds?: number[]
        }
      }
    >({
      query: updateUser => ({
        body: { ...updateUser.userData },
        url: `/ga-admin/user/${updateUser.id}`,
        method: 'PUT',
      }),
      invalidatesTags: [{ type: 'MyManagementUser' }],
    }),
    inviteManagementUser: builder.mutation<
      UserPermission,
      {
        firstName: string
        lastName: string
        name: string
        email: string
        userType: string
        accessLevel: string
        jobTitle?: string
        moduleAccessIds: number[]
        companyId: string
      }
    >({
      query: ({
        firstName,
        lastName,
        name,
        email,
        userType,
        accessLevel,
        jobTitle,
        moduleAccessIds,
        companyId,
      }) => ({
        body: {
          firstName,
          lastName,
          name,
          email,
          userType,
          accessLevel,
          jobTitle,
          moduleAccessIds,
          companyId,
        },
        url: `/ga-admin/user/invite`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'MyManagementUser' }],
    }),
    resetManagementUserPassword: builder.mutation<void, { userID: string }>({
      query: ({ userID }) => ({
        url: `/ga-admin/user/${userID}/reset-password`,
        method: 'PUT',
      }),
    }),
    resendManagementUserInvite: builder.mutation<void, { userID: string }>({
      query: ({ userID }) => ({
        url: `/ga-admin/user/${userID}/resend-invite`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useGetManagementUsersListQuery,
  useGetManagementUserQuery,
  useUpdatedManagementUserMutation,
  useInviteManagementUserMutation,
  useResetManagementUserPasswordMutation,
  useResendManagementUserInviteMutation,
} = myManagementUserApi
