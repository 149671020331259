export type TVManagementData = {
  id: string
  imdbId: string
  title: string
  date: string
  networkType: TVNetworkType
  network: TVNetwork
  networkImdbId?: string | null | undefined
  tvType: TVType
  tvSubtype: TVSubtype | null
  genre: TVGenre | null
}

export enum TVNetworkType {
  TVCable = 'tVCable',
  TVStreamer = 'tVStreamer',
  FilmDistributor = 'filmDistributor',
  TvBroadcast = 'tvBroadcast',
}

export enum TVNetwork {
  ApplePlus = 'apple+',
  Cinemax = 'ainemax',
  DisneyPlus = 'aisney+',
  Max = 'max',
  Netflix = 'netflix',
}

export enum TVType {
  Series = 'Series',
  Film = 'Film',
  LiveEventsSpecials = 'LiveEventsSpecials',
}

export enum TVSubtype {
  StreamingOriginal = 'StreamingOriginal',
  StreamingPremiere = 'StreamingPremiere',
  SeriesPremiere = 'SeriesPremiere',
  SeasonPremiere = 'SeasonPremiere',
}

export enum TVGenre {
  Comedy = 'comedy',
  Drama = 'drama',
  Action = 'action',
  Animation = 'animation',
  FamilyLiveAction = 'familyLiveAction',
  Horror = 'horror',
  Cooking = 'cooking',
  Documentary = 'documentary',
  GameShow = 'gameShow',
  HomeDIY = 'homeDIY',
  LimitedAnthologySeries = 'limitedAnthologySeries',
  RealityCompetition = 'realityCompetition',
  RealityDocuseries = 'realityDocuseries',
  ScriptedVariety = 'scriptedVariety',
  Sports = 'sports',
  TalkSeries = 'talkSeries',
}

export enum TVManagementColKey {
  EditEntry = 'editEntry',
  ImdbId = 'imdbId',
  Title = 'title',
  Date = 'date',
  NetworkType = 'networkType',
  Network = 'network',
  NetworkImdbId = 'networkImdbId',
  TvType = 'tvType',
  TvSubtype = 'tvSubtype',
  Genre = 'genre',
}

export type TVManagementSortColKey =
  | TVManagementColKey.Title
  | TVManagementColKey.ImdbId
  | TVManagementColKey.Network
