import { ReleaseWeek } from '../../../types'
import dayjs from 'dayjs'

interface MonthAheadProps {
  months: string[]
  weeks: ReleaseWeek[] | null
}

interface Week {
  title: string
  value: string
  startDate: string
  endDate: string
}

export const MonthAhead = ({ months, weeks }: MonthAheadProps) => {
  const calculateDaysInEachMonth = (
    weeks: Week[] | null,
  ): Record<string, number> => {
    const daysInMonth: Record<string, number> = {}
    if (!weeks) return daysInMonth
    weeks.forEach(week => {
      const start = dayjs(week.startDate)
      const end = dayjs(week.endDate)

      let current = start
      while (current.isBefore(end) || current.isSame(end, 'day')) {
        const monthName = current.format('MMMM')
        if (!daysInMonth[monthName]) {
          daysInMonth[monthName] = 0
        }
        daysInMonth[monthName] += 1
        current = current.add(1, 'day')
      }
    })

    return daysInMonth
  }

  const daysInEachMonth = calculateDaysInEachMonth(weeks)

  return (
    <div className='flex w-full p-2 text-start text-xs font-medium text-grey-7'>
      {months.map((month, i) => (
        <span
          key={`${month}_${i + 1}`}
          className='min-w-min pr-2 [&:not(:first-child)]:border-l [&:not(:first-child)]:pl-2'
          style={{
            width: `${daysInEachMonth[month] * 18 - 8}px`,
          }}
        >
          {month}
        </span>
      ))}
    </div>
  )
}
