import { IconTab } from './IconTab'
import { twMerge } from 'tailwind-merge'

export type IconTabsKind = 'base' | 'outline'

type IconTabsProps<T> = {
  onChange: (value: IconTab<T>) => void
  selectedTab?: IconTab<T> | undefined
  kind: IconTabsKind
  tabs: IconTab<T>[]
  classList?: string
  size?: 'small' | 'medium'
}

export const IconTabs = <T extends string>({
  onChange,
  selectedTab,
  tabs,
  kind,
  classList,
  size = 'medium',
}: IconTabsProps<T>) => {
  return (
    <div
      className={twMerge(
        'flex w-fit flex-row overflow-hidden',
        kind === 'base'
          ? 'space-x-0.5'
          : 'rounded border border-primary-black/10 ',
        classList,
      )}
    >
      {tabs.map(tab => (
        <IconTab
          key={tab.value}
          size={size}
          onChange={() => onChange(tab)}
          icon={tab.icon}
          label={tab.label}
          value={tab.value ?? ''}
          selectedTab={selectedTab}
          kind={kind}
        />
      ))}
    </div>
  )
}
