import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
} from 'react-hook-form'
import { Checkbox, Input } from 'components'
import { MgmtEntryForm } from 'modules/management/shared'

import {
  releaseCalendarEventFormResolver,
  type ReleaseCalendarEventFormData,
} from './releaseCalendarEventFormSchema'

import { formatDate } from 'utils'
import { ReleaseCalendarDateInput } from '../ReleaseCalendarDateInput'
import { ChangeEvent, useMemo } from 'react'
import dayjs from 'dayjs'

interface ReleaseCalendarEventFormProps {
  submitButtonLabel: string
  cancelButtonLabel?: string
  defaultValues?: ReleaseCalendarEventFormData
  onSubmit: (data: ReleaseCalendarEventFormData) => void
  onClose: () => void
}

const formatEventDate = (date: Date) => formatDate(date, 'MM/DD/YYYY')
const parseEventDate = (value: string) =>
  dayjs(value, 'MM/DD/YYYY', true).toDate()

export const ReleaseCalendarEventForm = ({
  submitButtonLabel,
  cancelButtonLabel,
  defaultValues,
  onSubmit,
  onClose,
}: ReleaseCalendarEventFormProps) => {
  const defaultValuesResolved = useMemo(() => {
    if (!defaultValues) {
      return undefined
    }

    const startDate = formatEventDate(new Date(defaultValues.startDate))
    const endDate = defaultValues.endDate
      ? formatEventDate(new Date(defaultValues.endDate))
      : undefined

    return {
      ...defaultValues,
      startDate,
      endDate,
      isOneDayEvent: startDate === endDate,
      isEndDateUnknown: !endDate,
    }
  }, [defaultValues])

  const form = useForm<ReleaseCalendarEventFormData>({
    resolver: releaseCalendarEventFormResolver,
    defaultValues: defaultValuesResolved,
  })

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = form

  const [isOneDayEvent, isEndDateUnknown, startDate] = watch([
    'isOneDayEvent',
    'isEndDateUnknown',
    'startDate',
  ])
  const renderControlledInput = (
    name: Extract<keyof ReleaseCalendarEventFormData, 'title'>,
    label: string,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? undefined}
          inputClassName='h-8 px-3'
          labelClassName='text-sm font-semibold text-primary-black'
          label={label}
          error={errors[name]?.message}
          isError={!!errors[name]}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )

  const renderControlledDateInput = (
    name: Extract<keyof ReleaseCalendarEventFormData, 'startDate' | 'endDate'>,
    label: string,
    disabled = false,
    onChange: (
      filed: ControllerRenderProps<ReleaseCalendarEventFormData, typeof name>,
      value: string | undefined,
    ) => void = (field, value) => field.onChange(value),
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            <ReleaseCalendarDateInput
              {...field}
              label={label}
              placeholder='mm/dd/yyyy'
              formatDate={formatEventDate}
              parseDate={parseEventDate}
              error={errors[name]?.message}
              isError={!!errors[name]}
              disabled={disabled}
              onChange={value => onChange(field, value)}
            />
          </>
        )
      }}
    />
  )
  const renderControlledCheckbox = (
    name: Extract<
      keyof ReleaseCalendarEventFormData,
      'isOneDayEvent' | 'isEndDateUnknown'
    >,
    label: string,
    disabled = false,
    onChange: (
      filed: ControllerRenderProps<ReleaseCalendarEventFormData, typeof name>,
      event: ChangeEvent<HTMLInputElement>,
    ) => void,
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { value, ...rest } = field
        return (
          <Checkbox
            {...rest}
            kind='medium'
            label={label}
            checked={value}
            disabled={disabled}
            onChange={event => onChange(field, event)}
          />
        )
      }}
    />
  )

  return (
    <FormProvider {...form}>
      <MgmtEntryForm
        submitButtonLabel={submitButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        onSubmit={handleSubmit(onSubmit)}
        onClose={onClose}
      >
        {renderControlledInput('title', 'Event Title')}
        {renderControlledDateInput(
          'startDate',
          'Start Date',
          false,
          (field, value) => {
            field.onChange(value)
            if (isOneDayEvent) {
              setValue('endDate', value)
            }
          },
        )}
        {renderControlledCheckbox(
          'isOneDayEvent',
          'This is a one-day event',
          false,
          (field, event) => {
            field.onChange(event)
            if (event.target.checked) {
              setValue('isEndDateUnknown', false)
            }
            setValue('endDate', event.target.checked ? startDate : null)
            trigger('endDate')
          },
        )}
        {renderControlledDateInput(
          'endDate',
          'End Date',
          isOneDayEvent || isEndDateUnknown,
        )}
        {renderControlledCheckbox(
          'isEndDateUnknown',
          'I don’t know what the end date is.',
          isOneDayEvent,
          (field, event) => {
            field.onChange(event)
            setValue('endDate', null)
            trigger('endDate')
          },
        )}
      </MgmtEntryForm>
    </FormProvider>
  )
}
