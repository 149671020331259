import { Calendar, Input } from 'components'
import { Colors } from 'constnants'
import { isValid } from 'date-fns'
import { useClickOutside, useToggle } from 'hooks'
import { ForwardedRef, forwardRef, useMemo, useRef } from 'react'
import { DayPicker } from 'react-day-picker'

interface DateInputProps {
  value: string | null | undefined
  name?: string
  label?: string
  placeholder?: string
  error?: string
  isError?: boolean
  disabled?: boolean
  formatDate: (date: Date) => string
  parseDate: (value: string) => Date
  onChange: (value: string | undefined) => void
}

const ReleaseCalendarDateInputInner = (
  {
    value,
    name,
    label,
    placeholder,
    error,
    isError,
    disabled,
    parseDate,
    formatDate,
    onChange,
  }: DateInputProps,
  inputRef: ForwardedRef<HTMLInputElement>,
) => {
  const popoverRef = useRef<HTMLDivElement>(null)
  const [dayPickerState, dayPickerStateControls] = useToggle()
  const date = useMemo(() => {
    if (!value) {
      return undefined
    }
    const date = parseDate(value)
    return isValid(date) ? date : undefined
  }, [value, parseDate])

  useClickOutside({
    ref: popoverRef,
    onClose: dayPickerStateControls.deactivate,
  })

  return (
    <div className='relative'>
      <Input
        ref={inputRef}
        name={name}
        value={value ?? ''}
        type='text'
        label={label}
        placeholder={placeholder}
        error={error}
        isError={isError}
        disabled={disabled}
        inputClassName='h-8 px-3'
        labelClassName='text-sm font-semibold text-primary-black'
        rightIcon={
          <Calendar
            fill={Colors.BASE_ICON}
            className='pr-1'
            onClick={disabled ? undefined : dayPickerStateControls.activate}
          />
        }
        onChange={e => onChange(e.target.value)}
      />
      {dayPickerState.active && (
        <div
          className='absolute top-[70px] z-50 flex w-[230px] items-center justify-center rounded border border-primary-black bg-primary-white p-3 shadow-lg'
          ref={popoverRef}
        >
          <DayPicker
            mode='single'
            captionLayout='dropdown'
            showOutsideDays
            selected={date}
            defaultMonth={date}
            onSelect={date => {
              onChange(date ? formatDate(date) : undefined)
              dayPickerStateControls.deactivate()
            }}
          />
        </div>
      )}
    </div>
  )
}

export const ReleaseCalendarDateInput = forwardRef(
  ReleaseCalendarDateInputInner,
)
