import { AdminMgmtUser } from '../../types'
import { commonToastStyles } from 'utils'
import {
  AdminMgmtUserEditFormData,
  adminMgmtUserEditFormResolver,
} from './adminMgmtUserEditFormSchema'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, Select, Success } from 'components'
import { useGaAdminProfileControllerUpdateAdminMutation } from 'store/api'
import { useCallback, useMemo } from 'react'
import { OptionType } from 'types'
import { toast } from 'react-toastify'
import { AdminMgmtUserDetailsRow } from '../AdminMgmtUserDetailsRow'
import {
  formatJoinedDate,
  formatLastLoginDate,
  getAdminMgmtUserAccountStatus,
} from '../../utils'
import { adminMgmtUserStatusOptions } from '../../config'
import { AdminMgmtAccountStatusRow } from '../AdminMgmtAccountStatusRow'

interface AdminMgmtUserEditProps {
  user: AdminMgmtUser
  onClose: () => void
  onSubmitted: (user: AdminMgmtUser) => void
}

export const AdminMgmtUserEdit = ({
  user,
  onClose,
  onSubmitted,
}: AdminMgmtUserEditProps) => {
  const defaultValues = useMemo(
    () => ({
      name: user.name ?? '',
      email: user.email ?? '',
      status: user.status,
    }),
    [user],
  )
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AdminMgmtUserEditFormData>({
    resolver: adminMgmtUserEditFormResolver,
    defaultValues,
  })

  const [updateAdminUser] = useGaAdminProfileControllerUpdateAdminMutation()

  const handleUpdateAdminUser = useCallback(
    async (data: AdminMgmtUserEditFormData) => {
      try {
        const userUpdated = await updateAdminUser({
          adminId: user.id,
          updateGaAdminDto: {
            fullName: data.name,
            email: data.email,
            status: data.status,
          },
        }).unwrap()

        onSubmitted(userUpdated)

        onClose()

        toast.success('User Details Updated', {
          position: 'bottom-right',
          icon: <Success width={24} height={24} />,
          ...commonToastStyles,
        })
      } catch (error) {
        console.error(error)
      }
    },
    [updateAdminUser],
  )

  const renderControlledInput = (name: keyof AdminMgmtUserEditFormData) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          value={field.value ?? undefined}
          inputClassName='pl-3 text-md py-2'
          labelClassName='text-sm font-semibold text-primary-black'
          containerWrapperClassName='pr-5'
          wrapperClassName='w-full h-8 rounded-md'
          error={errors[name]?.message}
          isError={!!errors[name]}
          onChange={e => field.onChange(e)}
        />
      )}
    />
  )

  const renderControlledSelect = (
    name: keyof AdminMgmtUserEditFormData,
    options: OptionType[],
  ) => (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          value={field.value ?? undefined}
          size='small'
          alignItems='start'
          errorClass='pt-1.5 text-xs'
          buttonClass='h-8 items-center whitespace-nowrap'
          containerClass='w-full mr-5'
          hasRadioButton={false}
          isError={!!errors[name]}
          error={errors[name]?.message}
          options={options}
          handleClick={option => field.onChange(option.value)}
        />
      )}
    />
  )

  const accountStatus = useMemo(
    () =>
      getAdminMgmtUserAccountStatus(
        user.status,
        user.last_login_date,
        user.last_logout_date,
      ),
    [user],
  )

  return (
    <form
      className='flex-1 overflow-auto pb-4'
      onSubmit={handleSubmit(handleUpdateAdminUser)}
    >
      <div className='mx-auto w-[800px] max-w-[90%]'>
        <div className='mb-3 flex items-center justify-between'>
          <p className={'text-sm font-medium text-primary-black'}>
            User Details
          </p>
          <div className='flex items-center gap-2'>
            <Button type='button' size='small' kind='text' onClick={onClose}>
              Cancel
            </Button>
            <Button
              type='submit'
              size='small'
              kind='filled'
              className='bg-primary-red hover:bg-red-9 active:bg-primary-red/50'
              loading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>

        <div className='mb-[44px] rounded border border-primary-black/20'>
          <AdminMgmtUserDetailsRow label='Name' isEditable>
            {renderControlledInput('name')}
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='Email' isEditable>
            {renderControlledInput('email')}
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='User Type'>
            GL Super Admin
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='Date Joined'>
            {formatJoinedDate(user.created_at)}
          </AdminMgmtUserDetailsRow>

          <AdminMgmtUserDetailsRow label='Last Log In' hasBorder={false}>
            {formatLastLoginDate(user.last_login_date) ?? '--'}
          </AdminMgmtUserDetailsRow>

          {accountStatus === 'pending' ? (
            <AdminMgmtAccountStatusRow
              user={user}
              accountStatus={accountStatus}
            />
          ) : (
            <AdminMgmtUserDetailsRow label='Account Status' isEditable>
              {renderControlledSelect('status', adminMgmtUserStatusOptions)}
            </AdminMgmtUserDetailsRow>
          )}
        </div>
      </div>
    </form>
  )
}
